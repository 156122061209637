import * as actions from "./actionTypes";

export const getClients = () => ({
  type: actions.GET_CLIENTS_REQUEST,
});

export const createClient = (data) => ({
  type: actions.CREATE_CLIENT_REQUEST,
  payload: data,
});

export const updateClient = (data) => ({
  type: actions.UPDATE_CLIENT_REQUEST,
  payload: data,
});

export const deleteClient = (data) => ({
  type: actions.DELETE_CLIENT_REQUEST,
  payload: data,
});

export const createContact = (data) => ({
  type: actions.CREATE_CONTACT_REQUEST,
  payload: data,
});
