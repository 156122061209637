import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/inventory/type`;

export const getTypes = async (token) => {
  return axios({
    method: "GET",
    url: `${apiUrl}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createType = async (token, typeDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}`,
    headers: {
      "x-access-token": token,
    },
    data: typeDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const updateType = async (token, typeDetails) => {
  return axios({
    method: "PUT",
    url: `${apiUrl}/${typeDetails.typeId}`,
    headers: {
      "x-access-token": token,
    },
    data: typeDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
