import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_INVENTORY_TYPES_REQUEST,
  GET_INVENTORY_TYPES_SUCCESS,
  GET_INVENTORY_TYPES_FAILURE,
  CREATE_INVENTORY_TYPE_REQUEST,
  CREATE_INVENTORY_TYPE_SUCCESS,
  CREATE_INVENTORY_TYPE_FAILURE,
  UPDATE_INVENTORY_TYPE_REQUEST,
  UPDATE_INVENTORY_TYPE_SUCCESS,
  UPDATE_INVENTORY_TYPE_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";
import { toast } from "react-toastify";
import _ from "lodash";

export function* typeSaga() {
  yield takeLatest(GET_INVENTORY_TYPES_REQUEST, getTypes);
  yield takeLatest(CREATE_INVENTORY_TYPE_REQUEST, createType);
  yield takeLatest(UPDATE_INVENTORY_TYPE_REQUEST, updateType);
}

function* updateType(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const type = yield call(
      apis.inventoryApis.updateType,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (type.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: UPDATE_INVENTORY_TYPE_FAILURE,
        error: type.body.data.error,
      });
      if (_.isString(type.body.data.error)) {
        errorMessage = type.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({ type: UPDATE_INVENTORY_TYPE_SUCCESS, data: type.body.data });
      const data = {
        offset: 0,
      };
      yield put({ type: GET_INVENTORY_TYPES_REQUEST });
      toast.success("Type updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getTypes() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const types = yield call(
      apis.inventoryApis.getTypes,
      loggedUser.userDetails.auth.accessToken
    );
    if (types.error) {
      yield put({
        type: GET_INVENTORY_TYPES_FAILURE,
        error: types.body.data.error,
      });
    } else {
      yield put({
        type: GET_INVENTORY_TYPES_SUCCESS,
        data: types.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createType(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const type = yield call(
      apis.inventoryApis.createType,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (type.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: CREATE_INVENTORY_TYPE_FAILURE,
        error: type.body.data.error,
      });
      if (_.isString(type.body.data.error)) {
        errorMessage = type.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({ type: CREATE_INVENTORY_TYPE_SUCCESS, data: type.body.data });
      const data = {
        offset: 0,
      };
      yield put({ type: GET_INVENTORY_TYPES_REQUEST });
      toast.success("Type created successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
