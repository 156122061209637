import React from "react";
import BEMhelper from "react-bem-helper";

const Card = (props) => {
  const classes = new BEMhelper("card");
  return (
    <div {...classes("statCard")}>
      <div className="header">
        <div className="leftboxs">
          <h3>{props.title}</h3>
          {/* <div className="balance">
            <p>Balance 32 %</p>
          </div> */}
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="leavecontent">{props.body}</div>
    </div>
  );
};

export default Card;
