import { createUser, updateUser } from "./user";
import { getDashboardData } from "./dashboard";
import { featureAccessRole, createDesignation } from "./common";

const adminApis = {
  createUser,
  getDashboardData,
  updateUser,
  featureAccessRole,
  createDesignation,
};

export default adminApis;
