import React, { Component } from "react";
// import './App.css';
import Routes from "../../../routes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import userModule from "../../../redux/user";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class App extends Component {
  render() {
    const appActions = {
      logout: this.props.logout,
    };
    return (
      <div className="App">
        <Routes appState={this.props.appState} appActions={appActions} />
        <ToastContainer />
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: userModule.actions.logout,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    appState: state,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(App);
