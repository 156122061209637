import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAILURE,
  CREATE_TASK_REQUEST,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAILURE,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
  UPDATE_TASK_REQUEST,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";

export function* taskSaga() {
  yield takeLatest(GET_TASKS_REQUEST, getTasks);
  yield takeLatest(CREATE_TASK_REQUEST, createTask);
  yield takeLatest(UPDATE_TASK_REQUEST, updateTask);
  yield takeLatest(DELETE_TASK_REQUEST, deleteTask);
}

function* getTasks() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const tasks = yield call(
      apis.timeTrackApis.getTasks,
      loggedUser.userDetails.auth.accessToken
    );
    if (tasks.error) {
      yield put({
        type: GET_TASKS_FAILURE,
        error: tasks.body.data.error,
      });
    } else {
      yield put({ type: GET_TASKS_SUCCESS, data: tasks.body.data });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createTask(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const task = yield call(
      apis.timeTrackApis.createTask,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (task.error) {
      yield put({
        type: CREATE_TASK_FAILURE,
        error: task.body.data.error,
      });
    } else {
      yield put({ type: CREATE_TASK_SUCCESS, data: task.body.data });
      yield put({ type: GET_TASKS_REQUEST });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* updateTask(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const task = yield call(
      apis.timeTrackApis.updateTask,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (task.error) {
      yield put({
        type: UPDATE_TASK_FAILURE,
        error: task.body.data.error,
      });
    } else {
      yield put({ type: UPDATE_TASK_SUCCESS, data: task.body.data });
      const data = {
        date: details.payload.formValues.date,
      };
      yield put({ type: GET_TASKS_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* deleteTask(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const task = yield call(
      apis.timeTrackApis.deleteTask,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues.taskId
    );
    if (task.error) {
      yield put({
        type: DELETE_TASK_FAILURE,
        error: task.body.data.error,
      });
    } else {
      yield put({ type: DELETE_TASK_SUCCESS, data: task.body.data });
      const data = {
        date: details.payload.formValues.date,
      };
      yield put({ type: GET_TASKS_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
