import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/hrm/vacancy`;

export const getVacancies = async (token) => {
  return axios({
    method: "GET",
    url: `${apiUrl}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createVacancy = async (token, vacancyDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}`,
    headers: {
      "x-access-token": token,
      "'content-type'": "multipart/form-data",
    },
    data: vacancyDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const updateVacancy = async (token, vacancyDetails, vacancyId) => {
  return axios({
    method: "PUT",
    url: `${apiUrl}/${vacancyId}`,
    headers: {
      "x-access-token": token,
      "'content-type'": "multipart/form-data",
    },
    data: vacancyDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getVacancy = async (vacancyId) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/${vacancyId}`,
    headers: {
      // "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
