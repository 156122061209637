import React, { Component } from "react";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import BtnNormal from "../../components/button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import DatePicker from "reactstrap-date-picker";
class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      joindate: new Date().toISOString(),
      validate: {
        email: "",
        firstname: "",
        lastname: "",
        joinDate: "",
        password: "",
      },
    };
  }

  validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state;
    if (emailRex.test(e.target.value)) {
      validate.emailState = "has-success";
    } else {
      validate.emailState = "has-danger";
    }
    this.setState({ validate });
  }
  handleChangeDate = (value, formattedValue) => {
    this.setState({
      joindate: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      formattedValue: formattedValue, // Formatted String, ex: "11/19/2016"
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const { name } = target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    await this.setState({
      [name]: value,
    });
  };
  submitForm = (e) => {
    e.preventDefault();
    const details = {
      email: this.state.email,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      joinDate: this.state.joindate,
      password: this.state.password,
    };
  };
  render() {
    const {
      firstname,
      joindate,
      lastname,
      email,
      password,
      rePassword,
    } = this.state;
    return (
      <Container fluid className="signup">
        <Row>
          <Container>
            <Row>
              <Col
                sm={12}
                md={{ size: 6, offset: 4 }}
                className="signup-form text-center"
              >
                <div className="wrapper">
                  <div className="title">
                    <h1>Signup - HRSI</h1>
                  </div>
                  <Form onSubmit={(e) => this.submitForm(e)}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="firstname"
                        id="examplefirstname"
                        placeholder="Firstname"
                        value={firstname}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="text"
                        name="lastname"
                        id="examplelastname"
                        placeholder="Lastname"
                        value={lastname}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                    <FormGroup>
                      {/* <DatePicker
                        id="example-datepicker"
                        value={joindate}
                        onChange={(v, f) => this.handleChangeDate(v, f)}
                      /> */}
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="email"
                        name="email"
                        id="exampleemail"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="password"
                        name="password"
                        id="examplePassword"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="password"
                        name="repassword"
                        id="exampleRePassword"
                        placeholder="Re-Enter Password"
                        value={rePassword}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </FormGroup>
                    <BtnNormal text="signup" />
                  </Form>
                  <div className="button"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // loginUser: userModule.actions.loginUser
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    // loggedUser: state.loggedUser
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(Signup);
