import {
  GET_HRM_CANDIDATES_REQUEST,
  GET_HRM_CANDIDATES_SUCCESS,
  GET_HRM_CANDIDATES_FAILURE,
} from "../actionTypes";

const initialState = {
  loading: true,
  data: [],
  error: null,
};

const allCandidatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HRM_CANDIDATES_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case GET_HRM_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case GET_HRM_CANDIDATES_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default allCandidatesReducer;
