import { USERLIST_REQUEST, USERLIST_SUCCESS } from '../actionTypes';

const initialState = {
  loading: true,
  userList: [],
  error: null,
};

const userListReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERLIST_REQUEST:
      return {
        ...state,
        loading: true,
        userList: [],
        error: null
      };
    case USERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action.data,
        error: null
      };
    default:
      return state;
  }
};

export default userListReducer;