import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Toggle.scss";

class Toggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: !!this.props.checked,
    };

    this.onClick = this.onClick.bind(this);
  }

  static get defaultProps() {
    return {
      className: "",
      checked: false,
      onChange: () => {},
      disabled: false,
      size: "md",
    };
  }

  onClick() {
    if (this.props.disabled) {
      return;
    }
    const newValue = !this.state.checked;

    this.setState({
      checked: newValue,
    });

    this.props.onChange(newValue);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== this.props.checked) {
      this.setState({
        checked: nextProps.checked,
      });
    }
  }

  render() {
    const className = ["Toggle", ...this.props.className.split(" ")];
    if (this.props.color) {
      className.push(`Toggle--${this.props.color}`);
    }

    className.push(`Toggle--${this.props.size}`);

    return (
      <div onClick={this.onClick}>
        <input
          className="Toggle__hidden-checkbox"
          type="checkbox"
          readOnly
          checked={this.state.checked}
          disabled={this.props.disabled}
          name={this.props.name}
          ref={this.props.register}
        />
        <div className={className.join(" ")}>
          <div className="Toggle__handle"></div>
        </div>
      </div>
    );
  }
}

Toggle.colors = [
  "deep-blue",
  "blue",
  "bright-blue",
  "red",
  "orange",
  "black",
  "green",
];
Toggle.sizes = ["xl", "lg", "md", "sm", "xs", "xxs"];

Toggle.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  color: PropTypes.oneOf(Toggle.colors),
  size: PropTypes.oneOf(Toggle.sizes),
};

export default Toggle;
