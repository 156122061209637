/** @format */

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import BEMhelper from "react-bem-helper";

const AgentPrivateRoute = ({ component: Component, ...rest }) => {
  const classes = new BEMhelper("main");
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isLoggedIn ? (
          <Container fluid>
            <Row>
              <div {...classes("main-wrapper")}>
                <Component {...props} {...rest} />
              </div>
            </Row>
          </Container>
        ) : (
          <Redirect to={"/agent/login"} />
        )
      }
    />
  );
};

export default AgentPrivateRoute;
