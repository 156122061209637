import {
  getSurveys,
  createSurvey,
  getSurveyInfo,
  resetSurveys,
  getSurveyDetails,
  submitFeedback,
  getSignleFeedbackInfo,
  getSurveyReport,
  getSurveyPaper,
  archiveSurvey,
} from "./actionCreators";
import { surveySaga } from "./saga/surveySaga";
import allSurveysReducer from "./reducer/allSurveysReducer";
import surveyDetailsReducer from "./reducer/surveyDetailsReducer";
import signleFeedbackInfoReducer from "./reducer/signleFeedbackInfoReducer";
import surveyReportReducer from "./reducer/surveyReportReducer";
import surveyPaperReducer from "./reducer/surveyPaperReducer";

const surveyModule = {
  actions: {
    getSurveys,
    createSurvey,
    getSurveyInfo,
    resetSurveys,
    getSurveyDetails,
    submitFeedback,
    getSignleFeedbackInfo,
    getSurveyReport,
    getSurveyPaper,
    archiveSurvey,
  },
  reducers: {
    allSurveysReducer: allSurveysReducer,
    surveyDetailsReducer: surveyDetailsReducer,
    signleFeedbackInfoReducer: signleFeedbackInfoReducer,
    surveyReportReducer: surveyReportReducer,
    surveyPaperReducer: surveyPaperReducer,
  },
  sagas: {
    surveySaga: surveySaga,
  },
};

export default surveyModule;
