import React, { Component } from "react";
import PropTypes from "prop-types";
// import Icon from "../Icon";
import "./inputNew.scss";

const states = ["error", "focus", "warning", "success", "none"];
const sizes = ["lg", "md", "sm"];
const colors = ["white", "grey-2"];

const propTypes = {
  /**
   * The CSS class name of the wrapper element.
   */
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  /**
   * Type of the input element. It should be a valid HTML5 input type.
   */
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputClassName: PropTypes.string.isRequired,
  size: PropTypes.oneOf(sizes),
  state: PropTypes.oneOf(states),
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noborder: PropTypes.bool,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  inputRef: PropTypes.func,
  inputStyle: PropTypes.object,
  style: PropTypes.object,
  clear: PropTypes.bool,
  clearIcon: PropTypes.string,
  onClear: PropTypes.func,
  color: PropTypes.oneOf(colors),
  autofocus: PropTypes.bool,
  name: PropTypes.string,
};

class Input extends Component {
  onClear(e) {
    this.props.onClear(e);

    const input = this._input;
    if (!e.defaultPrevented) {
      // Set value to empty
      input.value = "";
      this.props.onChange({ target: input });
      this.toggleClear();
    }
    input.focus();
  }

  toggleClear() {
    if (!this.props.clear || !this._clear) {
      return;
    }
    if (this._input && this._input.value && this._input.value.length) {
      this._clear.classList.add("Input__clear-icon--visible");
    } else {
      this._clear.classList.remove("Input__clear-icon--visible");
    }
  }

  componentDidMount() {
    this.toggleClear();

    if (this.props.autofocus) {
      this._input.focus();
    }
  }

  componentDidUpdate() {
    this.toggleClear();
  }

  onClearOnChange(e) {
    this.toggleClear();
    this.props.onChange(e);
  }

  render() {
    const {
      state,
      className,
      inputClassName,
      disabled,
      inline,
      size,
      noborder,
      icon,
      inputRef,
      inputStyle,
      style,
      clear,
      clearIcon,
      onClear,
      autofocus,
      color,
      name,
      register,
      type,
      ...inputProps
    } = this.props;
    const outerClassName = ["Input", ...(className || "").split(" ")];

    if (state) {
      outerClassName.push(`Input--${state}`);
    }

    outerClassName.push(`Input--${size}`);

    if (disabled) {
      outerClassName.push("Input--disabled");
    }

    if (inline) {
      outerClassName.push("Input--inline");
    }

    if (noborder) {
      outerClassName.push("Input--noborder");
    }

    if (icon) {
      outerClassName.push("Input--has-icon");
    }

    outerClassName.push(`Input--${color}`);

    return (
      <div className={`${outerClassName.join(" ")}`} style={style}>
        {/* {icon ? <Icon className="Input__icon" name={icon} /> : null} */}
        <input
          {...inputProps}
          type={type}
          className={`Input__input ${inputClassName}`}
          disabled={disabled}
          style={inputStyle}
          ref={register && register(this.props.refdetails)}
          name={name}
          onChange={(e) =>
            clear ? this.onClearOnChange(e) : this.props.onChange(e)
          }
          // ref={(r) => {
          //   this._input = r;
          //   inputRef(r);
          // }}
        />
        {clear ? (
          <div
            className="Input__clear-icon"
            ref={(r) => (this._clear = r)}
            onClick={(e) => this.onClear(e)}
          >
            {/* <Icon name={clearIcon} /> */}
          </div>
        ) : null}
      </div>
    );
  }
}

Input.sizes = sizes;
Input.propTypes = propTypes;
Input.defaultProps = {
  type: "text",
  size: "md",
  placeholder: "",
  className: "",
  inputClassName: "",
  clear: false,
  clearIcon: "times",
  onClear: () => {},
  onChange: () => {},
  inputRef: () => {},
  color: "white",
  autofocus: false,
  name: "",
};

Input.colors = colors;

export default Input;
