import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const Dropzone = (props) => {
  const onDrop = useCallback((acceptedFiles) => {
    props.addedFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={`dropzone-main ${props.disabled ? "diabled" : ""}`}
    >
      <input {...getInputProps()} disabled={props.disabled || false} />
      {isDragActive ? (
        <p className="dropzone-placeholder">Drop the files here...</p>
      ) : (
        <p className="dropzone-placeholder">
          {props.bodyText
            ? props.bodyText
            : "Drag 'n' drop some files here, or click to select files"}
        </p>
      )}
    </div>
  );
};

export default Dropzone;
