export const GET_INVENTORY_REPORT_REQUEST = "GET_INVENTORY_REPORT_REQUEST";
export const GET_INVENTORY_REPORT_SUCCESS = "GET_INVENTORY_REPORT_SUCCESS";
export const GET_INVENTORY_REPORT_FAILURE = "GET_INVENTORY_REPORT_FAILURE";

export const GET_INVENTORY_CUSTOM_REPORT_REQUEST =
  "GET_INVENTORY_CUSTOM_REPORT_REQUEST";
export const GET_INVENTORY_CUSTOM_REPORT_SUCCESS =
  "GET_INVENTORY_CUSTOM_REPORT_SUCCESS";
export const GET_INVENTORY_CUSTOM_REPORT_FAILURE =
  "GET_INVENTORY_CUSTOM_REPORT_FAILURE";

export const GET_INVENTORY_CUSTOM_REPORT_DOWNLOAD_REQUEST =
  "GET_INVENTORY_CUSTOM_REPORT_DOWNLOAD_REQUEST";
export const GET_INVENTORY_CUSTOM_REPORT_DOWNLOAD_SUCCESS =
  "GET_INVENTORY_CUSTOM_REPORT_DOWNLOAD_SUCCESS";
export const GET_INVENTORY_CUSTOM_REPORT_DOWNLOAD_FAILURE =
  "GET_INVENTORY_CUSTOM_REPORT_DOWNLOAD_FAILURE";

export const RESET_REPORT_REQUEST = "RESET_REPORT_REQUEST";
