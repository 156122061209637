import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";
import Normalbtn from "../../../button";
import BEMhelper from "react-bem-helper";

const classes = new BEMhelper("AddCategoryModal");
const AddCategoryModal = (props) => {
  const {
    title,
    openModal,
    toggle,
    onSubmit,
    isEditMode,
    objectToEdit,
  } = props;
  // const [categoryType, setType] = useState("");
  const [categoryName, setName] = useState(isEditMode ? objectToEdit.name : "");

  return (
    <div>
      <Modal
        {...classes("modal")}
        isOpen={openModal}
        toggle={() => toggle()}
        centered={true}
        draggable={false}
        size="md"
      >
        <ModalHeader toggle={() => toggle()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row className="form-row">
              <Col sm={12}>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  value={categoryName}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
            </Row>
            {/* <Row className="form-row">
              <Col sm={12}>
                <Input
                  type="text"
                  name="type"
                  id="type"
                  placeholder="Type"
                  value={categoryType}
                  onChange={(e) => setType(e.target.value)}
                />
              </Col>
            </Row> */}
          </Container>
        </ModalBody>
        <ModalFooter>
          <Normalbtn
            color="secondary"
            text="Save"
            class={"modal-btn-submit"}
            onClick={() => onSubmit(categoryName)}
            disabled={!categoryName}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddCategoryModal;
