import * as actions from "./actionTypes";

export const getCategories = () => ({
  type: actions.GET_CATEGORIES_REQUEST,
});

export const createCategory = (data) => ({
  type: actions.CREATE_CATEGORY_REQUEST,
  payload: data,
});

export const updateCategory = (data) => ({
  type: actions.UPDATE_CATEGORY_REQUEST,
  payload: data,
});

export const deleteCategory = (data) => ({
  type: actions.DELETE_CATEGORY_REQUEST,
  payload: data,
});
