import * as actions from "./actionTypes";

export const getUserList = () => ({
  type: actions.USERLIST_REQUEST,
});

export const getSingleUser = (data) => ({
  type: actions.GETUSER_REQUEST,
  payload: data,
});

export const resetSingleUser = (data) => ({
  type: actions.RESET_SINGLE_USER_REQUEST,
  payload: data,
});

export const loginUser = (data) => ({
  type: actions.LOGIN_REQUEST,
  payload: data,
});

export const signupUser = (data) => ({
  type: actions.SIGNUP_REQUEST,
  payload: data,
});

export const getUserRole = () => ({
  type: actions.GET_USER_ROLE_REQUEST,
});

export const getUserDesignation = () => ({
  type: actions.GET_USER_DESIGNATION_REQUEST,
});

export const logout = () => ({
  type: actions.LOGOUT_REQUEST,
});

export const getUserDepartmentList = () => ({
  type: actions.USER_DEPARTMENT_REQUEST,
});

export const updateSignupReducer = (data) => ({
  type: actions.SIGNUP_DATA_UPDATE,
  payload: data,
});

export const forgotPassword = (data) => ({
  type: actions.FORGOT_PASSWORD_REQUEST,
  payload: data,
});

export const resetPassword = (data) => ({
  type: actions.RESET_PASSWORD_REQUEST,
  payload: data,
});

export const resetMyPassword = (data) => ({
  type: actions.RESET_MY_PASSWORD_REQUEST,
  payload: data,
});
