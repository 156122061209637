import * as actions from "./actionTypes";

export const getUsers = () => ({
  type: actions.GET_USERS_REQUEST,
});

export const createUser = (data) => ({
  type: actions.CREATE_USER_REQUEST,
  payload: data,
});

export const updateUser = (data) => ({
  type: actions.UPDATE_USER_REQUEST,
  payload: data,
});

export const deleteUser = (data) => ({
  type: actions.DELETE_USER_REQUEST,
  payload: data,
});
