import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const TablePagination = (props) => {
  return (
    <Pagination size="sm" aria-label="Page navigation example">
      <PaginationItem disabled={props.currentPage <= 0}>
        <PaginationLink
          onClick={(e) => props.handleClick(e, props.currentPage - 1)}
          previous
          href="#"
        />
      </PaginationItem>
      {[...Array(props.PageCount)].map((page, i) => (
        <PaginationItem active={i === props.currentPage} key={i}>
          <PaginationLink onClick={(e) => props.handleClick(e, i)} href="#">
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={props.currentPage >= props.PageCount - 1}>
        <PaginationLink
          onClick={(e) => props.handleClick(e, props.currentPage + 1)}
          next
          href="#"
        />
      </PaginationItem>
    </Pagination>
  );
};

export default TablePagination;
