import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import Button from "../../../ui-components/Button";

const classes = new BEMhelper("DeleteModal");
const DeleteModal = (props) => {
  const { title, body, openModal, onClose, onConfirm, deleteBtnText } = props;
  return (
    <div>
      <Modal
        {...classes("modal")}
        isOpen={openModal}
        toggle={() => onClose()}
        centered={true}
        draggable={false}
        size="sm"
      >
        <ModalHeader toggle={() => onClose()}>
          {title ? title : "Confirm Delete"}
        </ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <Row>
            <Col>
              <Button onClick={() => onConfirm()} color="red">
                {deleteBtnText ? deleteBtnText : "Delete"}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteModal;
