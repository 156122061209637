import {
  getCategories,
  createCategory,
  deleteCategory,
  updateCategory,
} from "./actionCreators";
import allCategoriesReducer from "./reducer/allCategoriesReducer";
import { categorySaga } from "./saga/categorySaga";

const categoryModule = {
  actions: {
    getCategories,
    createCategory,
    deleteCategory,
    updateCategory,
  },
  reducers: {
    allCategoriesReducer: allCategoriesReducer,
  },
  sagas: {
    categorySaga: categorySaga,
  },
};

export default categoryModule;
