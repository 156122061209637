import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import leaveModule from "../../../../redux/leave";
import { Row, Col } from "reactstrap";
import Card from "../../../components/card";
import Loader from "../../../components/loader";
import BEMhelper from "react-bem-helper";
import moment from "moment";

class Dashboard extends Component {
  componentDidMount() {
    const loggedUser = this.props.loggedUser.userDetails;
    this.props.leaveBalance(loggedUser.user.userId);
  }

  getCardbody = (leave) => {
    return (
      <React.Fragment>
        <div className="leave">
          <h6>Entitle</h6>
          <div className="balance">
            <p>{leave.entitled}</p>
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="leave">
          <h6>Available</h6>
          <div className="balance">
            <p>{leave.available}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const classes = new BEMhelper("dashboard");
    return (
      <Row {...classes("cardWrapper")}>
        <Col sm={8} className="title">
          <h2>
            Hi<span> {this.props.loggedUser.userDetails.user.firstName}</span>
          </h2>
        </Col>
        <Col sm={4} className="text-right time">
          <h4>
            {moment().format("dddd")}, {moment().format("LL")}
          </h4>
        </Col>

        <Col sm={12}>
          <div className="hr"></div>
          <Row>
            {this.props.leaveBalanceGroup.loading ? (
              <Loader />
            ) : this.props.leaveBalanceGroup.error ? (
              <p>{this.props.leaveBalanceGroup.error}</p>
            ) : (
              this.props.leaveBalanceGroup.data.map((leave, key) => {
                return (
                  <Col sm={4} className={["card" + key, "text-left"]} key={key}>
                    <Card
                      title={`${leave.type} Leave`}
                      body={this.getCardbody(leave)}
                    />
                  </Col>
                );
              })
            )}
          </Row>
        </Col>
      </Row>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      leaveBalance: leaveModule.actions.leaveBalance,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    loggedUser: state.loggedUser,
    leaveBalanceGroup: state.leaveBalanceNew,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(Dashboard);
