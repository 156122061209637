import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  CardBody,
} from "reactstrap";
import Button from "../../../../components/ui-components/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import inventoryModule from "../../../../../redux/inventory";
import userModule from "../../../../../redux/user";
import bemClass from "../../../../components/ui-components/Utils/bemClass";
import "./reportType.scss";

class InventoryReportType extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const classes = new bemClass("InventoryReportType");
    return (
      <Container className={classes.elem("wrapper").b()}>
        <Row>
          <Col md={4}>
            <Card className="text-center">
              <CardImg
                top
                width="100%"
                src="https://fundraisingreportcard.com/wp-content/uploads/2017/10/end-of-year-reporting.jpg"
                alt="Card image cap"
              />
              <CardBody>
                <CardTitle>General Report</CardTitle>
                <CardText>
                  Generate a report for you inventory with broad variety of
                  filters. These reports target te general audience.
                </CardText>
                <CardText>
                  <Button
                    className={classes.elem("generate-btn").b()}
                    onClick={() =>
                      this.props.history.push("/inventory/reports/general")
                    }
                  >
                    Generate Report
                  </Button>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="text-center">
              <CardImg
                top
                width="100%"
                src="https://italianacademy.org/wp-content/uploads/2018/08/Report-Writing.png"
                alt="Card image cap"
              />
              <CardBody>
                <CardTitle>Quantity Report</CardTitle>
                <CardText>
                  Generate a report to identify your stock quantity. Get an idea
                  about whats in-use and whats not compared to your stock.
                </CardText>
                <CardText>
                  <Button
                    className={classes.elem("generate-btn").b()}
                    onClick={() =>
                      this.props.history.push("/inventory/reports/quantity")
                    }
                  >
                    Generate Report
                  </Button>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="text-center">
              <CardImg
                top
                width="100%"
                src="https://www.wrike.com/blog/content/uploads/2016/03/project-management-predictions-2016.jpg"
                alt="Card image cap"
              />
              <CardBody>
                <CardTitle>Cost Report</CardTitle>
                <CardText>
                  Generate a report to identify cost of you inventory with
                  plenty of filters added. Get an idea about your inventory
                  cost.
                </CardText>
                <CardText>
                  <Button
                    className={classes.elem("generate-btn").b()}
                    onClick={() =>
                      this.props.history.push("/inventory/reports/cost")
                    }
                  >
                    Generate Report
                  </Button>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={4}>
            <Card className="text-center">
              <CardImg
                top
                width="100%"
                src="https://blog.capterra.com/wp-content/uploads/2020/02/word-image-10.png"
                alt="Card image cap"
              />
              <CardBody>
                <CardTitle>Sign Off Report</CardTitle>
                <CardText>
                  Generate a report for sign off when handing over items to
                  authority. This helps to keep track of item history.
                </CardText>
                <CardText>
                  <Button
                    className={classes.elem("generate-btn").b()}
                    onClick={() =>
                      this.props.history.push("/inventory/reports/signOff")
                    }
                  >
                    Generate Report
                  </Button>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="text-center">
              <CardImg
                top
                width="100%"
                src="https://images.idgesg.net/images/article/2017/07/sort_filter_folders_organizing-100728379-large.jpg"
                alt="Card image cap"
              />
              <CardBody>
                <CardTitle>Log Report</CardTitle>
                <CardText>
                  Take a look at items history to identify what are the changes
                  that the item went through and take easy decisions.
                </CardText>
                <CardText>
                  <Button
                    className={classes.elem("generate-btn").b()}
                    onClick={() =>
                      this.props.history.push("/inventory/reports/log")
                    }
                  >
                    Generate Report
                  </Button>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // getReport: inventoryModule.reportModule.actions.getReport,
      getUserList: userModule.actions.getUserList,
      getCategories: inventoryModule.categoryModule.actions.getCategories,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    categories: state.inventoryMainCategories,
    items: state.inventoryItems,
    userList: state.userList,
    report: state.inventoryReport,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(InventoryReportType);
