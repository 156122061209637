import {
  createSurvey,
  getSurveys,
  getSurveyInfo,
  getSurveyDetails,
  submitFeedback,
  getFeedbackInfo,
  getSurveyReport,
  getSurveyPaper,
  archiveSurvey,
} from "./survey";

const performance = {
  createSurvey,
  getSurveys,
  getSurveyInfo,
  getSurveyDetails,
  submitFeedback,
  getFeedbackInfo,
  getSurveyReport,
  getSurveyPaper,
  archiveSurvey,
};

export default performance;
