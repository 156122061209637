import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/hrm/performance/survey`;
const apiUrlFeedback = `${process.env.REACT_APP_API_URL}/api/v1/hrm/performance`;

export const getSurveys = async (token, details) => {
  return axios({
    method: "GET",
    url: `${apiUrl}${details.query ? `?${details.query}` : ""}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getSurveyPaper = async (token, details) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/${details.surveyId}/paper`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getSurveyDetails = async (details) => {
  return axios({
    method: "GET",
    url: `${apiUrlFeedback}/surveyFeedbackForm`,
    headers: {
      "x-access-token": details.token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const submitFeedback = async (details) => {
  return axios({
    method: "POST",
    url: `${apiUrlFeedback}/surveyFeedbackSubmit`,
    headers: {
      "x-access-token": details.token,
    },
    data: details.body,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const archiveSurvey = async (token, details) => {
  return axios({
    method: "PUT",
    url: `${apiUrl}/${details.surveyId}?${details.query}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getSurveyInfo = async (token, details) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/${details.surveyId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getSurveyReport = async (token, details) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/${details.surveyId}/report/${details.reportType}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getFeedbackInfo = async (token, details) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/${details.surveyId}/employee/${details.employeeId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createSurvey = async (token, surveyDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}`,
    headers: {
      "x-access-token": token,
    },
    data: surveyDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
