import { signupUser, updateUser, createDesignation } from "./actionCreators";
import { userSaga } from "./saga/userSaga";

const userModule = {
  actions: {
    signupUser,
    updateUser,
    createDesignation,
  },
  reducers: {
    // featureAccessRoleReducer: featureAccessRoleReducer,
    // userListReducer: userListReducer,
    // singleUserReducer: singleUserReducer,
    // loggedUserReducer: loggedUserReducer,
    // userSignupReducer: userSignupReducer,
    // userGetRoleReducer: userGetRoleReducer,
    // userGetDesignationReducer: userGetDesignationReducer,
    // userDepartmentReducer: userDepartmentReducer,
  },
  sagas: {
    userSaga: userSaga,
  },
};

export default userModule;
