import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Container, Row, Col, ButtonGroup, Badge } from "reactstrap";
import { connect } from "react-redux";
import hrmModule from "../../../../../../redux/hrm";
import Button from "../../../../../components/ui-components/Button";
import BEMhelper from "react-bem-helper";
import { FaPlus, FaExternalLinkAlt, FaEdit } from "react-icons/fa";
import CustomTable from "../../../../../components/table";
import ToolTip from "../../../../../components/toolTip";
import AddVacancyModal from "../../../../../components/modals/hrm/recruitment/vacancy/addVacancy";
import "./vacancyList.scss";

class VacancyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVacancyModal: false,
      selectedVacancy: null,
      editMode: false,
    };
  }

  editVacancy = (vacancy) => {
    this.setState({
      selectedVacancy: vacancy,
      editMode: true,
      showVacancyModal: true,
    });
  };

  getVacancyList = (vacancyList) => {
    const loggedUserAccess = this.props.user.userDetails.accessRoles;
    if (vacancyList && vacancyList.length) {
      return vacancyList.map((row, key) => {
        return (
          <tr key={key} className="value-row">
            <td className="vacancies__name">
              {row.name}
              {row.isUrgent ? (
                <Badge pill color="danger">
                  Urgent
                </Badge>
              ) : null}
            </td>
            <td>
              <Badge pill color="warning">
                {row.isClosed ? "Closed" : "Open"}
              </Badge>
            </td>
            <td className="vacancies__application-actions">
              <ButtonGroup size="sm">
                {loggedUserAccess.isAdmin ||
                loggedUserAccess.isHrmAdmin ||
                loggedUserAccess.isSuperAdmin ? (
                  <React.Fragment>
                    <Button
                      size="sm"
                      onClick={() => this.editVacancy(row)}
                      color="grey"
                      id={`edit-${key}`}
                    >
                      <FaEdit />
                    </Button>
                    <ToolTip
                      position="top"
                      tip="Edit vacancy"
                      rowId={`edit-${key}`}
                    />
                  </React.Fragment>
                ) : null}
                <a
                  href={`/vacancy/${row.hrmVacancyId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  id={`external-${key}`}
                >
                  <Button size="sm" color="medium-blue">
                    <FaExternalLinkAlt />
                  </Button>
                  <ToolTip
                    position="top"
                    tip="View external link"
                    rowId={`external-${key}`}
                  />
                </a>
              </ButtonGroup>
            </td>
          </tr>
        );
      });
    }
    return [];
  };

  closeVacancyModal = () => {
    this.setState({
      showVacancyModal: false,
      selectedVacancy: null,
      editMode: false,
    });
  };

  addVacancy = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    if (values.jobDescription) {
      formData.append("jobDescription", values.jobDescription);
    }
    formData.append("isUrgent", values.urgent);
    if (values.jdFile && values.jdFile[0]) {
      formData.append("jd", values.jdFile[0]);
    }
    if (this.state.editMode && values.jdImageUrl) {
      formData.append("jdImageUrl", values.jdImageUrl);
    }
    const data = {
      formValues: formData,
      closeModal: this.closeVacancyModal,
      ...(this.state.editMode && {
        vacancyId: this.state.selectedVacancy.hrmVacancyId,
      }),
    };
    if (this.state.editMode) {
      this.props.updateVacancy(data);
    } else {
      this.props.createVacancy(data);
    }
  };

  componentDidMount = () => {
    this.props.getVacancies();
  };

  render() {
    const classes = new BEMhelper("vacancies");
    const loggedUserAccess = this.props.user.userDetails.accessRoles;
    return (
      <Container fluid {...classes("list")}>
        {this.state.showVacancyModal ? (
          <AddVacancyModal
            openModal={this.state.showVacancyModal}
            title={"Add Vacancy"}
            toggle={this.closeVacancyModal}
            submit={this.addVacancy}
            isEditMode={this.state.editMode}
            objectToEdit={this.state.selectedVacancy}
          />
        ) : null}

        {this.props.vacancies.loading || !this.props.vacancies.data ? (
          <p>Loading...</p>
        ) : (
          <React.Fragment>
            <Row>
              <Col sm={7} className="text-left">
                <div className="headerTitle-wrap">
                  <h2 className="list-header-title">Vacancy List</h2>
                </div>
              </Col>
              <Col sm={5} className="text-right">
                {loggedUserAccess.isAdmin ||
                loggedUserAccess.isHrmAdmin ||
                loggedUserAccess.isSuperAdmin ? (
                  <Button
                    color="deep-blue"
                    onClick={() => {
                      this.setState({
                        showVacancyModal: true,
                      });
                    }}
                  >
                    <FaPlus /> New Vacancy
                  </Button>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className="hr"></div>
                <CustomTable
                  content={this.getVacancyList(this.props.vacancies.data)}
                  tableHeadings={["Vacancy name", "Status", ""]}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getVacancies: hrmModule.vacancyModule.actions.getVacancies,
      createVacancy: hrmModule.vacancyModule.actions.createVacancy,
      updateVacancy: hrmModule.vacancyModule.actions.updateVacancy,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    vacancies: state.hrmVacancies,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(VacancyList);
