import {
  getVacancies,
  createVacancy,
  updateVacancy,
  getVacancy,
} from "./actionCreators";
import { vacancySaga } from "./saga/vacancySaga";
import allVacanciesReducer from "./reducer/allVacanciesReducer";
import vacancyReducer from "./reducer/vacancyReducer";

const vacancyModule = {
  actions: {
    getVacancies,
    createVacancy,
    updateVacancy,
    getVacancy,
  },
  reducers: {
    allVacanciesReducer: allVacanciesReducer,
    vacancyReducer: vacancyReducer,
  },
  sagas: {
    vacancySaga: vacancySaga,
  },
};

export default vacancyModule;
