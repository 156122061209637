import React from "react";
import PropTypes from "prop-types";
import ButtonGroup from "../ButtonGroup";
import Button from "../Button";
import formatClassName from "../Utils/formatClassName";

import "./Paginator.scss";

const alignments = ["center", "left", "right"];

const propTypes = {
  limit: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  paddButtons: PropTypes.number.isRequired,
  align: PropTypes.oneOf(alignments),
  size: PropTypes.oneOf(Button.sizes),
  style: PropTypes.object.isRequired,
};

const defaultProps = {
  limit: 10,
  offset: 0,
  total: 100,
  onChange: () => ({}),
  paddButtons: 4,
  align: alignments[0],
  size: "xs",
  style: {},
};

class Paginator extends React.Component {
  renderButton(i, isActive, text) {
    const { onChange, limit, size } = this.props;
    return (
      <Button
        className="Paginator__Button"
        onClick={() => onChange(i * limit, i)}
        key={"paginator-page-" + i + "-" + text}
        color={isActive ? "bright-blue" : "white"}
        text={text || "" + (i + 1)}
        size={size}
      />
    );
  }
  renderPages({ limit, offset, total, paddButtons }) {
    const DOTS = "...";
    const acitveIndex = Math.floor(offset / limit);
    const maxPages = limit <= total ? paddButtons * 2 + 1 : 0;
    const buttons = [];
    const numPages = Math.ceil(total / limit);
    const lowerPadded = acitveIndex - paddButtons;
    const higherPadded = acitveIndex + paddButtons;
    const maxStart = numPages - maxPages;
    const start = Math.max(0, Math.min(lowerPadded, maxStart));
    const end = Math.min(numPages, Math.max(higherPadded, maxPages));

    for (var i = start; i < end; i++) {
      const isDotted =
        (i === end - 1 && i !== numPages - 1) || (i === start && i !== 0);
      buttons.push(
        this.renderButton(i, acitveIndex === i, isDotted ? DOTS : null)
      );
    }
    if (start !== 0) {
      // Unshift link to first page
      buttons.unshift(this.renderButton(0, false));
    }
    if (end !== numPages || numPages === 0) {
      // Push link to last page
      buttons.push(this.renderButton(numPages - 1, false));
    }
    return buttons;
  }
  render() {
    const { align, style } = this.props;
    const classMap = { align: `Paginator--align-${align}` };
    return (
      <div
        className={formatClassName(this.props, classMap, ["Paginator"])}
        style={style}
      >
        <ButtonGroup style={{}}>{this.renderPages(this.props)}</ButtonGroup>
      </div>
    );
  }
}

Paginator.sizes = Button.sizes;
Paginator.propTypes = propTypes;
Paginator.defaultProps = defaultProps;
export default Paginator;
