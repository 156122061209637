import React, { useState } from "react";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../../../components/ui-components/Button";
import FormFeedBack from "../../../../../components/ui-components/FormFeedBack";
import bemClass from "../../../../../components/ui-components/Utils/bemClass";
import Select from "react-select";
import { FaTimes } from "react-icons/fa";
import "./addUsers.scss";

const HrmSurveyAddUsers = (props) => {
  const classes = new bemClass("HrmSurveyAddUsers");
  const {
    submit,
    stepOneData,
    allUserList,
    changeTab,
    stepThreeData,
    submitLoading,
  } = props;
  const [users, setUsers] = useState(stepThreeData ? stepThreeData : []);

  const defaultValues = {};

  const usersOptions = allUserList.map((user) => {
    return {
      value: user.userId,
      label: `${user.firstName} ${user.lastName}`,
    };
  });

  const { handleSubmit, errors, control, reset } = useForm({
    defaultValues,
  });

  const onSubmitUser = (values) => {
    const allUsers = users;
    allUsers.push({
      employee: values.employee,
      supervisors: values.supervisors,
    });

    setUsers([...allUsers]);
    reset({
      employee: null,
      supervisors: null,
    });
  };

  const submitStage = () => {
    submit(users);
  };

  return (
    <Container fluid className={classes.elem("wrapper").b()}>
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <FormGroup className={classes.elem("header").b()}>
            <div>
              <h3>{stepOneData.surveyName}</h3>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} className={classes.elem("question-card").b()}>
          <form onSubmit={handleSubmit(onSubmitUser)}>
            <FormGroup>
              <Label>Employee</Label>
              <Controller
                as={
                  <Select
                    placeholder="Select employee"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: `1px solid ${
                          errors.employee ? "#dc3545" : "#cad2dd"
                        }`,
                      }),
                    }}
                  />
                }
                options={usersOptions}
                name="employee"
                isClearable
                control={control}
                onChange={([selected]) => {
                  return { value: selected };
                }}
                rules={{
                  required: "Employee is required",
                }}
              />
              {errors.employee ? (
                <FormFeedBack feedBack={errors.employee.message} />
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label>Supervisors</Label>
              <Controller
                as={
                  <Select
                    placeholder="Select supervisors"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: `1px solid ${
                          errors.supervisors ? "#dc3545" : "#cad2dd"
                        }`,
                      }),
                    }}
                  />
                }
                options={usersOptions}
                name="supervisors"
                isClearable
                isMulti
                control={control}
                onChange={([selected]) => {
                  return { value: selected };
                }}
                rules={{
                  required: "Please select supervisor(s)",
                }}
              />
              {errors.supervisors ? (
                <FormFeedBack feedBack={errors.supervisors.message} />
              ) : null}
            </FormGroup>
            <FormGroup>
              <Button size="sm" submit>
                Add User
              </Button>
            </FormGroup>
          </form>
        </Col>
        <Col md={6}>
          <ol>
            {users.map((user, index) => {
              return (
                <React.Fragment>
                  <li>
                    {user.employee.label}{" "}
                    <FaTimes
                      onClick={() => {
                        const newUsers = users
                          .slice(0, index)
                          .concat(users.slice(index + 1, users.length));
                        setUsers(newUsers);
                      }}
                    />
                  </li>
                  <ul>
                    {user.supervisors.map((supervisor, sIndex) => {
                      return (
                        <React.Fragment>
                          <li className={classes.elem("supervisor-label").b()}>
                            {supervisor.label}{" "}
                            <FaTimes
                              onClick={() => {
                                const newSupervisors = user.supervisors
                                  .slice(0, index)
                                  .concat(
                                    user.supervisors.slice(
                                      index + 1,
                                      user.supervisors.length
                                    )
                                  );
                                if (newSupervisors.length) {
                                  const newUsers = users.map((u, uIndex) => {
                                    if (uIndex === index) {
                                      u.supervisors = newSupervisors;
                                    }
                                    return u;
                                  });
                                  setUsers(newUsers);
                                } else {
                                  const newUsers = users
                                    .slice(0, index)
                                    .concat(
                                      users.slice(index + 1, users.length)
                                    );
                                  setUsers(newUsers);
                                }
                              }}
                            />
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                  <br />
                </React.Fragment>
              );
            })}
          </ol>
        </Col>
      </Row>
      <div className="hr"></div>
      <Row>
        <Col md={12}>
          <div className={classes.elem("submit-info").b()}>
            <FormFeedBack
              feedBack="Submitting the survey will send out emails to relevant supervisiors."
              feedBackType="info"
            />
          </div>
          <FormGroup className={classes.elem("action-btns").b()}>
            <Button
              color="light-grey"
              onClick={() => changeTab(1, users)}
              shadow={false}
            >
              Prev
            </Button>{" "}
            <Button
              size="sm"
              onClick={() => submitStage()}
              disabled={!users.length}
              loading={submitLoading}
            >
              Finish
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default HrmSurveyAddUsers;
