import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  FEATURE_ACCESS_ROLE_REQUEST,
  FEATURE_ACCESS_ROLE_SUCCESS,
  FEATURE_ACCESS_ROLE_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";

export function* commonSaga() {
  yield takeLatest(FEATURE_ACCESS_ROLE_REQUEST, getFeatureAccessRoles);
}

function* getFeatureAccessRoles() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const featureAccessRole = yield call(
      apis.adminApis.featureAccessRole,
      loggedUser.userDetails.auth.accessToken
    );
    if (featureAccessRole.error) {
      yield put({
        type: FEATURE_ACCESS_ROLE_FAILURE,
        error: featureAccessRole.body.data.error,
      });
    } else {
      yield put({
        type: FEATURE_ACCESS_ROLE_SUCCESS,
        data: featureAccessRole.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
