export const GET_HRM_MENTORSHIP_FEEDBACK_REQUEST =
  "GET_HRM_MENTORSHIP_FEEDBACK_REQUEST";
export const GET_HRM_MENTORSHIP_FEEDBACK_SUCCESS =
  "GET_HRM_MENTORSHIP_FEEDBACK_SUCCESS";
export const GET_HRM_MENTORSHIP_FEEDBACK_FAILURE =
  "GET_HRM_MENTORSHIP_FEEDBACK_FAILURE";

export const CREATE_HRM_MENTORSHIP_FEEDBACK_REQUEST =
  "CREATE_HRM_MENTORSHIP_FEEDBACK_REQUEST";
export const CREATE_HRM_MENTORSHIP_FEEDBACK_SUCCESS =
  "CREATE_HRM_MENTORSHIP_FEEDBACK_SUCCESS";
export const CREATE_HRM_MENTORSHIP_FEEDBACK_FAILURE =
  "CREATE_HRM_MENTORSHIP_FEEDBACK_FAILURE";

export const GET_HRM_MENTORSHIP_USERS_FEEDBACK_REQUEST =
  "GET_HRM_MENTORSHIP_USERS_FEEDBACK_REQUEST";
export const GET_HRM_MENTORSHIP_USERS_FEEDBACK_SUCCESS =
  "GET_HRM_MENTORSHIP_USERS_FEEDBACK_SUCCESS";
export const GET_HRM_MENTORSHIP_USERS_FEEDBACK_FAILURE =
  "GET_HRM_MENTORSHIP_USERS_FEEDBACK_FAILURE";
