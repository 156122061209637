import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import hrmModule from "../../../../../redux/hrm";
import Button from "../../../../components/ui-components/Button";
import { FaPlus } from "react-icons/fa";
import StarRatings from "react-star-ratings";
import { Container, Row, Col, Alert, Badge } from "reactstrap";
import CustomTable from "../../../../components/table";
import moment from "moment";
import bemClass from "../../../../components/ui-components/Utils/bemClass";
import Loader from "../../../../components/ui-components/Loader";
import "./singleSurveyReport.scss";

class HrmSurveySingleReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getSurveyReport({
      surveyId: this.props.match.params.surveyId,
      reportType: "single",
    });
  }

  getOverallColor = (number) => {
    if (number > 3.5) {
      return "success";
    } else if (number >= 2.5 && number <= 3.5) {
      return "warning";
    } else {
      return "danger";
    }
  };

  getBadgeText = (number) => {
    if (number > 3.5) {
      return "Good";
    } else if (number >= 2.5 && number <= 3.5) {
      return "Average";
    } else {
      return "Improve";
    }
  };

  getSurveyList = (reportData) => {
    return (reportData.userReportData || []).map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>
            {row.employee.firstName} {row.employee.lastName}
          </td>
          <td>{row.userCount * 5}</td>
          <td>{row.totalRating || 0}</td>
          <td>
            <StarRatings
              rating={((row.totalRating / row.userCount) * 5) / 5}
              starRatedColor="blue"
              isSelectable={false}
              numberOfStars={5}
              name="rating"
              starDimension="20px"
              starSpacing="5px"
              starRatedColor="#052a4f"
            />
          </td>
          <td>
            <Badge
              pill
              color={this.getOverallColor(
                ((row.totalRating / row.userCount) * 5) / 5
              )}
            >
              {this.getBadgeText(((row.totalRating / row.userCount) * 5) / 5)}
            </Badge>
          </td>
        </tr>
      );
    });
  };

  render() {
    const classes = new bemClass("HrmSurveySingleReport");
    return (
      <Container fluid className={classes.elem("wrapper").b()}>
        {this.props.report.loading ? (
          <div className={classes.elem("loader").b()}>
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            {this.props.report.error ? (
              <Alert color="danger">
                <p>{this.props.report.error}</p>
              </Alert>
            ) : (
              <React.Fragment>
                <Row>
                  <Col sm={7} className="text-left">
                    <div className="headerTitle-wrap">
                      <h2 className="list-header-title">Survey Report</h2>
                    </div>
                  </Col>
                  <Col sm={5} className="text-right"></Col>
                </Row>
                <hr />
                <Row>
                  <Col md={12}>
                    <CustomTable
                      content={this.getSurveyList(this.props.report.data)}
                      tableHeadings={[
                        "Employee Name",
                        "Total Rating",
                        "Achieved Rating",
                        "Overall Rating",
                        "badge",
                      ]}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSurveyReport:
        hrmModule.hrmPerformanceModule.surveyModule.actions.getSurveyReport,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    report: state.hrmPerformanceSurveyReport,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(HrmSurveySingleReport);
