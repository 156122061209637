import React from "react";
import PropTypes from "prop-types";

import { Label } from "reactstrap";
import "./RadioItem.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const sizes = ["xl", "lg", "md", "sm", "xs"];

const propTypes = {
  checked: PropTypes.bool,
  size: PropTypes.oneOf(sizes),
  children: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const onClick = ({ onChange, checked, value }) => {
  if (value && !checked) {
    onChange && onChange(value);
  } else if (value && checked) {
    onChange && onChange(null);
  } else {
    onChange && onChange(checked);
  }
};

const RadioItem = ({
  size,
  checked,
  onChange,
  value,
  disabled,
  label,
  children,
  ...otherProps
}) => {
  const classList = ["RadioItem"];

  if (checked) {
    classList.push("RadioItem--checked");
  }

  if (size) {
    classList.push(`RadioItem--${size}`);
  }

  if (disabled) {
    classList.push("RadioItem--disabled");
  }

  if (children) {
    return (
      <div
        className="RadioListItem"
        onClick={() => !disabled && onClick({ checked, onChange, value })}
        {...otherProps}
      >
        <div className={classList.join(" ").trim()}>
          {checked ? (
            <span className="radio-check">
              <FontAwesomeIcon icon={faCheck} />
            </span>
          ) : null}
        </div>
        {children}
      </div>
    );
  }

  return (
    <div
      className="RadioListItem"
      onClick={() => !disabled && onClick({ checked, onChange, value })}
      {...otherProps}
    >
      <div className={classList.join(" ").trim()}>
        {checked ? (
          <span className="radio-check">
            <FontAwesomeIcon icon={faCheck} />
          </span>
        ) : null}
      </div>
      {label ? <Label>{label}</Label> : null}
    </div>
  );
};

RadioItem.sizes = sizes;

RadioItem.defaultProps = {
  size: "md",
  checked: false,
  disabled: false,
};

RadioItem.propTypes = propTypes;
export default RadioItem;
