import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/time-tracking`;

export const getClients = async (token) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/client`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createClient = async (token, clientDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/client`,
    headers: {
      "x-access-token": token,
    },
    data: clientDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const updateClient = async (token, clientDetails) => {
  return axios({
    method: "PUT",
    url: `${apiUrl}/client/${clientDetails.clientId}`,
    headers: {
      "x-access-token": token,
    },
    data: clientDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const deleteClient = async (token, clientId) => {
  return axios({
    method: "DELETE",
    url: `${apiUrl}/client/${clientId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createContact = async (token, contactDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/client/contact`,
    headers: {
      "x-access-token": token,
    },
    data: contactDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
