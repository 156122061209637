import {
  CREATE_INVENTORY_ITEMS_REQUEST,
  CREATE_INVENTORY_ITEMS_SUCCESS,
  CREATE_INVENTORY_ITEMS_FAILURE,
  CREATE_INVENTORY_ITEMS_BULK_REQUEST,
  CREATE_INVENTORY_ITEMS_BULK_SUCCESS,
  CREATE_INVENTORY_ITEMS_BULK_FAILURE,
} from "../../item/actionTypes";

import {
  CREATE_INVENTORY_TYPE_REQUEST,
  CREATE_INVENTORY_TYPE_SUCCESS,
  CREATE_INVENTORY_TYPE_FAILURE,
} from "../../type/actionTypes";

import {
  CREATE_INVENTORY_CATEGORY_REQUEST,
  CREATE_INVENTORY_CATEGORY_SUCCESS,
  CREATE_INVENTORY_CATEGORY_FAILURE,
} from "../../category/actionTypes";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const createItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_INVENTORY_ITEMS_REQUEST:
    case CREATE_INVENTORY_TYPE_REQUEST:
    case CREATE_INVENTORY_ITEMS_BULK_REQUEST:
    case CREATE_INVENTORY_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case CREATE_INVENTORY_ITEMS_SUCCESS:
    case CREATE_INVENTORY_TYPE_SUCCESS:
    case CREATE_INVENTORY_ITEMS_BULK_SUCCESS:
    case CREATE_INVENTORY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case CREATE_INVENTORY_ITEMS_FAILURE:
    case CREATE_INVENTORY_TYPE_FAILURE:
    case CREATE_INVENTORY_ITEMS_BULK_FAILURE:
    case CREATE_INVENTORY_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default createItemReducer;
