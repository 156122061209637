import {
  getFeedbacks,
  createFeedback,
  getAllUsersFeedbacks,
} from "./actionCreators";
import { mentorshipSaga } from "./saga/mentorshipSaga";
import allFeedbacksReducer from "./reducer/allFeedbacksReducer";

const mentorshipModule = {
  actions: {
    getFeedbacks,
    createFeedback,
    getAllUsersFeedbacks,
  },
  reducers: {
    allFeedbacksReducer: allFeedbacksReducer,
  },
  sagas: {
    mentorshipSaga: mentorshipSaga,
  },
};

export default mentorshipModule;
