import React, { Component } from "react";
import CustomTable from "../../../components/table";
import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Button,
  ButtonGroup,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import BEMhelper from "react-bem-helper";
import adminModule from "../../../../redux/admin";
import leaveModule from "../../../../redux/leave";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { statusColor } from "../../../../utils/statusHelper";
import ToolTip from "../../../components/toolTip";
import TablePagination from "../../../components/pagination";

class LeaveList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      PageCount: 0,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  getTableHeading = () => {
    return [
      "UserName",
      "Leave Type",
      "Request On",
      "Leave Date",
      "Reason",
      "Status",
      "",
    ];
  };

  getTableContent = (data) => {
    return data.map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>
            {row.user.firstName} {row.user.lastName}
          </td>
          <td>
            {row.leaveType.type} - {row.leaveDateType.dateType}
          </td>
          <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
          <td>{moment(row.leaveDate).format("YYYY-MM-DD")}</td>
          <td>{row.reason}</td>
          <td>
            <Badge color={statusColor(row.processStatus.status)} pill>
              {row.processStatus.status}
            </Badge>
          </td>
          <td>
            {row.processStatus.processStatusId !== 1 ? null : (
              <span>
                <ButtonGroup size="sm">
                  <Button
                    outline
                    color="info"
                    size="sm"
                    onClick={() => this.processLeave(row.leaveHistoryId, 2)}
                    id={`approve-${key}`}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </Button>
                  <ToolTip
                    position="top"
                    tip="Approve request"
                    rowId={`approve-${key}`}
                  />
                  <Button
                    outline
                    color="danger"
                    size="sm"
                    onClick={() => this.processLeave(row.leaveHistoryId, 3)}
                    id={`reject-${key}`}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </Button>
                  <ToolTip
                    position="top"
                    tip="Reject request"
                    rowId={`reject-${key}`}
                  />
                </ButtonGroup>
              </span>
            )}
          </td>
        </tr>
      );
    });
  };

  processLeave = (leaveHistoryId, choice) => {
    const data = {
      formValues: {
        leaveHistoryId: leaveHistoryId,
        processStatusId: choice,
      },
      clearState: this.clearState,
    };
    this.props.processLeave(data);
  };

  clearState = () => {
    this.props.leaveList({ page: this.state.currentPage });
  };

  handleClick(e, index) {
    e.preventDefault();

    this.setState({
      currentPage: index,
    });
    this.props.leaveList({ page: index });
  }

  getPageCount = (count) => {
    return Math.ceil(count / 10);
  };

  componentDidMount() {
    this.props.leaveList({ page: 0 });
  }

  render() {
    const { currentPage } = this.state;
    const classes = new BEMhelper("leaveList");
    return (
      <React.Fragment>
        <Container fluid {...classes("leavelist-wrapper")}>
          <Card body>
            <Row className="header-table-space text-left">
              <Col sm={12} className="text-left">
                <h2 className="list-header-title">Leave List</h2>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {this.props.leaveListGroup.loading ? (
                  <p>Loading...</p>
                ) : (
                  <React.Fragment>
                    <CustomTable
                      content={this.getTableContent(
                        this.props.leaveListGroup.data.records
                      )}
                      tableHeadings={this.getTableHeading()}
                    />
                    <TablePagination
                      currentPage={currentPage}
                      handleClick={this.handleClick}
                      PageCount={this.getPageCount(
                        this.props.leaveListGroup.data.count
                      )}
                    />
                  </React.Fragment>
                )}
              </Col>
            </Row>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      leaveList: adminModule.actions.leaveList,
      processLeave: leaveModule.actions.processLeave,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    leaveListGroup: state.leaveList,
    processLeaveRequest: state.processLeave,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(LeaveList);
