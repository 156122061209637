const itemColumns = [
  {
    label: "Item Name",
    value: "name",
    active: true,
    quantityReportDefault: true,
  },
  {
    label: "Brand",
    value: "brand",
  },
  {
    label: "Serial Number",
    value: "serialNumber",
  },
  {
    label: "Quantity In Hand",
    value: "quantity",
    quantityReportDefault: true,
  },
  {
    label: "Quantity Available",
    value: "available",
    quantityReportDefault: true,
  },
  {
    label: "Type",
    value: "inventoryItemType.name",
  },
  {
    label: "Vendor",
    value: "vendor",
  },
  {
    label: "Occupied User",
    value: "user.fullName",
  },
  {
    label: "Category",
    value: "inventoryMainCategory.name",
  },
  {
    label: "Warranty Period",
    value: "warrantyMonth",
  },
  {
    label: "Created On",
    value: "createdAt",
  },
  {
    label: "Item Location",
    value: "assetLocation",
  },
  {
    label: "Condition",
    value: "condition",
  },
  {
    label: "Purchased Date",
    value: "purchaseDate",
  },
  {
    label: "Expire Date",
    value: "warrantyExpireDate",
  },
  {
    label: "Price",
    value: "cost",
  },
];

export default itemColumns;
