import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import hrmModule from "../../../../../redux/hrm";
import Button from "../../../../components/ui-components/Button";
import { FaPlus } from "react-icons/fa";
import { Container, Row, Col, Alert } from "reactstrap";
import CustomTable from "../../../../components/table";
import moment from "moment";
import bemClass from "../../../../components/ui-components/Utils/bemClass";
import Loader from "../../../../components/ui-components/Loader";
import "./surveyInfo.scss";

class HrmSurveyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getSurveyInfo({
      surveyId: this.props.match.params.surveyId,
    });
  }

  componentWillUnmount() {
    this.props.resetSurveys();
  }

  getSurveyList = (surveys) => {
    return (surveys || []).map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>
            {row.employee.firstName} {row.employee.lastName}
          </td>
          <td>{row.surveysSent || 0}</td>
          <td>{row.feedbacksReceived || 0}</td>
          <td>
            {row.surveysSent === row.feedbacksReceived
              ? "Completed"
              : "Ongoing"}
          </td>
          <td>
            <Button
              size="sm"
              onClick={() =>
                this.props.history.push(
                  `/hrm/performance/survey/${row.hrmSurveyId}/employee/${row.employeeId}`
                )
              }
            >
              View Info
            </Button>
          </td>
        </tr>
      );
    });
  };

  render() {
    const classes = new bemClass("HrmSurveyInfo");
    return (
      <Container fluid className={classes.elem("wrapper").b()}>
        {this.props.surveys.loadingSurveyInfo ? (
          <div className={classes.elem("loader").b()}>
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            {this.props.surveys.error ? (
              <Alert color="danger">
                <p>{this.props.surveys.error}</p>
              </Alert>
            ) : (
              <React.Fragment>
                <Row>
                  <Col sm={7} className="text-left">
                    <div className="headerTitle-wrap">
                      <h2 className="list-header-title">
                        {this.props.surveys.surveyInfo.survey.name}
                      </h2>
                    </div>
                  </Col>
                  <Col sm={5} className="text-right">
                    {this.props.surveys.surveyInfo.survey.isCompleted ? (
                      <Button
                        color="deep-blue"
                        onClick={() =>
                          this.props.history.push(
                            `/hrm/performance/survey/${this.props.surveys.surveyInfo.survey.hrmSurveyId}/report`
                          )
                        }
                      >
                        View Performace
                      </Button>
                    ) : null}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={12}>
                    <CustomTable
                      content={this.getSurveyList(
                        this.props.surveys.surveyInfo.surveyFeedbacks
                      )}
                      tableHeadings={[
                        "Employee Name",
                        "Surveys Sent",
                        "Feedbacks Received",
                        "Status",
                        "",
                      ]}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSurveyInfo:
        hrmModule.hrmPerformanceModule.surveyModule.actions.getSurveyInfo,
      resetSurveys:
        hrmModule.hrmPerformanceModule.surveyModule.actions.resetSurveys,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    surveys: state.hrmPerformanceSurveys,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(HrmSurveyInfo);
