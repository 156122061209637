import {
  getProjects,
  createProject,
  deleteProject,
  updateProject,
} from "./project";
import {
  getCategories,
  createCategory,
  deleteCategory,
  updateCategory,
} from "./category";
import {
  createEntry,
  getEntriesForDate,
  updateEntry,
  deleteEntry,
} from "./entry";
import {
  getClients,
  createClient,
  deleteClient,
  updateClient,
  createContact,
} from "./client";
import { createTask, getTasks, deleteTask, updateTask } from "./task";
import {
  createUser,
  getUsers,
  deleteUser,
  updateUser,
  assignTeamToProject,
} from "./user";
import { getMainEntryReport } from "./report";
import { getPermissionTypes } from "./common";

const timeTrackApis = {
  getProjects,
  createProject,
  getCategories,
  createCategory,
  updateCategory,
  createEntry,
  getEntriesForDate,
  updateEntry,
  deleteEntry,
  getClients,
  createClient,
  createTask,
  getTasks,
  createUser,
  getUsers,
  deleteCategory,
  deleteClient,
  updateClient,
  deleteProject,
  updateProject,
  deleteTask,
  deleteUser,
  updateTask,
  updateUser,
  getMainEntryReport,
  createContact,
  getPermissionTypes,
  assignTeamToProject,
};

export default timeTrackApis;
