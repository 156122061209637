import React from "react";
import PropTypes from "prop-types";
import "./ButtonGroup.scss";
import Button from "../Button";
import BemClass from "../Utils/bemClass";

const propTypes = {
  children: PropTypes.arrayOf(Button),
  block: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const defaultProps = {
  block: false,
  className: "",
};

const ButtonGroup = ({ block, children, className, ...props }) => {
  const classes = new BemClass("ButtonGroup", className);

  classes.mod({
    block,
  });

  return (
    <div className={classes.b()} {...props}>
      {children}
    </div>
  );
};

ButtonGroup.propTypes = propTypes;
ButtonGroup.defaultProps = defaultProps;

export default ButtonGroup;
