export const GET_HRM_CANDIDATES_REQUEST = "GET_HRM_CANDIDATES_REQUEST";
export const GET_HRM_CANDIDATES_SUCCESS = "GET_HRM_CANDIDATES_SUCCESS";
export const GET_HRM_CANDIDATES_FAILURE = "GET_HRM_CANDIDATES_FAILURE";

export const CREATE_HRM_CANDIDATE_REQUEST = "CREATE_HRM_CANDIDATE_REQUEST";
export const CREATE_HRM_CANDIDATE_SUCCESS = "CREATE_HRM_CANDIDATE_SUCCESS";
export const CREATE_HRM_CANDIDATE_FAILURE = "CREATE_HRM_CANDIDATE_FAILURE";

export const CREATE_HRM_CANDIDATE_QUESTION_CATEGORIES_REQUEST =
  "CREATE_HRM_CANDIDATE_QUESTION_CATEGORIES_REQUEST";
export const CREATE_HRM_CANDIDATE_QUESTION_CATEGORIES_SUCCESS =
  "CREATE_HRM_CANDIDATE_QUESTION_CATEGORIES_SUCCESS";
export const CREATE_HRM_CANDIDATE_QUESTION_CATEGORIES_FAILURE =
  "CREATE_HRM_CANDIDATE_QUESTION_CATEGORIES_FAILURE";

export const GET_HRM_CANDIDATE_REQUEST = "GET_HRM_CANDIDATE_REQUEST";
export const GET_HRM_CANDIDATE_SUCCESS = "GET_HRM_CANDIDATE_SUCCESS";
export const GET_HRM_CANDIDATE_FAILURE = "GET_HRM_CANDIDATE_FAILURE";

export const CREATE_HRM_CANDIDATE_FEEDBACK_REQUEST =
  "CREATE_HRM_CANDIDATE_FEEDBACK_REQUEST";
export const CREATE_HRM_CANDIDATE_FEEDBACK_SUCCESS =
  "CREATE_HRM_CANDIDATE_FEEDBACK_SUCCESS";
export const CREATE_HRM_CANDIDATE_FEEDBACK_FAILURE =
  "CREATE_HRM_CANDIDATE_FEEDBACK_FAILURE";

export const GET_HRM_ALL_CANDIDATE_FEEDBACK_REQUEST =
  "GET_HRM_ALL_CANDIDATE_FEEDBACK_REQUEST";
export const GET_HRM_ALL_CANDIDATE_FEEDBACK_SUCCESS =
  "GET_HRM_ALL_CANDIDATE_FEEDBACK_SUCCESS";
export const GET_HRM_ALL_CANDIDATE_FEEDBACK_FAILURE =
  "GET_HRM_ALL_CANDIDATE_FEEDBACK_FAILURE";

export const UPDATE_HRM_CANDIDATE_REQUEST = "UPDATE_HRM_CANDIDATE_REQUEST";
export const UPDATE_HRM_CANDIDATE_SUCCESS = "UPDATE_HRM_CANDIDATE_SUCCESS";
export const UPDATE_HRM_CANDIDATE_FAILURE = "UPDATE_HRM_CANDIDATE_FAILURE";

export const DELETE_HRM_CANDIDATE_REQUEST = "DELETE_HRM_CANDIDATE_REQUEST";
export const DELETE_HRM_CANDIDATE_SUCCESS = "DELETE_HRM_CANDIDATE_SUCCESS";
export const DELETE_HRM_CANDIDATE_FAILURE = "DELETE_HRM_CANDIDATE_FAILURE";

export const CREATE_HRM_DIRECT_CANDIDATE_REQUEST =
  "CREATE_HRM_DIRECT_CANDIDATE_REQUEST";
export const CREATE_HRM_DIRECT_CANDIDATE_SUCCESS =
  "CREATE_HRM_DIRECT_CANDIDATE_SUCCESS";
export const CREATE_HRM_DIRECT_CANDIDATE_FAILURE =
  "CREATE_HRM_DIRECT_CANDIDATE_FAILURE";

export const GET_HRM_CANDIDATE_INTERVIEW_INVITATION_REQUEST =
  "GET_HRM_CANDIDATE_INTERVIEW_INVITATION_REQUEST";
export const GET_HRM_CANDIDATE_INTERVIEW_INVITATION_SUCCESS =
  "GET_HRM_CANDIDATE_INTERVIEW_INVITATION_SUCCESS";
export const GET_HRM_CANDIDATE_INTERVIEW_INVITATION_FAILURE =
  "GET_HRM_CANDIDATE_INTERVIEW_INVITATION_FAILURE";

export const CREATE_HRM_CANDIDATE_INTERVIEW_INVITATION_REQUEST =
  "CREATE_HRM_CANDIDATE_INTERVIEW_INVITATION_REQUEST";
export const CREATE_HRM_CANDIDATE_INTERVIEW_INVITATION_SUCCESS =
  "CREATE_HRM_CANDIDATE_INTERVIEW_INVITATION_SUCCESS";
export const CREATE_HRM_CANDIDATE_INTERVIEW_INVITATION_FAILURE =
  "CREATE_HRM_CANDIDATE_INTERVIEW_INVITATION_FAILURE";
