import * as actions from "./actionTypes";

export const getCategories = () => ({
  type: actions.GET_INVENTORY_CATEGORIES_REQUEST,
});

export const createCategory = (data) => ({
  type: actions.CREATE_INVENTORY_CATEGORY_REQUEST,
  payload: data,
});

export const updateCategory = (data) => ({
  type: actions.UPDATE_INVENTORY_CATEGORY_REQUEST,
  payload: data,
});
