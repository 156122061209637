import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin`;
const apiUrlNew = `${process.env.REACT_APP_API_URL}/api/v1/administration`;

export const featureAccessRole = async (token, userId) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/featureAccess/role`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createDesignation = async (token, details) => {
  return axios({
    method: "POST",
    url: `${apiUrlNew}/user/designations`,
    headers: {
      "x-access-token": token,
    },
    data: details,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
