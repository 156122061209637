import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import timeTrackModule from "../../../../redux/timetracking";
import { Container, Row, Col, Button, ButtonGroup } from "reactstrap";
import BEMhelper from "react-bem-helper";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AddCategoryModal from "../../../components/modals/timeTracking/addNewCategoryModal";
import CustomTable from "../../../components/table";
import DeleteModal from "../../../components/modals/common/deleteModal";
import Text from "../../../components/text";
import _ from "lodash";

class TimeTrackReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment(),
      startDate: moment().startOf("month").format("D"),
      endDate: moment().endOf("month").format("D"),
      month: moment().format("MMMM"),
      year: moment().format("YYYY"),
      filterBy: "month",
      totalHours: 0,
    };
  }

  componentDidMount() {
    const from = moment().startOf("month").format("YYYY-MM-DD");
    const to = moment().endOf("month").format("YYYY-MM-DD");
    const data = {
      from: from,
      to: to,
    };
    this.props.getMainEntryReport(data);
  }

  getTableHeading = () => {
    return [
      "Date",
      "Description",
      "Client",
      "Project",
      "Category",
      "Task",
      "Hours",
    ];
  };

  getTableContent = (data) => {
    return data.map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>{row.date}</td>
          <td>{row.description}</td>
          <td>{row.timeTrackProject.timeTrackClient.name}</td>
          <td>{row.timeTrackProject.name}</td>
          <td>{row.timeTrackTask.timeTrackCategory.name}</td>
          <td>{row.timeTrackTask.name}</td>
          <td>
            {row.hours}:{row.minutes}
          </td>
        </tr>
      );
    });
  };

  changeSelectedDate = (type) => {
    if (this.state.filterBy === "month") {
      let updatedDate = null;
      if (type === "next") {
        updatedDate = moment(this.state.selectedDate).add(1, "M");
      } else if (type === "prev") {
        updatedDate = moment(this.state.selectedDate).subtract(1, "M");
      } else {
        updatedDate = moment();
      }
      const monthName = updatedDate.format("MMMM");
      const year = updatedDate.format("YYYY");
      const futureMonthStart = moment(updatedDate).startOf("month").format("D");
      const futureMonthEnd = moment(updatedDate).endOf("month").format("D");

      this.setState({
        month: monthName,
        selectedDate: updatedDate,
        startDate: futureMonthStart,
        endDate: futureMonthEnd,
        year: year,
      });

      const data = {
        from: moment(updatedDate).startOf("month").format("YYYY-MM-DD"),
        to: moment(updatedDate).endOf("month").format("YYYY-MM-DD"),
      };
      this.props.getMainEntryReport(data);
    }
  };

  getTotalHours = () => {
    let hours = 0;
    let minutes = 0;
    _.forEach(this.props.entryReportData.data, function (entry) {
      hours = hours + parseInt(entry.hours);
      minutes = minutes + parseInt(entry.minutes);
    });
    if (minutes > 59) {
      const duration = moment.duration(minutes, "minutes");
      hours = hours + duration.hours();
      minutes = duration.minutes();
    }
    return this.props.entryReportData.data.length > 0
      ? `${hours}:${minutes}`
      : this.state.totalHours;
  };

  render() {
    const classes = new BEMhelper("time-tracking");
    return (
      <Container {...classes("reports")}>
        {this.state.showUserModal ? (
          <AddCategoryModal
            openModal={this.state.showUserModal}
            toggle={this.toggle}
            title={this.state.editMode ? "Edit category" : "Add new category"}
            onSubmit={this.addCategory}
            isEditMode={this.state.editMode}
            objectToEdit={this.state.categorySelected}
          />
        ) : null}
        {this.state.showDeleteModal ? (
          <DeleteModal
            openModal={this.state.showDeleteModal}
            body={"Are you sure you want to delete this category?"}
            onClose={this.closeDeleteModal}
            onConfirm={this.deleteCategory}
          />
        ) : null}

        <Row>
          <Col sm={12} className="text-left">
            <Row>
              <Col sm={2}>
                <ButtonGroup>
                  <Button onClick={() => this.changeSelectedDate("prev")}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>
                  <Button onClick={() => this.changeSelectedDate("next")}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </ButtonGroup>
              </Col>
              <Col sm={8}>
                <h2>
                  {this.state.startDate} - {this.state.endDate}{" "}
                  {this.state.month} {this.state.year}
                </h2>
                <Text
                  color="bright-blue"
                  pointer
                  onClick={() => this.changeSelectedDate("current")}
                >
                  Return to current month
                </Text>
              </Col>
              <Col sm={2}>
                <Text bold>Total Hours</Text>
                <Text>{this.getTotalHours()}</Text>
              </Col>
            </Row>
          </Col>
          <Col sm={12}>
            <div className="hr"></div>
            {this.props.entryReportData.loading ? (
              <p>Loading...</p>
            ) : (
              <CustomTable
                content={this.getTableContent(this.props.entryReportData.data)}
                tableHeadings={this.getTableHeading()}
              />
            )}
          </Col>
          {/* <Col sm={12}>
            <div className="hr"></div>
            <Row>
              <Col sm={7}>
                <Row>
                  <Col sm={6}>
                    <Card body> Hours tracked</Card>
                  </Col>
                  <Col sm={6}>
                    <Card body>Projects assigned</Card>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Card body>Billable amount</Card>
                  </Col>
                  <Col sm={6}>
                    <Card body>lol</Card>
                  </Col>
                </Row>
              </Col>
              <Col sm={5}>
                <Card body>
                  <div id="chart">{this.renderchart()}</div>
                </Card>
              </Col>
            </Row>
          </Col> */}
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMainEntryReport:
        timeTrackModule.reportModule.actions.getMainEntryReport,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    categories: state.timeTrackingEntryCategories,
    entryReportData: state.timeTrackingEntryReport,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(TimeTrackReports);
