import React from "react";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import Input from "../../../../components/inputNew";
import Button from "../../../../components/ui-components/Button";
import BEMhelper from "react-bem-helper";
import "../Addusers/AddUser.scss";

const classes = new BEMhelper("AddUserStepThree");
const StepThree = (props) => {
  const { submit, changeTab } = props;

  const defaultValues = {
    police: props.isEditMode ? props.stepThreeData.police : null,
    passportnumber: props.isEditMode
      ? props.stepThreeData.passportnumber
      : null,
    drivinglicensenumber: props.isEditMode
      ? props.stepThreeData.drivinglicensenumber
      : null,
    electorate: props.isEditMode ? props.stepThreeData.electorate : null,
  };

  const { handleSubmit, register, errors } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => submit(values);

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...classes("formThree")}>
      <Container fluid>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>Police Station</Label>
              <Input
                name="police"
                register={register}
                state={errors.police ? "error" : "none"}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Passport Number</Label>
              <Input
                name="passportnumber"
                register={register}
                state={errors.passportnumber ? "error" : "none"}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Driving License Number</Label>
              <Input
                name="drivinglicensenumber"
                register={register}
                state={errors.drivinglicensenumber ? "error" : "none"}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>Electorate</Label>
              <Input
                name="electorate"
                register={register}
                state={errors.electorate ? "error" : "none"}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup {...classes("buttonGroup")}>
              <Button color="light-grey" onClick={() => changeTab(1)}>
                Prev
              </Button>{" "}
              <Button submit>Submit</Button>
            </FormGroup>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default StepThree;
