import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  ASSIGN_TEAM_TO_PROJECT_REQUEST,
  ASSIGN_TEAM_TO_PROJECT_SUCCESS,
  ASSIGN_TEAM_TO_PROJECT_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";

export function* projectSaga() {
  yield takeLatest(GET_PROJECTS_REQUEST, getProjects);
  yield takeLatest(CREATE_PROJECT_REQUEST, createProject);
  yield takeLatest(UPDATE_PROJECT_REQUEST, updateProject);
  yield takeLatest(DELETE_PROJECT_REQUEST, deleteProject);
  yield takeLatest(ASSIGN_TEAM_TO_PROJECT_REQUEST, assignTeamToProject);
}

function* getProjects() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const projects = yield call(
      apis.timeTrackApis.getProjects,
      loggedUser.userDetails.auth.accessToken
    );
    if (projects.error) {
      yield put({
        type: GET_PROJECTS_FAILURE,
        error: projects.body.data.error,
      });
    } else {
      yield put({ type: GET_PROJECTS_SUCCESS, data: projects.body.data });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createProject(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const project = yield call(
      apis.timeTrackApis.createProject,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (project.error) {
      yield put({
        type: CREATE_PROJECT_FAILURE,
        error: project.body.data.error,
      });
    } else {
      yield put({ type: CREATE_PROJECT_SUCCESS, data: project.body.data });
      yield put({ type: GET_PROJECTS_REQUEST });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* updateProject(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const project = yield call(
      apis.timeTrackApis.updateProject,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (project.error) {
      yield put({
        type: UPDATE_PROJECT_FAILURE,
        error: project.body.data.error,
      });
    } else {
      yield put({ type: UPDATE_PROJECT_SUCCESS, data: project.body.data });
      const data = {
        date: details.payload.formValues.date,
      };
      yield put({ type: GET_PROJECTS_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* deleteProject(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const project = yield call(
      apis.timeTrackApis.deleteProject,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues.projectId
    );
    if (project.error) {
      yield put({
        type: DELETE_PROJECT_FAILURE,
        error: project.body.data.error,
      });
    } else {
      yield put({ type: DELETE_PROJECT_SUCCESS, data: project.body.data });
      const data = {
        date: details.payload.formValues.date,
      };
      yield put({ type: GET_PROJECTS_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* assignTeamToProject(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const assignedTeam = yield call(
      apis.timeTrackApis.assignTeamToProject,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (assignedTeam.error) {
      yield put({
        type: ASSIGN_TEAM_TO_PROJECT_FAILURE,
        error: assignedTeam.body.data.error,
      });
    } else {
      yield put({
        type: ASSIGN_TEAM_TO_PROJECT_SUCCESS,
        data: assignedTeam.body.data,
      });
      yield put({ type: GET_PROJECTS_REQUEST });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
