export const GET_ENTRIES_FOR_DAY_REQUEST = "GET_ENTRIES_FOR_DAY_REQUEST";
export const GET_ENTRIES_FOR_DAY_SUCCESS = "GET_ENTRIES_FOR_DAY_SUCCESS";
export const GET_ENTRIES_FOR_DAY_FAILURE = "GET_ENTRIES_FOR_DAY_FAILURE";

export const CREATE_ENTRY_REQUEST = "CREATE_ENTRY_REQUEST";
export const CREATE_ENTRY_SUCCESS = "CREATE_ENTRY_SUCCESS";
export const CREATE_ENTRY_FAILURE = "CREATE_ENTRY_FAILURE";

export const UPDATE_ENTRY_REQUEST = "UPDATE_ENTRY_REQUEST";
export const UPDATE_ENTRY_SUCCESS = "UPDATE_ENTRY_SUCCESS";
export const UPDATE_ENTRY_FAILURE = "UPDATE_ENTRY_FAILURE";

export const DELETE_ENTRY_REQUEST = "DELETE_ENTRY_REQUEST";
export const DELETE_ENTRY_SUCCESS = "DELETE_ENTRY_SUCCESS";
export const DELETE_ENTRY_FAILURE = "DELETE_ENTRY_FAILURE";
