import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import { Container, Row, Col, Alert } from "reactstrap";
import BtnNormal from "../../button";

const AddFeatureAccess = (props) => {
  const {
    className,
    title,
    openModal,
    toggle,
    onSubmit,
    featureAccessRoleGroup,
    userGroup,
    addFeatureAccessGroup,
  } = props;

  const onValidSubmit = (event, values) => {
    onSubmit(values);
  };

  const onInvalidSubmit = (values) => {};

  const clearState = () => {
    toggle();
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        toggle={() => clearState()}
        className={className}
      >
        <ModalHeader toggle={() => clearState()}>{title}</ModalHeader>
        <AvForm onValidSubmit={onValidSubmit} onInvalidSubmit={onInvalidSubmit}>
          <ModalBody>
            {featureAccessRoleGroup.loading || userGroup.loading ? (
              <p>Add spinner here.....</p>
            ) : (
              <Container fluid className="add-user-wrapper">
                {addFeatureAccessGroup.error ? (
                  <Row>
                    <Col sm={12}>
                      <Alert color="danger">
                        <p>{addFeatureAccessGroup.error}</p>
                      </Alert>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col sm={12}>
                    <AvGroup>
                      <AvInput
                        type="select"
                        name="accessType"
                        id="accessType"
                        required
                      >
                        <option value="">Access type</option>
                        {featureAccessRoleGroup.data.map((accessType, key) => {
                          return (
                            <option
                              value={accessType.featureAccessRoleId}
                              key={key}
                            >
                              {accessType.roleType}
                            </option>
                          );
                        })}
                      </AvInput>
                    </AvGroup>
                  </Col>
                  <Col sm={12}>
                    <AvGroup>
                      <AvInput type="select" name="user" id="user" required>
                        <option value="">User</option>
                        {userGroup.userList.map((user, key) => {
                          return (
                            <option value={user.userId} key={key}>
                              {user.firstName} {user.lastName}
                            </option>
                          );
                        })}
                      </AvInput>
                    </AvGroup>
                  </Col>
                </Row>
              </Container>
            )}
          </ModalBody>
          <ModalFooter>
            <BtnNormal color="primary" text="Add" loading={false} />
          </ModalFooter>
        </AvForm>
      </Modal>
    </div>
  );
};

export default AddFeatureAccess;
