import { LEAVE_BALANCE_REQUEST, LEAVE_BALANCE_SUCCESS, LEAVE_BALANCE_FAILURE } from '../actionTypes';

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const leaveBalanceNewReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEAVE_BALANCE_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null
      };
    case LEAVE_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null
      };
    case LEAVE_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error
      };
    default:
      return state;
  }
};

export default leaveBalanceNewReducer;