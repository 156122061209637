import * as actions from "./actionTypes";

export const getReport = (data) => ({
  type: actions.GET_INVENTORY_REPORT_REQUEST,
  payload: data,
});

export const getCustomReport = (data) => ({
  type: actions.GET_INVENTORY_CUSTOM_REPORT_REQUEST,
  payload: data,
});

export const downloadCustomReport = (data) => ({
  type: actions.GET_INVENTORY_CUSTOM_REPORT_DOWNLOAD_REQUEST,
  payload: data,
});

export const resetReport = () => ({
  type: actions.RESET_REPORT_REQUEST,
});
