import * as actions from "./actionTypes";

export const getVacancies = () => ({
  type: actions.GET_HRM_VACANCIES_REQUEST,
});

export const createVacancy = (data) => ({
  type: actions.CREATE_HRM_VACANCIES_REQUEST,
  payload: data,
});

export const updateVacancy = (data) => ({
  type: actions.UPDATE_HRM_VACANCIES_REQUEST,
  payload: data,
});

export const getVacancy = (data) => ({
  type: actions.GET_HRM_VACANCY_REQUEST,
  payload: data,
});
