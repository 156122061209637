import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import timeTrackModule from "../../../../redux/timetracking";
import { Container, Row, Col, ButtonGroup } from "reactstrap";
import BEMhelper from "react-bem-helper";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import AddClientModal from "../../../components/modals/timeTracking/addClientModal";
import CustomTable from "../../../components/table";
import DeleteModal from "../../../components/modals/common/deleteModal";
import Button from "../../../components/ui-components/Button";
import AddContactModal from "../../../components/modals/timeTracking/addContactModal";

class TimeTrackClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClientModal: false,
      showDeleteModal: false,
      showContactModal: false,
      clientSelected: null,
      editMode: false,
    };
  }

  addClient = (values) => {
    const data = {
      formValues: {
        name: values.name,
        address: values.address,
        currency: values.currency.value,
        ...(this.state.editMode && {
          clientId: this.state.clientSelected.timeTrackClientId,
        }),
      },
      closeModal: this.toggle,
    };
    if (this.state.editMode) {
      this.props.updateClient(data);
    } else {
      this.props.createClient(data);
    }
  };

  addContact = (values) => {
    const data = {
      formValues: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        title: values.title,
        officeNumber: values.officeNumber,
        mobileNumber: values.mobileNumber,
        faxNumber: values.faxNumber,
        clientId: this.state.clientSelected.timeTrackClientId,
      },
      closeModal: this.toggle,
    };
    this.props.createContact(data);
    // if (this.state.editMode) {
    //   this.props.updateClient(data);
    // } else {
    //   this.props.createContact(data);
    // }
  };

  openForAdd = () => {
    this.setState({
      editMode: false,
      clientSelected: null,
    });
    this.toggle(true);
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      clientSelected: null,
    });
  };

  openForEdit = (client) => {
    this.setState({
      editMode: true,
      clientSelected: client,
    });
    this.toggle(true);
  };

  openForDelete = (client) => {
    this.setState({
      showDeleteModal: true,
      clientSelected: client,
    });
  };

  openForAddContact = (client) => {
    this.setState({
      showContactModal: true,
      clientSelected: client,
    });
  };

  toggle = (toggleClientState = false, toggleContactState = false) => {
    this.setState({
      showClientModal: toggleClientState,
      showContactModal: toggleContactState,
    });
  };

  deleteClient = () => {
    const data = {
      formValues: {
        clientId: this.state.clientSelected.timeTrackClientId,
      },
      closeModal: this.closeDeleteModal,
    };
    this.props.deleteClient(data);
  };

  componentDidMount() {
    this.props.getClients();
  }

  getTableHeading = () => {
    return ["Name", "Address", "Currency", "Added on", ""];
  };

  getTableContent = (data) => {
    return data.map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>{row.name}</td>
          <td>{row.address}</td>
          <td>{row.currency}</td>
          <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
          <td>
            <ButtonGroup size="sm">
              <Button
                outline
                color="blue"
                size="sm"
                onClick={() => this.openForAddContact(row)}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <Button
                outline
                color="info"
                size="sm"
                onClick={() => this.openForEdit(row)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              {/* <Button
                outline
                color="danger"
                size="sm"
                onClick={() => this.openForDelete(row)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button> */}
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  render() {
    const classes = new BEMhelper("time-tracking");
    return (
      <Container {...classes("clients")}>
        {this.state.showClientModal ? (
          <AddClientModal
            openModal={this.state.showClientModal}
            toggle={this.toggle}
            title={this.state.editMode ? "Edit client" : "Add new client"}
            submit={this.addClient}
            isEditMode={this.state.editMode}
            objectToEdit={this.state.clientSelected}
          />
        ) : null}
        {this.state.showDeleteModal ? (
          <DeleteModal
            openModal={this.state.showDeleteModal}
            body={"Are you sure you want to delete this client?"}
            onClose={this.closeDeleteModal}
            onConfirm={this.deleteClient}
          />
        ) : null}
        {this.state.showContactModal ? (
          <AddContactModal
            openModal={this.state.showContactModal}
            toggle={this.toggle}
            title={this.state.editMode ? "Edit contact" : "Add new contact"}
            submit={this.addContact}
            isEditMode={this.state.editMode}
            objectToEdit={this.state.clientSelected}
          />
        ) : null}

        <Row>
          <Col sm={9} className="text-left">
            <h2 className="list-header-title">Clients</h2>
          </Col>
          <Col sm={3} className="text-right">
            <Button
              className="btn-primary"
              onClick={() => {
                this.openForAdd();
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> New Client
            </Button>
          </Col>

          <Col sm={12}>
            <div className="hr"></div>
            {this.props.clients.loading ? (
              <p>Loading...</p>
            ) : (
              <CustomTable
                content={this.getTableContent(this.props.clients.data)}
                tableHeadings={this.getTableHeading()}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createClient: timeTrackModule.clientModule.actions.createClient,
      getClients: timeTrackModule.clientModule.actions.getClients,
      deleteClient: timeTrackModule.clientModule.actions.deleteClient,
      updateClient: timeTrackModule.clientModule.actions.updateClient,
      createContact: timeTrackModule.clientModule.actions.createContact,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    clients: state.timeTrackingClients,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(TimeTrackClient);
