import React, { Component } from "react";
import { bindActionCreators } from "redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap";
import StarRatings from "react-star-ratings";
import { connect } from "react-redux";
import hrmModule from "../../../../../../redux/hrm";
import userModule from "../../../../../../redux/user";
import Button from "../../../../../components/ui-components/Button";
import BEMhelper from "react-bem-helper";
import {
  FaPlus,
  FaMinusCircle,
  FaFilePdf,
  FaListAlt,
  FaCheck,
  FaTimes,
} from "react-icons/fa";
import InfoModal from "../../../../../components/modals/infoModal";
import RejectReasonModal from "../../../../../components/modals/hrm/recruitment/candidate/rejectReason";
import TurnDownReasonModal from "../../../../../components/modals/hrm/recruitment/candidate/turnDownReason";
import SingleFeedback from "../singleFeedback";
import CustomTable from "../../../../../components/table";
import processStatusses from "../../../../../../enum/common/processStatus";
import SendInterviewInvitationModal from "../../../../../components/modals/hrm/recruitment/candidate/sendInterviewInvitation";
import "./candidateApplication.scss";

class CandidateApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResumeModal: false,
      showInvitationListModal: false,
      showSendInvitationModal: false,
      showRejectModal: false,
      showTurnDownModal: false,
    };
  }

  changeRating = (value, key) => {
    const questions = this.state.questions;
    questions[key].rating = value;
    this.setState({ questions: questions });
  };

  getFeedbacks = (data) => {
    if (data.feedbacks) {
      return data.feedbacks.map((feedback) => {
        return <SingleFeedback feedback={feedback} />;
      });
    }
  };

  getOverallColor = (number, isStatus = false) => {
    if (isStatus) {
      if (number === 1) {
        return "warning";
      } else if (number === 2) {
        return "success";
      } else {
        return "danger";
      }
    }
    if (number > 3.5) {
      return "green";
    } else if (number >= 2.5 && number <= 3.5) {
      return "orange";
    } else {
      return "red";
    }
  };

  getResume = () => {
    return (
      <div className="candidates__cvPool-resumePreview">
        <iframe
          src={this.props.candidate.data.cvUrl}
          width="100%"
          height="100%"
          className="iframe"
        ></iframe>
      </div>
    );
  };

  getInvitationList = () => {
    const content = this.props.candidateInterviews.data.map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>
            {row.scheduledToUser.firstName} {row.scheduledToUser.lastName}
          </td>
          <td>{row.scheduledToUser.designation.name}</td>
          <td>{row.hrmInterviewStage.name}</td>
        </tr>
      );
    });
    return (
      <CustomTable
        content={content}
        tableHeadings={[
          "Member name",
          "Member designation",
          "Interview status",
        ]}
      />
    );
  };

  rejectCandidate = (values) => {
    this.takeAction(processStatusses.Rejected, false, values.reason);
    this.closeResumeModal();
  };

  turnDownOffer = (values) => {
    this.takeAction(
      this.props.candidate.data.processStatus.processStatusId,
      false,
      null,
      false,
      { isturedDown: true, reason: values.reason || null }
    );
    this.closeResumeModal();
  };

  takeAction = (
    action,
    isHired = false,
    rejectReason = null,
    addHiredDate = false,
    offerTurnDown = { isturedDown: false, reason: null }
  ) => {
    let candidate = this.props.candidate.data;
    const formData = new FormData();
    formData.append("name", candidate.name);
    if (candidate.noticePeriod) {
      formData.append("noticePeriod", candidate.noticePeriod);
    }
    formData.append("appliedVacancy", candidate.hrmVacancyId);
    if (candidate.expectedSalary) {
      formData.append("expectedSalary", candidate.expectedSalary);
    }
    formData.append("sourceOfHire", candidate.sourceOfHire);
    formData.append("email", candidate.email);
    formData.append("cvUrl", candidate.cvUrl);
    formData.append("processStatusId", action);
    formData.append("rejectReason", rejectReason);
    formData.append("hired", isHired);
    if (candidate.hrmAgent) {
      formData.append("agentId", candidate.hrmAgent.hrmAgentId);
    }
    if (
      addHiredDate &&
      candidate.processStatus.processStatusId === processStatusses.Approved
    ) {
      formData.append("updateHiredDate", addHiredDate);
    }
    if (
      offerTurnDown.isturedDown &&
      candidate.processStatus.processStatusId !== processStatusses.Rejected
    ) {
      formData.append("isOfferTurnDown", offerTurnDown.isturedDown);
      formData.append("turnDownReason", offerTurnDown.reason);
    }
    if (action === processStatusses.Rejected) {
      formData.append("sendRejectMail", true);
    }
    const data = {
      formValues: formData,
      candidateId: candidate.hrmCandidateId,
    };
    this.props.updateCandidate(data);
  };

  closeResumeModal = () => {
    this.setState({
      showResumeModal: false,
      showInvitationListModal: false,
      showSendInvitationModal: false,
      showRejectModal: false,
      showTurnDownModal: false,
    });
  };

  sendInvitation = (values) => {
    const data = {
      formValues: {
        candidateId: this.props.match.params.applicationId,
        interviewStageId: values.stage.value,
        scheduledTo: values.interviewer.value,
        reviewLink: `${window.location.origin}/hrm/cvPool/feedback`,
      },
      closeModal: this.closeResumeModal,
    };
    this.props.createInterviewInvitation(data);
  };

  componentDidMount = () => {
    this.props.getCandidate({
      candidateId: this.props.match.params.applicationId,
    });
    this.props.getAllCandidateFeedback({
      candidateId: this.props.match.params.applicationId,
    });
    this.props.getInterviewInvitations({
      candidateId: this.props.match.params.applicationId,
    });
    this.props.getStages();
    this.props.getUserList();
  };

  render() {
    const classes = new BEMhelper("application");
    return (
      <Container fluid {...classes("candidate")}>
        {this.state.showResumeModal ? (
          <InfoModal
            openModal={this.state.showResumeModal}
            toggle={this.closeResumeModal}
            title={"View Resume"}
            body={this.getResume()}
          />
        ) : null}
        {this.state.showInvitationListModal ? (
          <InfoModal
            openModal={this.state.showInvitationListModal}
            toggle={this.closeResumeModal}
            title={"Invitation List"}
            body={this.getInvitationList()}
          />
        ) : null}
        {this.state.showSendInvitationModal ? (
          <SendInterviewInvitationModal
            openModal={this.state.showSendInvitationModal}
            toggle={this.closeResumeModal}
            title={"Send Invitation"}
            submit={this.sendInvitation}
            stages={this.props.stages.data}
            team={this.props.userList.userList}
            creatInvitation={this.props.creatInvitation}
          />
        ) : null}
        {this.state.showRejectModal ? (
          <RejectReasonModal
            openModal={this.state.showRejectModal}
            toggle={this.closeResumeModal}
            title={"Add Reject Reason"}
            submit={this.rejectCandidate}
          />
        ) : null}
        {this.state.showTurnDownModal ? (
          <TurnDownReasonModal
            openModal={this.state.showTurnDownModal}
            toggle={this.closeResumeModal}
            title={"Add Turn Down Reason"}
            submit={this.turnDownOffer}
          />
        ) : null}

        {this.props.candidate.loading || !this.props.candidate.data ? (
          <p>Loading...</p>
        ) : (
          <React.Fragment>
            <Row>
              <Col sm={3} className="text-left">
                <div className="headerTitle-wrap">
                  <h2 className="list-header-title">Application</h2>
                </div>
              </Col>
              <Col sm={9} className="text-right">
                {this.props.candidate.data.processStatus.processStatusId !==
                  processStatusses.Rejected &&
                !this.props.candidate.data.hired &&
                !this.props.candidate.data.hrmOfferTurnDownId ? (
                  <Button
                    color="red"
                    onClick={() => {
                      this.setState({ showTurnDownModal: true });
                    }}
                    className="application-action-btn"
                  >
                    <FaTimes /> Offer Turn Down
                  </Button>
                ) : null}
                <Button
                  onClick={() => {
                    this.setState({
                      showResumeModal: true,
                    });
                  }}
                  className="application-action-btn"
                  color="medium-blue"
                >
                  <FaFilePdf /> View Resume
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      showInvitationListModal: true,
                    });
                  }}
                  className="application-action-btn"
                  color="medium-blue"
                >
                  <FaListAlt /> Invitation List
                </Button>
                {this.props.candidate.data.processStatus.processStatusId ===
                  processStatusses.Pending &&
                !this.props.candidate.data.hrmOfferTurnDownId ? (
                  <Button
                    onClick={() => {
                      this.setState({
                        showSendInvitationModal: true,
                      });
                    }}
                    className="application-action-btn"
                    color="medium-blue"
                  >
                    <FaPlus /> Send Invitation
                  </Button>
                ) : null}
                {this.props.authorized &&
                !this.props.candidate.data.hired &&
                !this.props.candidate.data.hrmOfferTurnDownId ? (
                  <React.Fragment>
                    {this.props.candidate.data.processStatus.processStatusId ===
                      processStatusses.Approved ||
                    this.props.candidate.data.processStatus.processStatusId ===
                      processStatusses.Pending ? (
                      <Button
                        color="red"
                        onClick={() => {
                          this.setState({ showRejectModal: true });
                        }}
                        className="application-action-btn"
                      >
                        <FaMinusCircle /> Reject
                      </Button>
                    ) : null}

                    {this.props.candidate.data.processStatus.processStatusId ===
                    processStatusses.Pending ? (
                      <Button
                        color="deep-blue"
                        onClick={() => {
                          this.takeAction(processStatusses.Approved);
                        }}
                      >
                        <FaCheck /> Approve
                      </Button>
                    ) : null}

                    {this.props.candidate.data.processStatus.processStatusId ===
                    processStatusses.Approved ? (
                      <Button
                        color="deep-blue"
                        onClick={() => {
                          this.takeAction(
                            processStatusses.Approved,
                            true,
                            null,
                            true
                          );
                        }}
                      >
                        Hired
                      </Button>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className="hr"></div>
                {this.props.candidateFeedbacks.loading ? (
                  <p>Loading...</p>
                ) : (
                  <div>
                    <Card body>
                      <CardText>
                        <ListGroup>
                          <ListGroupItem>
                            <Row>
                              <Col md={3}>
                                <h5>Candidate Name</h5>
                              </Col>
                              <Col md={3} className="row-breaker">
                                <p>{this.props.candidate.data.name}</p>
                              </Col>
                              <Col md={3}>
                                <h5>Applied Vacancy</h5>
                              </Col>
                              <Col md={3}>
                                <p>
                                  {this.props.candidate.data.hrmVacancy.name}
                                </p>
                              </Col>
                            </Row>
                          </ListGroupItem>
                          <ListGroupItem>
                            <Row>
                              <Col md={3}>
                                <h5>Last Updated By</h5>
                              </Col>
                              <Col md={3} className="row-breaker">
                                <p>
                                  {this.props.candidate.data.processStatusUpdate
                                    ? `${this.props.candidate.data.processStatusUpdate.firstName}
                                        ${this.props.candidate.data.processStatusUpdate.lastName}`
                                    : "-"}
                                </p>
                              </Col>
                              <Col md={3}>
                                <h5>Average Rating</h5>
                              </Col>
                              <Col md={3}>
                                <StarRatings
                                  rating={
                                    this.props.candidateFeedbacks.data
                                      .overallRating || 0
                                  }
                                  starRatedColor="blue"
                                  isSelectable={false}
                                  numberOfStars={5}
                                  name="rating"
                                  starDimension="20px"
                                  starSpacing="5px"
                                  starRatedColor={this.getOverallColor(
                                    this.props.candidateFeedbacks.data
                                      .overallRating
                                  )}
                                />
                              </Col>
                            </Row>
                          </ListGroupItem>
                          <ListGroupItem>
                            <Row>
                              <Col md={3}>
                                <h5>Status</h5>
                              </Col>
                              <Col md={3} className="row-breaker">
                                <p>
                                  <Badge
                                    pill
                                    color={this.getOverallColor(
                                      this.props.candidate.data.processStatus
                                        .processStatusId,
                                      true
                                    )}
                                  >
                                    {this.props.candidate.data.hired
                                      ? "Hired"
                                      : this.props.candidate.data
                                          .hrmOfferTurnDownId
                                      ? "Offer Turned Down"
                                      : this.props.candidate.data.processStatus
                                          .status}
                                  </Badge>
                                </p>
                              </Col>
                              <Col md={3}>
                                <h5>Notice Period</h5>
                              </Col>
                              <Col md={3}>
                                <p>{this.props.candidate.data.noticePeriod}</p>
                              </Col>
                            </Row>
                          </ListGroupItem>
                          <ListGroupItem>
                            <Row>
                              <Col md={3}>
                                <h5>Source Of Hire</h5>
                              </Col>
                              <Col md={3} className="row-breaker">
                                <p>{this.props.candidate.data.sourceOfHire}</p>
                              </Col>
                              <Col md={3}>
                                <h5>Expected Salary</h5>
                              </Col>
                              <Col md={3}>
                                <p>
                                  {this.props.user.userDetails.accessRoles &&
                                  (this.props.user.userDetails.accessRoles
                                    .isAdmin ||
                                    this.props.user.userDetails.accessRoles
                                      .isHrmAdmin ||
                                    this.props.user.userDetails.accessRoles
                                      .isSuperAdmin)
                                    ? this.props.candidate.data.expectedSalary
                                    : "-"}
                                </p>
                              </Col>
                            </Row>
                          </ListGroupItem>
                          {this.props.candidate.data.sourceOfHire ===
                          "agency" ? (
                            <ListGroupItem>
                              <Row>
                                <Col md={3}>
                                  <h5>Agent</h5>
                                </Col>
                                <Col md={3}>
                                  <p>
                                    {
                                      this.props.candidate.data.hrmAgent
                                        .CompanyName
                                    }{" "}
                                    -
                                    {
                                      this.props.candidate.data.hrmAgent
                                        .agentName
                                    }
                                  </p>
                                </Col>
                              </Row>
                            </ListGroupItem>
                          ) : null}
                          {this.props.candidate.data.hrmOfferTurnDownId ? (
                            <ListGroupItem>
                              <Row>
                                <Col md={3}>
                                  <h5>Turn Down Reason</h5>
                                </Col>
                                <Col md={3}>
                                  <p>
                                    {this.props.candidate.data.hrmOfferTurnDown
                                      .reason || "-"}
                                  </p>
                                </Col>
                              </Row>
                            </ListGroupItem>
                          ) : null}
                          {this.props.candidate.data.rejectReason ? (
                            <ListGroupItem>
                              <Row>
                                <Col md={3}>
                                  <h5>Reject Reason</h5>
                                </Col>
                                <Col md={3}>
                                  <p>
                                    {this.props.candidate.data.rejectReason ||
                                      "-"}
                                  </p>
                                </Col>
                              </Row>
                            </ListGroupItem>
                          ) : null}
                        </ListGroup>
                      </CardText>
                    </Card>
                    {this.getFeedbacks(this.props.candidateFeedbacks.data)}
                  </div>
                )}
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCandidate: hrmModule.candidateModule.actions.getCandidate,
      getAllCandidateFeedback:
        hrmModule.candidateModule.actions.getAllCandidateFeedback,
      updateCandidate: hrmModule.candidateModule.actions.updateCandidate,
      getInterviewInvitations:
        hrmModule.candidateModule.actions.getInterviewInvitations,
      getStages: hrmModule.interviewStageModule.actions.getStages,
      getUserList: userModule.actions.getUserList,
      createInterviewInvitation:
        hrmModule.candidateModule.actions.createInterviewInvitation,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    candidate: state.hrmCandidate,
    candidateFeedbacks: state.hrmCandidateFeedbacks,
    candidateInterviews: state.hrmCandidateInterviews,
    stages: state.hrmInterviewStages,
    userList: state.userList,
    creatInvitation: state.hrmCreateItem,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(CandidateApplication);
