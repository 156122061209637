import React, { Component } from "react";
import PropTypes from "prop-types";
import "./FormFeedBack.scss";

class FormFeedBack extends Component {
  render() {
    const { feedBackType, feedBack } = this.props;

    return <div className={`Feedback__${feedBackType}`}>{feedBack}</div>;
  }
}

FormFeedBack.propTypes = {
  feedBackType: PropTypes.oneOf(["invalid", "valid", "info"]),
  feedBack: PropTypes.string,
};

FormFeedBack.defaultProps = {
  feedBackType: "invalid",
  feedBack: "Something is wrong!",
};

export default FormFeedBack;
