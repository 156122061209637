import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
import BEMhelper from "react-bem-helper";
import _ from "lodash";
import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";
import Button from "../../../ui-components/Button";
import Input from "../../../inputNew";
import FormFeedBack from "../../../ui-components/FormFeedBack";
import Toggle from "../../../ui-components/Toggle";
import Textarea from "../../../ui-components/Textarea";

const classes = new BEMhelper("AddProjectModal");
const AddProjectModal = (props) => {
  const {
    title,
    openModal,
    toggle,
    submit,
    clients,
    isEditMode,
    objectToEdit,
  } = props;
  let selectedClient = null;
  let selectedType = null;

  const clientOptions = clients.map((client) => {
    return {
      value: client.timeTrackClientId,
      label: client.name,
    };
  });

  const projectTypeList = [
    { value: "Fixed-budget", label: "Fixed budget" },
    { value: "Non-billable", label: "Non-billable" },
    { value: "Custom", label: "Custom" },
  ];

  if (isEditMode) {
    _.forEach(clientOptions, function (client) {
      if (client.value === objectToEdit.timeTrackClientId) {
        selectedClient = client;
      }
    });

    _.forEach(projectTypeList, function (type) {
      if (type.value === objectToEdit.type) {
        selectedType = type;
      }
    });
  }

  const defaultValues = {
    name: isEditMode ? objectToEdit.name : null,
    notes: isEditMode ? objectToEdit.notes : null,
    client: selectedClient,
    type: selectedType,
    goByPerson: isEditMode ? objectToEdit.goByUserRate : false,
    hourlyRate: isEditMode ? objectToEdit.hourlyRate : null,
    isActive: isEditMode ? objectToEdit.isActive : false,
  };

  const { handleSubmit, register, errors, control, watch } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    if (values.type.value === "Fixed-budget" && !values.fixedBudget) {
      toast.error("Please provide a budget", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (
      values.type.value === "Custom" &&
      !values.hourlyRate &&
      !values.goByPerson
    ) {
      toast.error("Please provide hourly rate or tick go by person rate", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      submit(values);
    }
  };
  const projectTypeWatcher = watch(["type", "goByPerson", "hourlyRate"]);

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => toggle()}
      centered={true}
      draggable={false}
      size="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => toggle()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    name="name"
                    register={register}
                    refdetails={{
                      required: "Project name required",
                    }}
                    state={errors.name ? "error" : "none"}
                  />
                  {errors.name ? (
                    <FormFeedBack feedBack={errors.name.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Client</Label>
                  <Controller
                    as={
                      <Select
                        placeholder="Select client"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: `1px solid ${
                              errors.client ? "#dc3545" : "#cad2dd"
                            }`,
                          }),
                        }}
                      />
                    }
                    options={clientOptions}
                    name="client"
                    isClearable
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    rules={{
                      required: "Client is required",
                    }}
                  />
                  {errors.client ? (
                    <FormFeedBack feedBack={errors.client.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Notes</Label>
                  <Textarea
                    name="notes"
                    register={register}
                    state={errors.notes ? "error" : "none"}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Project type</Label>
                  <Controller
                    as={
                      <Select
                        placeholder="Select type"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: `1px solid ${
                              errors.type ? "#dc3545" : "#cad2dd"
                            }`,
                          }),
                        }}
                      />
                    }
                    options={projectTypeList}
                    name="type"
                    isClearable
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    rules={{
                      required: "Project type is required",
                    }}
                  />
                  {errors.type ? (
                    <FormFeedBack feedBack={errors.type.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            {projectTypeWatcher && projectTypeWatcher.type ? (
              <Row>
                {projectTypeWatcher.type.value === "Fixed-budget" ? (
                  <Col md={6}>
                    <FormGroup>
                      <Label>Budget</Label>
                      <Input
                        name="fixedBudget"
                        register={register}
                        type="number"
                      />
                      <FormFeedBack
                        feedBack={"Project budget in dollars"}
                        feedBackType="info"
                      />
                    </FormGroup>
                  </Col>
                ) : projectTypeWatcher.type.value === "Custom" ? (
                  <React.Fragment>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Hourly rate</Label>
                        <Input
                          name="hourlyRate"
                          register={register}
                          type="number"
                          disabled={projectTypeWatcher.goByPerson}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Go by person rate</Label>
                        <Controller
                          as={
                            <Toggle
                              checked={
                                isEditMode ? objectToEdit.goByUserRate : false
                              }
                            />
                          }
                          name="goByPerson"
                          control={control}
                          onChange={([selected]) => {
                            return { value: selected };
                          }}
                        />
                        <FormFeedBack
                          feedBack={"Bill by each developers hourly rate"}
                          feedBackType="info"
                        />
                      </FormGroup>
                    </Col>
                  </React.Fragment>
                ) : null}
              </Row>
            ) : null}
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Active</Label>
                      <Controller
                        as={
                          <Toggle
                            checked={isEditMode ? objectToEdit.isActive : false}
                          />
                        }
                        name="isActive"
                        control={control}
                        onChange={([selected]) => {
                          return { value: selected };
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button submit>Submit</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddProjectModal;
