import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/inventory`;

export const getItems = async (token, details) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/item?offset=${details.offset}&searchVal=${
      details.searchVal ? details.searchVal : ""
    }&userId=${details.userId ? details.userId : ""}&filterType=${
      details.filterType ? details.filterType : ""
    }&excludeOccupied=${
      details.excludeOccupied ? details.excludeOccupied : ""
    }`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createItem = async (token, itemDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/item`,
    headers: {
      "x-access-token": token,
      "'content-type'": "multipart/form-data",
    },
    data: itemDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createItemsBulk = async (token, itemsDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/item-bulk`,
    headers: {
      "x-access-token": token,
      "'content-type'": "multipart/form-data",
    },
    data: itemsDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const updateItem = async (token, itemDetails) => {
  return axios({
    method: "PUT",
    url: `${apiUrl}/item/${itemDetails.get("itemId")}`,
    headers: {
      "x-access-token": token,
      "'content-type'": "multipart/form-data",
    },
    data: itemDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const deleteItem = async (token, itemId) => {
  return axios({
    method: "DELETE",
    url: `${apiUrl}/item/${itemId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const deleteItemFile = async (token, fileDetails) => {
  return axios({
    method: "DELETE",
    url: `${apiUrl}/item/file`,
    headers: {
      "x-access-token": token,
    },
    data: fileDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const swopUserItems = async (token, swopDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/swopUserItem`,
    headers: {
      "x-access-token": token,
    },
    data: swopDetails,
  })
    .then(function (response) {
      return { error: false, body: response };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
