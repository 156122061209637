import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE, SIGNUP_DATA_UPDATE } from '../actionTypes';

const initialState = {
  loading: true,
  userDetails: '',
  auth: false,
  error: null,
};

const signupUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        userDetails: '',
        auth: false,
        error: null
      };
    case SIGNUP_DATA_UPDATE:
      return {
        ...state,
        loading: true,
        userDetails: action.payload,
        auth: true,
        error: null
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.data,
        auth: true,
        error: null
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        loading: true,
        userDetails: '',
        auth: false,
        error: action.data
      };
    default:
      return state;
  }
};

export default signupUserReducer;