import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Container, Row, Col, ButtonGroup, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import hrmModule from "../../../../../../redux/hrm";
import Button from "../../../../../components/ui-components/Button";
import BEMhelper from "react-bem-helper";
import { FaFilePdf, FaStreetView } from "react-icons/fa";
import CustomTable from "../../../../../components/table";
import InfoModal from "../../../../../components/modals/infoModal";
import ToolTip from "../../../../../components/toolTip";
import Paginator from "../../../../../components/ui-components/Paginator";
import Input from "../../../../../components/inputNew";
import processStatusses from "../../../../../../enum/common/processStatus";
import moment from "moment";
import "./approvedPool.scss";

class CandidateApprovedPool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResumeModal: false,
      selectedCandidate: null,
      offset: 0,
      searchVal: null,
    };
  }

  viewCandidate = (candidate) => {
    this.setState({ selectedCandidate: candidate, showResumeModal: true });
  };

  getResume = () => {
    return (
      <div className="candidates__cvPool-resumePreview">
        <iframe
          title="approved-candidate-resume"
          src={this.state.selectedCandidate.cvUrl}
          width="100%"
          height="100%"
          className="iframe"
        ></iframe>
      </div>
    );
  };

  getCandidateList = (candidateList) => {
    const loggedUserAccess = this.props.user.userDetails.accessRoles;
    if (candidateList.candidates && candidateList.candidates.length) {
      return candidateList.candidates.map((row, key) => {
        return (
          <tr key={key} className="value-row">
            <td>{row.name}</td>
            <td className="candidate__appliedPosition">
              <a
                href={`/vacancy/${row.hrmVacancy.hrmVacancyId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {row.hrmVacancy.name}
              </a>
            </td>
            <td>{row.hired ? "Hired" : row.processStatus.status}</td>
            <td>{moment(row.updatedAt).format("YYYY-MM-DD")}</td>
            <td className="candidate__application-actions">
              <ButtonGroup size="sm">
                {loggedUserAccess.isAdmin ||
                loggedUserAccess.isHrmAdmin ||
                loggedUserAccess.isSuperAdmin ? (
                  <React.Fragment>
                    <Button
                      size="sm"
                      onClick={() => this.viewCandidate(row)}
                      color="grey"
                      id={`resume-${key}`}
                    >
                      <FaFilePdf />
                    </Button>
                    <ToolTip
                      position="top"
                      tip="View Resume"
                      rowId={`resume-${key}`}
                    />
                    <Link
                      to={`/hrm/recruitment/cvPool/application/${row.hrmCandidateId}`}
                      id={`application-${key}`}
                    >
                      <Button size="sm">
                        <FaStreetView />
                      </Button>
                    </Link>
                    <ToolTip
                      position="top"
                      tip="View Application"
                      rowId={`application-${key}`}
                    />
                  </React.Fragment>
                ) : null}
              </ButtonGroup>
            </td>
          </tr>
        );
      });
    }
    return [];
  };

  closeResumeModal = () => {
    this.setState({ selectedCandidate: null, showResumeModal: false });
  };

  searchItem = (e) => {
    this.setState({ searchVal: e.target.value });
    this.props.getCandidates({
      offset: 0,
      searchVal: e.target.value,
      processStatusId: processStatusses.Approved,
    });
  };

  componentDidMount = () => {
    this.props.getCandidates({
      offset: 0,
      processStatusId: processStatusses.Approved,
    });
  };

  render() {
    const classes = new BEMhelper("candidates");
    return (
      <Container fluid {...classes("approvedPool")}>
        {this.state.showResumeModal ? (
          <InfoModal
            openModal={this.state.showResumeModal}
            toggle={this.closeResumeModal}
            title={"View Resume"}
            body={this.getResume()}
          />
        ) : null}
        <React.Fragment>
          <Row>
            <Col sm={7} className="text-left">
              <div className="headerTitle-wrap">
                <h2 className="list-header-title">Approved Candidates</h2>
                <h4 className="list-sub-header-title">
                  {!this.props.candidates.loading &&
                    (this.props.candidates.data.statuses || []).map(
                      (status) => {
                        if (
                          status.processStatusId === processStatusses.Approved
                        ) {
                          return (
                            <Badge color="secondary">
                              Total - {status.count}
                            </Badge>
                          );
                        }
                      }
                    )}
                </h4>
              </div>
            </Col>
            <Col sm={5} className="text-right">
              <Input
                name="search"
                placeholder="Search candidate name"
                {...classes("itemSearch")}
                onChange={this.searchItem}
              />
            </Col>
          </Row>
          {this.props.candidates.loading ? (
            <p>Loading...</p>
          ) : (
            <Row>
              <Col sm={12}>
                <div className="hr"></div>
                <CustomTable
                  content={this.getCandidateList(this.props.candidates.data)}
                  tableHeadings={[
                    "Candidate name",
                    "Applied vacancy",
                    "Application status",
                    "Last updated on",
                    "",
                  ]}
                />
                <Paginator
                  style={{ padding: "10px 0" }}
                  limit={10}
                  offset={this.state.offset}
                  total={this.props.candidates.data.count}
                  align="center"
                  onChange={(offset) => {
                    this.props.getCandidates({
                      page: offset,
                      limit: 5,
                      offset: offset,
                      searchVal: this.state.searchVal,
                      processStatusId: processStatusses.Approved,
                    });
                    this.setState({ offset: offset });
                  }}
                />
              </Col>
            </Row>
          )}
        </React.Fragment>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCandidates: hrmModule.candidateModule.actions.getCandidates,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    candidates: state.hrmCandidates,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(CandidateApprovedPool);
