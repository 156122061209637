import { call, put, takeLatest, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
  GET_HRM_CANDIDATES_REQUEST,
  GET_HRM_CANDIDATES_SUCCESS,
  GET_HRM_CANDIDATES_FAILURE,
  CREATE_HRM_CANDIDATE_REQUEST,
  CREATE_HRM_CANDIDATE_SUCCESS,
  CREATE_HRM_CANDIDATE_FAILURE,
  CREATE_HRM_CANDIDATE_QUESTION_CATEGORIES_REQUEST,
  CREATE_HRM_CANDIDATE_QUESTION_CATEGORIES_SUCCESS,
  CREATE_HRM_CANDIDATE_QUESTION_CATEGORIES_FAILURE,
  GET_HRM_CANDIDATE_REQUEST,
  GET_HRM_CANDIDATE_SUCCESS,
  GET_HRM_CANDIDATE_FAILURE,
  CREATE_HRM_CANDIDATE_FEEDBACK_REQUEST,
  CREATE_HRM_CANDIDATE_FEEDBACK_SUCCESS,
  CREATE_HRM_CANDIDATE_FEEDBACK_FAILURE,
  GET_HRM_ALL_CANDIDATE_FEEDBACK_REQUEST,
  GET_HRM_ALL_CANDIDATE_FEEDBACK_SUCCESS,
  GET_HRM_ALL_CANDIDATE_FEEDBACK_FAILURE,
  UPDATE_HRM_CANDIDATE_REQUEST,
  UPDATE_HRM_CANDIDATE_SUCCESS,
  UPDATE_HRM_CANDIDATE_FAILURE,
  DELETE_HRM_CANDIDATE_REQUEST,
  DELETE_HRM_CANDIDATE_SUCCESS,
  DELETE_HRM_CANDIDATE_FAILURE,
  CREATE_HRM_DIRECT_CANDIDATE_REQUEST,
  CREATE_HRM_DIRECT_CANDIDATE_SUCCESS,
  CREATE_HRM_DIRECT_CANDIDATE_FAILURE,
  GET_HRM_CANDIDATE_INTERVIEW_INVITATION_REQUEST,
  GET_HRM_CANDIDATE_INTERVIEW_INVITATION_SUCCESS,
  GET_HRM_CANDIDATE_INTERVIEW_INVITATION_FAILURE,
  CREATE_HRM_CANDIDATE_INTERVIEW_INVITATION_REQUEST,
  CREATE_HRM_CANDIDATE_INTERVIEW_INVITATION_SUCCESS,
  CREATE_HRM_CANDIDATE_INTERVIEW_INVITATION_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";
import processStatusses from "../../../../enum/common/processStatus";
import { toast } from "react-toastify";
import _ from "lodash";

export function* candidateSaga() {
  yield takeLatest(GET_HRM_CANDIDATES_REQUEST, getCandidates);
  yield takeLatest(CREATE_HRM_CANDIDATE_REQUEST, createCandidate);
  yield takeLatest(
    CREATE_HRM_CANDIDATE_QUESTION_CATEGORIES_REQUEST,
    getCandidateQuestionCategories
  );
  yield takeLatest(GET_HRM_CANDIDATE_REQUEST, getCandidate);
  yield takeLatest(
    CREATE_HRM_CANDIDATE_FEEDBACK_REQUEST,
    createCandidateFeedback
  );
  yield takeLatest(
    GET_HRM_ALL_CANDIDATE_FEEDBACK_REQUEST,
    getCandidateFeedbacks
  );
  yield takeLatest(UPDATE_HRM_CANDIDATE_REQUEST, updateCandidate);
  yield takeLatest(DELETE_HRM_CANDIDATE_REQUEST, deleteCandidate);
  yield takeLatest(CREATE_HRM_DIRECT_CANDIDATE_REQUEST, createDirectCandidate);
  yield takeLatest(
    GET_HRM_CANDIDATE_INTERVIEW_INVITATION_REQUEST,
    getInterviewInvitations
  );
  yield takeLatest(
    CREATE_HRM_CANDIDATE_INTERVIEW_INVITATION_REQUEST,
    createInterviewInvitation
  );
}

function* createInterviewInvitation(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const invitation = yield call(
      apis.hrmApis.createInterviewInvitation,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (invitation.error) {
      yield put({
        type: CREATE_HRM_CANDIDATE_INTERVIEW_INVITATION_FAILURE,
        error: invitation.body.data.error,
      });
    } else {
      toast.success("Invitation sent successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put({
        type: CREATE_HRM_CANDIDATE_INTERVIEW_INVITATION_SUCCESS,
        error: invitation.body.data.error,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getInterviewInvitations(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const invitations = yield call(
      apis.hrmApis.getInterviewInvitations,
      loggedUser.userDetails.auth.accessToken,
      details.payload.candidateId
    );
    if (invitations.error) {
      yield put({
        type: GET_HRM_CANDIDATE_INTERVIEW_INVITATION_FAILURE,
        error: invitations.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_CANDIDATE_INTERVIEW_INVITATION_SUCCESS,
        data: invitations.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getCandidates(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const candidates = yield call(
      apis.hrmApis.getCandidates,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (candidates.error) {
      yield put({
        type: GET_HRM_CANDIDATES_FAILURE,
        error: candidates.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_CANDIDATES_SUCCESS,
        data: candidates.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createDirectCandidate(details) {
  try {
    const candidate = yield call(
      apis.hrmApis.createDirectCandidate,
      details.payload.formValues
    );
    if (candidate.error) {
      yield put({
        type: CREATE_HRM_DIRECT_CANDIDATE_FAILURE,
        error: candidate.body.data.error,
      });
    } else {
      toast.success("Thank you! We received your application.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put({
        type: CREATE_HRM_DIRECT_CANDIDATE_SUCCESS,
        error: candidate.body.data.error,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createCandidate(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const candidate = yield call(
      apis.hrmApis.createCandidate,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (candidate.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: CREATE_HRM_CANDIDATE_FAILURE,
        error: candidate.body.data.error,
      });
      if (_.isString(candidate.body.data.error)) {
        errorMessage = candidate.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.success("Candidate created successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put({
        type: CREATE_HRM_CANDIDATE_SUCCESS,
        error: candidate.body.data.error,
      });
      yield put({
        type: GET_HRM_CANDIDATES_REQUEST,
        payload: { processStatusId: processStatusses.Pending },
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getCandidateQuestionCategories() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const categories = yield call(
      apis.hrmApis.getCandidateQuestionCategories,
      loggedUser.userDetails.auth.accessToken
    );
    if (categories.error) {
      yield put({
        type: CREATE_HRM_CANDIDATE_QUESTION_CATEGORIES_FAILURE,
        error: categories.body.data.error,
      });
    } else {
      yield put({
        type: CREATE_HRM_CANDIDATE_QUESTION_CATEGORIES_SUCCESS,
        data: categories.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getCandidate(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const candidate = yield call(
      apis.hrmApis.getCandidate,
      loggedUser.userDetails.auth.accessToken,
      details.payload.candidateId,
      details.payload.token
    );
    if (candidate.error) {
      yield put({
        type: GET_HRM_CANDIDATE_FAILURE,
        error: candidate.body.data.error,
      });
      if (candidate.body.status === 404) {
        yield put(push("/hrm/cvPool"));
      }
    } else {
      yield put({
        type: GET_HRM_CANDIDATE_SUCCESS,
        data: candidate.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createCandidateFeedback(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const feedback = yield call(
      apis.hrmApis.createCandidateFeedback,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (feedback.error) {
      yield put({
        type: CREATE_HRM_CANDIDATE_FEEDBACK_FAILURE,
        error: feedback.body.data.error,
      });
    } else {
      yield put({
        type: CREATE_HRM_CANDIDATE_FEEDBACK_SUCCESS,
        data: feedback.body.data,
      });
      toast.success("Feedback submitted successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (
        loggedUser.userDetails.accessRoles.isSuperAdmin ||
        loggedUser.userDetails.accessRoles.isHrmAdmin ||
        loggedUser.userDetails.accessRoles.isAdmin
      ) {
        yield put(push("/hrm/cvPool"));
      } else {
        yield put(push("/landing"));
      }
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getCandidateFeedbacks(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const candidates = yield call(
      apis.hrmApis.getCandidateFeedbacks,
      loggedUser.userDetails.auth.accessToken,
      details.payload.candidateId
    );
    if (candidates.error) {
      yield put({
        type: GET_HRM_ALL_CANDIDATE_FEEDBACK_FAILURE,
        error: candidates.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_ALL_CANDIDATE_FEEDBACK_SUCCESS,
        data: candidates.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* updateCandidate(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const candidate = yield call(
      apis.hrmApis.updateCandidate,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues,
      details.payload.candidateId
    );
    if (candidate.error) {
      yield put({
        type: UPDATE_HRM_CANDIDATE_FAILURE,
        error: candidate.body.data.error,
      });
    } else {
      yield put({
        type: UPDATE_HRM_CANDIDATE_SUCCESS,
        error: candidate.body.data,
      });
      yield put({
        type: GET_HRM_CANDIDATE_REQUEST,
        payload: { candidateId: details.payload.candidateId },
      });
      toast.success("Candidate updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* deleteCandidate(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const candidate = yield call(
      apis.hrmApis.deleteCandidate,
      loggedUser.userDetails.auth.accessToken,
      details.payload.candidateId
    );
    if (candidate.error) {
      yield put({
        type: DELETE_HRM_CANDIDATE_FAILURE,
        error: candidate.body.data.error,
      });
    } else {
      yield put({
        type: DELETE_HRM_CANDIDATE_SUCCESS,
        data: candidate.body.data,
      });
      yield put({
        type: GET_HRM_CANDIDATES_REQUEST,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
