import { fork, all } from "redux-saga/effects";
import leaveModule from "./leave";
import userModule from "./user";
import supervisorModule from "./supervisor";
import adminModule from "../redux/admin";
import claimModule from "../redux/claim";
import timeTrackModule from "../redux/timetracking";
import commonModule from "../redux/common";
import inventoryModule from "../redux/inventory";
import hrmModule from "../redux/hrm";

const sagas = [
  leaveModule.sagas.leaveSaga,
  userModule.sagas.userSaga,
  supervisorModule.sagas.supervisorSaga,
  // adminModule.sagas.adminSaga,
  adminModule.userModule.sagas.userSaga,
  adminModule.dashboardModule.sagas.dashboardSaga,
  adminModule.commonModule.sagas.commonSaga,
  claimModule.sagas.claimSaga,
  timeTrackModule.projectModule.sagas.projectSaga,
  timeTrackModule.categoryModule.sagas.categorySaga,
  timeTrackModule.entryModule.sagas.entrySaga,
  timeTrackModule.clientModule.sagas.clientSaga,
  timeTrackModule.taskModule.sagas.taskSaga,
  timeTrackModule.userModule.sagas.userSaga,
  timeTrackModule.reportModule.sagas.reportSaga,
  commonModule.sagas.permissionTypeSaga,
  commonModule.sagas.commonDataSaga,
  inventoryModule.categoryModule.sagas.categorySaga,
  inventoryModule.typeModule.sagas.typeSaga,
  inventoryModule.itemModule.sagas.itemSaga,
  inventoryModule.reportModule.sagas.reportSaga,
  inventoryModule.dashboardModule.sagas.dashboardSaga,
  hrmModule.mentorshipModule.sagas.mentorshipSaga,
  hrmModule.candidateModule.sagas.candidateSaga,
  hrmModule.agentModule.sagas.agentSaga,
  hrmModule.vacancyModule.sagas.vacancySaga,
  hrmModule.interviewStageModule.sagas.interviewStageSaga,
  hrmModule.dashboardModule.sagas.dashboardSaga,
  hrmModule.hrmPerformanceModule.surveyModule.sagas.surveySaga,
];

function* globalSagas() {
  const globalSagasForks = sagas.map((saga) => fork(saga));
  yield all([...globalSagasForks]);
}

export default globalSagas;
