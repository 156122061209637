import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import inventoryModule from "../../../../redux/inventory";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import moment from "moment";
import _ from "lodash";
import {
  FaPlus,
  FaTrashAlt,
  FaEdit,
  FaCoins,
  FaEllipsisV,
} from "react-icons/fa";
import AddItemModal from "../../../components/modals/inventory/addItemModal";
import AddItemTypeModal from "../../../components/modals/inventory/addItemTypeModal";
import AddItemCategoryModal from "../../../components/modals/inventory/addItemCategoryModal";
import BulkAddModal from "../../../components/modals/common/bulkAddModal";
import CustomTable from "../../../components/table";
import DeleteModal from "../../../components/modals/common/deleteModal";
import Button from "../../../components/ui-components/Button";
import userModule from "../../../../redux/user";
import Paginator from "../../../components/ui-components/Paginator";
import Input from "../../../components/inputNew";
import Loader from "../../../components/ui-components/Loader";
import ToolTip from "../../../components/toolTip";
import "./itemList.scss";

class InventoryItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showItemModal: false,
      showAddTypeModal: false,
      showAddCategoryModal: false,
      showBulkAddModal: false,
      itemSelected: null,
      editMode: false,
      showDeleteModal: false,
      offset: 0,
      searchVal: null,
      optionsIsOpen: false,
    };
  }

  addItem = (values) => {
    const formData = new FormData();
    formData.append("categoryId", values.categoryId.value);
    formData.append("name", values.name);
    formData.append(
      "purchaseDate",
      moment(values.purchaseDate).format("YYYY-MM-DD")
    );
    formData.append("condition", values.condition.value);
    formData.append("quantity", parseInt(values.quantity));
    formData.append("available", parseInt(values.available));
    formData.append("notes", values.notes);
    formData.append("typeId", values.typeId ? values.typeId.value : "");
    formData.append("brand", values.brand || "");
    formData.append("serialNumber", values.serialNumber || "");
    formData.append("cost", values.price);
    formData.append("vendor", values.vendor || "");
    if (values.occupiedBy) {
      formData.append("occupiedBy", values.occupiedBy.value);
    }
    formData.append(
      "warrantyMonth",
      values.warrantyMonth ? values.warrantyMonth.value : ""
    );
    formData.append("assetLocation", values.assetLocation || "");
    if (values.files.length) {
      _.forEach(values.files, function (file) {
        if (file instanceof File) {
          formData.append("itemFiles", file);
        }
      });
    }
    if (this.state.editMode) {
      formData.append("itemId", this.state.itemSelected.inventoryItemId);
    }

    const data = {
      formValues: formData,
      closeModal: this.toggle,
    };
    if (this.state.editMode) {
      this.props.updateItem(data);
    } else {
      this.props.createItem(data);
    }
  };

  openForAddItem = (item) => {
    this.setState({
      showItemModal: true,
      editMode: false,
      itemSelected: null,
    });
  };

  openForEdit = (item) => {
    this.setState({
      editMode: true,
      itemSelected: item,
      showItemModal: true,
    });
  };

  toggle = (toggleItemState = false) => {
    if (this.state.editMode) {
      this.props.getItems({
        page: this.state.offset,
        limit: 5,
        offset: this.state.offset,
        searchVal: this.state.searchVal,
      });
    }
    this.setState({
      showItemModal: toggleItemState,
      editMode: false,
      itemSelected: null,
    });
  };

  openForDelete = (item) => {
    this.setState({
      showDeleteModal: true,
      itemSelected: item,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      itemSelected: null,
    });
  };

  closeBulkAddModal = () => {
    this.setState({
      showBulkAddModal: false,
    });
  };

  deleteItem = () => {
    const data = {
      formValues: {
        itemId: this.state.itemSelected.inventoryItemId,
      },
      closeModal: this.closeDeleteModal,
    };
    this.props.deleteItem(data);
  };

  componentDidMount() {
    this.props.getItems({ offset: 0 });
    this.props.getCategories();
    this.props.getTypes();
    this.props.getUserList();
  }

  getTableHeading = () => {
    const featurePermissions = this.props.user.userDetails.accessRoles;
    const hasAccess =
      featurePermissions.isInventoryAdmin ||
      featurePermissions.isInventoryManager ||
      featurePermissions.isSuperAdmin;
    return (
      <tr>
        <th>Category</th>
        <th>Product name</th>
        <th>Quantity</th>
        <th>Available</th>
        <th>Added on</th>
        <th
          className={hasAccess ? "" : "align-center"}
        >{`Price (${this.props.user.userDetails.company.currency})`}</th>
        {hasAccess ? <th></th> : null}
      </tr>
    );
  };

  getTableContent = (data) => {
    const featurePermissions = this.props.user.userDetails.accessRoles;
    if (data.records) {
      return data.records.map((row, key) => {
        return (
          <tr key={key} className="value-row">
            <td>{row.inventoryMainCategory.name}</td>
            <td>{row.name}</td>
            <td>{row.quantity}</td>
            <td>{row.available}</td>
            <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
            <td className="Inventory__itemCost">{row.cost}</td>
            {featurePermissions.isInventoryAdmin ||
            featurePermissions.isInventoryManager ||
            featurePermissions.isSuperAdmin ? (
              <td className="Inventory__item-actions">
                <ButtonGroup size="sm">
                  <Button
                    color="bright-blue"
                    size="sm"
                    onClick={() => this.openForEdit(row)}
                  >
                    <FaEdit />
                  </Button>
                  <Button
                    color="red"
                    size="sm"
                    onClick={() => this.openForDelete(row)}
                  >
                    <FaTrashAlt />
                  </Button>
                  <Button
                    color="blue"
                    id={`log-${key}`}
                    size="sm"
                    onClick={() =>
                      this.props.history.push(
                        `reports/log?itemId=${row.inventoryItemId}`
                      )
                    }
                  >
                    <FaCoins />
                  </Button>
                  <ToolTip
                    position="top"
                    tip="Item Logs"
                    rowId={`log-${key}`}
                  />
                </ButtonGroup>
              </td>
            ) : null}
          </tr>
        );
      });
    }
  };

  searchItem = (e) => {
    this.setState({ searchVal: e.target.value });
    this.props.getItems({ offset: 0, searchVal: e.target.value });
  };

  showAddItemType = () => {
    this.setState({ showAddTypeModal: true });
  };

  showAddItemCategory = () => {
    this.setState({ showAddCategoryModal: true });
  };

  closeAddItemType = () => {
    this.setState({ showAddTypeModal: false, showAddCategoryModal: false });
  };

  uploadBulkFile = (file) => {
    const formData = new FormData();
    formData.append("itemList", file);
    const data = {
      formValues: formData,
      closeModal: this.closeBulkAddModal,
    };
    this.props.createItemsBulk(data);
  };

  toggleOptions = () => {
    this.setState({ optionsIsOpen: this.state.optionsIsOpen ? false : true });
  };

  render() {
    const classes = new BEMhelper("inventory");
    return (
      <Container {...classes("items")}>
        {this.state.showItemModal ? (
          <AddItemModal
            openModal={this.state.showItemModal}
            toggle={this.toggle}
            title={this.state.editMode ? "Edit Item" : "Add New Item"}
            submit={this.addItem}
            isEditMode={this.state.editMode}
            objectToEdit={this.state.itemSelected}
            categories={this.props.categories.data}
            users={this.props.userList.userList}
            btnLoading={this.props.creating.loading}
            typesNew={this.props.types.data}
            showAddItemType={this.showAddItemType}
            showAddItemCategory={this.showAddItemCategory}
          />
        ) : null}
        {this.state.showDeleteModal ? (
          <DeleteModal
            openModal={this.state.showDeleteModal}
            body={
              "Are you sure you want to delete this item? This will delete all the data related to this item!"
            }
            onClose={this.closeDeleteModal}
            onConfirm={this.deleteItem}
          />
        ) : null}
        {this.state.showAddTypeModal ? (
          <AddItemTypeModal
            openModal={this.state.showAddTypeModal}
            onClose={this.closeAddItemType}
            title="Add Item Type"
          />
        ) : null}
        {this.state.showAddCategoryModal ? (
          <AddItemCategoryModal
            openModal={this.state.showAddCategoryModal}
            onClose={this.closeAddItemType}
            title={"Add Item Category"}
          />
        ) : null}

        {this.state.showBulkAddModal ? (
          <BulkAddModal
            openModal={this.state.showBulkAddModal}
            title={"Item Bulk Upload"}
            onClose={this.closeBulkAddModal}
            onConfirm={this.uploadBulkFile}
            errors={this.props.creating.error}
            errorCsvData={{
              csvHeaders: [
                { label: "Item Name", key: "ItemName" },
                { label: "Category", key: "Category" },
                { label: "Total Price", key: "TotalPrice" },
                { label: "Condition", key: "Condition" },
                { label: "Quantity", key: "Quantity" },
                { label: "Available", key: "Available" },
                { label: "Purchase Date", key: "PurchaseDate" },
                { label: "Serial Number", key: "SerialNumber" },
                { label: "Item Location", key: "ItemLocation" },
                { label: "Warranty", key: "Warranty" },
                { label: "Brand", key: "Brand" },
                { label: "Vendor", key: "Vendor" },
                { label: "Type", key: "Type" },
                { label: "Occupied By", key: "OccupiedBy" },
                { label: "Notes", key: "Notes" },
                { label: "Reject Reason", key: "RejectReason" },
              ],
              csvData: this.props.creating.error,
            }}
          />
        ) : null}
        <Row>
          <Col sm={5} className="text-left">
            <h2 className="list-header-title">Items</h2>
          </Col>
          <Col sm={7} className="text-right">
            <Input
              name="search"
              placeholder="Search item by name"
              {...classes("itemSearch")}
              onChange={this.searchItem}
            />
            <Button
              {...classes("addItem-btn")}
              color="deep-blue"
              onClick={() => {
                this.openForAddItem();
              }}
            >
              <FaPlus /> New Item
            </Button>
            <Button
              color="deep-blue"
              onClick={() => {
                this.setState({ showBulkAddModal: true });
              }}
            >
              <FaPlus /> Bulk Create
            </Button>
            <ButtonDropdown
              isOpen={this.state.optionsIsOpen}
              toggle={this.toggleOptions}
            >
              <DropdownToggle size="md" {...classes("option-btn")}>
                <FaEllipsisV />
              </DropdownToggle>
              <DropdownMenu {...classes("option-menu")}>
                <DropdownItem
                  onClick={() => this.props.history.push(`item/categories`)}
                >
                  Categories
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.props.history.push(`item/types`)}
                >
                  Types
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>

          <Col sm={12}>
            <div className="hr"></div>
            {this.props.items.loading ? (
              <div {...classes("loader")}>
                <Loader />
              </div>
            ) : this.props.items.data.records ? (
              <React.Fragment>
                <CustomTable
                  content={this.getTableContent(this.props.items.data)}
                  tableHeadings={this.getTableHeading()}
                  customHeaders
                />
                <Paginator
                  style={{ padding: "10px 0" }}
                  limit={10}
                  offset={this.state.offset}
                  total={this.props.items.data.count}
                  align="center"
                  onChange={(offset) => {
                    this.props.getItems({
                      page: offset,
                      limit: 5,
                      offset: offset,
                      searchVal: this.state.searchVal,
                    });
                    this.setState({ offset: offset });
                  }}
                />
              </React.Fragment>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCategories: inventoryModule.categoryModule.actions.getCategories,
      getTypes: inventoryModule.typeModule.actions.getTypes,
      createItem: inventoryModule.itemModule.actions.createItem,
      getItems: inventoryModule.itemModule.actions.getItems,
      updateItem: inventoryModule.itemModule.actions.updateItem,
      deleteItem: inventoryModule.itemModule.actions.deleteItem,
      getUserList: userModule.actions.getUserList,
      createItemsBulk: inventoryModule.itemModule.actions.createItemsBulk,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    categories: state.inventoryMainCategories,
    types: state.inventoryItemTypes,
    creating: state.inventoryCreateItem,
    items: state.inventoryItems,
    userList: state.userList,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(InventoryItemList);
