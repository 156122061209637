import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import CustomTable from "../../../../components/table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import inventoryModule from "../../../../../redux/inventory";
import bemClass from "../../../../components/ui-components/Utils/bemClass";
import Button from "../../../../components/ui-components/Button";
import Paginator from "../../../../components/ui-components/Paginator";
import Loader from "../../../../components/ui-components/Loader";
import { FaCheck } from "react-icons/fa";
import "./quantity.scss";

class InventoryQuantityReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      filterByType: false,
    };
  }

  getTableHeading = () => {
    return (
      <tr>
        <th>Item Name</th>
        <th>In Use</th>
        <th>Available</th>
        <th>Total</th>
      </tr>
    );
  };

  getTableContent = (data) => {
    return (data.records || []).map((row, key) => {
      if (this.state.filterByType) {
        return (
          <tr key={key} className="value-row">
            <td>{row.type}</td>
            <td>{row.total_inUse}</td>
            <td>{row.total_available}</td>
            <td>{row.total_quantity}</td>
          </tr>
        );
      }
      return (
        <tr key={key} className="value-row">
          <td>{row.name}</td>
          <td>{row.quantity - row.available}</td>
          <td>{row.available}</td>
          <td>{row.quantity}</td>
        </tr>
      );
    });
  };

  applyFilters = () => {
    const filterByType = !this.state.filterByType;
    this.setState({ filterByType: filterByType });
    const data = {
      offset: 0,
      reportType: "QuantityReport",
      filterByType: filterByType,
    };
    this.props.getCustomReport(data);
  };

  downloadReport = (type) => {
    const data = {
      reportType: "QuantityReport",
      filterByType: this.state.filterByType,
      documentType: type,
    };
    this.props.downloadCustomReport(data);
  };

  componentDidMount() {
    this.props.getCustomReport({
      offset: this.state.offset,
      reportType: "QuantityReport",
      filterByType: false,
    });
  }

  componentWillUnmount() {
    this.props.resetReport();
  }

  render() {
    const classes = new bemClass("InventoryQuantityReport");
    return (
      <Container className={classes.elem("wrapper").b()}>
        <Row>
          <Col sm={8} className="text-left">
            <h2 className="list-header-title">Quantity Report</h2>
          </Col>
          <Col sm={4} className="text-right">
            <React.Fragment>
              <Button
                color="light-grey"
                onClick={() => {
                  this.downloadReport("pdf");
                }}
                className="report-action-btn"
              >
                Export Pdf
              </Button>
              <Button
                color="light-grey"
                onClick={() => {
                  this.downloadReport("csv");
                }}
                className="report-action-btn"
              >
                Export CSV
              </Button>
              <Button
                type={this.state.filterByType ? null : "lined"}
                color="bright-blue"
                onClick={() => {
                  this.applyFilters();
                  this.setState({ offset: 0 });
                }}
              >
                {this.state.filterByType ? <FaCheck /> : null} Filter By Type
              </Button>
            </React.Fragment>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="hr"></div>
            {this.props.report.loading ? (
              <div className={classes.elem("loader").b()}>
                <Loader />
              </div>
            ) : (
              <React.Fragment>
                <CustomTable
                  content={this.getTableContent(this.props.report.data)}
                  tableHeadings={this.getTableHeading()}
                  customHeaders
                />
                <Paginator
                  style={{ padding: "10px 0" }}
                  limit={10}
                  offset={this.state.offset}
                  total={
                    this.state.filterByType ? 1 : this.props.report.data.count
                  }
                  align="center"
                  onChange={(offset) => {
                    const data = {
                      reportType: "QuantityReport",
                      offset: offset,
                      filterByType: this.state.filterByType,
                      documentType: null,
                    };
                    this.props.getCustomReport(data);
                    this.setState({ offset: offset });
                  }}
                />
              </React.Fragment>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCustomReport: inventoryModule.reportModule.actions.getCustomReport,
      downloadCustomReport:
        inventoryModule.reportModule.actions.downloadCustomReport,
      resetReport: inventoryModule.reportModule.actions.resetReport,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    report: state.inventoryReport,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(InventoryQuantityReport);
