export const GET_HRM_INTERVIEW_STAGES_REQUEST =
  "GET_HRM_INTERVIEW_STAGES_REQUEST";
export const GET_HRM_INTERVIEW_STAGES_SUCCESS =
  "GET_HRM_INTERVIEW_STAGES_SUCCESS";
export const GET_HRM_INTERVIEW_STAGES_FAILURE =
  "GET_HRM_INTERVIEW_STAGES_FAILURE";

export const CREATE_HRM_INTERVIEW_STAGE_REQUEST =
  "CREATE_HRM_INTERVIEW_STAGE_REQUEST";
export const CREATE_HRM_INTERVIEW_STAGE_SUCCESS =
  "CREATE_HRM_INTERVIEW_STAGE_SUCCESS";
export const CREATE_HRM_INTERVIEW_STAGE_FAILURE =
  "CREATE_HRM_INTERVIEW_STAGE_FAILURE";

export const UPDATE_HRM_INTERVIEW_STAGE_REQUEST =
  "UPDATE_HRM_INTERVIEW_STAGE_REQUEST";
export const UPDATE_HRM_INTERVIEW_STAGE_SUCCESS =
  "UPDATE_HRM_INTERVIEW_STAGE_SUCCESS";
export const UPDATE_HRM_INTERVIEW_STAGE_FAILURE =
  "UPDATE_HRM_INTERVIEW_STAGE_FAILURE";
