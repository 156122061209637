const itemWarrenties = [
  {
    value: "1 month",
    label: "1 Month",
  },
  {
    value: "2 month",
    label: "2 Months",
  },
  {
    value: "3 month",
    label: "3 Months",
  },
  {
    value: "4 month",
    label: "4 Months",
  },
  {
    value: "5 month",
    label: "5 Months",
  },
  {
    value: "6 month",
    label: "6 Months",
  },
  {
    value: "7 month",
    label: "7 Months",
  },
  {
    value: "8 month",
    label: "8 Months",
  },
  {
    value: "9 month",
    label: "9 Months",
  },
  {
    value: "10 month",
    label: "10 Months",
  },
  {
    value: "11 month",
    label: "11 Months",
  },
  {
    value: "1 year",
    label: "1 Year",
  },
  {
    value: "2 year",
    label: "2 Years",
  },
  {
    value: "3 year",
    label: "3 Years",
  },
  {
    value: "4 year",
    label: "4 Years",
  },
  {
    value: "5 year",
    label: "5 Years",
  },
  {
    value: "6 year",
    label: "6 Years",
  },
  {
    value: "7 year",
    label: "7 Years",
  },
  {
    value: "8 year",
    label: "8 Years",
  },
  {
    value: "9 year",
    label: "9 Years",
  },
  {
    value: "10 year",
    label: "10 Years",
  },
  {
    value: "expired",
    label: "Expired",
  },
];

export default itemWarrenties;
