import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_INVENTORY_REPORT_REQUEST,
  GET_INVENTORY_REPORT_SUCCESS,
  GET_INVENTORY_REPORT_FAILURE,
  GET_INVENTORY_CUSTOM_REPORT_REQUEST,
  GET_INVENTORY_CUSTOM_REPORT_SUCCESS,
  GET_INVENTORY_CUSTOM_REPORT_FAILURE,
  GET_INVENTORY_CUSTOM_REPORT_DOWNLOAD_REQUEST,
} from "../actionTypes";
import apis from "../../../../api";
import moment from "moment";
import { toast } from "react-toastify";

export function* reportSaga() {
  yield takeLatest(GET_INVENTORY_REPORT_REQUEST, getReport);
  yield takeLatest(GET_INVENTORY_CUSTOM_REPORT_REQUEST, getCustomReport);
  yield takeLatest(
    GET_INVENTORY_CUSTOM_REPORT_DOWNLOAD_REQUEST,
    downloadCustomReport
  );
}

function* downloadCustomReport(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    toast.success(
      "We are exporting your document. Will download automatically in few seconds.",
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
    const report = yield call(
      apis.inventoryApis.downloadCustomReport,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    let url = "";
    if (details.payload.documentType === "csv")
      url = window.URL.createObjectURL(
        new Blob([report.body.data.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

    if (details.payload.documentType === "pdf") {
      let bytes = new Uint8Array(report.body.data.data.data);
      const blob = new Blob([bytes], { type: "application/pdf" });
      url = window.URL.createObjectURL(blob);
    }

    const link = document.createElement("a");
    link.href = url;
    const nowTime = moment().format("HH:mm");
    const nowDate = moment().format("YYYY-MM-DD");
    link.setAttribute(
      "download",
      `custom-report-${nowDate}-${nowTime}.${details.payload.documentType}`
    );
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getReport(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const report = yield call(
      apis.inventoryApis.getReport,
      loggedUser.userDetails.auth.accessToken,
      details.payload.userId ? details.payload.userId : details.payload
    );
    if (report.error) {
      yield put({
        type: GET_INVENTORY_REPORT_FAILURE,
        error: report.body.data.error,
      });
    } else {
      yield put({ type: GET_INVENTORY_REPORT_SUCCESS, data: report.body.data });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getCustomReport(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const report = yield call(
      apis.inventoryApis.getCustomReport,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (report.error) {
      yield put({
        type: GET_INVENTORY_CUSTOM_REPORT_FAILURE,
        error: report.body.data.error,
      });
    } else {
      yield put({
        type: GET_INVENTORY_CUSTOM_REPORT_SUCCESS,
        data: report.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
