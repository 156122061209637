import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import timeTrackModule from "../../../../redux/timetracking";
import { Container, Row, Col, ButtonGroup } from "reactstrap";
import BEMhelper from "react-bem-helper";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import AddTaskModal from "../../../components/modals/timeTracking/addTaskModal";
import CustomTable from "../../../components/table";
import DeleteModal from "../../../components/modals/common/deleteModal";
import Button from "../../../components/ui-components/Button";

class TimeTrackTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserModal: false,
      showDeleteModal: false,
      taskSelected: null,
      editMode: false,
    };
  }

  addTask = (values) => {
    const data = {
      formValues: {
        name: values.name,
        categoryId: values.category.value,
        hourlyRate: values.hourlyRate,
        isActive: values.activeStatus,
        ...(this.state.editMode && {
          taskId: this.state.taskSelected.timeTrackTaskId,
        }),
      },
      closeModal: this.toggle,
    };
    if (this.state.editMode) {
      this.props.updateTask(data);
    } else {
      this.props.createTask(data);
    }
  };

  toggle = (toggleState = false) => {
    this.setState({
      showUserModal: toggleState,
    });
  };

  openForAdd = () => {
    this.setState({
      editMode: false,
      taskSelected: null,
    });
    this.toggle(true);
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      taskSelected: null,
    });
  };

  openForDelete = (task) => {
    this.setState({
      showDeleteModal: true,
      taskSelected: task,
    });
  };

  openForEdit = (task) => {
    this.setState({
      editMode: true,
      taskSelected: task,
    });
    this.toggle(true);
  };

  deleteTask = () => {
    const data = {
      formValues: {
        taskId: this.state.taskSelected.timeTrackTaskId,
      },
      closeModal: this.closeDeleteModal,
    };
    this.props.deleteTask(data);
  };

  componentDidMount() {
    this.props.getTasks();
    this.props.getCategories();
  }

  getTableHeading = () => {
    return ["Name", "category", "Hourly rate", "Status", "Added on", ""];
  };

  getTableContent = (data) => {
    return data.map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>{row.name}</td>
          <td>{row.timeTrackCategory.name}</td>
          <td>{row.hourlyRate}</td>
          <td>{row.isActive ? "Active" : "Inactive"}</td>
          <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
          <td>
            <ButtonGroup size="sm">
              <Button
                outline
                color="info"
                size="sm"
                onClick={() => this.openForEdit(row)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  render() {
    const classes = new BEMhelper("time-tracking");
    return (
      <Container {...classes("categories")}>
        {this.state.showUserModal ? (
          <AddTaskModal
            openModal={this.state.showUserModal}
            toggle={this.toggle}
            title={this.state.editMode ? "Edit task" : "Add new task"}
            submit={this.addTask}
            categories={this.props.categories.data}
            isEditMode={this.state.editMode}
            objectToEdit={this.state.taskSelected}
          />
        ) : null}
        {this.state.showDeleteModal ? (
          <DeleteModal
            openModal={this.state.showDeleteModal}
            body={"Are you sure you want to delete this task?"}
            onClose={this.closeDeleteModal}
            onConfirm={this.deleteTask}
          />
        ) : null}

        <Row>
          <Col sm={9} className="text-left">
            <h2 className="list-header-title">Tasks</h2>
          </Col>
          <Col sm={3} className="text-right">
            <Button
              className="btn-primary"
              onClick={() => {
                this.openForAdd();
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> New Task
            </Button>
          </Col>

          <Col sm={12}>
            <div className="hr"></div>
            {this.props.tasks.loading ? (
              <p>Loading...</p>
            ) : (
              <CustomTable
                content={this.getTableContent(this.props.tasks.data)}
                tableHeadings={this.getTableHeading()}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCategories: timeTrackModule.categoryModule.actions.getCategories,
      getTasks: timeTrackModule.taskModule.actions.getTasks,
      createTask: timeTrackModule.taskModule.actions.createTask,
      deleteTask: timeTrackModule.taskModule.actions.deleteTask,
      updateTask: timeTrackModule.taskModule.actions.updateTask,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    categories: state.timeTrackingEntryCategories,
    tasks: state.timeTrackingTasks,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(TimeTrackTask);
