import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { bindActionCreators } from "redux";
import timeTrackModule from "../../../../redux/timetracking";
import { Container, Row, Col, ButtonGroup } from "reactstrap";
import BEMhelper from "react-bem-helper";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import AddProjectModal from "../../../components/modals/timeTracking/addNewProjectModal";
import CustomTable from "../../../components/table";
import DeleteModal from "../../../components/modals/common/deleteModal";
import Button from "../../../components/ui-components/Button";
import AddTeamMemberModal from "../../../components/modals/timeTracking/addTeamMemberModal";

class TimeTrackProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserModal: false,
      showDeleteModal: false,
      projectSelected: null,
      editMode: false,
      showTeamModal: false,
    };
  }

  addProject = (values) => {
    const data = {
      formValues: {
        name: values.name,
        type: values.type.value,
        budget: values.fixedBudget,
        hourlyRate: values.hourlyRate,
        goByUserRate: values.goByPerson,
        notes: values.notes || null,
        isActive: values.isActive,
        clientId: values.client.value,
        ...(this.state.editMode && {
          projectId: this.state.projectSelected.timeTrackProjectId,
        }),
      },
      closeModal: this.toggle,
    };
    if (this.state.editMode) {
      this.props.updateProject(data);
    } else {
      this.props.createProject(data);
    }
  };

  addTeam = (values) => {
    const userIds = [];
    _.forEach(values.team, function (member) {
      userIds.push(member.value.timeTrackUserId);
    });
    const data = {
      formValues: {
        userIds: userIds,
        projectId: this.state.projectSelected.timeTrackProjectId,
      },
      closeModal: this.toggle,
    };
    this.props.assignTeamToProject(data);
  };

  toggle = (toggleState = false) => {
    this.setState({
      showUserModal: toggleState,
      showTeamModal: toggleState,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      projectSelected: null,
    });
  };

  openForAdd = () => {
    this.setState({
      editMode: false,
      projectSelected: null,
      showUserModal: true,
    });
    // this.toggle(true);
  };

  openForAddTeam = (project) => {
    this.setState({
      editMode: true,
      projectSelected: project,
      showTeamModal: true,
    });
  };

  openForEdit = (project) => {
    this.setState({
      editMode: true,
      projectSelected: project,
      showUserModal: true,
    });
    // this.toggle(true);
  };

  openForDelete = (project) => {
    this.setState({
      showDeleteModal: true,
      projectSelected: project,
    });
  };

  componentDidMount() {
    this.props.getProjects();
    this.props.getClients();
    this.props.getUsers();
  }

  deleteProject = () => {
    const data = {
      formValues: {
        projectId: this.state.projectSelected.timeTrackProjectId,
      },
      closeModal: this.closeDeleteModal,
    };
    this.props.deleteProject(data);
  };

  getTableHeading = () => {
    return ["Name", "Client", "Type", "Status", "Added on", ""];
  };

  getTableContent = (data) => {
    return data.map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>{row.name}</td>
          <td>{row.timeTrackClient.name}</td>
          <td>{row.type}</td>
          <td>{row.isActive ? "Active" : "Inactive"}</td>
          <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
          <td>
            <ButtonGroup size="sm">
              <Button
                outline
                color="info"
                size="sm"
                onClick={() => this.openForEdit(row)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                outline
                color="orange"
                size="sm"
                onClick={() => this.openForAddTeam(row)}
              >
                Team
              </Button>
              {/* <Button
                outline
                color="danger"
                size="sm"
                onClick={() => this.openForDelete(row)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button> */}
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  render() {
    const classes = new BEMhelper("time-tracking");
    return (
      <Container {...classes("projects")}>
        {this.state.showUserModal ? (
          <AddProjectModal
            openModal={this.state.showUserModal}
            toggle={this.toggle}
            title={this.state.editMode ? "Edit project" : "Add new project"}
            submit={this.addProject}
            clients={this.props.clients.data}
            isEditMode={this.state.editMode}
            objectToEdit={this.state.projectSelected}
          />
        ) : null}
        {this.state.showDeleteModal ? (
          <DeleteModal
            openModal={this.state.showDeleteModal}
            body={"Are you sure you want to delete this project?"}
            onClose={this.closeDeleteModal}
            onConfirm={this.deleteProject}
          />
        ) : null}
        {this.state.showTeamModal ? (
          <AddTeamMemberModal
            openModal={this.state.showTeamModal}
            toggle={this.toggle}
            title={"Manage project members"}
            submit={this.addTeam}
            userList={this.props.users.data}
            isEditMode={this.state.editMode}
            objectToEdit={this.state.projectSelected}
          />
        ) : null}

        <Row>
          <Col sm={10} className="text-left">
            <h2 className="list-header-title">Projects</h2>
          </Col>
          <Col sm={2} className="text-right">
            <Button
              className="btn-primary"
              onClick={() => {
                this.openForAdd();
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> New Project
            </Button>
          </Col>

          <Col sm={12}>
            <div className="hr"></div>
            {this.props.projects.loading ? (
              <p>Loading...</p>
            ) : (
              <CustomTable
                content={this.getTableContent(this.props.projects.data)}
                tableHeadings={this.getTableHeading()}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProjects: timeTrackModule.projectModule.actions.getProjects,
      createProject: timeTrackModule.projectModule.actions.createProject,
      getClients: timeTrackModule.clientModule.actions.getClients,
      deleteProject: timeTrackModule.projectModule.actions.deleteProject,
      updateProject: timeTrackModule.projectModule.actions.updateProject,
      getUsers: timeTrackModule.userModule.actions.getUsers,
      assignTeamToProject:
        timeTrackModule.projectModule.actions.assignTeamToProject,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    projects: state.timeTrackingProjects,
    clients: state.timeTrackingClients,
    users: state.timeTrackingUsers,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(TimeTrackProjects);
