import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  CREATE_ENTRY_REQUEST,
  CREATE_ENTRY_SUCCESS,
  CREATE_ENTRY_FAILURE,
  GET_ENTRIES_FOR_DAY_REQUEST,
  GET_ENTRIES_FOR_DAY_SUCCESS,
  GET_ENTRIES_FOR_DAY_FAILURE,
  UPDATE_ENTRY_REQUEST,
  UPDATE_ENTRY_SUCCESS,
  UPDATE_ENTRY_FAILURE,
  DELETE_ENTRY_REQUEST,
  DELETE_ENTRY_SUCCESS,
  DELETE_ENTRY_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";

export function* entrySaga() {
  yield takeLatest(CREATE_ENTRY_REQUEST, createEntry);
  yield takeLatest(GET_ENTRIES_FOR_DAY_REQUEST, getEntriesForDate);
  yield takeLatest(UPDATE_ENTRY_REQUEST, updateEntry);
  yield takeLatest(DELETE_ENTRY_REQUEST, deleteEntry);
}

function* getEntriesForDate(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const entries = yield call(
      apis.timeTrackApis.getEntriesForDate,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (entries.error) {
      yield put({
        type: GET_ENTRIES_FOR_DAY_FAILURE,
        error: entries.body.data.error,
      });
    } else {
      yield put({ type: GET_ENTRIES_FOR_DAY_SUCCESS, data: entries.body.data });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createEntry(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const entry = yield call(
      apis.timeTrackApis.createEntry,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (entry.error) {
      yield put({
        type: CREATE_ENTRY_FAILURE,
        error: entry.body.data.error,
      });
    } else {
      yield put({ type: CREATE_ENTRY_SUCCESS, data: entry.body.data });
      const data = {
        date: details.payload.formValues.date,
      };
      yield put({ type: GET_ENTRIES_FOR_DAY_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* updateEntry(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const entry = yield call(
      apis.timeTrackApis.updateEntry,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (entry.error) {
      yield put({
        type: UPDATE_ENTRY_FAILURE,
        error: entry.body.data.error,
      });
    } else {
      yield put({ type: UPDATE_ENTRY_SUCCESS, data: entry.body.data });
      const data = {
        date: details.payload.formValues.date,
      };
      yield put({ type: GET_ENTRIES_FOR_DAY_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* deleteEntry(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const entry = yield call(
      apis.timeTrackApis.deleteEntry,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues.entryId
    );
    if (entry.error) {
      yield put({
        type: DELETE_ENTRY_FAILURE,
        error: entry.body.data.error,
      });
    } else {
      yield put({ type: DELETE_ENTRY_SUCCESS, data: entry.body.data });
      const data = {
        date: details.payload.formValues.date,
      };
      yield put({ type: GET_ENTRIES_FOR_DAY_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
