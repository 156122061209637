import React, { Component } from "react";
import BEMhelper from "react-bem-helper";
import { Spinner } from "reactstrap";
class Loader extends Component {
  render() {
    const classes = new BEMhelper("Loader");
    return (
      <div {...classes("wrap")}>
        <Spinner style={{ width: "3rem", height: "3rem" }} type="grow" />
      </div>
    );
  }
}

export default Loader;
