import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

export const getUserList = async (token) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/user`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getUserById = async (token, userId) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/user/${userId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const login = async (loginDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/user/signIn`,
    data: loginDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const forgotPassword = async (forgotPasswordDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/user/forgot-password`,
    data: forgotPasswordDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const resetPassword = async (resetPasswordDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/user/reset-password`,
    headers: {
      "x-access-token": resetPasswordDetails.token,
    },
    data: resetPasswordDetails.formValues,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const signup = async (signupDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/user`,
    data: signupDetails,
    // headers: {
    //   'x-access-token': token,
    // }
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getRoles = async () => {
  return axios({
    method: "GET",
    url: `${apiUrl}//user/roles`,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getDesignation = async () => {
  return axios({
    method: "GET",
    url: `${apiUrl}/user/designations`,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getDepartmentList = async () => {
  return axios({
    method: "GET",
    url: `${apiUrl}/user/departments`,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
