import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/time-tracking`;

export const getEntriesForDate = async (token, entryDateDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/entry/details`,
    headers: {
      "x-access-token": token,
    },
    data: entryDateDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createEntry = async (token, entryDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/entry`,
    headers: {
      "x-access-token": token,
    },
    data: entryDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const updateEntry = async (token, entryDetails) => {
  return axios({
    method: "PUT",
    url: `${apiUrl}/entry`,
    headers: {
      "x-access-token": token,
    },
    data: entryDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const deleteEntry = async (token, entryId) => {
  return axios({
    method: "DELETE",
    url: `${apiUrl}/entry/${entryId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
