export const GET_HRM_VACANCIES_REQUEST = "GET_HRM_VACANCIES_REQUEST";
export const GET_HRM_VACANCIES_SUCCESS = "GET_HRM_VACANCIES_SUCCESS";
export const GET_HRM_VACANCIES_FAILURE = "GET_HRM_VACANCIES_FAILURE";

export const CREATE_HRM_VACANCIES_REQUEST = "CREATE_HRM_VACANCIES_REQUEST";
export const CREATE_HRM_VACANCIES_SUCCESS = "CREATE_HRM_VACANCIES_SUCCESS";
export const CREATE_HRM_VACANCIES_FAILURE = "CREATE_HRM_VACANCIES_FAILURE";

export const UPDATE_HRM_VACANCIES_REQUEST = "UPDATE_HRM_VACANCIES_REQUEST";
export const UPDATE_HRM_VACANCIES_SUCCESS = "UPDATE_HRM_VACANCIES_SUCCESS";
export const UPDATE_HRM_VACANCIES_FAILURE = "UPDATE_HRM_VACANCIES_FAILURE";

export const GET_HRM_VACANCY_REQUEST = "GET_HRM_VACANCY_REQUEST";
export const GET_HRM_VACANCY_SUCCESS = "GET_HRM_VACANCY_SUCCESS";
export const GET_HRM_VACANCY_FAILURE = "GET_HRM_VACANCY_FAILURE";
