import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import StarRatings from "react-star-ratings";
import hrmModule from "../../../../../redux/hrm";
import Button from "../../../../components/ui-components/Button";
import InfoModal from "../../../../components/modals/infoModal";
import { FaArrowLeft } from "react-icons/fa";
import { Container, Row, Col, Card, FormGroup, Label, Alert } from "reactstrap";
import CustomTable from "../../../../components/table";
import moment from "moment";
import bemClass from "../../../../components/ui-components/Utils/bemClass";
import Loader from "../../../../components/ui-components/Loader";
import "./surveyEmployee.scss";

class HrmSurveyEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
      selectedFeedback: [],
    };
  }

  componentDidMount() {
    this.props.getFeedbackInfo({
      surveyId: this.props.match.params.surveyId,
      employeeId: this.props.match.params.employeeId,
    });
  }

  getSurveyList = (feedbacks) => {
    return (feedbacks || []).map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>
            {row.supervisor.firstName} {row.supervisor.lastName}
          </td>
          <td>{row.supervisor.designation.name}</td>
          <td>
            <StarRatings
              rating={row.rating || 0}
              starRatedColor="blue"
              isSelectable={false}
              numberOfStars={5}
              name="rating"
              starDimension="20px"
              starSpacing="5px"
              starRatedColor="#052a4f"
            />
          </td>
          <td>{row.isSubmitted ? "Submitted" : "Pending"}</td>
          <td>
            <Button
              size="sm"
              disabled={!row.isSubmitted}
              onClick={() =>
                this.setState({
                  showInfoModal: true,
                  selectedFeedback: row.feedbacks,
                })
              }
            >
              View Feedback
            </Button>
          </td>
        </tr>
      );
    });
  };

  toggle = () => {
    this.setState({
      showInfoModal: false,
    });
  };

  getModalBody = () => {
    const classes = new bemClass("HrmSurveyEmployee");
    return (
      <ol>
        {this.state.selectedFeedback.map((feedback) => {
          return (
            <div className={classes.elem("feedback-question").b()}>
              <li>{feedback.hrmSurveyQuestion.question}</li>
              <p>
                {feedback.hrmSurveyQuestion.type === "multipleChoice"
                  ? feedback.hrmSurveyMultiChoice.choice
                  : feedback.answer}
              </p>
            </div>
          );
        })}
      </ol>
    );
  };

  render() {
    const classes = new bemClass("HrmSurveyEmployee");
    return (
      <Container fluid className={classes.elem("wrapper").b()}>
        {this.state.showInfoModal ? (
          <InfoModal
            openModal={this.state.showInfoModal}
            toggle={this.toggle}
            title={"Feedback"}
            body={this.getModalBody()}
            size="md"
          />
        ) : null}
        {this.props.feedbackInfo.loading ? (
          <div className={classes.elem("loader").b()}>
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            {this.props.feedbackInfo.error ? (
              <Alert color="danger">
                <p>{this.props.feedbackInfo.error}</p>
              </Alert>
            ) : (
              <React.Fragment>
                <Row>
                  <Col sm={7} className="text-left">
                    <div className="headerTitle-wrap">
                      <h2 className="list-header-title">
                        {this.props.feedbackInfo.data.survey.name}
                      </h2>
                    </div>
                  </Col>
                  <Col sm={5} className="text-right">
                    <Button
                      color="deep-blue"
                      onClick={() => {
                        this.props.history.push(
                          `/hrm/performance/survey-info/${this.props.feedbackInfo.data.survey.hrmSurveyId}`
                        );
                      }}
                    >
                      <FaArrowLeft /> Go Back
                    </Button>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm={12}>
                    <Card body>
                      <Row>
                        <Col md={3} className={classes.elem("detailBox").b()}>
                          <FormGroup
                            className={classes.elem("detailBoxFormGroup").b()}
                          >
                            <Label>Employee Name</Label>
                            <p>
                              {this.props.feedbackInfo.data.employee.firstName}{" "}
                              {this.props.feedbackInfo.data.employee.lastName}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md={3} className={classes.elem("detailBox").b()}>
                          <FormGroup
                            className={classes.elem("detailBoxFormGroup").b()}
                          >
                            <Label>Survey Status</Label>
                            <p>
                              {this.props.feedbackInfo.data.survey.isCompleted
                                ? "Completed"
                                : "Ongoing"}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md={3} className={classes.elem("detailBox").b()}>
                          <FormGroup
                            className={classes.elem("detailBoxFormGroup").b()}
                          >
                            <Label>Feedback Ratio</Label>
                            <p>
                              {
                                this.props.feedbackInfo.data.survey
                                  .feedbacksReceived
                              }
                              /{this.props.feedbackInfo.data.survey.surveysSent}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup
                            className={classes.elem("detailBoxFormGroup").b()}
                          >
                            <Label>Started On</Label>
                            <p>
                              {moment(
                                this.props.feedbackInfo.data.survey.createdAt
                              ).format("YYYY-MM-DD")}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <CustomTable
                      content={this.getSurveyList(
                        this.props.feedbackInfo.data.surveyFeedbacks
                      )}
                      tableHeadings={[
                        "Supervisor Name",
                        "Supervisor Designation",
                        "Overall Rating",
                        "Status",
                        "",
                      ]}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSurveyInfo:
        hrmModule.hrmPerformanceModule.surveyModule.actions.getSurveyInfo,
      getFeedbackInfo:
        hrmModule.hrmPerformanceModule.surveyModule.actions
          .getSignleFeedbackInfo,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    feedbackInfo: state.hrmPerformanceSingleFeedback,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(HrmSurveyEmployee);
