import React, { Component } from "react";
import CustomTable from "../../../components/table";
import AddFeatureAccess from "../../../components/modals/addFeatureAccessModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Button, Card } from "reactstrap";
import userModule from "../../../../redux/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import adminModule from "../../../../redux/admin";
import BEMhelper from "react-bem-helper";

class FeatureAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserModal: false,
    };
  }

  toggle = (reloadData = false) => {
    if (reloadData) {
      this.props.featureAccessUsers();
    }
    this.setState({
      showUserModal: false,
    });
  };

  getTableHeading = () => {
    return ["Access Type", "UserName", ""];
  };

  getTableContent = (data) => {
    return data.map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>{row.featureAccessRole.roleType}</td>
          <td>
            {row.user.firstName} {row.user.lastName}
          </td>
          <td>Delete</td>
        </tr>
      );
    });
  };

  openAddAccessModal = () => {
    this.props.users();
    this.props.featureAccessRole();
    this.setState({
      showUserModal: true,
    });
  };

  addAccess = (values) => {
    const details = {
      formValues: {
        accessRoleId: values.accessType,
        userId: values.user,
      },
      closeModal: this.toggle,
    };
    this.props.addFeatureAccess(details);
  };

  componentDidMount() {
    this.props.featureAccessUsers();
  }

  render() {
    const classes = new BEMhelper("featurAccess");
    return (
      <React.Fragment>
        {this.state.showUserModal ? (
          <AddFeatureAccess
            openModal={this.state.showUserModal}
            toggle={this.toggle}
            title={"Add Feature Access"}
            featureAccessRoleGroup={this.props.featureAccessRoleGroup}
            addFeatureAccessGroup={this.props.addFeatureAccessGroup}
            userGroup={this.props.userGroup}
            onSubmit={this.addAccess}
          />
        ) : null}

        <Container fluid {...classes("featuraccess-wrapper")}>
          <Card body>
            <Row className="header-table-space text-left">
              <Col sm={10} className="text-left">
                <h2 className="list-header-title">Feature Access List</h2>
              </Col>
              <Col sm={2}>
                <Button
                  color="primary"
                  onClick={() => {
                    this.openAddAccessModal();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add Access
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {this.props.featureAccessUsersGroup.loading ? (
                  <p>Loading...</p>
                ) : (
                  <CustomTable
                    content={this.getTableContent(
                      this.props.featureAccessUsersGroup.data
                    )}
                    tableHeadings={this.getTableHeading()}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      users: userModule.actions.getUserList,
      featureAccessRole: adminModule.actions.featureAccessRole,
      addFeatureAccess: adminModule.actions.addFeatureAccess,
      featureAccessUsers: adminModule.actions.featureAccessUsers,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    userGroup: state.userList,
    featureAccessRoleGroup: state.featureAccessRole,
    addFeatureAccessGroup: state.addFeatureAccess,
    featureAccessUsersGroup: state.featureAccessUsers,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(FeatureAccess);
