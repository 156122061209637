import { GET_USER_DESIGNATION_REQUEST, GET_USER_DESIGNATION_SUCCESS } from '../actionTypes';

const initialState = {
  loading: true,
  roles: [],
  error: null,
};

const userDesignationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DESIGNATION_REQUEST:
      return {
        ...state,
        loading: true,
        roles: [],
        error: null
      };
    case GET_USER_DESIGNATION_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.data,
        error: null
      };
    default:
      return state;
  }
};

export default userDesignationReducer;