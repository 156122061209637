import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../ui-components/Button";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import inventoryModule from "../../../../../redux/inventory";
import commonModule from "../../../../../redux/common";
import "./signOffModal.scss";

const classes = new BEMhelper("SignOffModal");
const SignOffModal = (props) => {
  const [generateReport, setGenerateReport] = useState(false);
  const dispatch = useDispatch();
  let availableItems = [];
  useEffect(() => {
    const filters = {
      offset: 0,
      filterType: "all",
      excludeOccupied: "yes",
      apiEndpoint: "inventoryGetItems",
    };
    dispatch(commonModule.actions.getCommonData(filters));
  }, [dispatch]);

  const { title, openModal, onClose, item, userId } = props;
  const defaultValues = {};

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    const details = {
      formValues: {
        signOutItemId: item.inventoryItemId,
        userId: userId,
        ...(values.itemId && { signInTo: values.itemId.value }),
        generateDocument: generateReport,
      },
      closeModal: onClose,
    };
    dispatch(inventoryModule.itemModule.actions.swopUserItems(details));
  };

  const itemList = useSelector((state) => state.commonDataList);
  const itemUpdate = useSelector((state) => state.inventoryUpdateItem);

  if (itemList.data && itemList.data.records) {
    availableItems = (itemList.data.records || []).map((item) => {
      return {
        value: item.inventoryItemId,
        label: `${item.name}`,
      };
    });
  }

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => onClose()}
      centered={true}
      draggable={false}
      backdrop="static"
      size="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => onClose()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Swop with</Label>
                  <Controller
                    as={
                      <Select
                        placeholder="Select item"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: `1px solid ${
                              errors.itemId ? "#dc3545" : "#cad2dd"
                            }`,
                          }),
                        }}
                      />
                    }
                    options={availableItems}
                    name="itemId"
                    isClearable
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            submit
            disabled={itemList.loading || itemUpdate.loading}
            color="orange"
            onClick={() => setGenerateReport(true)}
          >
            Save and Generate Report
          </Button>
          <Button submit disabled={itemList.loading || itemUpdate.loading}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default SignOffModal;
