import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import Button from "../../../../components/ui-components/Button";
import Tabs from "../../../../components/ui-components/Tabs";
import Tab from "../../../../components/ui-components/Tab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomTable from "../../../../components/table";
import hrmModule from "../../../../../redux/hrm";
import userModule from "../../../../../redux/user";
import moment from "moment";
import BEMhelper from "react-bem-helper";
import AddFeedbackModal from "../../../../components/modals/hrm/mentorship/addFeedback";
import InfoModal from "../../../../components/modals/infoModal";
import Loader from "../../../../components/ui-components/Loader";
import "./viewAllFeedback.scss";

class ViewAllFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "mentee",
      showFeedbackModal: false,
      showViewFeedbackModal: false,
      feedbackSelected: null,
      editMode: false,
    };
  }

  handleTabChange = (tab) => {
    this.setState({ selectedTab: tab });
    this.props.getFeedbacks({ feedbackType: tab });
  };

  viewFeedback = (feedback) => {
    this.setState({ feedbackSelected: feedback, showViewFeedbackModal: true });
  };

  getFeedbackList = (feedbackList) => {
    if (feedbackList.length) {
      return feedbackList.map((row, key) => {
        return (
          <tr key={key} className="value-row">
            <td>
              {this.state.selectedTab === "mentee"
                ? `${row.mentorDetails.firstName} ${row.mentorDetails.lastName}`
                : `${row.menteeDetails.firstName} ${row.menteeDetails.lastName}`}
            </td>
            <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
            <td className="feedback-actions">
              <Button size="sm" onClick={() => this.viewFeedback(row)}>
                View feedback
              </Button>
            </td>
          </tr>
        );
      });
    }
    return [];
  };

  getFeedbackHeadings = () => {
    if (this.state.selectedTab === "mentee") {
      return ["Mentor", "Added on", ""];
    }
    return ["Mentee", "Added on", ""];
  };

  addFeedback = (values) => {
    const data = {
      formValues: {
        menteeId: values.mentee
          ? values.colleague.value
          : this.props.user.userId,
        mentorId: values.mentee
          ? this.props.user.userId
          : values.colleague.value,
        feedback: values.feedback,
      },
      closeModal: this.closeFeedbackModal,
    };
    this.props.createFeedback(data);
  };

  openForAddFeedback = () => {
    this.setState({ showFeedbackModal: true });
  };

  closeFeedbackModal = (tab = "mentee") => {
    this.setState({ showFeedbackModal: false, selectedTab: tab });
  };

  closeViewFeedbackModal = () => {
    this.setState({ feedbackSelected: null, showViewFeedbackModal: false });
  };

  getFeedbackContent = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: this.state.feedbackSelected.feedback,
        }}
      />
    );
  };

  componentDidMount = () => {
    this.props.getUserList();
    this.props.getFeedbacks({ feedbackType: "mentee" });
  };

  render() {
    const classes = new BEMhelper("mentorship");
    return (
      <Container fluid {...classes("feedbacks")}>
        {this.state.showFeedbackModal ? (
          <AddFeedbackModal
            openModal={this.state.showFeedbackModal}
            title={"Create mentorship feedback"}
            toggle={this.closeFeedbackModal}
            submit={this.addFeedback}
            users={this.props.userList.userList}
          />
        ) : null}
        {this.state.showViewFeedbackModal ? (
          <InfoModal
            openModal={this.state.showViewFeedbackModal}
            toggle={this.closeViewFeedbackModal}
            title={"Feedback"}
            body={this.getFeedbackContent()}
          />
        ) : null}

        <Row>
          <Col sm={7} className="text-left">
            <div className="headerTitle-wrap">
              <h2 className="list-header-title">Feedback List</h2>
              {this.props.authorized.isSuperAdmin ||
              this.props.authorized.isAdmin ||
              this.props.authorized.hasHrmFeedbackAccess ? (
                <Button
                  size="sm"
                  type="link"
                  onClick={() => this.props.history.push("/hrm/mentorship/all")}
                >
                  All Users
                </Button>
              ) : null}
            </div>
          </Col>
          <Col sm={5} className="text-right">
            <Button
              color="deep-blue"
              onClick={() => {
                this.openForAddFeedback();
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> New Feedback
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="hr"></div>
            <React.Fragment>
              <Tabs
                selected={this.state.selectedTab}
                onChange={this.handleTabChange}
              >
                <Tab id="mentee" title={"Mentee"} />
                <Tab id="mentor" title={"Mentor"} />
              </Tabs>
              {this.props.feedbackList.loading ? (
                <div {...classes("loader")}>
                  <Loader />
                </div>
              ) : (
                <CustomTable
                  content={this.getFeedbackList(this.props.feedbackList.data)}
                  tableHeadings={this.getFeedbackHeadings()}
                />
              )}
            </React.Fragment>
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFeedbacks: hrmModule.mentorshipModule.actions.getFeedbacks,
      createFeedback: hrmModule.mentorshipModule.actions.createFeedback,
      getUserList: userModule.actions.getUserList,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.loggedUser.userDetails.user,
    feedbackList: state.hrmMentorshipFeedback,
    userList: state.userList,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(ViewAllFeedback);
