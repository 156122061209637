import createItemReducer from "./reducer/createItemReducer";
import updateItemReducer from "./reducer/updateItemReducer";
// import deleteItemReducer from "./reducer/deleteItemReducer";
import featureAccessRoleReducer from "./reducer/featureAccessRoleReducer";
import { featureAccessRole } from "./actionCreators";
import { commonSaga } from "./saga/commonSaga";

const commonModule = {
  actions: {
    featureAccessRole,
  },
  reducers: {
    createItemReducer: createItemReducer,
    updateItemReducer: updateItemReducer,
    // deleteItemReducer: deleteItemReducer,
    featureAccessRoleReducer: featureAccessRoleReducer,
  },
  sagas: {
    commonSaga: commonSaga,
  },
};

export default commonModule;
