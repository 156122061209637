import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import hrmModule from "../../../../../redux/hrm";
import Button from "../../../../components/ui-components/Button";
import { FaPlus } from "react-icons/fa";
import { Container, Row, Col, ButtonGroup } from "reactstrap";
import CustomTable from "../../../../components/table";
import moment from "moment";
import bemClass from "../../../../components/ui-components/Utils/bemClass";
import Loader from "../../../../components/ui-components/Loader";
import InfoModal from "../../../../components/modals/infoModal";
import Textarea from "../../../../components/ui-components/Textarea";
import DeleteModal from "../../../../components/modals/common/deleteModal";
import "./surveyList.scss";

class HrmSurveyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
      showDeleteModal: false,
      selectedSurvey: null,
      filters: [
        {
          label: "Ongoing",
          value: "ongoing",
        },
        {
          label: "Completed",
          value: "completed",
        },
        {
          label: "Archived",
          value: "archived",
        },
        {
          label: "All",
          value: "all",
        },
      ],
      appliedFilters: null,
    };
  }

  componentDidMount() {
    const details = {
      query: null,
    };
    this.props.getSurveys(details);
  }

  getSurveyList = (surveys) => {
    const classes = new bemClass("HrmSurveyList");
    return (surveys || []).map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>{row.name}</td>
          <td>{row.isCompleted ? "Completed" : "Ongoing"}</td>
          <td>{row.numberOfEmployees}</td>
          <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
          <td className="candidate__application-actions">
            <ButtonGroup size="sm">
              <React.Fragment>
                <Button
                  color={row.isArchived ? "blue" : "red"}
                  size="sm"
                  onClick={() => {
                    if (row.isArchived) {
                      const details = {
                        surveyId: row.hrmSurveyId,
                        query: `archive=false`,
                        appliedFilters: this.state.appliedFilters,
                        closeModal: this.toggle,
                      };
                      this.props.archiveSurvey(details);
                    } else {
                      this.setState({
                        selectedSurvey: row,
                        showDeleteModal: true,
                      });
                    }
                  }}
                  className={classes.elem("archive-btn").b()}
                  type="lined"
                >
                  {row.isArchived ? "Unarchive" : "Archive"}
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    this.setState({ showInfoModal: true });
                    this.props.getSurveyPaper({ surveyId: row.hrmSurveyId });
                  }}
                >
                  View Survey
                </Button>
                <Button
                  size="sm"
                  color="medium-blue"
                  onClick={() => {
                    this.props.history.push(
                      `/hrm/performance/survey-info/${row.hrmSurveyId}`
                    );
                  }}
                >
                  View Result
                </Button>
              </React.Fragment>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  toggle = () => {
    this.setState({
      showInfoModal: false,
      showDeleteModal: false,
      selectedSurvey: null,
    });
  };

  getBody = () => {
    if (this.props.surveyPaper.data) {
      return (
        <ol>
          {this.props.surveyPaper.data.questions.map((question) => {
            return (
              <React.Fragment>
                <li
                  className={`survey-question ${
                    question.required ? "required-label" : ""
                  }`}
                >
                  {question.question}
                </li>
                {question.type === "multipleChoice" ? (
                  question.choices.map((choice) => {
                    return (
                      <div className="single-choice">
                        <input
                          value={choice.hrmSurveyMultiChoiceId}
                          name={question.hrmSurveyQuestionId}
                          type="radio"
                          disabled
                        />
                        <span>{choice.choice}</span>
                      </div>
                    );
                  })
                ) : (
                  <Textarea name={question.hrmSurveyQuestionId} disabled />
                )}
              </React.Fragment>
            );
          })}
        </ol>
      );
    }
  };

  archiveSurvey = () => {
    const details = {
      surveyId: this.state.selectedSurvey.hrmSurveyId,
      query: `archive=true`,
      appliedFilters: this.state.appliedFilters,
      closeModal: this.toggle,
    };
    this.props.archiveSurvey(details);
  };

  componentWillUnmount() {
    this.props.resetSurveys();
  }

  render() {
    const classes = new bemClass("HrmSurveyList");
    const loggedUserAccess = this.props.user.userDetails.accessRoles;
    return (
      <Container fluid className={classes.elem("wrapper").b()}>
        {this.state.showInfoModal ? (
          <InfoModal
            openModal={this.state.showInfoModal}
            toggle={this.toggle}
            title={"Survey Form"}
            body={this.getBody()}
            bodyLoading={this.props.surveyPaper.loading}
            size="md"
          />
        ) : null}
        {this.state.showDeleteModal ? (
          <DeleteModal
            openModal={this.state.showDeleteModal}
            body={
              "Are you sure you want to archive this survey? This will restrict supervisors from submitting feedback."
            }
            title="Confirm Archive"
            deleteBtnText="Archive"
            onClose={this.toggle}
            onConfirm={this.archiveSurvey}
          />
        ) : null}

        <Row>
          <Col sm={8} className="text-left">
            <div className="headerTitle-wrap">
              <h2 className="list-header-title">Survey List</h2>
            </div>
          </Col>
          <Col sm={4}>
            {loggedUserAccess.isAdmin ||
            loggedUserAccess.isHrmAdmin ||
            loggedUserAccess.isSuperAdmin ? (
              <div className={classes.elem("header-actions").b()}>
                <Select
                  isClearable={true}
                  isSearchable={true}
                  options={this.state.filters}
                  placeholder="Filter By"
                  onChange={(state) => {
                    let query = "";
                    if (state) {
                      if (state.value === "ongoing") {
                        query = "isCompleted=false";
                      }
                      if (state.value === "completed") {
                        query = "isCompleted=true";
                      }
                      if (state.value === "archived") {
                        query = "isArchived=true";
                      }
                    }
                    this.setState({ appliedFilters: query });
                    const details = {
                      query: query,
                    };
                    this.props.getSurveys(details);
                  }}
                  className={classes.elem("filter").b()}
                />
                <Button
                  color="deep-blue"
                  onClick={() => {
                    this.props.history.push("/hrm/performance/create-survey");
                  }}
                >
                  <FaPlus /> New Survey
                </Button>
              </div>
            ) : null}
          </Col>
        </Row>
        <React.Fragment>
          <Row>
            <Col sm={12}>
              <div className="hr"></div>
              {this.props.surveys.loading ? (
                <div className={classes.elem("loader").b()}>
                  <Loader />
                </div>
              ) : (
                <CustomTable
                  content={this.getSurveyList(this.props.surveys.data)}
                  tableHeadings={[
                    "Name",
                    "Status",
                    "No.Of Employees",
                    "Started On",
                    "",
                  ]}
                />
              )}
            </Col>
          </Row>
        </React.Fragment>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSurveys:
        hrmModule.hrmPerformanceModule.surveyModule.actions.getSurveys,
      resetSurveys:
        hrmModule.hrmPerformanceModule.surveyModule.actions.resetSurveys,
      getSurveyPaper:
        hrmModule.hrmPerformanceModule.surveyModule.actions.getSurveyPaper,
      archiveSurvey:
        hrmModule.hrmPerformanceModule.surveyModule.actions.archiveSurvey,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    surveys: state.hrmPerformanceSurveys,
    surveyPaper: state.hrmPerformanceSurveyPaper,
    updateItem: state.hrmPerformanceUpdateItem,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(HrmSurveyList);
