import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_INVENTORY_DASHBOARD_DATA_REQUEST,
  GET_INVENTORY_DASHBOARD_DATA_SUCCESS,
  GET_INVENTORY_DASHBOARD_DATA_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";

export function* dashboardSaga() {
  yield takeLatest(GET_INVENTORY_DASHBOARD_DATA_REQUEST, getDashboardData);
}

function* getDashboardData() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const dashboardData = yield call(
      apis.inventoryApis.getDashboardData,
      loggedUser.userDetails.auth.accessToken
    );
    if (dashboardData.error) {
      yield put({
        type: GET_INVENTORY_DASHBOARD_DATA_FAILURE,
        error: dashboardData.body.data.error,
      });
    } else {
      yield put({
        type: GET_INVENTORY_DASHBOARD_DATA_SUCCESS,
        data: dashboardData.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
