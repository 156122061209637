import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import hrmModule from "../../../../../redux/hrm";
import userModule from "../../../../../redux/user";
import BEMhelper from "react-bem-helper";
import { Container, Row, Col, Card } from "reactstrap";
import HrmSurveyAddQuestions from "./addQuestions";
import HrmSurveySurveyName from "./surveyName";
import HrmSurveyAddUsers from "./addUsers";
import Stepper from "react-stepper-horizontal";

class HrmSurveyCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      stepOneData: null,
      stepTwoData: null,
      stepThreeData: null,
    };
  }

  getFormattedEmployees = (allEmployees) => {
    const employees = allEmployees.map((user) => {
      return {
        employeeId: user.employee.value,
        supervisors: user.supervisors.map((supervisor) => {
          return {
            supervisorId: supervisor.value,
          };
        }),
      };
    });
    return employees;
  };

  submitValues = (values) => {
    const tab = this.state.activeTab;
    if (tab === 0) {
      this.setState({
        activeTab: 1,
        stepOneData: values,
      });
    } else if (tab === 1) {
      this.setState({
        activeTab: 2,
        stepTwoData: values,
      });
    } else {
      const surveyDetails = {
        name: this.state.stepOneData.surveyName,
        questions: this.state.stepTwoData || [],
        employees: this.getFormattedEmployees(values),
      };
      this.props.createSurvey(surveyDetails);
    }
  };

  changeTab = (tabValue) => {
    this.setState({
      activeTab: tabValue,
    });
  };

  componentDidMount() {
    this.props.getUserList();
  }

  render() {
    const classes = new BEMhelper("HrmSurveyCreate");
    return (
      <Container fluid {...classes("list")}>
        <Row>
          <Col sm={7} className="text-left">
            <div className="headerTitle-wrap">
              <h2 className="list-header-title">Create Survey</h2>
            </div>
          </Col>
          <Col sm={5} className="text-right"></Col>
        </Row>
        <div className="hr"></div>
        <Card>
          <Row>
            <Col sm={12}>
              <div>
                <Stepper
                  steps={[
                    {
                      title: "Survey Name",
                    },
                    {
                      title: "Questions",
                    },
                    {
                      title: "Employees/ Supervisors",
                    },
                  ]}
                  activeStep={this.state.activeTab}
                  circleFontSize={0}
                />
              </div>
              <div className="hr"></div>
              {this.state.activeTab === 0 ? (
                <HrmSurveySurveyName
                  submit={(values) => this.submitValues(values)}
                  stepOneData={this.state.stepOneData}
                />
              ) : null}
              {this.state.activeTab === 1 ? (
                <HrmSurveyAddQuestions
                  stepOneData={this.state.stepOneData}
                  submit={(values) => this.submitValues(values)}
                  changeTab={(tab) => this.changeTab(tab)}
                  stepTwoData={this.state.stepTwoData}
                />
              ) : null}
              {this.state.activeTab === 2 ? (
                <HrmSurveyAddUsers
                  stepOneData={this.state.stepOneData}
                  allUserList={this.props.userList.userList}
                  submit={(values) => this.submitValues(values)}
                  stepThreeData={this.state.stepThreeData}
                  changeTab={(tab, users) => {
                    this.setState({ stepThreeData: users });
                    this.changeTab(tab);
                  }}
                  submitLoading={this.props.submitSurvey.loading}
                />
              ) : null}
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserList: userModule.actions.getUserList,
      createSurvey:
        hrmModule.hrmPerformanceModule.surveyModule.actions.createSurvey,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    userList: state.userList,
    submitSurvey: state.hrmPerformanceCreateItem,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(HrmSurveyCreate);
