import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  ADMIN_CREATE_DESIGNATION_REQUEST,
  ADMIN_CREATE_DESIGNATION_SUCCESS,
  ADMIN_CREATE_DESIGNATION_FAILURE,
} from "../../user/actionTypes";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const createItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
    case ADMIN_CREATE_DESIGNATION_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case SIGNUP_SUCCESS:
    case ADMIN_CREATE_DESIGNATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case SIGNUP_FAILURE:
    case ADMIN_CREATE_DESIGNATION_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default createItemReducer;
