import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

export const applyLeave = async (token, leaveDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/leave/apply`,
    headers: {
      "x-access-token": token,
    },
    data: leaveDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const leaveBalance = async (token, userId) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/leave/balance/${userId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const leaveDateType = async (token) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/leave/dateType`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const leaveHistory = async (token, userId) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/leave/history/${userId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const leaveList = async (token, paginate) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/leave/list?page=${paginate.page}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const processLeave = async (token, leaveDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/leave/process`,
    headers: {
      "x-access-token": token,
    },
    data: leaveDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
