import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/hrm/interviewStage`;

export const getStages = async (token) => {
  return axios({
    method: "GET",
    url: `${apiUrl}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createStage = async (token, stageDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}`,
    headers: {
      "x-access-token": token,
    },
    data: stageDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const updateStage = async (token, stageDetails) => {
  return axios({
    method: "PUT",
    url: `${apiUrl}/${stageDetails.stageId}`,
    headers: {
      "x-access-token": token,
    },
    data: stageDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
