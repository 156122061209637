import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import { useForm } from "react-hook-form";
import Button from "../../../../../ui-components/Button";
import Textarea from "../../../../../ui-components/Textarea";
import FormFeedBack from "../../../../../ui-components/FormFeedBack";

const classes = new BEMhelper("TurnDownReasonModal");
const TurnDownReasonModal = (props) => {
  const { title, openModal, toggle, submit } = props;

  const defaultValues = {};

  const { handleSubmit, register, errors } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    submit(values);
  };

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => toggle()}
      centered={true}
      draggable={false}
      size="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => toggle()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Reason</Label>
                  <Textarea
                    name="reason"
                    register={register}
                    state={errors.reason ? "error" : "none"}
                    refdetails={{
                      required: "Reason required",
                    }}
                  />
                  {errors.reason ? (
                    <FormFeedBack feedBack={errors.reason.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <FormFeedBack
              feedBack={"Please note that this action is irreversible!"}
              feedBackType={"info"}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button submit color="red">
            Submit Turn Down
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default TurnDownReasonModal;
