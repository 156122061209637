import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_HRM_MENTORSHIP_FEEDBACK_REQUEST,
  GET_HRM_MENTORSHIP_FEEDBACK_SUCCESS,
  GET_HRM_MENTORSHIP_FEEDBACK_FAILURE,
  CREATE_HRM_MENTORSHIP_FEEDBACK_REQUEST,
  CREATE_HRM_MENTORSHIP_FEEDBACK_SUCCESS,
  CREATE_HRM_MENTORSHIP_FEEDBACK_FAILURE,
  GET_HRM_MENTORSHIP_USERS_FEEDBACK_REQUEST,
  GET_HRM_MENTORSHIP_USERS_FEEDBACK_SUCCESS,
  GET_HRM_MENTORSHIP_USERS_FEEDBACK_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";

export function* mentorshipSaga() {
  yield takeLatest(GET_HRM_MENTORSHIP_FEEDBACK_REQUEST, getFeedbacks);
  yield takeLatest(CREATE_HRM_MENTORSHIP_FEEDBACK_REQUEST, createFeedback);
  yield takeLatest(
    GET_HRM_MENTORSHIP_USERS_FEEDBACK_REQUEST,
    getAllUsersFeedbacks
  );
}

function* getFeedbacks(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const feedbacks = yield call(
      apis.hrmApis.getFeedbacks,
      loggedUser.userDetails.auth.accessToken,
      details.payload.feedbackType
    );
    if (feedbacks.error) {
      yield put({
        type: GET_HRM_MENTORSHIP_FEEDBACK_FAILURE,
        error: feedbacks.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_MENTORSHIP_FEEDBACK_SUCCESS,
        data: feedbacks.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createFeedback(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const feedback = yield call(
      apis.hrmApis.createFeedback,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (feedback.error) {
      yield put({
        type: CREATE_HRM_MENTORSHIP_FEEDBACK_FAILURE,
        error: feedback.body.data.error,
      });
    } else {
      yield put({
        type: CREATE_HRM_MENTORSHIP_FEEDBACK_SUCCESS,
        data: feedback.body.data,
      });
      yield put({
        type: GET_HRM_MENTORSHIP_FEEDBACK_REQUEST,
        payload: { feedbackType: "mentor" },
      });
      details.payload.closeModal("mentor");
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getAllUsersFeedbacks(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const feedbacks = yield call(
      apis.hrmApis.getAllUsersFeedbacks,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (feedbacks.error) {
      yield put({
        type: GET_HRM_MENTORSHIP_USERS_FEEDBACK_FAILURE,
        error: feedbacks.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_MENTORSHIP_USERS_FEEDBACK_SUCCESS,
        data: feedbacks.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
