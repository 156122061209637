import Bem from "./bemClass.js";
// Formats className string from props and classMap
export default (props = {}, classMap = {}, baseClass = []) => {
  let classes = Array.isArray(baseClass) ? baseClass : [baseClass];

  if (
    props.className &&
    (typeof props.className === "string" || props.className instanceof Bem)
  ) {
    classes = classes.concat(props.className.split(" "));
  } else if (Array.isArray(props.className)) {
    classes = classes.concat(props.className);
  }

  const keys = Object.keys(classMap);
  for (var i = 0; i < keys.length; i++) {
    if (props[keys[i]]) {
      if (Array.isArray(classMap[keys[i]])) {
        classes = classes.concat(classMap[keys[i]]);
      } else {
        classes.push(classMap[keys[i]]);
      }
    }
  }
  return classes.join(" ");
};
