const itemConditions = [
  {
    value: "purchasedNew",
    label: "Purchased New",
  },
  {
    value: "existing",
    label: "Existing",
  },
  {
    value: "recondition",
    label: "Recondition",
  },
];

export default itemConditions;
