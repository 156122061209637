import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import timeTrackModule from "../../../../redux/timetracking";
import commonModule from "../../../../redux/common";
import { Container, Row, Col, ButtonGroup } from "reactstrap";
import BEMhelper from "react-bem-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import AddUserModal from "../../../components/modals/timeTracking/addUserModal";
import CustomTable from "../../../components/table";
import userModule from "../../../../redux/user";
import Button from "../../../components/ui-components/Button";

class TimeTrackUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserModal: false,
      userSelected: null,
      editMode: false,
    };
  }

  addUser = (values) => {
    const data = {
      formValues: {
        userId: values.user.value,
        billableRate: parseFloat(values.billableRate),
        internalRate: parseFloat(values.internalCost),
        capacity: parseFloat(values.capacity),
        permissionId: parseFloat(values.userRole.value),
        isActive: values.activeStatus,
        ...(this.state.editMode && {
          userId: this.state.userSelected.timeTrackUserId,
        }),
      },
      closeModal: this.toggle,
    };
    if (this.state.editMode) {
      this.props.updateUser(data);
    } else {
      this.props.createUser(data);
    }
  };

  openForAdd = () => {
    this.setState({
      editMode: false,
      userSelected: null,
    });
    this.toggle(true);
  };

  toggle = (toggleState = false) => {
    this.setState({
      showUserModal: toggleState,
    });
  };

  openForEdit = (user) => {
    this.setState({
      editMode: true,
      userSelected: user,
    });
    this.toggle(true);
  };

  componentDidMount() {
    this.props.getTasks();
    this.props.getCategories();
    this.props.getSystemUserList();
    this.props.getProjects();
    this.props.getUsers();
    this.props.getPermissionTypes({ module: "TimeTrack" });
  }

  getTableHeading = () => {
    return [
      "Name",
      "Billable Rate",
      "Internal Rate",
      "Capacity",
      "Role",
      "Status",
      "",
    ];
  };

  getTableContent = (data) => {
    return data.map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>
            {row.user.firstName} {row.user.lastName}
          </td>
          <td>{row.billableRate}</td>
          <td>{row.internalRate}</td>
          <td>{row.capacity}</td>
          <td>{row.permissionType.type}</td>
          <td>{row.isActive ? "Active" : "Inactive"}</td>
          <td>
            <ButtonGroup size="sm">
              <Button
                outline
                color="info"
                size="sm"
                onClick={() => this.openForEdit(row)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  render() {
    const classes = new BEMhelper("time-tracking");
    return (
      <Container {...classes("users")}>
        {this.state.showUserModal ? (
          <AddUserModal
            openModal={this.state.showUserModal}
            toggle={this.toggle}
            title={this.state.editMode ? "Edit member" : "Add new member"}
            submit={this.addUser}
            categories={this.props.categories.data}
            userList={this.props.userList.userList}
            projects={this.props.projects.data}
            isEditMode={this.state.editMode}
            objectToEdit={this.state.userSelected}
            permissionTypes={this.props.permissionTypes.data}
          />
        ) : null}

        <Row>
          <Col sm={9} className="text-left">
            <h2 className="list-header-title">Team</h2>
          </Col>
          <Col sm={3} className="text-right">
            <Button
              className="btn-primary"
              onClick={() => {
                this.openForAdd();
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> New Memeber
            </Button>
          </Col>

          <Col sm={12}>
            <div className="hr"></div>
            {this.props.users.loading ? (
              <p>Loading...</p>
            ) : (
              <CustomTable
                content={this.getTableContent(this.props.users.data)}
                tableHeadings={this.getTableHeading()}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCategories: timeTrackModule.categoryModule.actions.getCategories,
      getTasks: timeTrackModule.taskModule.actions.getTasks,
      createTask: timeTrackModule.taskModule.actions.createTask,
      getSystemUserList: userModule.actions.getUserList,
      getProjects: timeTrackModule.projectModule.actions.getProjects,
      createUser: timeTrackModule.userModule.actions.createUser,
      getUsers: timeTrackModule.userModule.actions.getUsers,
      updateUser: timeTrackModule.userModule.actions.updateUser,
      getPermissionTypes: commonModule.actions.getPermissionTypes,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    categories: state.timeTrackingEntryCategories,
    tasks: state.timeTrackingTasks,
    userList: state.userList,
    projects: state.timeTrackingProjects,
    users: state.timeTrackingUsers,
    permissionTypes: state.permissionTypes,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(TimeTrackUser);
