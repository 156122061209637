import {
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  ASSIGN_TEAM_TO_PROJECT_REQUEST,
  ASSIGN_TEAM_TO_PROJECT_SUCCESS,
  ASSIGN_TEAM_TO_PROJECT_FAILURE,
} from "../../project/actionTypes";
import {
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
} from "../../category/actionTypes";
import {
  CREATE_ENTRY_REQUEST,
  CREATE_ENTRY_SUCCESS,
  CREATE_ENTRY_FAILURE,
} from "../../entry/actionTypes";
import {
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
} from "../../client/actionTypes";
import {
  CREATE_TASK_REQUEST,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAILURE,
} from "../../task/actionTypes";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const createItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROJECT_REQUEST:
    case CREATE_CATEGORY_REQUEST:
    case CREATE_ENTRY_REQUEST:
    case CREATE_CLIENT_REQUEST:
    case CREATE_TASK_REQUEST:
    case CREATE_CONTACT_REQUEST:
    case ASSIGN_TEAM_TO_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case CREATE_PROJECT_SUCCESS:
    case CREATE_CATEGORY_SUCCESS:
    case CREATE_ENTRY_SUCCESS:
    case CREATE_CLIENT_SUCCESS:
    case CREATE_TASK_SUCCESS:
    case CREATE_CONTACT_SUCCESS:
    case ASSIGN_TEAM_TO_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case CREATE_PROJECT_FAILURE:
    case CREATE_CATEGORY_FAILURE:
    case CREATE_ENTRY_FAILURE:
    case CREATE_CLIENT_FAILURE:
    case CREATE_TASK_FAILURE:
    case CREATE_CONTACT_FAILURE:
    case ASSIGN_TEAM_TO_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default createItemReducer;
