import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/hrm/candidate`;

export const getCandidates = async (token, details = {}) => {
  return axios({
    method: "GET",
    url: `${apiUrl}?offset=${details.offset || 0}${
      details.searchVal ? `&searchVal=${details.searchVal}` : ""
    }${
      details.processStatusId
        ? `&processStatusId=${details.processStatusId}`
        : ""
    }&hired=${details.hired || false}&isTuredDown=${
      details.isTuredDown || false
    }`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createCandidate = async (token, candidateDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}`,
    headers: {
      "x-access-token": token,
      "'content-type'": "multipart/form-data",
    },
    data: candidateDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createDirectCandidate = async (candidateDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/direct`,
    headers: {
      "'content-type'": "multipart/form-data",
    },
    data: candidateDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const uploadCandidateResume = async (
  token,
  candidateDetails,
  candidateId
) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/uploadCV/${candidateId}`,
    headers: {
      "x-access-token": token,
      "'content-type'": "multipart/form-data",
    },
    data: candidateDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getCandidateQuestionCategories = async (token) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/question/category`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getCandidate = async (token, candidateId, inviteToken = null) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/${candidateId}?invite=${inviteToken}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getInterviewInvitations = async (token, candidateId) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/interview/list/${candidateId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createCandidateFeedback = async (token, feedbackDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/feedback/${feedbackDetails.candidateId}`,
    headers: {
      "x-access-token": token,
    },
    data: feedbackDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createInterviewInvitation = async (token, invitationDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/interview/Invitation`,
    headers: {
      "x-access-token": token,
    },
    data: invitationDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getCandidateFeedbacks = async (token, candidateId) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/feedback/${candidateId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const updateCandidate = async (token, candidateDetails, candidateId) => {
  return axios({
    method: "PUT",
    url: `${apiUrl}/${candidateId}`,
    headers: {
      "x-access-token": token,
      "'content-type'": "multipart/form-data",
    },
    data: candidateDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const deleteCandidate = async (token, candidateId) => {
  return axios({
    method: "DELETE",
    url: `${apiUrl}/${candidateId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
