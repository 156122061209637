import { reducer as formReducer } from "redux-form";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import leaveModule from "../redux/leave";
import userModule from "../redux/user";
import supervisorModule from "../redux/supervisor";
import adminModule from "../redux/admin";
import claimModule from "../redux/claim";
import commonModule from "../redux/common";
import timeTrackModule from "../redux/timetracking";
import inventoryModule from "../redux/inventory";
import hrmModule from "../redux/hrm";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    applyLeave: leaveModule.reducers.applyLeaveReducer,
    leaveBalanceNew: leaveModule.reducers.leaveBalanceNewReducer,
    leaveHistory: leaveModule.reducers.leaveHistoryReducer,
    leaveDateType: leaveModule.reducers.leaveDateTypeReducer,
    processLeave: leaveModule.reducers.processLeaveReducer,
    userList: userModule.reducers.userListReducer,
    singleUser: userModule.reducers.singleUserReducer,
    loggedUser: userModule.reducers.loggedUserReducer,
    forgotPassword: userModule.reducers.forgotPasswordReducer,
    resetPassword: userModule.reducers.resetPasswordReducer,
    signupUser: userModule.reducers.userSignupReducer,
    userRole: userModule.reducers.userGetRoleReducer,
    userDesignation: userModule.reducers.userGetDesignationReducer,
    userDepartment: userModule.reducers.userDepartmentReducer,
    supervisorLevels: supervisorModule.reducers.supervisorLevelReducer,
    // featureAccessRole: adminModule.reducers.featureAccessRoleReducer,
    // addFeatureAccess: adminModule.reducers.addFeatureAccessReducer,
    // featureAccessUsers: adminModule.reducers.featureAccessUsersReducer,
    // leaveList: adminModule.reducers.leaveListReducer,
    claimType: claimModule.reducers.claimTypeReducer,
    applyClaim: claimModule.reducers.applyClaimReducer,
    claimHistory: claimModule.reducers.claimHistoryReducer,

    permissionTypes: commonModule.reducers.allPermissionTypesReducer,
    commonDataList: commonModule.reducers.allDataReducer,

    adminCreateItem: adminModule.commonModule.reducers.createItemReducer,
    adminUpdateItem: adminModule.commonModule.reducers.updateItemReducer,
    adminDashboard: adminModule.dashboardModule.reducers.dashboardDataReducer,
    featureAccessRole:
      adminModule.commonModule.reducers.featureAccessRoleReducer,

    timeTrackingEntryCategories:
      timeTrackModule.categoryModule.reducers.allCategoriesReducer,
    timeTrackingProjects:
      timeTrackModule.projectModule.reducers.allProjectsReducer,
    timeTrackingEntriesForDate:
      timeTrackModule.entryModule.reducers.allEntriesForDayReducer,
    timeTrackingCreateItem:
      timeTrackModule.commonModule.reducers.createItemReducer,
    timeTrackingUpdateItem:
      timeTrackModule.commonModule.reducers.updateItemReducer,
    timeTrackingDeleteItem:
      timeTrackModule.commonModule.reducers.deleteItemReducer,
    timeTrackingClients:
      timeTrackModule.clientModule.reducers.allClientsReducer,
    timeTrackingTasks: timeTrackModule.taskModule.reducers.allTasksReducer,
    timeTrackingUsers: timeTrackModule.userModule.reducers.allUsersReducer,
    timeTrackingEntryReport:
      timeTrackModule.reportModule.reducers.allEntryReportDataReducer,

    inventoryMainCategories:
      inventoryModule.categoryModule.reducers.allCategoriesReducer,
    inventoryItemTypes: inventoryModule.typeModule.reducers.allTypesReducer,
    inventoryItems: inventoryModule.itemModule.reducers.allItemsReducer,
    inventoryCreateItem:
      inventoryModule.commonModule.reducers.createItemReducer,
    inventoryUpdateItem:
      inventoryModule.commonModule.reducers.updateItemReducer,
    inventoryDeleteItem:
      inventoryModule.commonModule.reducers.deleteItemReducer,
    inventoryReport: inventoryModule.reportModule.reducers.reportReducer,
    inventoryDashboardData:
      inventoryModule.dashboardModule.reducers.dashboardDataReducer,

    hrmMentorshipFeedback:
      hrmModule.mentorshipModule.reducers.allFeedbacksReducer,
    hrmCandidates: hrmModule.candidateModule.reducers.allCandidatesReducer,
    hrmCandidateQuestionCategories:
      hrmModule.candidateModule.reducers.candidateQuestionCategoriesReducer,
    hrmCandidate: hrmModule.candidateModule.reducers.candidateReducer,
    hrmCandidateFeedbacks:
      hrmModule.candidateModule.reducers.allCandidateFeedbackReducer,
    hrmCandidateInterviews:
      hrmModule.candidateModule.reducers.allInterviewInvitationReducer,

    hrmCreateItem: hrmModule.commonModule.reducers.createItemReducer,
    hrmUpdateItem: hrmModule.commonModule.reducers.updateItemReducer,
    hrmDeleteItem: hrmModule.commonModule.reducers.deleteItemReducer,

    hrmAgents: hrmModule.agentModule.reducers.allAgentsReducer,
    hrmAgentLogin: hrmModule.agentModule.reducers.agentLoginReducer,

    hrmVacancies: hrmModule.vacancyModule.reducers.allVacanciesReducer,
    hrmVacancy: hrmModule.vacancyModule.reducers.vacancyReducer,

    hrmInterviewStages:
      hrmModule.interviewStageModule.reducers.allStagesReducer,

    hrmRecruitmentDashboard:
      hrmModule.dashboardModule.reducers.dashboardDataReducer,

    hrmPerformanceSurveys:
      hrmModule.hrmPerformanceModule.surveyModule.reducers.allSurveysReducer,
    hrmPerformanceSurvey:
      hrmModule.hrmPerformanceModule.surveyModule.reducers.surveyDetailsReducer,
    hrmPerformanceCreateItem:
      hrmModule.hrmPerformanceModule.commonModule.reducers.createItemReducer,
    hrmPerformanceUpdateItem:
      hrmModule.hrmPerformanceModule.commonModule.reducers.updateItemReducer,
    hrmPerformanceSingleFeedback:
      hrmModule.hrmPerformanceModule.surveyModule.reducers
        .signleFeedbackInfoReducer,
    hrmPerformanceSurveyReport:
      hrmModule.hrmPerformanceModule.surveyModule.reducers.surveyReportReducer,
    hrmPerformanceSurveyPaper:
      hrmModule.hrmPerformanceModule.surveyModule.reducers.surveyPaperReducer,
  });

export default rootReducer;
