import React from "react";
import PropTypes from "prop-types";
import "./Tab.scss";
// import Icon from '../Icon';
import bemClass from "../Utils/bemClass";

const colors = ["white", "blue", "bright-blue", "medium-purple"];

const propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  icon: PropTypes.string,
  subtitle: PropTypes.string,
  selected: PropTypes.bool,
  noFlex: PropTypes.bool,
  color: PropTypes.oneOf(colors),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selectedRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: PropTypes.node,
};

const defaultProps = {
  className: "",
  title: null,
  subtitle: null,
  selected: false,
  color: "white",
};
const Tab = ({
  className,
  title,
  subtitle,
  icon,
  disabled,
  selected,
  noFlex,
  color,
  children,
  onClick,
  selectedRef,
  ...props
}) => {
  const classes = new bemClass("Tab", className);

  const modMap = {
    disabled,
    selected,
    [color]: color,
    "has-icon": icon,
    "no-flex": noFlex,
    "has-subtitle": subtitle,
    "only-icon": icon && !title && !subtitle,
  };

  let iconElem = null;
  // if(icon) {
  // 	iconElem = <Icon name={icon} className={classes.elem('icon').b()} />;
  // }
  let subtitleElem = null;
  if (subtitle) {
    subtitleElem = (
      <div className={classes.elem("subtitle").b()}>{subtitle}</div>
    );
  }

  return (
    <div
      className={classes.mod(modMap).b()}
      onClick={disabled ? null : onClick}
      ref={selectedRef}
      {...props}
    >
      {iconElem}
      {title || children}
      {subtitleElem}
    </div>
  );
};

Tab.propTypes = propTypes;
Tab.defaultProps = defaultProps;

export default Tab;
