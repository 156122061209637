import { getUsers, createUser, deleteUser, updateUser } from "./actionCreators";
import allUsersReducer from "./reducer/allUsersReducer";
import { userSaga } from "./saga/userSaga";

const userModule = {
  actions: {
    getUsers,
    createUser,
    deleteUser,
    updateUser,
  },
  reducers: {
    allUsersReducer: allUsersReducer,
  },
  sagas: {
    userSaga: userSaga,
  },
};

export default userModule;
