import * as actions from "./actionTypes";

export const getFeedbacks = (data) => ({
  type: actions.GET_HRM_MENTORSHIP_FEEDBACK_REQUEST,
  payload: data,
});

export const createFeedback = (data) => ({
  type: actions.CREATE_HRM_MENTORSHIP_FEEDBACK_REQUEST,
  payload: data,
});

export const getAllUsersFeedbacks = (data) => ({
  type: actions.GET_HRM_MENTORSHIP_USERS_FEEDBACK_REQUEST,
  payload: data,
});
