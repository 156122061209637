import {
  getAgents,
  createAgent,
  updateAgent,
  agentLogin,
  getVacancies,
  createCandidate,
  deleteAgreementFile,
} from "./actionCreators";
import { agentSaga } from "./saga/agentSaga";
import allAgentsReducer from "./reducer/allAgentsReducer";
import agentLoginReducer from "./reducer/agentLoginReducer";

const agentModule = {
  actions: {
    getAgents,
    createAgent,
    updateAgent,
    agentLogin,
    getVacancies,
    createCandidate,
    deleteAgreementFile,
  },
  reducers: {
    allAgentsReducer: allAgentsReducer,
    agentLoginReducer: agentLoginReducer,
  },
  sagas: {
    agentSaga: agentSaga,
  },
};

export default agentModule;
