import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import inventoryModule from "../../../../redux/inventory";
import { Container, Row, Col, ButtonGroup } from "reactstrap";
import moment from "moment";
import { FaPlus } from "react-icons/fa";
import CustomTable from "../../../components/table";
import Loader from "../../../components/ui-components/Loader";
import Button from "../../../components/ui-components/Button";
import bemClass from "../../../components/ui-components/Utils/bemClass";
import AddItemCategoryModal from "../../../components/modals/inventory/addItemCategoryModal";
import "./categoryList.scss";

class InventoryCategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddCategoryModal: false,
      selectedCategory: {},
      editMode: false,
    };
  }

  componentDidMount() {
    this.props.getCategories();
  }

  getTableHeading = () => {
    return (
      <tr>
        <th>Name</th>
        <th>Added on</th>
        <th></th>
      </tr>
    );
  };

  getTableContent = (data) => {
    return (data || []).map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>{row.name}</td>
          <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
          <td className="Inventory__category-list-actions">
            <ButtonGroup size="sm">
              <Button
                onClick={() =>
                  this.setState({
                    editMode: true,
                    showAddCategoryModal: true,
                    selectedCategory: row,
                  })
                }
                size="sm"
              >
                Edit
              </Button>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  closeAddCategoryModal = () => {
    this.setState({
      showAddCategoryModal: false,
      editMode: false,
      selectedCategory: {},
    });
  };

  render() {
    const classes = new bemClass("InventoryCategoryList");
    return (
      <Container className={classes.elem("wrapper").b()}>
        {this.state.showAddCategoryModal ? (
          <AddItemCategoryModal
            openModal={this.state.showAddCategoryModal}
            onClose={this.closeAddCategoryModal}
            title={
              this.state.editMode ? "Edit Item Category" : "Add Item Category"
            }
            isEditMode={this.state.editMode}
            objectToEdit={this.state.selectedCategory}
          />
        ) : null}
        <Row>
          <Col sm={7} className="text-left">
            <h2 className="list-header-title">Item Categories</h2>
          </Col>
          <Col sm={5} className="text-right">
            <Button
              color="deep-blue"
              onClick={() => {
                this.setState({ showAddCategoryModal: true, editMode: false });
              }}
            >
              <FaPlus /> Create Category
            </Button>
          </Col>

          <Col sm={12}>
            <div className="hr"></div>
            {this.props.categories.loading ? (
              <div className={classes.elem("loader").b()}>
                <Loader />
              </div>
            ) : this.props.categories.data ? (
              <React.Fragment>
                <CustomTable
                  content={this.getTableContent(this.props.categories.data)}
                  tableHeadings={this.getTableHeading()}
                  customHeaders
                />
              </React.Fragment>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCategories: inventoryModule.categoryModule.actions.getCategories,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    categories: state.inventoryMainCategories,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(InventoryCategoryList);
