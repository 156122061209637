import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import { useForm } from "react-hook-form";
import Button from "../../../ui-components/Button";
import FormFeedBack from "../../../ui-components/FormFeedBack";
import Input from "../../../inputNew";
import { useSelector, useDispatch } from "react-redux";
import inventoryModule from "../../../../../redux/inventory";

const classes = new BEMhelper("AddItemTypeModal");
const AddItemTypeModal = (props) => {
  const dispatch = useDispatch();
  const { openModal, onClose, title, isEditMode, objectToEdit } = props;
  const defaultValues = {
    name: isEditMode ? objectToEdit.name : "",
  };

  const { handleSubmit, register, errors } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    const details = {
      formValues: {
        name: values.name,
        ...(isEditMode && { typeId: objectToEdit.inventoryItemTypeId }),
      },
      closeModal: onClose,
    };
    if (isEditMode) {
      dispatch(inventoryModule.typeModule.actions.updateType(details));
    } else {
      dispatch(inventoryModule.typeModule.actions.createType(details));
    }
  };

  const requestDetails = useSelector((state) =>
    isEditMode ? state.inventoryUpdateItem : state.inventoryCreateItem
  );

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => onClose()}
      centered={true}
      draggable={false}
      backdrop="static"
      size="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => onClose()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    name="name"
                    register={register}
                    refdetails={{
                      required: "Type name is required",
                    }}
                    state={errors.name ? "error" : "none"}
                  />
                  {errors.name ? (
                    <FormFeedBack feedBack={errors.name.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button submit disabled={requestDetails.loading}>
            Submit
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddItemTypeModal;
