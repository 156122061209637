import {
  getFeedbacks,
  createFeedback,
  getAllUsersFeedbacks,
} from "./mentorship";
import {
  getCandidates,
  createCandidate,
  uploadCandidateResume,
  getCandidateQuestionCategories,
  getCandidate,
  createCandidateFeedback,
  getCandidateFeedbacks,
  updateCandidate,
  deleteCandidate,
  createDirectCandidate,
  getInterviewInvitations,
  createInterviewInvitation,
} from "./candidate";
import {
  getAgents,
  createAgent,
  updateAgent,
  agentLogin,
  getAgentVacancies,
  createAgentCandidate,
  deleteAgentAgreementFile,
} from "./agent";
import {
  getVacancies,
  createVacancy,
  updateVacancy,
  getVacancy,
} from "./vacancy";

import { getDashboardData } from "./dashBoard";

import { updateStage, createStage, getStages } from "./interviewStage";

import performance from "./performance";

const hrmApis = {
  getFeedbacks,
  createFeedback,
  getAllUsersFeedbacks,
  getCandidates,
  createCandidate,
  uploadCandidateResume,
  getCandidateQuestionCategories,
  getCandidate,
  createCandidateFeedback,
  getCandidateFeedbacks,
  updateCandidate,
  deleteCandidate,
  getAgents,
  createAgent,
  updateAgent,
  agentLogin,
  createVacancy,
  getVacancies,
  updateVacancy,
  getVacancy,
  getAgentVacancies,
  createAgentCandidate,
  createDirectCandidate,
  updateStage,
  createStage,
  getStages,
  getInterviewInvitations,
  createInterviewInvitation,
  deleteAgentAgreementFile,
  getDashboardData,
  performance,
};

export default hrmApis;
