export const CREATE_INVENTORY_ITEMS_REQUEST = "CREATE_INVENTORY_ITEMS_REQUEST";
export const CREATE_INVENTORY_ITEMS_SUCCESS = "CREATE_INVENTORY_ITEMS_SUCCESS";
export const CREATE_INVENTORY_ITEMS_FAILURE = "CREATE_INVENTORY_ITEMS_FAILURE";

export const GET_INVENTORY_ITEMS_REQUEST = "GET_INVENTORY_ITEMS_REQUEST";
export const GET_INVENTORY_ITEMS_SUCCESS = "GET_INVENTORY_ITEMS_SUCCESS";
export const GET_INVENTORY_ITEMS_FAILURE = "GET_INVENTORY_ITEMS_FAILURE";

export const UPDATE_INVENTORY_ITEM_REQUEST = "UPDATE_INVENTORY_ITEM_REQUEST";
export const UPDATE_INVENTORY_ITEM_SUCCESS = "UPDATE_INVENTORY_ITEM_SUCCESS";
export const UPDATE_INVENTORY_ITEM_FAILURE = "UPDATE_INVENTORY_ITEM_FAILURE";

export const DELETE_INVENTORY_ITEM_REQUEST = "DELETE_INVENTORY_ITEM_REQUEST";
export const DELETE_INVENTORY_ITEM_SUCCESS = "DELETE_INVENTORY_ITEM_SUCCESS";
export const DELETE_INVENTORY_ITEM_FAILURE = "DELETE_INVENTORY_ITEM_FAILURE";

export const DELETE_INVENTORY_ITEM_FILE_REQUEST =
  "DELETE_INVENTORY_ITEM_FILE_REQUEST";
export const DELETE_INVENTORY_ITEM_FILE_SUCCESS =
  "DELETE_INVENTORY_ITEM_FILE_SUCCESS";
export const DELETE_INVENTORY_ITEM_FILE_FAILURE =
  "DELETE_INVENTORY_ITEM_FILE_FAILURE";

export const CREATE_INVENTORY_ITEMS_BULK_REQUEST =
  "CREATE_INVENTORY_ITEMS_BULK_REQUEST";
export const CREATE_INVENTORY_ITEMS_BULK_SUCCESS =
  "CREATE_INVENTORY_ITEMS_BULK_SUCCESS";
export const CREATE_INVENTORY_ITEMS_BULK_FAILURE =
  "CREATE_INVENTORY_ITEMS_BULK_FAILURE";

export const SWOP_INVENTORY_USER_ITEM_REQUEST =
  "SWOP_INVENTORY_USER_ITEM_REQUEST";
export const SWOP_INVENTORY_USER_ITEM_SUCCESS =
  "SWOP_INVENTORY_USER_ITEM_SUCCESS";
export const SWOP_INVENTORY_USER_ITEM_FAILURE =
  "SWOP_INVENTORY_USER_ITEM_FAILURE";
