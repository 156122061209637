import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import { useForm } from "react-hook-form";
import Button from "../../../../../ui-components/Button";
import FormFeedBack from "../../../../../ui-components/FormFeedBack";
import Input from "../../../../../inputNew";

const classes = new BEMhelper("AddInterviewStageModal");
const AddInterviewStageModal = (props) => {
  const { title, openModal, toggle, isEditMode, objectToEdit, submit } = props;
  const defaultValues = {
    name: isEditMode ? objectToEdit.name : null,
  };

  const { handleSubmit, register, errors } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    submit(values);
  };

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => toggle()}
      centered={true}
      draggable={false}
      size="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => toggle()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Stage Name</Label>
                  <Input
                    name="name"
                    register={register}
                    refdetails={{
                      required: "Stage name required",
                    }}
                    state={errors.name ? "error" : "none"}
                  />
                  {errors.name ? (
                    <FormFeedBack feedBack={errors.name.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button submit>Submit</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddInterviewStageModal;
