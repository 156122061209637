import React from "react";
import PropTypes from "prop-types";
import "./text.scss";
// import '../Utils/Skeleton.scss';
// import colorMappings from '../Utils/colorMappings';
// import BemClass from '../Utils/bemClass';
import BemClass from "react-bem-helper";

const sizes = ["sm", "md", "lg", "xl"];
// const colors = [...colorMappings.keys, 'inherit', 'light-grey', 'grey', 'dark-grey'];

const propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(sizes).isRequired,
  // color: PropTypes.oneOf(colors),
  italic: PropTypes.bool.isRequired,
  bold: PropTypes.bool.isRequired,
  center: PropTypes.bool.isRequired,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loading: PropTypes.bool,
  pointer: PropTypes.bool,
};

const defaultProps = {
  size: "md",
  color: "black",
  className: "",
  italic: false,
  bold: false,
  center: false,
  loading: false,
  pointer: false,
};

const Text = ({
  className,
  size,
  color,
  italic,
  bold,
  center,
  children,
  loading,
  pointer,
  ...props
}) => {
  const classes = new BemClass("Text");

  const modifiers = [size, color];

  if (italic) {
    modifiers.push("italic");
  }

  if (bold) {
    modifiers.push("bold");
  }

  if (center) {
    modifiers.push("center");
  }

  if (loading) {
    modifiers.push("loading");
  }

  if (pointer) {
    modifiers.push("pointer");
  }

  var options = {
    element: className,
    modifiers: modifiers,
    extra: "",
  };

  return (
    <div {...classes(options)} {...props}>
      {children}
    </div>
  );
};

Text.sizes = sizes;
// Text.colors = colors;
Text.propTypes = propTypes;
Text.defaultProps = defaultProps;
export default Text;
