import {
  UPDATE_INVENTORY_ITEM_REQUEST,
  UPDATE_INVENTORY_ITEM_SUCCESS,
  UPDATE_INVENTORY_ITEM_FAILURE,
  SWOP_INVENTORY_USER_ITEM_REQUEST,
  SWOP_INVENTORY_USER_ITEM_SUCCESS,
  SWOP_INVENTORY_USER_ITEM_FAILURE,
} from "../../item/actionTypes";

import {
  UPDATE_INVENTORY_CATEGORY_REQUEST,
  UPDATE_INVENTORY_CATEGORY_SUCCESS,
  UPDATE_INVENTORY_CATEGORY_FAILURE,
} from "../../category/actionTypes";

import {
  UPDATE_INVENTORY_TYPE_REQUEST,
  UPDATE_INVENTORY_TYPE_SUCCESS,
  UPDATE_INVENTORY_TYPE_FAILURE,
} from "../../type/actionTypes";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const updateItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INVENTORY_ITEM_REQUEST:
    case SWOP_INVENTORY_USER_ITEM_REQUEST:
    case UPDATE_INVENTORY_CATEGORY_REQUEST:
    case UPDATE_INVENTORY_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case UPDATE_INVENTORY_ITEM_SUCCESS:
    case SWOP_INVENTORY_USER_ITEM_SUCCESS:
    case UPDATE_INVENTORY_CATEGORY_SUCCESS:
    case UPDATE_INVENTORY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case UPDATE_INVENTORY_ITEM_FAILURE:
    case SWOP_INVENTORY_USER_ITEM_FAILURE:
    case UPDATE_INVENTORY_CATEGORY_FAILURE:
    case UPDATE_INVENTORY_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default updateItemReducer;
