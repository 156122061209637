import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../ui-components/Button";
import Input from "../../../inputNew";
import FormFeedBack from "../../../ui-components/FormFeedBack";
import Textarea from "../../../ui-components/Textarea";
import DatePicker from "react-datepicker";
import "./addWorkplaceModal.scss";
import moment from "moment";
import { toast } from "react-toastify";

const classes = new BEMhelper("AddWorkplaceModal");
const AddWorkplaceModal = (props) => {
  const { submit, openModal, toggle, title } = props;

  const defaultValues = {};

  const {
    handleSubmit,
    register,
    errors,
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    if (moment(values.from).isSameOrAfter(moment(values.to))) {
      toast.error("Start date cannot be greater than end date", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      submit(values);
    }
  };

  const stepOneWatcher = watch(["from", "to"]);

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => toggle()}
      centered={true}
      draggable={false}
      size="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => toggle()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Company name</Label>
                  <Input
                    name="companyname"
                    register={register}
                    refdetails={{
                      required: "Name required",
                    }}
                    state={errors.companyname ? "error" : "none"}
                  />
                  {errors.companyname ? (
                    <FormFeedBack feedBack={errors.companyname.message} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Designation</Label>
                  <Input
                    name="designation"
                    register={register}
                    refdetails={{
                      required: "Designation required",
                    }}
                    state={errors.designation ? "error" : "none"}
                  />
                  {errors.designation ? (
                    <FormFeedBack feedBack={errors.designation.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>From</Label>
                  <Controller
                    as={
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        placeholderText={"Start date"}
                        className={`form-control ${
                          errors.from ? "date-error" : ""
                        }`}
                        selected={getValues().from}
                      />
                    }
                    valueName="selected"
                    name="from"
                    control={control}
                    onChange={(date) => setValue("from", date)}
                    rules={{
                      required: "Start date is required",
                    }}
                  />
                  {errors.from ? (
                    <FormFeedBack feedBack={errors.from.message} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>To</Label>
                  <Controller
                    as={
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        placeholderText={"End date"}
                        className={`form-control ${
                          errors.to ? "date-error" : ""
                        }`}
                        selected={getValues().to}
                      />
                    }
                    valueName="selected"
                    name="to"
                    control={control}
                    onChange={(date) => setValue("to", date)}
                    rules={{
                      required: "Start date is required",
                    }}
                  />
                  {errors.to ? (
                    <FormFeedBack feedBack={errors.to.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Description</Label>
                  <Textarea
                    name="notes"
                    register={register}
                    state={errors.notes ? "error" : "none"}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button submit>Submit</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddWorkplaceModal;
