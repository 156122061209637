import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_INVENTORY_ITEMS_REQUEST,
  GET_INVENTORY_ITEMS_SUCCESS,
  GET_INVENTORY_ITEMS_FAILURE,
  CREATE_INVENTORY_ITEMS_REQUEST,
  CREATE_INVENTORY_ITEMS_SUCCESS,
  CREATE_INVENTORY_ITEMS_FAILURE,
  UPDATE_INVENTORY_ITEM_REQUEST,
  UPDATE_INVENTORY_ITEM_SUCCESS,
  UPDATE_INVENTORY_ITEM_FAILURE,
  DELETE_INVENTORY_ITEM_REQUEST,
  DELETE_INVENTORY_ITEM_SUCCESS,
  DELETE_INVENTORY_ITEM_FAILURE,
  DELETE_INVENTORY_ITEM_FILE_REQUEST,
  DELETE_INVENTORY_ITEM_FILE_SUCCESS,
  DELETE_INVENTORY_ITEM_FILE_FAILURE,
  CREATE_INVENTORY_ITEMS_BULK_REQUEST,
  CREATE_INVENTORY_ITEMS_BULK_SUCCESS,
  CREATE_INVENTORY_ITEMS_BULK_FAILURE,
  SWOP_INVENTORY_USER_ITEM_REQUEST,
  SWOP_INVENTORY_USER_ITEM_SUCCESS,
  SWOP_INVENTORY_USER_ITEM_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";

export function* itemSaga() {
  yield takeLatest(GET_INVENTORY_ITEMS_REQUEST, getItems);
  yield takeLatest(CREATE_INVENTORY_ITEMS_REQUEST, createItem);
  yield takeLatest(UPDATE_INVENTORY_ITEM_REQUEST, updateItem);
  yield takeLatest(DELETE_INVENTORY_ITEM_REQUEST, deleteItem);
  yield takeLatest(DELETE_INVENTORY_ITEM_FILE_REQUEST, deleteItemFile);
  yield takeLatest(CREATE_INVENTORY_ITEMS_BULK_REQUEST, createItemsBulk);
  yield takeLatest(SWOP_INVENTORY_USER_ITEM_REQUEST, swopUserItems);
}

function* swopUserItems(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const swopItem = yield call(
      apis.inventoryApis.swopUserItems,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (swopItem.error) {
      yield put({
        type: SWOP_INVENTORY_USER_ITEM_FAILURE,
        error: swopItem.body.data.error,
      });
    } else {
      yield put({
        type: SWOP_INVENTORY_USER_ITEM_SUCCESS,
        data: swopItem.body.data,
      });
      toast.success("Item swopped successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      const getPayload = {
        offset: 0,
        userId: details.payload.formValues.userId,
      };
      yield put({
        type: GET_INVENTORY_ITEMS_REQUEST,
        payload: getPayload,
      });
      details.payload.closeModal();
      if (details.payload.formValues.generateDocument) {
        let bytes = new Uint8Array(swopItem.body.data.data.data);
        const blob = new Blob([bytes], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        const nowTime = moment().format("HH:mm");
        const nowDate = moment().format("YYYY-MM-DD");
        link.setAttribute(
          "download",
          `custom-report-${nowDate}-${nowTime}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      }
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createItemsBulk(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const items = yield call(
      apis.inventoryApis.createItemsBulk,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (items.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: CREATE_INVENTORY_ITEMS_BULK_FAILURE,
        error: items.body.data.error,
      });
      if (_.isString(items.body.data.error)) {
        errorMessage = items.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({
        type: CREATE_INVENTORY_ITEMS_BULK_SUCCESS,
        data: items.body.data,
      });
      const data = {
        offset: 0,
      };
      yield put({ type: GET_INVENTORY_ITEMS_REQUEST, payload: data });
      toast.success("We are proceeding your data. Will be updated soon.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* deleteItemFile(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const itemFile = yield call(
      apis.inventoryApis.deleteItemFile,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (itemFile.error) {
      yield put({
        type: DELETE_INVENTORY_ITEM_FILE_FAILURE,
        error: itemFile.body.data.error,
      });
    } else {
      yield put({
        type: DELETE_INVENTORY_ITEM_FILE_SUCCESS,
        data: itemFile.body.data,
      });
      toast.success("File removed successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getItems(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const items = yield call(
      apis.inventoryApis.getItems,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (items.error) {
      yield put({
        type: GET_INVENTORY_ITEMS_FAILURE,
        error: items.body.data.error,
      });
    } else {
      yield put({ type: GET_INVENTORY_ITEMS_SUCCESS, data: items.body.data });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createItem(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const item = yield call(
      apis.inventoryApis.createItem,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (item.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: CREATE_INVENTORY_ITEMS_FAILURE,
        error: item.body.data.error,
      });
      if (_.isString(item.body.data.error)) {
        errorMessage = item.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({ type: CREATE_INVENTORY_ITEMS_SUCCESS, data: item.body.data });
      const data = {
        offset: 0,
      };
      yield put({ type: GET_INVENTORY_ITEMS_REQUEST, payload: data });
      toast.success("Item created successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* updateItem(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const item = yield call(
      apis.inventoryApis.updateItem,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (item.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: UPDATE_INVENTORY_ITEM_FAILURE,
        error: item.body.data.error,
      });
      if (_.isString(item.body.data.error)) {
        errorMessage = item.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({ type: UPDATE_INVENTORY_ITEM_SUCCESS, data: item.body.data });
      const data = {
        offset: 0,
      };
      yield put({ type: GET_INVENTORY_ITEMS_REQUEST, payload: data });
      toast.success("Item updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* deleteItem(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const item = yield call(
      apis.inventoryApis.deleteItem,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues.itemId
    );
    if (item.error) {
      yield put({
        type: DELETE_INVENTORY_ITEM_FAILURE,
        error: item.body.data.error,
      });
    } else {
      yield put({ type: DELETE_INVENTORY_ITEM_SUCCESS, data: item.body.data });
      const data = {
        offset: 0,
      };
      yield put({ type: GET_INVENTORY_ITEMS_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
