import categoryModule from "./category";
import itemModule from "./item";
import commonModule from "./common";
import reportModule from "./report";
import dashboardModule from "./dashboard";
import typeModule from "./type";

const inventoryModule = {
  categoryModule,
  itemModule,
  commonModule,
  reportModule,
  dashboardModule,
  typeModule,
};

export default inventoryModule;
