import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  ADMIN_UPDATE_USER_REQUEST,
  ADMIN_UPDATE_USER_SUCCESS,
  ADMIN_UPDATE_USER_FAILURE,
  ADMIN_CREATE_DESIGNATION_REQUEST,
  ADMIN_CREATE_DESIGNATION_SUCCESS,
  ADMIN_CREATE_DESIGNATION_FAILURE,
} from "../actionTypes";
import { GET_USER_DESIGNATION_REQUEST } from "../../../user/actionTypes";
import apis from "../../../../api";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import _ from "lodash";

export function* userSaga() {
  yield takeLatest(SIGNUP_REQUEST, createUser);
  yield takeLatest(ADMIN_UPDATE_USER_REQUEST, updateUser);
  yield takeLatest(ADMIN_CREATE_DESIGNATION_REQUEST, createDesignation);
}

function* createDesignation(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const designation = yield call(
      apis.adminApis.createDesignation,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (designation.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: ADMIN_CREATE_DESIGNATION_FAILURE,
        error: designation.body.data.error,
      });
      if (Array.isArray(designation.body.data.error)) {
        errorMessage = designation.body.data.error[0].msg;
      }
      if (_.isString(designation.body.data.error)) {
        errorMessage = designation.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({
        type: ADMIN_CREATE_DESIGNATION_SUCCESS,
        data: designation.body.data,
      });
      yield put({
        type: GET_USER_DESIGNATION_REQUEST,
      });
      toast.success("Designation created successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (details.payload.closeModal) {
        details.payload.closeModal();
      }
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createUser(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const user = yield call(
      apis.adminApis.createUser,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (user.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: SIGNUP_FAILURE,
        error: user.body.data.error,
      });
      if (Array.isArray(user.body.data.error)) {
        errorMessage = user.body.data.error[0].msg;
      }
      if (_.isString(user.body.data.error)) {
        errorMessage = user.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({ type: SIGNUP_SUCCESS, data: user.body.data });
      toast.success("User created successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put(push("/administration/listusers"));
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* updateUser(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const user = yield call(
      apis.adminApis.updateUser,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (user.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: ADMIN_UPDATE_USER_FAILURE,
        error: user.body.data.error,
      });
      if (Array.isArray(user.body.data.error)) {
        errorMessage = user.body.data.error[0].msg;
      }
      if (_.isString(user.body.data.error)) {
        errorMessage = user.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({ type: ADMIN_UPDATE_USER_SUCCESS, data: user.body.data });
      toast.success("User updated successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put(push("/administration/listusers"));
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
