import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import BEMhelper from "react-bem-helper";
import Button from "../../ui-components/Button";
const classes = new BEMhelper("infoModall");
const InfoModal = (props) => {
  const { title, bodyLoading, body, openModal, toggle, size } = props;

  return (
    <div>
      <Modal
        {...classes("infoModal")}
        isOpen={openModal}
        toggle={() => toggle()}
        centered={true}
        draggable={false}
        size={size ? size : "lg"}
      >
        {title ? (
          <ModalHeader toggle={() => toggle()}>{title}</ModalHeader>
        ) : null}
        <ModalBody>{bodyLoading ? <p>Loading...</p> : body}</ModalBody>
        <ModalFooter>
          <Button onClick={() => toggle()}>Ok</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default InfoModal;
