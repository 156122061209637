import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  CLAIM_TYPE_REQUEST,
  CLAIM_TYPE_SUCCESS,
  CLAIM_TYPE_FAILURE,
  APPLY_CLAIM_REQUEST,
  APPLY_CLAIM_SUCCESS,
  APPLY_CLAIM_FAILURE,
  CLAIM_HISTORY_REQUEST,
  CLAIM_HISTORY_SUCCESS,
  CLAIM_HISTORY_FAILURE,
} from "../actionTypes";
import apis from "../../../api";
import { toast } from "react-toastify";

export function* claimSaga() {
  yield takeLatest(CLAIM_TYPE_REQUEST, claimType);
  yield takeLatest(APPLY_CLAIM_REQUEST, applyClaim);
  yield takeLatest(CLAIM_HISTORY_REQUEST, claimHistory);
}

function* claimType() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const claimType = yield call(
      apis.claimType,
      loggedUser.userDetails.auth.accessToken
    );
    if (claimType.error) {
      yield put({ type: CLAIM_TYPE_FAILURE, error: claimType.body.data.error });
    } else {
      yield put({ type: CLAIM_TYPE_SUCCESS, data: claimType.body.data });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* applyClaim(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const applyClaim = yield call(
      apis.applyClaim,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValue
    );
    if (applyClaim.error) {
      yield put({
        type: APPLY_CLAIM_FAILURE,
        error: applyClaim.body.data.error,
      });
      toast.error("Something bad happened!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put({ type: APPLY_CLAIM_SUCCESS, data: applyClaim.body.data });
      toast.success("Claim applied successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal(true);
    }
  } catch (error) {
    console.log("oops error -> ", error);
    toast.error("Something bad happened!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* claimHistory(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const claimHistory = yield call(
      apis.claimHistory,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (claimHistory.error) {
      yield put({
        type: CLAIM_HISTORY_FAILURE,
        error: claimHistory.body.data.error,
      });
    } else {
      yield put({ type: CLAIM_HISTORY_SUCCESS, data: claimHistory.body.data });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
