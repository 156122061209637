import * as actions from "./actionTypes";

export const getAgents = () => ({
  type: actions.GET_HRM_AGENTS_REQUEST,
});

export const createAgent = (data) => ({
  type: actions.CREATE_HRM_AGENTS_REQUEST,
  payload: data,
});

export const updateAgent = (data) => ({
  type: actions.UPDATE_HRM_AGENTS_REQUEST,
  payload: data,
});

export const agentLogin = (data) => ({
  type: actions.AGENT_LOGIN_REQUEST,
  payload: data,
});

export const getVacancies = () => ({
  type: actions.GET_VACANCIES_AGENT_REQUEST,
});

export const createCandidate = (data) => ({
  type: actions.CREATE_HRM_CANDIDATE_AGENT_REQUEST,
  payload: data,
});

export const deleteAgreementFile = (data) => ({
  type: actions.AGENT_DELETE_AGREEMENT_FILE_REQUEST,
  payload: data,
});
