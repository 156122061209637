import {
  applyLeave,
  leaveBalance,
  leaveDateType,
  leaveHistory,
  leaveList,
  processLeave,
} from "./leave";
import {
  getUserList,
  getUserById,
  login,
  signup,
  getDesignation,
  getRoles,
  getDepartmentList,
  forgotPassword,
  resetPassword,
} from "./user";
import {
  featureAccessRole,
  addFeatureAccess,
  featureAccessUsers,
} from "./admin";
import { getSupervisorLevels } from "./supervisor";
import { claimType, applyClaim, claimHistory } from "./claim";
import timeTrackApis from "./timetracking";
import inventoryApis from "./inventory";
import adminApis from "./admins";
import hrmApis from "./hrm";
import { resetMyPassword } from "./settings";

const apis = {
  getUserList,
  getUserById,
  login,
  signup,
  getDesignation,
  getRoles,
  getDepartmentList,
  getSupervisorLevels,
  applyLeave,
  leaveBalance,
  leaveDateType,
  leaveHistory,
  claimType,
  applyClaim,
  claimHistory,
  featureAccessRole,
  addFeatureAccess,
  featureAccessUsers,
  leaveList,
  processLeave,
  forgotPassword,
  resetPassword,
  resetMyPassword,
  timeTrackApis,
  inventoryApis,
  adminApis,
  hrmApis,
};

export default apis;
