import {
  createEntry,
  getEntrieForDate,
  updateEntry,
  deleteEntry,
} from "./actionCreators";
import { entrySaga } from "./saga/entrySaga";
import allEntriesForDayReducer from "./reducer/allEntriesForDayReducer";

const entryModule = {
  actions: {
    createEntry,
    getEntrieForDate,
    updateEntry,
    deleteEntry,
  },
  reducers: {
    allEntriesForDayReducer: allEntriesForDayReducer,
  },
  sagas: {
    entrySaga: entrySaga,
  },
};

export default entryModule;
