import createItemReducer from "./reducer/createItemReducer";
import updateItemReducer from "./reducer/updateItemReducer";
import deleteItemReducer from "./reducer/deleteItemReducer";

const commonModule = {
  reducers: {
    createItemReducer: createItemReducer,
    updateItemReducer: updateItemReducer,
    deleteItemReducer: deleteItemReducer,
  },
};

export default commonModule;
