import React, { useState } from "react";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../../../components/ui-components/Button";
import FormFeedBack from "../../../../../components/ui-components/FormFeedBack";
import Input from "../../../../../components/inputNew";
import bemClass from "../../../../../components/ui-components/Utils/bemClass";
import Select from "react-select";
import { FaTimes } from "react-icons/fa";
import "./addQuestions.scss";

const HrmSurveyAddQuestions = (props) => {
  const classes = new bemClass("HrmSurveyAddQuestions");
  const { submit, stepOneData, changeTab, stepTwoData } = props;
  const [questions, setQuestions] = useState(stepTwoData ? stepTwoData : []);
  const [newChoice, setChoice] = useState(null);
  const [choices, setChoices] = useState([]);

  const defaultValues = { isRequired: false };

  const {
    handleSubmit,
    register,
    errors,
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues,
  });

  const submitStage = () => {
    submit(questions);
  };

  const onSubmitQuestion = (values) => {
    const allQuestions = questions;
    allQuestions.push({
      type: values.questionType.value,
      title: values.title,
      choices: choices,
      required: values.isRequired,
    });
    setQuestions([...allQuestions]);
    setChoices([...[]]);
    setValue("isRequired", false);
    reset({
      questionType: null,
      title: null,
      choice: null,
      isRequired: false,
    });
  };

  const questionTypes = [
    {
      value: "multipleChoice",
      label: "Multiple Choice",
    },
    {
      value: "directAnswer",
      label: "Direct Answer",
    },
  ];

  const addChoice = () => {
    if (newChoice) {
      const allChoice = choices;
      allChoice.push(newChoice);
      setChoices([...allChoice]);
      setValue("choice", null);
      setChoice(null);
    }
  };

  const questionWatcher = watch(["questionType", "isRequired"]);
  const isMultipleChoice =
    questionWatcher &&
    questionWatcher.questionType &&
    questionWatcher.questionType.value === "multipleChoice";

  return (
    <Container fluid className={classes.elem("wrapper").b()}>
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <FormGroup className={classes.elem("header").b()}>
            <div>
              <h3>{stepOneData.surveyName}</h3>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} className={classes.elem("question-card").b()}>
          <form onSubmit={handleSubmit(onSubmitQuestion)}>
            <FormGroup>
              <Label>Type</Label>
              <Controller
                as={
                  <Select
                    placeholder="Select type"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: `1px solid ${
                          errors.questionType ? "#dc3545" : "#cad2dd"
                        }`,
                      }),
                    }}
                  />
                }
                options={questionTypes}
                name="questionType"
                isClearable
                control={control}
                onChange={([selected]) => {
                  return { value: selected };
                }}
                rules={{
                  required: "Type is required",
                }}
              />
              {errors.questionType ? (
                <FormFeedBack feedBack={errors.questionType.message} />
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label>Required</Label>
              <Input
                name="isRequired"
                type="checkbox"
                register={register}
                className="question-required-box"
              />
            </FormGroup>
            <FormGroup>
              <Label>Question</Label>
              <Input
                name="title"
                register={register}
                refdetails={{
                  required: "Title is required",
                }}
                state={errors.title ? "error" : "none"}
              />
              {errors.title ? (
                <FormFeedBack feedBack={errors.title.message} />
              ) : null}
            </FormGroup>
            {isMultipleChoice ? (
              <FormGroup>
                <Label>Choice</Label>
                <Row>
                  <Col md={10}>
                    <Input
                      name="choice"
                      register={register}
                      onChange={(e) => setChoice(e.target.value)}
                    />
                  </Col>
                  <Col md={2}>
                    <Button size="sm" onClick={() => addChoice()}>
                      Add
                    </Button>
                  </Col>
                </Row>
                <ol>
                  {choices.map((choice, index) => {
                    return (
                      <li>
                        {choice}{" "}
                        <FaTimes
                          onClick={() => {
                            const newChoices = choices
                              .slice(0, index)
                              .concat(choices.slice(index + 1, choices.length));
                            setChoices(newChoices);
                          }}
                        />
                      </li>
                    );
                  })}
                </ol>
              </FormGroup>
            ) : null}
            <FormGroup>
              <Button
                size="sm"
                submit
                disabled={isMultipleChoice && !choices.length}
              >
                Add Question
              </Button>
            </FormGroup>
          </form>
        </Col>
        <Col md={6}>
          <ol>
            {questions.map((question, index) => {
              if (question.type === "multipleChoice") {
                return (
                  <React.Fragment>
                    <li className={question.required ? "required-label" : ""}>
                      {question.title}{" "}
                      <FaTimes
                        onClick={() => {
                          const newQuestions = questions
                            .slice(0, index)
                            .concat(
                              questions.slice(index + 1, questions.length)
                            );
                          setQuestions(newQuestions);
                        }}
                      />
                    </li>
                    <ul>
                      {question.choices.map((choice) => {
                        return <li>{choice}</li>;
                      })}
                    </ul>
                    <br />
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment>
                  <li className={question.required ? "required-label" : ""}>
                    {question.title}{" "}
                    <FaTimes
                      onClick={() => {
                        const newQuestions = questions
                          .slice(0, index)
                          .concat(questions.slice(index + 1, questions.length));
                        setQuestions(newQuestions);
                      }}
                    />
                  </li>
                  <br />
                </React.Fragment>
              );
            })}
          </ol>
        </Col>
      </Row>
      <div className="hr"></div>
      <Row>
        <Col md={12}>
          <FormGroup className={classes.elem("action-btns").b()}>
            <Button
              color="light-grey"
              onClick={() => changeTab(0)}
              shadow={false}
            >
              Prev
            </Button>{" "}
            <Button
              size="sm"
              onClick={() => submitStage()}
              disabled={!questions.length}
            >
              Next
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default HrmSurveyAddQuestions;
