import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

export const claimType = async (token) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/claim/claimType`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const applyClaim = async (token, claimDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/claim/apply`,
    headers: {
      "x-access-token": token,
    },
    data: claimDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const claimHistory = async (token, userId) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/claim/history/${userId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
