import axios from 'axios';
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1`

export const getSupervisorLevels = async () => {
  return axios({
    method: 'GET',
    url: `${apiUrl}/supervisor/levels`
  }).then(function(response){
    return {error:false, body:response.data}
  }).catch(function(error){
    return {error:true, body:error.response}
  });
};