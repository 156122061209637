import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
import BEMhelper from "react-bem-helper";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import Button from "../../../ui-components/Button";
import FormFeedBack from "../../../ui-components/FormFeedBack";
import Textarea from "../../../ui-components/Textarea";
import Input from "../../../inputNew";

const classes = new BEMhelper("NewRecordModal");
const NewRecordModal = (props) => {
  const {
    bodyLoading,
    openModal,
    toggle,
    projects,
    submit,
    isEditMode,
    objectToEdit,
    tasks,
  } = props;
  let selectedProject = null;
  let selectedTask = null;

  const projectOptions = projects.map((project) => {
    return {
      value: project.timeTrackProjectId,
      label: project.name,
    };
  });

  const taskOptions = tasks.map((task) => {
    return {
      value: task.timeTrackTaskId,
      label: `${task.name} (${task.timeTrackCategory.name})`,
    };
  });

  if (isEditMode) {
    _.forEach(projectOptions, function (project) {
      if (project.value === objectToEdit.timeTrackProjectId) {
        selectedProject = project;
      }
    });

    _.forEach(taskOptions, function (task) {
      if (task.value === objectToEdit.timeTrackTaskId) {
        selectedTask = task;
      }
    });
  }

  const defaultValues = {
    project: selectedProject,
    task: selectedTask,
    notes: isEditMode ? objectToEdit.description : null,
    hours: isEditMode ? objectToEdit.hours : null,
    minutes: isEditMode ? objectToEdit.minutes : null,
  };

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => submit(values);

  const title = isEditMode ? "Edit time entry" : "New time entry";

  return (
    <div>
      <Modal
        {...classes("modal")}
        isOpen={openModal}
        toggle={() => toggle()}
        centered={true}
        draggable={false}
        size="md"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={() => toggle()} className="modal-header">
            {title}
          </ModalHeader>
          <ModalBody>
            {bodyLoading ? (
              <p>Loading...</p>
            ) : (
              <Container fluid>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label>Project</Label>
                      <Controller
                        as={
                          <Select
                            placeholder="Select project"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                border: `1px solid ${
                                  errors.project ? "#dc3545" : "#cad2dd"
                                }`,
                              }),
                            }}
                          />
                        }
                        options={projectOptions}
                        name="project"
                        isClearable
                        control={control}
                        onChange={([selected]) => {
                          return { value: selected };
                        }}
                        rules={{
                          required: "Project is required",
                        }}
                      />
                      {errors.project ? (
                        <FormFeedBack feedBack={errors.project.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label>Task</Label>
                      <Controller
                        as={
                          <Select
                            placeholder="Select task"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                border: `1px solid ${
                                  errors.task ? "#dc3545" : "#cad2dd"
                                }`,
                              }),
                            }}
                          />
                        }
                        options={taskOptions}
                        name="task"
                        isClearable
                        control={control}
                        onChange={([selected]) => {
                          return { value: selected };
                        }}
                        rules={{
                          required: "Task is required",
                        }}
                      />
                      {errors.task ? (
                        <FormFeedBack feedBack={errors.task.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label>Notes</Label>
                      <Textarea
                        name="notes"
                        register={register}
                        refdetails={{
                          required: "Description required",
                        }}
                        state={errors.notes ? "error" : "none"}
                      />
                      {errors.notes ? (
                        <FormFeedBack feedBack={errors.notes.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Hours</Label>
                      <Input
                        type="number"
                        name="hours"
                        register={register}
                        refdetails={{
                          required: "Hours required",
                          min: {
                            value: 0,
                            message: "Hours should not be less than 0",
                          },
                        }}
                        state={errors.hours ? "error" : "none"}
                      />
                      {errors.hours ? (
                        <FormFeedBack feedBack={errors.hours.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Minutes</Label>
                      <Input
                        type="number"
                        name="minutes"
                        register={register}
                        refdetails={{
                          required: "Minutes required",
                          min: {
                            value: 0,
                            message: "Minutes should not be less than 0",
                          },
                          max: {
                            value: 60,
                            message: "Minutes should not be greater than 60",
                          },
                        }}
                        state={errors.minutes ? "error" : "none"}
                      />
                      {errors.minutes ? (
                        <FormFeedBack feedBack={errors.minutes.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                {/* <Row className="form-row">
                <Col sm={12}>
                  <Select
                    defaultValue={
                      isEditMode
                        ? projectOptions[
                            _.findIndex(projectOptions, {
                              value:
                                objectToEdit.timeTrackProject
                                  .timeTrackProjectId,
                            })
                          ]
                        : null
                    }
                    onChange={(e) => setProject(e.value)}
                    options={projectOptions}
                    placeholder="Select project"
                  />
                </Col>
              </Row>
              <Row className="form-row">
                <Col sm={12}>
                  <Select
                    defaultValue={
                      isEditMode
                        ? taskOptions[
                            _.findIndex(taskOptions, {
                              value: objectToEdit.timeTrackTask.timeTrackTaskId,
                            })
                          ]
                        : null
                    }
                    onChange={(e) => setTask(e.value)}
                    options={taskOptions}
                    placeholder="Select category"
                  />
                </Col>
              </Row>
              <Row className="form-row">
                <Col sm={12}>
                  <Input
                    type="textarea"
                    name="notes"
                    id="notes"
                    placeholder="Description"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}></Col>
                <Col sm={3} className="record-time-hours">
                  <Input
                    type="number"
                    max={60}
                    min={1}
                    placeholder="Hours"
                    value={hours}
                    onChange={(e) => formatHours(e.target.value)}
                  />
                </Col>
                <Col sm={3} className="record-time-minutes">
                  <Input
                    type="number"
                    max={60}
                    placeholder="Minutes"
                    value={minutes}
                    onChange={(e) => formatMinutes(e.target.value)}
                  />
                </Col>
              </Row> */}
              </Container>
            )}
          </ModalBody>
          <ModalFooter>
            <Button submit>Submit</Button>
            {/* <Normalbtn
            color="secondary"
            text={submitBtnText}
            class="modal-btn-submit"
            onClick={() =>
              onSubmit(selectedProject, selectedTask, notes, hours, minutes)
            }
            disabled={
              !selectedProject || !selectedTask || !notes || !hours || !minutes
            }
          /> */}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default NewRecordModal;
