import {
  getClients,
  createClient,
  deleteClient,
  updateClient,
  createContact,
} from "./actionCreators";
import allClientsReducer from "./reducer/allClientsReducer";
import { clientSaga } from "./saga/clientSaga";

const clientModule = {
  actions: {
    getClients,
    createClient,
    deleteClient,
    updateClient,
    createContact,
  },
  reducers: {
    allClientsReducer: allClientsReducer,
  },
  sagas: {
    clientSaga: clientSaga,
  },
};

export default clientModule;
