import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import StarRatings from "react-star-ratings";
import { toast } from "react-toastify";
import hrmModule from "../../../../../redux/hrm";
import Button from "../../../../components/ui-components/Button";
import { Container, Row, Col, Card, Alert } from "reactstrap";
import bemClass from "../../../../components/ui-components/Utils/bemClass";
import Loader from "../../../../components/ui-components/Loader";
import Textarea from "../../../../components/ui-components/Textarea";
import FormFeedBack from "../../../../components/ui-components/FormFeedBack";
import "./surveyFeedback.scss";

class HrmSurveyFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overallRating: 0,
    };
  }

  handleChange = (e, type) => {
    const { name, value } = e.target;

    this.setState({
      [name]: { questionId: name, value: value, type: type },
    });
  };

  validateSubmission = () => {
    let errorMessage = null;
    if (this.state.overallRating === 0) {
      errorMessage = "Please answer the required questions!";
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      const feedbackList = [];
      for (const [key, value] of Object.entries(this.state)) {
        if (key !== "overallRating") {
          feedbackList.push({
            questionId: parseInt(value.questionId),
            answer: value.type === "directAnswer" ? value.value : null,
            choiceId:
              value.type === "multipleChoice" ? parseInt(value.value) : null,
          });
        }
      }

      const requiredQuestions = this.props.survey.data.questions.filter(
        (question) => question.required
      );
      if (requiredQuestions.length) {
        requiredQuestions.forEach((rQuestion) => {
          const findInList = feedbackList.find(
            (q) => q.questionId === rQuestion.hrmSurveyQuestionId
          );
          if (!findInList) {
            errorMessage = "Please answer the required questions!";
          } else {
            if (!findInList.answer && !findInList.choiceId) {
              errorMessage = "Please answer the required questions!";
            }
          }
        });
      }

      const query = new URLSearchParams(this.props.location.search);
      const auth = query.get("auth");

      const details = {
        token: auth,
        body: {
          rating: this.state.overallRating,
          feedback: feedbackList,
        },
      };
      if (errorMessage) {
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        this.props.submitFeedback(details);
      }
    }
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const auth = query.get("auth");
    this.props.getSurveyDetails({ token: auth });
  }

  render() {
    const classes = new bemClass("HrmSurveyFeedback");
    return (
      <Container fluid className={classes.elem("wrapper").b()}>
        <Row>
          <Col sm={12} md={{ size: 6, offset: 3 }}>
            {this.props.survey.loading ? (
              <div className={classes.elem("loader").b()}>
                <Loader />
              </div>
            ) : (
              <div className={classes.elem("survey-card").b()}>
                <Card body>
                  {this.props.survey.error ? (
                    <Alert color="danger">
                      <p>{this.props.survey.error}</p>
                    </Alert>
                  ) : (
                    <React.Fragment>
                      <div className="survey-header">
                        <h2>{this.props.survey.data.survey.name}</h2>
                        <FormFeedBack
                          feedBack={`Review form for ${this.props.survey.data.employee.fullName}`}
                          feedBackType="info"
                        />
                      </div>
                      <div>
                        {!this.props.submitSurvey.loading &&
                        this.props.submitSurvey.data.length ? (
                          <Alert color="success">
                            <p>Thank you for your feedback.</p>
                          </Alert>
                        ) : (
                          <React.Fragment>
                            <ol>
                              {this.props.survey.data.questions.map(
                                (question) => {
                                  return (
                                    <React.Fragment>
                                      <li
                                        className={`survey-question ${
                                          question.required
                                            ? "required-label"
                                            : ""
                                        }`}
                                      >
                                        {question.question}
                                      </li>
                                      {question.type === "multipleChoice" ? (
                                        question.choices.map((choice) => {
                                          return (
                                            <div className="single-choice">
                                              <input
                                                value={
                                                  choice.hrmSurveyMultiChoiceId
                                                }
                                                name={
                                                  question.hrmSurveyQuestionId
                                                }
                                                type="radio"
                                                onChange={(e) =>
                                                  this.handleChange(
                                                    e,
                                                    question.type
                                                  )
                                                }
                                              />
                                              <span>{choice.choice}</span>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <Textarea
                                          name={question.hrmSurveyQuestionId}
                                          onChange={(e) =>
                                            this.handleChange(e, question.type)
                                          }
                                        />
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                              <li className="survey-question required-label">
                                Overall Rating
                              </li>
                              <div className="overallStartRating">
                                <StarRatings
                                  rating={this.state.overallRating}
                                  changeRating={(val) =>
                                    this.setState({ overallRating: val })
                                  }
                                  numberOfStars={5}
                                  name="rating"
                                  starDimension="30px"
                                  starSpacing="5px"
                                  starRatedColor="#052a4f"
                                />
                              </div>
                            </ol>
                            <div></div>
                            <div className="survey-footer">
                              <FormFeedBack
                                feedBack="This feedback is not editable once submitted."
                                feedBackType="info"
                              />
                              <Button
                                onClick={() => this.validateSubmission()}
                                loading={this.props.submitFeedback.loading}
                              >
                                Submit
                              </Button>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </Card>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSurveyDetails:
        hrmModule.hrmPerformanceModule.surveyModule.actions.getSurveyDetails,
      submitFeedback:
        hrmModule.hrmPerformanceModule.surveyModule.actions.submitFeedback,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    survey: state.hrmPerformanceSurvey,
    submitSurvey: state.hrmPerformanceCreateItem,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(HrmSurveyFeedback);
