import React from "react";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import Input from "../../../../components/inputNew";
import Button from "../../../../components/ui-components/Button";
import FormFeedBack from "../../../../components/ui-components/FormFeedBack";
import BEMhelper from "react-bem-helper";
import DatePicker from "react-datepicker";
import "../Addusers/AddUser.scss";

const classes = new BEMhelper("AddUserStepOne");
const StepOne = (props) => {
  const { submit } = props;

  let selectedGender = null;
  let selectedMaritalStatus = null;
  let selectedBloodGroup = null;
  let selectedTitle = null;

  const genders = [
    {
      value: "male",
      label: "Male",
    },
    {
      value: "female",
      label: "Female",
    },
  ];

  const maritalStatus = [
    {
      value: "single",
      label: "Single",
    },
    {
      value: "married",
      label: "Married",
    },
    {
      value: "widowed",
      label: "Widowed",
    },
    {
      value: "divorced",
      label: "Divorced",
    },
  ];

  const bloodGroups = [
    {
      value: "A+",
      label: "A positive",
    },
    {
      value: "A-",
      label: "A negative",
    },
    {
      value: "B+",
      label: "B positive",
    },
    {
      value: "B-",
      label: "B negative",
    },
    {
      value: "O+",
      label: "O positive",
    },
    {
      value: "O-",
      label: "O negative",
    },
    {
      value: "AB+",
      label: "AB positive",
    },
    {
      value: "AB-",
      label: "AB negative",
    },
  ];

  const titles = [
    {
      value: "miss",
      label: "Miss",
    },
    {
      value: "mrs",
      label: "Mrs",
    },
    {
      value: "mr",
      label: "Mr",
    },
    {
      value: "ms",
      label: "Ms",
    },
  ];

  if (props.isEditMode) {
    _.forEach(genders, function (gender) {
      if (gender.value === props.stepOneData.gender.value) {
        selectedGender = gender;
      }
    });

    if (props.stepOneData.maritalstatus) {
      _.forEach(maritalStatus, function (status) {
        if (status.value === props.stepOneData.maritalstatus.value) {
          selectedMaritalStatus = status;
        }
      });
    }

    _.forEach(bloodGroups, function (group) {
      if (group.value === props.stepOneData.bloodGroup.value) {
        selectedBloodGroup = group;
      }
    });

    _.forEach(titles, function (title) {
      if (title.value === props.stepOneData.title.value) {
        selectedTitle = title;
      }
    });
  }

  const defaultValues = {
    firstname: props.isEditMode ? props.stepOneData.firstname : null,
    middlename: props.isEditMode ? props.stepOneData.middlename : null,
    lastname: props.isEditMode ? props.stepOneData.lastname : null,
    email: props.isEditMode ? props.stepOneData.email : null,
    nic: props.isEditMode ? props.stepOneData.nic : null,
    permanentaddress: props.isEditMode
      ? props.stepOneData.permanentaddress
      : null,
    mobilenumber: props.isEditMode ? props.stepOneData.mobilenumber : null,
    religion: props.isEditMode ? props.stepOneData.religion : null,
    district: props.isEditMode ? props.stepOneData.district : null,
    gender: selectedGender,
    maritalstatus: selectedMaritalStatus,
    dateofbirth: props.isEditMode
      ? new Date(props.stepOneData.dateofbirth)
      : null,
    bloodGroup: selectedBloodGroup,
    title: selectedTitle,
  };

  const {
    handleSubmit,
    register,
    errors,
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => submit(values);

  const stepOneWatcher = watch(["dateofbirth"]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...classes("formOne")}>
      <Container fluid>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>First Name</Label>
              <Input
                name="firstname"
                register={register}
                refdetails={{
                  required: "First name required",
                  maxLength: {
                    value: 20,
                    message: "Maximun length is 20",
                  },
                }}
                state={errors.firstname ? "error" : "none"}
              />
              {errors.firstname ? (
                <FormFeedBack feedBack={errors.firstname.message} />
              ) : null}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Middle Name</Label>
              <Input
                name="middlename"
                register={register}
                refdetails={{
                  required: "Middle name required",
                  maxLength: {
                    value: 20,
                    message: "Maximun length is 20",
                  },
                }}
                state={errors.middlename ? "error" : "none"}
              />
              {errors.middlename ? (
                <FormFeedBack feedBack={errors.middlename.message} />
              ) : null}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Last Name</Label>
              <Input
                name="lastname"
                register={register}
                refdetails={{
                  required: "Last name required",
                  maxLength: {
                    value: 20,
                    message: "Maximun length is 20",
                  },
                }}
                state={errors.lastname ? "error" : "none"}
              />
              {errors.lastname ? (
                <FormFeedBack feedBack={errors.lastname.message} />
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                name="email"
                register={register}
                refdetails={{
                  required: "Email required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                state={errors.email ? "error" : "none"}
              />
              {errors.email ? (
                <FormFeedBack feedBack={errors.email.message} />
              ) : null}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>NIC</Label>
              <Input
                name="nic"
                register={register}
                refdetails={{
                  required: "NIC required",
                  maxLength: {
                    value: 20,
                    message: "Maximun length is 20",
                  },
                }}
                state={errors.nic ? "error" : "none"}
              />
              {errors.nic ? (
                <FormFeedBack feedBack={errors.nic.message} />
              ) : null}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Permanent Address</Label>
              <Input
                name="permanentaddress"
                register={register}
                refdetails={{
                  required: "Permanent address required",
                  maxLength: {
                    value: 100,
                    message: "Maximun length is 20",
                  },
                }}
                state={errors.permanentaddress ? "error" : "none"}
              />
              {errors.permanentaddress ? (
                <FormFeedBack feedBack={errors.permanentaddress.message} />
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>Mobile Number</Label>
              <Input
                name="mobilenumber"
                register={register}
                state={errors.mobilenumber ? "error" : "none"}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Religion</Label>
              <Input
                name="religion"
                register={register}
                state={errors.religion ? "error" : "none"}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>District</Label>
              <Input
                name="district"
                register={register}
                state={errors.district ? "error" : "none"}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>Gender</Label>
              <Controller
                as={
                  <Select
                    placeholder="Select gender"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: `1px solid ${
                          errors.gender ? "#dc3545" : "#cad2dd"
                        }`,
                      }),
                    }}
                  />
                }
                options={genders}
                name="gender"
                isClearable
                control={control}
                onChange={([selected]) => {
                  return { value: selected };
                }}
                rules={{
                  required: "Gender is required",
                }}
              />
              {errors.gender ? (
                <FormFeedBack feedBack={errors.gender.message} />
              ) : null}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Marital Status</Label>
              <Controller
                as={
                  <Select
                    placeholder="Select status"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: `1px solid ${
                          errors.maritalstatus ? "#dc3545" : "#cad2dd"
                        }`,
                      }),
                    }}
                  />
                }
                options={maritalStatus}
                name="maritalstatus"
                isClearable
                control={control}
                onChange={([selected]) => {
                  return { value: selected };
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Date of Birth</Label>
              <Controller
                as={
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    placeholderText={"Date of birth"}
                    className={`form-control ${
                      errors.dateofbirth ? "date-error" : ""
                    }`}
                    selected={getValues().dateofbirth}
                  />
                }
                valueName="selected"
                name="dateofbirth"
                control={control}
                onChange={(date) => setValue("dateofbirth", date)}
                rules={{
                  required: "Date of birth is required",
                }}
              />
              {errors.dateofbirth ? (
                <FormFeedBack feedBack={errors.dateofbirth.message} />
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>Blood Group</Label>
              <Controller
                as={
                  <Select
                    placeholder="Select group"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: `1px solid ${
                          errors.bloodGroup ? "#dc3545" : "#cad2dd"
                        }`,
                      }),
                    }}
                  />
                }
                options={bloodGroups}
                name="bloodGroup"
                isClearable
                control={control}
                onChange={([selected]) => {
                  return { value: selected };
                }}
                rules={{
                  required: "Blood group is required",
                }}
              />
              {errors.bloodGroup ? (
                <FormFeedBack feedBack={errors.bloodGroup.message} />
              ) : null}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Title</Label>
              <Controller
                as={
                  <Select
                    placeholder="Select title"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: `1px solid ${
                          errors.title ? "#dc3545" : "#cad2dd"
                        }`,
                      }),
                    }}
                  />
                }
                options={titles}
                name="title"
                isClearable
                control={control}
                onChange={([selected]) => {
                  return { value: selected };
                }}
                rules={{
                  required: "Title is required",
                }}
              />
              {errors.title ? (
                <FormFeedBack feedBack={errors.title.message} />
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup {...classes("buttonGroup")}>
              <Button submit>Next</Button>
            </FormGroup>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default StepOne;
