import {
  getUserList,
  getSingleUser,
  loginUser,
  signupUser,
  getUserRole,
  getUserDesignation,
  logout,
  getUserDepartmentList,
  updateSignupReducer,
  resetSingleUser,
  forgotPassword,
  resetPassword,
  resetMyPassword,
} from "./actionCreators";
import userListReducer from "./reducer/userListReducer";
import singleUserReducer from "./reducer/singleUserReducer";
import loggedUserReducer from "./reducer/loggedUserReducer";
import userSignupReducer from "./reducer/userSignUpReducer";
import userGetRoleReducer from "./reducer/userRoleReducer";
import userGetDesignationReducer from "./reducer/userDesignationReducer";
import userDepartmentReducer from "./reducer/userDepartmentReducer";
import forgotPasswordReducer from "./reducer/forgotPasswordReducer";
import resetPasswordReducer from "./reducer/resetPasswordReducer";
import { userSaga } from "./saga/userSaga";

const userModule = {
  actions: {
    getUserList,
    getSingleUser,
    loginUser,
    signupUser,
    getUserRole,
    getUserDesignation,
    logout,
    getUserDepartmentList,
    updateSignupReducer,
    resetSingleUser,
    forgotPassword,
    resetPassword,
    resetMyPassword,
  },
  reducers: {
    userListReducer: userListReducer,
    singleUserReducer: singleUserReducer,
    loggedUserReducer: loggedUserReducer,
    userSignupReducer: userSignupReducer,
    userGetRoleReducer: userGetRoleReducer,
    userGetDesignationReducer: userGetDesignationReducer,
    userDepartmentReducer: userDepartmentReducer,
    forgotPasswordReducer: forgotPasswordReducer,
    resetPasswordReducer: resetPasswordReducer,
  },
  sagas: {
    userSaga: userSaga,
  },
};

export default userModule;
