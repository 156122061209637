import React from "react";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import Button from "../../../../../components/ui-components/Button";
import FormFeedBack from "../../../../../components/ui-components/FormFeedBack";
import Input from "../../../../../components/inputNew";
import bemClass from "../../../../../components/ui-components/Utils/bemClass";
import "./surveyName.scss";

const HrmSurveySurveyName = (props) => {
  const classes = new bemClass("HrmSurveySurveyName");
  const { submit, stepOneData } = props;
  const defaultValues = {
    surveyName: stepOneData ? stepOneData.surveyName : null,
  };

  const { handleSubmit, register, errors } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    submit(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container fluid className={classes.elem("wrapper").b()}>
        <Row>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <FormGroup>
              <Label>Provide a name for your survey</Label>
              <Input
                name="surveyName"
                register={register}
                refdetails={{
                  required: "Survey name is required",
                }}
                state={errors.surveyName ? "error" : "none"}
              />
              {errors.surveyName ? (
                <FormFeedBack feedBack={errors.surveyName.message} />
              ) : null}
              <div className={classes.elem("create-btn").b()}>
                <Button submit>Create</Button>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default HrmSurveySurveyName;
