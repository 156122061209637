import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const ToolTip = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
      {props.component}
      <Tooltip
        placement={props.position}
        isOpen={tooltipOpen}
        target={props.rowId}
        toggle={toggle}
      >
        {props.tip}
      </Tooltip>
    </div>
  );
};

export default ToolTip;
