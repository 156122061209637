import { getStages, createStage, updateStage } from "./actionCreators";
import { interviewStageSaga } from "./saga/interviewStageSaga";
import allStagesReducer from "./reducer/allStagesReducer";

const interviewStageModule = {
  actions: {
    getStages,
    createStage,
    updateStage,
  },
  reducers: {
    allStagesReducer: allStagesReducer,
  },
  sagas: {
    interviewStageSaga: interviewStageSaga,
  },
};

export default interviewStageModule;
