import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import {
  FaListAlt,
  FaEthernet,
  FaSmileBeam,
  FaHome,
  FaChalkboardTeacher,
  FaUsers,
  FaCogs,
  FaUserTag,
} from "react-icons/fa";
import { MdReport } from "react-icons/md";
import { Link } from "react-router-dom";
import BEMhelper from "react-bem-helper";
import "./sideBar.scss";

const isModule = (module) => {
  return window.location.pathname.includes(module) ? true : false;
};

const generateModuleTitle = () => {
  const module = window.location.pathname;
  if (module.includes("/hrm/")) {
    return "HRM";
  } else if (module.includes("/administration/")) {
    return "ADMINISTRATION";
  } else if (module.includes("/time-track/")) {
    return "TIME-TARCK";
  } else if (module.includes("/inventory/")) {
    return "INVENTORY";
  } else {
    return "KushZo";
  }
};

const renderContent = (props) => {
  if (isModule("/hrm/")) {
    return (
      <React.Fragment>
        <MenuItem icon={<FaChalkboardTeacher />}>
          Mentorship Program
          <Link to="/hrm/mentorship" />
        </MenuItem>
        {props.featurePermission &&
        (props.featurePermission.isHrmAdmin ||
          props.featurePermission.isSuperAdmin ||
          props.featurePermission.isAdmin) ? (
          <React.Fragment>
            <SubMenu title="Perfomance" icon={<FaSmileBeam />}>
              <MenuItem>
                360 Survey
                <Link to="/hrm/performance/surveys" />
              </MenuItem>
            </SubMenu>
            <SubMenu title="Recruitment" icon={<FaSmileBeam />}>
              <MenuItem>
                Dashboard
                <Link to="/hrm/recruitment/dashboard" />
              </MenuItem>
              <MenuItem>
                Agents
                <Link to="/hrm/recruitment/agents" />
              </MenuItem>
              <MenuItem>
                Vacancies
                <Link to="/hrm/recruitment/vacancies" />
              </MenuItem>
              <SubMenu title="CV Pool" icon={<FaSmileBeam />}>
                <MenuItem>
                  Processing
                  <Link to="/hrm/recruitment/cvpool" />
                </MenuItem>
                <MenuItem>
                  Hired
                  <Link to="/hrm/recruitment/hiredpool" />
                </MenuItem>
                <MenuItem>
                  Approved
                  <Link to="/hrm/recruitment/approvedpool" />
                </MenuItem>
                <MenuItem>
                  Rejected
                  <Link to="/hrm/recruitment/rejectedpool" />
                </MenuItem>
                <MenuItem>
                  Turn Downs
                  <Link to="/hrm/recruitment/turnDownPool" />
                </MenuItem>
              </SubMenu>
            </SubMenu>
            <SubMenu title="Settings" icon={<FaCogs />}>
              <SubMenu title="Recruitment" icon={<FaSmileBeam />}>
                <MenuItem>
                  Interview stages
                  <Link to="/hrm/settings/recruitment/stages" />
                </MenuItem>
              </SubMenu>
            </SubMenu>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  } else if (isModule("/inventory/")) {
    return (
      <React.Fragment>
        {props.featurePermission &&
        (props.featurePermission.isInventoryAdmin ||
          props.featurePermission.isInventoryManager ||
          props.featurePermission.isSuperAdmin ||
          props.featurePermission.isAdmin) ? (
          <React.Fragment>
            <MenuItem icon={<FaEthernet />}>
              Dashboard
              <Link to="/inventory/dashboard" />
            </MenuItem>
            <MenuItem icon={<FaListAlt />}>
              Items
              <Link to="/inventory/items" />
            </MenuItem>
            <MenuItem icon={<MdReport />}>
              Reports
              <Link to="/inventory/reports" />
            </MenuItem>
          </React.Fragment>
        ) : null}
        <MenuItem icon={<FaUserTag />}>
          My Items
          <Link to="/inventory/my-items" />
        </MenuItem>
      </React.Fragment>
    );
  } else if (isModule("/administration/")) {
    return (
      <React.Fragment>
        {props.featurePermission &&
        (props.featurePermission.isSuperAdmin ||
          props.featurePermission.isAdmin) ? (
          <SubMenu title="Users" icon={<FaUsers />}>
            <MenuItem>
              Add User
              <Link to="/administration/addusers" />
            </MenuItem>
            <MenuItem>
              List Users
              <Link to="/administration/listusers" />
            </MenuItem>
          </SubMenu>
        ) : null}
      </React.Fragment>
    );
  } else {
    return <span></span>;
  }
};

const SideBar = (props) => {
  const classes = new BEMhelper("sideNavBar");
  return (
    <div {...classes("side-bar")}>
      <ProSidebar image={require("../../../assets/img/sidbar-background.jpg")}>
        <SidebarHeader>
          <div
            style={{
              padding: "20px",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 18,
              letterSpacing: "1px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {generateModuleTitle()}
          </div>
        </SidebarHeader>
        <SidebarContent>
          <div {...classes("company")}>
            <img src={props.company.logo} alt="logo" className="logo" />
            <p className="name">{props.company.name}</p>
            <hr />
          </div>
          <Menu iconShape="circle">
            <MenuItem icon={<FaHome />}>
              Home
              <Link to="/landing" />
            </MenuItem>
            {renderContent(props)}
            {/* <MenuItem icon={<FaGem />}>
              Dashboard
              <Link to="/" />
            </MenuItem>
            <SubMenu title="Components" icon={<FaHeart />}>
              <MenuItem>Component 1</MenuItem>
              <MenuItem>Component 2</MenuItem>
            </SubMenu> */}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 24px",
            }}
          >
            <div {...classes("profile")}>
              <img
                src={require("../../../assets/img/placeholder-profile-1.jpg")}
                alt="profile"
                className="logo"
              />
              <p className="name">
                {props.user.firstName} {props.user.lastName}
              </p>
              <p className="title">{props.user.designation.name}</p>
              {/* <hr /> */}
            </div>
          </div>
          {/* <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 24px",
            }}
          >
            <a
              href="https://github.com/azouaoui-med/react-pro-sidebar"
              target="_blank"
              className="sidebar-btn"
              rel="noopener noreferrer"
            >
              <FaGithub />
              <span>
                {props.user.firstName} {props.user.lastName}
              </span>
            </a>
          </div> */}
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};

export default SideBar;
