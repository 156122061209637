import * as actions from "./actionTypes";

export const getStages = () => ({
  type: actions.GET_HRM_INTERVIEW_STAGES_REQUEST,
});

export const createStage = (data) => ({
  type: actions.CREATE_HRM_INTERVIEW_STAGE_REQUEST,
  payload: data,
});

export const updateStage = (data) => ({
  type: actions.UPDATE_HRM_INTERVIEW_STAGE_REQUEST,
  payload: data,
});
