import {
  GET_HRM_VACANCIES_REQUEST,
  GET_HRM_VACANCIES_SUCCESS,
  GET_HRM_VACANCIES_FAILURE,
} from "../actionTypes";

import {
  GET_VACANCIES_AGENT_REQUEST,
  GET_VACANCIES_AGENT_SUCCESS,
  GET_VACANCIES_AGENT_FAILURE,
} from "../../agent/actionTypes";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const allVacanciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HRM_VACANCIES_REQUEST:
    case GET_VACANCIES_AGENT_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case GET_HRM_VACANCIES_SUCCESS:
    case GET_VACANCIES_AGENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case GET_HRM_VACANCIES_FAILURE:
    case GET_VACANCIES_AGENT_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default allVacanciesReducer;
