import { createStore, applyMiddleware, compose } from "redux";
import rootreducer from "./rootReducer";
import globalSagas from "./rootSaga";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { routerMiddleware } from "connected-react-router";

export const history = createBrowserHistory();
const enhancers = [];

if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line no-underscore-dangle
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const persistConfig = persistReducer(
  {
    key: "root",
    storage: storage,
    whitelist: ["loggedUser", "signupUser", "hrmAgentLogin"],
    stateReconciler: autoMergeLevel2,
  },
  rootreducer(history)
);

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, routerMiddleware(history)];

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export const store = createStore(persistConfig, {}, composedEnhancers);
export const persistor = persistStore(store);

sagaMiddleware.run(globalSagas);
