import {
  GET_INVENTORY_REPORT_REQUEST,
  GET_INVENTORY_REPORT_SUCCESS,
  GET_INVENTORY_REPORT_FAILURE,
  GET_INVENTORY_CUSTOM_REPORT_REQUEST,
  GET_INVENTORY_CUSTOM_REPORT_SUCCESS,
  GET_INVENTORY_CUSTOM_REPORT_FAILURE,
  RESET_REPORT_REQUEST,
} from "../actionTypes";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVENTORY_REPORT_REQUEST:
    case GET_INVENTORY_CUSTOM_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case GET_INVENTORY_REPORT_SUCCESS:
    case GET_INVENTORY_CUSTOM_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case GET_INVENTORY_REPORT_FAILURE:
    case GET_INVENTORY_CUSTOM_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
    case RESET_REPORT_REQUEST:
      return {
        ...state,
        loading: false,
        data: [],
        error: null,
      };
    default:
      return state;
  }
};

export default reportReducer;
