import { claimType, applyClaim, claimHistory } from "./actionCreators";
import claimTypeReducer from "./reducer/claimTypeReducer";
import applyClaimReducer from "./reducer/applyClaimReducer";
import claimHistoryReducer from "./reducer/claimHistoryReducer";
import { claimSaga } from "./saga/claimSaga";

const claimModule = {
  actions: {
    claimType,
    applyClaim,
    claimHistory,
  },
  reducers: {
    claimTypeReducer: claimTypeReducer,
    applyClaimReducer: applyClaimReducer,
    claimHistoryReducer: claimHistoryReducer,
  },
  sagas: {
    claimSaga: claimSaga,
  },
};

export default claimModule;
