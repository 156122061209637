import React from "react";
import { Container, Row, Col, FormGroup, Card, Alert } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Input from "../../components/inputNew";
import Button from "../../components/ui-components/Button";
import FormFeedBack from "../../components/ui-components/FormFeedBack";
import BEMhelper from "react-bem-helper";
import userModule from "../../../redux/user";
import hrmModule from "../../../redux/hrm";
import "./login.scss";

const classes = new BEMhelper("Login");
const Login = (props) => {
  const defaultValues = {};

  const { handleSubmit, register, errors } = useForm({
    defaultValues,
  });
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const details = {
      email: values.email,
      password: values.password,
    };
    const modulepath = window.location.pathname;
    if (modulepath.includes("/agent/")) {
      dispatch(hrmModule.agentModule.actions.agentLogin(details));
    } else {
      dispatch(userModule.actions.loginUser(details));
    }
  };
  const loginRequest = useSelector((state) => state.loggedUser);
  const agentLoginRequest = useSelector((state) => state.hrmAgentLogin);

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...classes("form")}>
      <Container fluid>
        <Row>
          <Col sm={12} md={{ size: 6, offset: 3 }} className="text-center">
            <div className="login-form-card">
              <h1 className="login-form-header">Login</h1>
              <Card body>
                {loginRequest.error || agentLoginRequest.error ? (
                  <Row>
                    <Col md={12}>
                      <Alert color="danger">
                        Invalid Credentials! Try again.
                      </Alert>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Input
                        placeholder="Email"
                        name="email"
                        register={register}
                        refdetails={{
                          required: "Email required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        }}
                        state={errors.email ? "error" : "none"}
                      />
                      {errors.email ? (
                        <FormFeedBack feedBack={errors.email.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Input
                        type="password"
                        placeholder="Password"
                        name="password"
                        register={register}
                        refdetails={{
                          required: "Password required",
                        }}
                        state={errors.password ? "error" : "none"}
                      />
                      {errors.password ? (
                        <FormFeedBack feedBack={errors.password.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup {...classes("submit")}>
                      <Button submit loading={loginRequest.loading}>
                        Submit
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Link to="/forgot-password">
                      <Button type="link">Forgot Password?</Button>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default Login;
