import mentorshipModule from "./mentorship";
import commonModule from "./common";
import candidateModule from "./candidate";
import agentModule from "./agent";
import vacancyModule from "./vacancy";
import interviewStageModule from "./interviewStage";
import dashboardModule from "./dashboard";
import hrmPerformanceModule from "./performance";

const hrmModule = {
  mentorshipModule,
  commonModule,
  candidateModule,
  agentModule,
  vacancyModule,
  interviewStageModule,
  dashboardModule,
  hrmPerformanceModule,
};

export default hrmModule;
