import * as actions from "./actionTypes";

export const getTypes = () => ({
  type: actions.GET_INVENTORY_TYPES_REQUEST,
});

export const createType = (data) => ({
  type: actions.CREATE_INVENTORY_TYPE_REQUEST,
  payload: data,
});

export const updateType = (data) => ({
  type: actions.UPDATE_INVENTORY_TYPE_REQUEST,
  payload: data,
});
