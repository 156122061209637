export const APPLY_LEAVE_REQUEST = "APPLY_LEAVE_REQUEST";
export const APPLY_LEAVE_SUCCESS = "APPLY_LEAVE_SUCCESS";
export const APPLY_LEAVE_FAILURE = "APPLY_LEAVE_FAILURE";

export const LEAVE_BALANCE_REQUEST = "LEAVE_BALANCE_REQUEST";
export const LEAVE_BALANCE_SUCCESS = "LEAVE_BALANCE_SUCCESS";
export const LEAVE_BALANCE_FAILURE = "LEAVE_BALANCE_FAILURE";

export const LEAVE_DATE_TYPE_REQUEST = "LEAVE_DATE_TYPE_REQUEST";
export const LEAVE_DATE_TYPE_SUCCESS = "LEAVE_DATE_TYPE_SUCCESS";
export const LEAVE_DATE_TYPE_FAILURE = "LEAVE_DATE_TYPE_FAILURE";

export const LEAVE_HISTORY_REQUEST = "LEAVE_HISTORY_REQUEST";
export const LEAVE_HISTORY_SUCCESS = "LEAVE_HISTORY_SUCCESS";
export const LEAVE_HISTORY_FAILURE = "LEAVE_HISTORY_FAILURE";

export const PROCESS_LEAVE_REQUEST = "PROCESS_LEAVE_REQUEST";
export const PROCESS_LEAVE_SUCCESS = "PROCESS_LEAVE_SUCCESS";
export const PROCESS_LEAVE_FAILURE = "PROCESS_LEAVE_FAILURE";
