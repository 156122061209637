const hireSources = [
  {
    value: "newspaper",
    label: "Newspaper",
  },
  {
    value: "media",
    label: "Media",
  },
  {
    value: "friend",
    label: "Friend",
  },
  {
    value: "direct",
    label: "Direct",
  },
  {
    value: "agency",
    label: "Agency",
  },
  {
    value: "referral",
    label: "Referral",
  },
];

export default hireSources;
