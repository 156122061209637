import React from "react";
import PropTypes from "prop-types";
// import Loader from '../Loader';
import { Spinner } from "reactstrap";

import "./Button.scss";

const colors = [
  "black",
  "blue",
  "bright-blue",
  "bright-green",
  "bright-purple",
  "bright-purple",
  "deep-blue",
  "deep-purple",
  "green",
  "grey",
  "light-grey",
  "medium-blue",
  "medium-green",
  "medium-purple",
  "orange",
  "purple",
  "red",
  "super-light-grey",
  "white",
  "info",
];
const sizes = ["xl", "lg", "md", "sm", "xs"];
const types = ["lined", "link"];

const propTypes = {
  /**
   * The CSS class name of the wrapper element.
   */
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /**
   * Text inside the button this is instead of child
   */
  text: PropTypes.string,
  /**
   * Children, this should usually be a string
   */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.element,
  ]),
  /**
   * the function that fires on click of the button
   */
  color: PropTypes.oneOf(colors),
  hoverColor: PropTypes.oneOf(colors),
  size: PropTypes.oneOf(sizes),
  disabled: PropTypes.bool,
  gradient: PropTypes.bool,
  submit: PropTypes.bool,
  block: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(types),
  shadow: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  outline: PropTypes.bool,
};

/**
 * This is the Button component for Upsales UI buttons that is being used within the CRM.
 */
const Button = ({
  children,
  className,
  text,
  color,
  type,
  block,
  shadow,
  size,
  hoverColor,
  loading,
  submit,
  disabled,
  gradient,
  ...props
}) => {
  let content = children;
  const classList = (className || "").split(" ");

  classList.push("Button");
  classList.push("up-btn");

  if (text) {
    content = text;
  }

  classList.push(`btn-${color}`);

  if (type) {
    classList.push(`btn-${type}`);
  }

  if (block === true) {
    classList.push("btn-block");
  }

  if (shadow === "none") {
    classList.push("no-shadow");
  }

  if (shadow === "high") {
    classList.push("high-shadow");
  }

  if (size) {
    classList.push(`btn-${size}`);
  }

  if (hoverColor) {
    classList.push(`btn-hover-${hoverColor}`);
  }

  if (gradient) {
    classList.push("btn-gradient");
  }

  if (loading) {
    content = (
      <div className="Button__loader-wrap">
        <Spinner size="sm" color="light" />
        <span style={{ opacity: 0 }}>{text || children}</span>
      </div>
    );
  }

  return (
    <button
      className={classList.join(" ").trim()}
      type={submit ? "submit" : "button"}
      disabled={disabled || loading}
      {...props}
    >
      {content}
    </button>
  );
};

Button.colors = colors;
Button.sizes = sizes;
Button.types = types;

Button.propTypes = propTypes;
Button.defaultProps = {
  color: "bright-blue",
};

export default Button;
