import projectModule from "./project";
import commonModule from "./common";
import categoryModule from "./category";
import entryModule from "./entry";
import clientModule from "./client";
import taskModule from "./task";
import userModule from "./user";
import reportModule from "./report";

const timeTrackModule = {
  projectModule,
  commonModule,
  categoryModule,
  entryModule,
  clientModule,
  taskModule,
  userModule,
  reportModule,
};

export default timeTrackModule;
