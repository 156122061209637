import createItemReducer from "./reducer/createItemReducer";
import updateItemReducer from "./reducer/updateItemReducer";

const commonModule = {
  reducers: {
    createItemReducer: createItemReducer,
    updateItemReducer: updateItemReducer,
  },
};

export default commonModule;
