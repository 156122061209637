import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import Select from "react-select";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../ui-components/Button";
import Input from "../../../inputNew";
import FormFeedBack from "../../../ui-components/FormFeedBack";
import Toggle from "../../../ui-components/Toggle";

const classes = new BEMhelper("AddUserModal");
const AddUserModal = (props) => {
  const {
    title,
    openModal,
    toggle,
    submit,
    userList,
    objectToEdit,
    isEditMode,
    permissionTypes,
  } = props;

  let selectedUser = null;
  let selectedType = null;

  const userOptions = userList.map((user) => {
    return {
      value: user.userId,
      label: `${user.firstName} ${user.lastName}`,
    };
  });

  const permissionTypeOptions = permissionTypes.map((permission) => {
    return {
      value: permission.permissionTypeId,
      label: permission.type,
    };
  });

  if (isEditMode) {
    _.forEach(userOptions, function (role) {
      if (role.value === objectToEdit.userId) {
        selectedUser = role;
      }
    });

    _.forEach(permissionTypeOptions, function (type) {
      if (type.value === objectToEdit.permissionTypeId) {
        selectedType = type;
      }
    });
  }

  const defaultValues = {
    user: isEditMode ? selectedUser : null,
    capacity: isEditMode ? parseInt(objectToEdit.capacity) : null,
    billableRate: isEditMode ? parseInt(objectToEdit.billableRate) : null,
    internalCost: isEditMode ? parseInt(objectToEdit.internalRate) : null,
    userRole: isEditMode ? selectedType : null,
    activeStatus: isEditMode ? objectToEdit.isActive : true,
  };

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => submit(values);

  return (
    <div>
      <Modal
        {...classes("modal")}
        isOpen={openModal}
        toggle={() => toggle()}
        centered={true}
        draggable={false}
        size="md"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={() => toggle()} className="modal-header">
            {title}
          </ModalHeader>
          <ModalBody>
            <Container fluid>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>User</Label>
                    <Controller
                      as={
                        <Select
                          isDisabled={isEditMode}
                          placeholder="Select user"
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              border: `1px solid ${
                                errors.user ? "#dc3545" : "#cad2dd"
                              }`,
                            }),
                          }}
                        />
                      }
                      options={userOptions}
                      name="user"
                      isClearable
                      control={control}
                      onChange={([selected]) => {
                        return { value: selected };
                      }}
                      rules={{
                        required: "User is required",
                      }}
                    />
                    {errors.user ? (
                      <FormFeedBack feedBack={errors.user.message} />
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Capacity</Label>
                    <Input
                      type="number"
                      name="capacity"
                      register={register}
                      refdetails={{
                        required: "Capacity required",
                      }}
                      state={errors.capacity ? "error" : "none"}
                      min={0}
                    />
                    <FormFeedBack
                      feedBack={"Number of hours this person works per week!"}
                      feedBackType="info"
                    />
                    {errors.capacity ? (
                      <FormFeedBack feedBack={errors.capacity.message} />
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Billable rate</Label>
                    <Input
                      type="number"
                      name="billableRate"
                      register={register}
                      refdetails={{
                        required: "Billable rate required",
                      }}
                      state={errors.billableRate ? "error" : "none"}
                      min={0}
                    />
                    <FormFeedBack
                      feedBack={
                        "Cost billable per hour for any project (IN DOLLARS)"
                      }
                      feedBackType="info"
                    />
                    {errors.billableRate ? (
                      <FormFeedBack feedBack={errors.billableRate.message} />
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Internal cost</Label>
                    <Input
                      type="number"
                      name="internalCost"
                      register={register}
                      refdetails={{
                        required: "Internal cost rate required",
                      }}
                      state={errors.internalCost ? "error" : "none"}
                      min={0}
                    />
                    <FormFeedBack
                      feedBack={"Internal cost per hour (IN DOLLARS)"}
                      feedBackType="info"
                    />
                    {errors.internalCost ? (
                      <FormFeedBack feedBack={errors.internalCost.message} />
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>User role</Label>
                    <Controller
                      as={
                        <Select
                          placeholder="Select role"
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              border: `1px solid ${
                                errors.userRole ? "#dc3545" : "#cad2dd"
                              }`,
                            }),
                          }}
                        />
                      }
                      options={permissionTypeOptions}
                      name="userRole"
                      isClearable
                      control={control}
                      onChange={([selected]) => {
                        return { value: selected };
                      }}
                      rules={{
                        required: "User role is required",
                      }}
                    />
                    {errors.userRole ? (
                      <FormFeedBack feedBack={errors.userRole.message} />
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Active status</Label>
                    <Controller
                      as={
                        <Toggle
                          checked={isEditMode ? objectToEdit.isActive : true}
                        />
                      }
                      name="activeStatus"
                      control={control}
                      onChange={([selected]) => {
                        return { value: selected };
                      }}
                    />
                    {errors.activeStatus ? (
                      <FormFeedBack feedBack={errors.activeStatus.message} />
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button submit>Submit</Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default AddUserModal;
