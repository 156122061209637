import React from "react";
import { Table } from "reactstrap";
import "./Table.scss";

const CustomTable = (props) => {
  //Todo: Change the table type to be changed dynamically
  return (
    <div className="tableWrapper">
      <Table responsive>
        <thead>
          {props.customHeaders ? (
            props.tableHeadings
          ) : (
            <tr>
              {props.tableHeadings.map((heading, key) => {
                return <th key={key}>{heading}</th>;
              })}
            </tr>
          )}
        </thead>
        <tbody>{props.content}</tbody>
      </Table>
      {!props.content.length ? <p className="noDataMessage">No data</p> : null}
    </div>
  );
};

export default CustomTable;
