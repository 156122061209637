import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../../../ui-components/Button";
import FormFeedBack from "../../../../../ui-components/FormFeedBack";
import Select from "react-select";
import "./sendInterviewInvitation.scss";

const classes = new BEMhelper("SendInterviewInvitationModal");
const SendInterviewInvitationModal = (props) => {
  const {
    title,
    openModal,
    toggle,
    submit,
    stages,
    team,
    creatInvitation,
  } = props;

  const stageOptions = stages.map((stage) => {
    return {
      value: stage.hrmInterviewStageId,
      label: stage.name,
    };
  });

  const teamOptions = team.map((member) => {
    return {
      value: member.userId,
      label: `${member.firstName} ${member.lastName}`,
    };
  });

  const defaultValues = {};

  const { handleSubmit, errors, control } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    submit(values);
  };

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => toggle()}
      centered={true}
      draggable={false}
      size="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => toggle()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Interview Stage</Label>
                  <Controller
                    as={
                      <Select
                        placeholder={"Select stage"}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: `1px solid ${
                              errors.stage ? "#dc3545" : "#cad2dd"
                            }`,
                          }),
                        }}
                      />
                    }
                    options={stageOptions}
                    name="stage"
                    isClearable
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    rules={{
                      required: "Stage is required",
                    }}
                  />
                  {errors.stage ? (
                    <FormFeedBack feedBack={errors.stage.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Interviewer</Label>
                  <Controller
                    as={
                      <Select
                        placeholder={"Select interviewer"}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: `1px solid ${
                              errors.interviewer ? "#dc3545" : "#cad2dd"
                            }`,
                          }),
                        }}
                      />
                    }
                    options={teamOptions}
                    name="interviewer"
                    isClearable
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    rules={{
                      required: "Interviewer is required",
                    }}
                  />
                  {errors.interviewer ? (
                    <FormFeedBack feedBack={errors.interviewer.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button submit loading={creatInvitation.loading}>
            Send Invitation
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default SendInterviewInvitationModal;
