import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import inventoryModule from "../../../../redux/inventory";
import { Container, Row, Col, ButtonGroup } from "reactstrap";
import moment from "moment";
import _ from "lodash";
import CustomTable from "../../../components/table";
import userModule from "../../../../redux/user";
import Paginator from "../../../components/ui-components/Paginator";
import Loader from "../../../components/ui-components/Loader";
import Button from "../../../components/ui-components/Button";
import bemClass from "../../../components/ui-components/Utils/bemClass";
import InventoryItemInfo from "../itemInfo";
import InfoModal from "../../../components/modals/infoModal";
import SignOffModal from "../../../components/modals/inventory/signOffModal";
import "./myItems.scss";

class InventoryMyItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      showItemModal: false,
      selectedItem: null,
      showSignoffModal: false,
      userId: null,
    };
  }

  componentDidMount() {
    const refinedUid = this.getUserId();
    this.props.getItems({
      offset: 0,
      userId: refinedUid,
    });
    this.setState({ userId: refinedUid });
  }

  getUserId = () => {
    const query = new URLSearchParams(this.props.location.search);
    const userId = query.get("uid");
    const refinedUid =
      userId && this.hasAccessToSignOff()
        ? userId
        : this.props.user.userDetails.user.userId;
    return refinedUid;
  };

  getTableHeading = () => {
    return (
      <tr>
        <th>Category</th>
        <th>Product name</th>
        <th>Quantity</th>
        {/* <th>Available</th> */}
        <th>Added on</th>
        <th
          className={"align-center"}
        >{`Price (${this.props.user.userDetails.company.currency})`}</th>
        <th></th>
        <th></th>
      </tr>
    );
  };

  hasAccessToSignOff = () => {
    const userAccess = this.props.user.userDetails.accessRoles;
    if (
      userAccess.isAdmin ||
      userAccess.isInventoryAdmin ||
      userAccess.isInventoryManager ||
      userAccess.isSuperAdmin
    ) {
      return true;
    }
    return false;
  };

  getTableContent = (data) => {
    if (data.records) {
      return data.records.map((row, key) => {
        return (
          <tr key={key} className="value-row">
            <td>{row.inventoryMainCategory.name}</td>
            <td>{row.name}</td>
            <td>{row.quantity}</td>
            {/* <td>{row.available}</td> */}
            <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
            <td className="Inventory__itemCost">{row.cost}</td>
            <td className="Inventory__my-list-item-actions">
              <ButtonGroup size="sm">
                <Button
                  onClick={() =>
                    this.setState({ showItemModal: true, selectedItem: row })
                  }
                  size="sm"
                >
                  View Item
                </Button>
                {this.hasAccessToSignOff() ? (
                  <Button
                    onClick={() =>
                      this.setState({
                        showSignoffModal: true,
                        selectedItem: row,
                      })
                    }
                    size="sm"
                    color="blue"
                  >
                    Sign Off
                  </Button>
                ) : null}
              </ButtonGroup>
            </td>
          </tr>
        );
      });
    }
  };

  closeItemModal = () => {
    this.setState({ selectedItem: null, showItemModal: false });
  };

  closeSignOffModal = () => {
    this.setState({ selectedItem: null, showSignoffModal: false });
  };

  render() {
    const classes = new bemClass("InventoryMyItems");
    return (
      <Container className={classes.elem("wrapper").b()}>
        {this.state.showItemModal ? (
          <InfoModal
            openModal={this.state.showItemModal}
            toggle={this.closeItemModal}
            title={"View Item"}
            body={<InventoryItemInfo item={this.state.selectedItem} />}
          />
        ) : null}
        {this.state.showSignoffModal ? (
          <SignOffModal
            openModal={this.state.showSignoffModal}
            title={
              `Sign Off: ${this.state.selectedItem.name}` || "Sign Off Item"
            }
            item={this.state.selectedItem}
            userId={this.state.userId}
            onClose={this.closeSignOffModal}
          />
        ) : null}
        <Row>
          <Col sm={7} className="text-left">
            <h2 className="list-header-title">Items</h2>
          </Col>
          <Col sm={5} className="text-right"></Col>

          <Col sm={12}>
            <div className="hr"></div>
            {this.props.items.loading ? (
              <div className={classes.elem("loader").b()}>
                <Loader />
              </div>
            ) : this.props.items.data.records ? (
              <React.Fragment>
                <CustomTable
                  content={this.getTableContent(this.props.items.data)}
                  tableHeadings={this.getTableHeading()}
                  customHeaders
                />
                <Paginator
                  style={{ padding: "10px 0" }}
                  limit={10}
                  offset={this.state.offset}
                  total={this.props.items.data.count}
                  align="center"
                  onChange={(offset) => {
                    this.props.getItems({
                      page: offset,
                      limit: 5,
                      offset: offset,
                      userId: this.getUserId(),
                    });
                    this.setState({ offset: offset });
                  }}
                />
              </React.Fragment>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCategories: inventoryModule.categoryModule.actions.getCategories,
      createItem: inventoryModule.itemModule.actions.createItem,
      getItems: inventoryModule.itemModule.actions.getItems,
      updateItem: inventoryModule.itemModule.actions.updateItem,
      deleteItem: inventoryModule.itemModule.actions.deleteItem,
      getUserList: userModule.actions.getUserList,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    categories: state.inventoryMainCategories,
    creating: state.inventoryCreateItem,
    items: state.inventoryItems,
    userList: state.userList,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(InventoryMyItems);
