export const GET_INVENTORY_TYPES_REQUEST = "GET_INVENTORY_TYPES_REQUEST";
export const GET_INVENTORY_TYPES_SUCCESS = "GET_INVENTORY_TYPES_SUCCESS";
export const GET_INVENTORY_TYPES_FAILURE = "GET_INVENTORY_TYPES_FAILURE";

export const CREATE_INVENTORY_TYPE_REQUEST = "CREATE_INVENTORY_TYPE_REQUEST";
export const CREATE_INVENTORY_TYPE_SUCCESS = "CREATE_INVENTORY_TYPE_SUCCESS";
export const CREATE_INVENTORY_TYPE_FAILURE = "CREATE_INVENTORY_TYPE_FAILURE";

export const UPDATE_INVENTORY_TYPE_REQUEST = "UPDATE_INVENTORY_TYPE_REQUEST";
export const UPDATE_INVENTORY_TYPE_SUCCESS = "UPDATE_INVENTORY_TYPE_SUCCESS";
export const UPDATE_INVENTORY_TYPE_FAILURE = "UPDATE_INVENTORY_TYPE_FAILURE";
