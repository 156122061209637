import React, { Component } from "react";
import { Container, Row, Col, Card, CardText } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa";
import Button from "../../components/ui-components/Button";
import bemClass from "../../components/ui-components/Utils/bemClass";
import ToolTip from "../../components/toolTip";
import "./landingPage.scss";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserModal: false,
    };
  }
  componentDidMount = () => {
    if (!this.props.isLoggedIn) {
      return <Redirect to={"/"} />;
    }
  };

  render() {
    const classes = new bemClass("LandingPage");
    const accessRoles = this.props.user && this.props.user.accessRoles;
    const inventoryDashboardAccess =
      accessRoles &&
      (accessRoles.isInventoryAdmin ||
        accessRoles.isSuperAdmin ||
        accessRoles.isAdmin);
    return (
      <Container fluid className="landing-page">
        <Row>
          <Col md={12} className={classes.elem("logout").b()}>
            <Button
              color="white"
              type="link"
              id={`log-out`}
              onClick={() => this.props.logoutAction()}
            >
              <FaPowerOff />
            </Button>
            <ToolTip position="top" tip="Logout" rowId={`log-out`} />
          </Col>
        </Row>
        <Row>
          <Col
            sm={12}
            md={{ size: 10, offset: 1 }}
            className="landing-wrapper text-center"
          >
            <Row>
              {/* <Col sm={4}>
                <Link to="/hrm/mentorship">
                  <Card body inverse>
                    <CardText>HRM</CardText>
                  </Card>
                </Link>
              </Col> */}
              {/* <Col sm={3}>
                <Link to="/time-track/dashboard">
                  <Card body inverse>
                    <CardText>Time Tracking</CardText>
                  </Card>
                </Link>
              </Col> */}
              <Col sm={6}>
                <Link
                  to={
                    inventoryDashboardAccess
                      ? "/inventory/dashboard"
                      : "/inventory/my-items"
                  }
                >
                  <Card body inverse>
                    <CardText>Inventory</CardText>
                  </Card>
                </Link>
              </Col>
              <Col sm={6}>
                <Link to="/administration/listusers">
                  <Card body inverse>
                    <CardText>Administration</CardText>
                  </Card>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.loggedUser.userDetails,
  };
}

export default connect(mapStateToProps, null)(LandingPage);
