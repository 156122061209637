import React from "react";
import ReactDOM from "react-dom";
import App from "./views/layouts/defaultLayout/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { history, store, persistor } from "./redux/store";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/lib/integration/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/_index.scss";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import { LOGOUT_REQUEST } from "./redux/user/actionTypes";
const { dispatch } = store;
// Use only in developemnt
// persistor.purge();

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      toast.warning("Your session has been expired. Please login again!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch({ type: LOGOUT_REQUEST });
    } else {
      return Promise.reject(error);
    }
  }
);

ReactDOM.render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </PersistGate>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
