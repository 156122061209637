import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_MAIN_ENTRY_REPORT_REQUEST,
  GET_MAIN_ENTRY_REPORT_SUCCESS,
  GET_MAIN_ENTRY_REPORT_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";

export function* reportSaga() {
  yield takeLatest(GET_MAIN_ENTRY_REPORT_REQUEST, getMainEntryReport);
}

function* getMainEntryReport(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const reportData = yield call(
      apis.timeTrackApis.getMainEntryReport,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (reportData.error) {
      yield put({
        type: GET_MAIN_ENTRY_REPORT_FAILURE,
        error: reportData.body.data.error,
      });
    } else {
      yield put({
        type: GET_MAIN_ENTRY_REPORT_SUCCESS,
        data: reportData.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
