import { USER_DEPARTMENT_REQUEST, USER_DEPARTMENT_SUCCESS, USER_DEPARTMENT_FAILURE } from '../actionTypes';

const initialState = {
  loading: true,
  departments: null,
  error: null,
};

const userDepartmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        departments: null,
        error: null
      };
    case USER_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departments: action.data,
        error: null
      };
    case USER_DEPARTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        departments: null,
        error: action.data
      };
    default:
      return state;
  }
};

export default userDepartmentReducer;