import { SUPERVISOR_LEVELS_REQUEST, SUPERVISOR_LEVELS_SUCCESS, SUPERVISOR_LEVELS_FAILURE } from '../actionTypes';

const initialState = {
  loading: true,
  data: null,
  error: null,
};

const supervisorLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPERVISOR_LEVELS_REQUEST:
      return {
        ...state,
        loading: true,
        data: null,
        error: null
      };
    case SUPERVISOR_LEVELS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null
      };
    case SUPERVISOR_LEVELS_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.error
      };
    default:
      return state;
  }
};

export default supervisorLevelReducer;