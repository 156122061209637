import React from "react";
import PropTypes from "prop-types";
import "./Loader.scss";

const Loader = ({ className, noU, dark, size, ...props }) => {
  let classArr = (className || "").split(" ");
  classArr.push("Loader");

  const svg = `<svg width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#003162">
  <g fill="none" fill-rule="evenodd" stroke-width="2">
      <circle cx="22" cy="22" r="1">
          <animate attributeName="r"
              begin="0s" dur="1.8s"
              values="1; 20"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.165, 0.84, 0.44, 1"
              repeatCount="indefinite" />
          <animate attributeName="stroke-opacity"
              begin="0s" dur="1.8s"
              values="1; 0"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.3, 0.61, 0.355, 1"
              repeatCount="indefinite" />
      </circle>
      <circle cx="22" cy="22" r="1">
          <animate attributeName="r"
              begin="-0.9s" dur="1.8s"
              values="1; 20"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.165, 0.84, 0.44, 1"
              repeatCount="indefinite" />
          <animate attributeName="stroke-opacity"
              begin="-0.9s" dur="1.8s"
              values="1; 0"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.3, 0.61, 0.355, 1"
              repeatCount="indefinite" />
      </circle>
  </g>
</svg>`;

  if (dark) {
    classArr.push("dark");
  } else {
    classArr.push("light");
  }

  classArr.push("Loader--" + size);

  return (
    <div
      className={classArr.join(" ").trim()}
      dangerouslySetInnerHTML={{
        __html: svg,
      }}
      {...props}
    ></div>
  );
};

Loader.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  noU: PropTypes.bool,
  dark: PropTypes.bool,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
};

Loader.defaultProps = {
  noU: false,
  size: "md",
};

export default Loader;
