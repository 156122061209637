import * as actions from "./actionTypes";

export const createEntry = (data) => ({
  type: actions.CREATE_ENTRY_REQUEST,
  payload: data,
});

export const getEntrieForDate = (data) => ({
  type: actions.GET_ENTRIES_FOR_DAY_REQUEST,
  payload: data,
});

export const updateEntry = (data) => ({
  type: actions.UPDATE_ENTRY_REQUEST,
  payload: data,
});

export const deleteEntry = (data) => ({
  type: actions.DELETE_ENTRY_REQUEST,
  payload: data,
});
