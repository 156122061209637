import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_PERMISSION_TYPES_REQUEST,
  GET_PERMISSION_TYPES_SUCCESS,
  GET_PERMISSION_TYPES_FAILURE,
} from "../actionTypes";
import apis from "../../../api";

export function* permissionTypeSaga() {
  yield takeLatest(GET_PERMISSION_TYPES_REQUEST, getPermissionTypes);
}

function* getPermissionTypes(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const permissionTypes = yield call(
      apis.timeTrackApis.getPermissionTypes,
      loggedUser.userDetails.auth.accessToken,
      details.payload.module
    );
    if (permissionTypes.error) {
      yield put({
        type: GET_PERMISSION_TYPES_FAILURE,
        error: permissionTypes.body.data.error,
      });
    } else {
      yield put({
        type: GET_PERMISSION_TYPES_SUCCESS,
        data: permissionTypes.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
