export const GET_HRM_PERFORMANCE_SURVEY_REQUEST =
  "GET_HRM_PERFORMANCE_SURVEY_REQUEST";
export const GET_HRM_PERFORMANCE_SURVEY_SUCCESS =
  "GET_HRM_PERFORMANCE_SURVEY_SUCCESS";
export const GET_HRM_PERFORMANCE_SURVEY_FAILURE =
  "GET_HRM_PERFORMANCE_SURVEY_FAILURE";

export const CREATE_HRM_PERFORMANCE_SURVEY_REQUEST =
  "CREATE_HRM_PERFORMANCE_SURVEY_REQUEST";
export const CREATE_HRM_PERFORMANCE_SURVEY_SUCCESS =
  "CREATE_HRM_PERFORMANCE_SURVEY_SUCCESS";
export const CREATE_HRM_PERFORMANCE_SURVEY_FAILURE =
  "CREATE_HRM_PERFORMANCE_SURVEY_FAILURE";

export const GET_HRM_PERFORMANCE_SURVEY_INFO_REQUEST =
  "GET_HRM_PERFORMANCE_SURVEY_INFO_REQUEST";
export const GET_HRM_PERFORMANCE_SURVEY_INFO_SUCCESS =
  "GET_HRM_PERFORMANCE_SURVEY_INFO_SUCCESS";
export const GET_HRM_PERFORMANCE_SURVEY_INFO_FAILURE =
  "GET_HRM_PERFORMANCE_SURVEY_INFO_FAILURE";

export const RESET_HRM_PERFORMANCE_SURVEY_REQUEST =
  "RESET_HRM_PERFORMANCE_SURVEY_REQUEST";

export const GET_HRM_PERFORMANCE_SURVEY_DETAILS_REQUEST =
  "GET_HRM_PERFORMANCE_SURVEY_DETAILS_REQUEST";
export const GET_HRM_PERFORMANCE_SURVEY_DETAILS_SUCCESS =
  "GET_HRM_PERFORMANCE_SURVEY_DETAILS_SUCCESS";
export const GET_HRM_PERFORMANCE_SURVEY_DETAILS_FAILURE =
  "GET_HRM_PERFORMANCE_SURVEY_DETAILS_FAILURE";

export const SUBMIT_HRM_PERFORMANCE_SURVEY_FEEDBACK_REQUEST =
  "SUBMIT_HRM_PERFORMANCE_SURVEY_FEEDBACK_REQUEST";
export const SUBMIT_HRM_PERFORMANCE_SURVEY_FEEDBACK_SUCCESS =
  "SUBMIT_HRM_PERFORMANCE_SURVEY_FEEDBACK_SUCCESS";
export const SUBMIT_HRM_PERFORMANCE_SURVEY_FEEDBACK_FAILURE =
  "SUBMIT_HRM_PERFORMANCE_SURVEY_FEEDBACK_FAILURE";

export const GET_HRM_PERFORMANCE_SIGNLE_SURVEY_INFO_REQUEST =
  "GET_HRM_PERFORMANCE_SIGNLE_SURVEY_INFO_REQUEST";
export const GET_HRM_PERFORMANCE_SIGNLE_SURVEY_INFO_SUCCESS =
  "GET_HRM_PERFORMANCE_SIGNLE_SURVEY_INFO_SUCCESS";
export const GET_HRM_PERFORMANCE_SIGNLE_SURVEY_INFO_FAILURE =
  "GET_HRM_PERFORMANCE_SIGNLE_SURVEY_INFO_FAILURE";

export const GET_HRM_PERFORMANCE_REPORT_REQUEST =
  "GET_HRM_PERFORMANCE_REPORT_REQUEST";
export const GET_HRM_PERFORMANCE_REPORT_SUCCESS =
  "GET_HRM_PERFORMANCE_REPORT_SUCCESS";
export const GET_HRM_PERFORMANCE_REPORT_FAILURE =
  "GET_HRM_PERFORMANCE_REPORT_FAILURE";

export const GET_HRM_PERFORMANCE_SURVEY_PAPER_REQUEST =
  "GET_HRM_PERFORMANCE_SURVEY_PAPER_REQUEST";
export const GET_HRM_PERFORMANCE_SURVEY_PAPER_SUCCESS =
  "GET_HRM_PERFORMANCE_SURVEY_PAPER_SUCCESS";
export const GET_HRM_PERFORMANCE_SURVEY_PAPER_FAILURE =
  "GET_HRM_PERFORMANCE_SURVEY_PAPER_FAILURE";

export const ARCHIVE_HRM_PERFORMANCE_SURVEY_REQUEST =
  "ARCHIVE_HRM_PERFORMANCE_SURVEY_REQUEST";
export const ARCHIVE_HRM_PERFORMANCE_SURVEY_SUCCESS =
  "ARCHIVE_HRM_PERFORMANCE_SURVEY_SUCCESS";
export const ARCHIVE_HRM_PERFORMANCE_SURVEY_FAILURE =
  "ARCHIVE_HRM_PERFORMANCE_SURVEY_FAILURE";
