import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/time-tracking`;

export const getTasks = async (token) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/task`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createTask = async (token, taskDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/task`,
    headers: {
      "x-access-token": token,
    },
    data: taskDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const updateTask = async (token, taskDetails) => {
  return axios({
    method: "PUT",
    url: `${apiUrl}/task/${taskDetails.taskId}`,
    headers: {
      "x-access-token": token,
    },
    data: taskDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const deleteTask = async (token, taskId) => {
  return axios({
    method: "DELETE",
    url: `${apiUrl}/task/${taskId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
