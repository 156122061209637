import {
  DELETE_ENTRY_REQUEST,
  DELETE_ENTRY_SUCCESS,
  DELETE_ENTRY_FAILURE,
} from "../../entry/actionTypes";
import {
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
} from "../../category/actionTypes";
import {
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
} from "../../client/actionTypes";
import {
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
} from "../../project/actionTypes";
import {
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
} from "../../task/actionTypes";
import {
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from "../../user/actionTypes";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const deleteItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_ENTRY_REQUEST:
    case DELETE_CATEGORY_REQUEST:
    case DELETE_CLIENT_REQUEST:
    case DELETE_PROJECT_REQUEST:
    case DELETE_TASK_REQUEST:
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case DELETE_ENTRY_SUCCESS:
    case DELETE_CATEGORY_SUCCESS:
    case DELETE_CLIENT_SUCCESS:
    case DELETE_PROJECT_SUCCESS:
    case DELETE_TASK_SUCCESS:
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case DELETE_ENTRY_FAILURE:
    case DELETE_CATEGORY_FAILURE:
    case DELETE_CLIENT_FAILURE:
    case DELETE_PROJECT_FAILURE:
    case DELETE_TASK_FAILURE:
    case DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default deleteItemReducer;
