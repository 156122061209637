import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_INVENTORY_CATEGORIES_REQUEST,
  GET_INVENTORY_CATEGORIES_SUCCESS,
  GET_INVENTORY_CATEGORIES_FAILURE,
  CREATE_INVENTORY_CATEGORY_REQUEST,
  CREATE_INVENTORY_CATEGORY_SUCCESS,
  CREATE_INVENTORY_CATEGORY_FAILURE,
  UPDATE_INVENTORY_CATEGORY_REQUEST,
  UPDATE_INVENTORY_CATEGORY_SUCCESS,
  UPDATE_INVENTORY_CATEGORY_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";
import { toast } from "react-toastify";
import _ from "lodash";

export function* categorySaga() {
  yield takeLatest(GET_INVENTORY_CATEGORIES_REQUEST, getCategories);
  yield takeLatest(CREATE_INVENTORY_CATEGORY_REQUEST, createCategory);
  yield takeLatest(UPDATE_INVENTORY_CATEGORY_REQUEST, updateCategory);
}

function* updateCategory(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const category = yield call(
      apis.inventoryApis.updateCategory,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (category.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: UPDATE_INVENTORY_CATEGORY_FAILURE,
        error: category.body.data.error,
      });
      if (_.isString(category.body.data.error)) {
        errorMessage = category.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({
        type: UPDATE_INVENTORY_CATEGORY_SUCCESS,
        data: category.body.data,
      });
      yield put({ type: GET_INVENTORY_CATEGORIES_REQUEST });
      toast.success("Category updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createCategory(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const category = yield call(
      apis.inventoryApis.createCategory,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (category.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: CREATE_INVENTORY_CATEGORY_FAILURE,
        error: category.body.data.error,
      });
      if (_.isString(category.body.data.error)) {
        errorMessage = category.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({
        type: CREATE_INVENTORY_CATEGORY_SUCCESS,
        data: category.body.data,
      });
      yield put({ type: GET_INVENTORY_CATEGORIES_REQUEST });
      toast.success("Category created successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getCategories() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const categories = yield call(
      apis.inventoryApis.getCategories,
      loggedUser.userDetails.auth.accessToken
    );
    if (categories.error) {
      yield put({
        type: GET_INVENTORY_CATEGORIES_FAILURE,
        error: categories.body.data.error,
      });
    } else {
      yield put({
        type: GET_INVENTORY_CATEGORIES_SUCCESS,
        data: categories.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
