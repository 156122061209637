export const GET_CLIENTS_REQUEST = "GET_CLIENTS_REQUEST";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE";

export const CREATE_CLIENT_REQUEST = "CREATE_CLIENT_REQUEST";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAILURE = "CREATE_CLIENT_FAILURE";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";

export const CREATE_CONTACT_REQUEST = "CREATE_CONTACT_REQUEST";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_FAILURE = "CREATE_CONTACT_FAILURE";
