import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../ui-components/Button";
import Input from "../../../inputNew";
import FormFeedBack from "../../../ui-components/FormFeedBack";
import Textarea from "../../../ui-components/Textarea";
import Select from "react-select";
import { countries } from "countries-list";

const classes = new BEMhelper("AddClientModal");
const AddClientModal = (props) => {
  const { title, openModal, toggle, isEditMode, objectToEdit, submit } = props;
  let cur = null;
  const countryList = [];
  Object.values(countries).forEach((country) => {
    countryList.push({
      label: `${country.name} (${country.currency})`,
      value: country.currency,
    });
    if (isEditMode && objectToEdit.currency === country.currency) {
      cur = {
        label: `${country.name} (${country.currency})`,
        value: country.currency,
      };
    }
  });
  const defaultValues = {
    address: isEditMode ? objectToEdit.address : null,
    name: isEditMode ? objectToEdit.name : null,
    currency: cur,
  };
  const { handleSubmit, register, errors, control } = useForm({
    defaultValues,
  });
  const onSubmit = (values) => submit(values);

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => toggle()}
      centered={true}
      draggable={false}
      size="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => toggle()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    name="name"
                    register={register}
                    refdetails={{
                      required: "Client name required",
                    }}
                    state={errors.name ? "error" : "none"}
                  />
                  {errors.name ? (
                    <FormFeedBack feedBack={errors.name.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Currency</Label>
                  <Controller
                    as={
                      <Select
                        placeholder="Select currency"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: `1px solid ${
                              errors.currency ? "#dc3545" : "#cad2dd"
                            }`,
                          }),
                        }}
                      />
                    }
                    options={countryList}
                    name="currency"
                    isClearable
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    rules={{
                      required: "Curency is required",
                    }}
                  />
                  {errors.currency ? (
                    <FormFeedBack feedBack={errors.currency.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Address</Label>
                  <Textarea
                    name="address"
                    register={register}
                    refdetails={{
                      required: "Client address required",
                    }}
                    state={errors.address ? "error" : "none"}
                  />
                  {errors.address ? (
                    <FormFeedBack feedBack={errors.address.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button submit>Submit</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddClientModal;
