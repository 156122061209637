import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../../ui-components/Button";
import FormFeedBack from "../../../../ui-components/FormFeedBack";
import RadioItem from "../../../../ui-components/RadioItem";
import Select from "react-select";
import "./addFeedbackModal.scss";

const classes = new BEMhelper("AddFeedbackModal");
const AddFeedbackModal = (props) => {
  const { title, openModal, toggle, submit, users } = props;

  ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "insertTable",
        // "|",
        // "imageUpload",
        "|",
        "undo",
        "redo",
      ],
    },
    // image: {
    //   toolbar: [
    //     'imageStyle:full',
    //     'imageStyle:side',
    //     '|',
    //     'imageTextAlternative'
    //   ]
    // },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
  };

  const userOptions = users.map((user) => {
    return {
      value: user.userId,
      label: `${user.firstName} ${user.lastName}`,
    };
  });

  const defaultValues = {
    mentee: true,
  };
  const { handleSubmit, errors, control, watch, setValue } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    submit(values);
  };

  const feedbackWatcher = watch(["mentee", "mentor"]);

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => toggle()}
      centered={true}
      draggable={false}
      size="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => toggle()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Add feedback for</Label>
                  <Controller
                    name="mentee"
                    render={({ onChange }) => {
                      return (
                        <RadioItem
                          size="sm"
                          onChange={(value) => {
                            onChange(value);
                            setValue("mentor", false);
                          }}
                          label="Mentee"
                          value={true}
                          checked={
                            feedbackWatcher.mentee ||
                            (!feedbackWatcher.mentee && !feedbackWatcher.mentor)
                          }
                        />
                      );
                    }}
                    control={control}
                  />
                  <Controller
                    name="mentor"
                    render={({ onChange }) => {
                      return (
                        <RadioItem
                          size="sm"
                          onChange={(value) => {
                            onChange(value);
                            setValue("mentee", false);
                          }}
                          label="Mentor"
                          value={true}
                          checked={feedbackWatcher.mentor}
                        />
                      );
                    }}
                    control={control}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Colleague</Label>
                  <Controller
                    as={
                      <Select
                        placeholder={"Select colleague"}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: `1px solid ${
                              errors.colleague ? "#dc3545" : "#cad2dd"
                            }`,
                          }),
                        }}
                      />
                    }
                    options={userOptions}
                    name="colleague"
                    isClearable
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    rules={{
                      required: "Colleague is required",
                    }}
                  />
                  {errors.colleague ? (
                    <FormFeedBack feedBack={errors.colleague.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Feedback</Label>
                  <Controller
                    name="feedback"
                    render={({ onChange }) => {
                      return (
                        <CKEditor
                          editor={ClassicEditor}
                          onChange={(value, editor) => {
                            onChange(editor.getData());
                          }}
                        />
                      );
                    }}
                    control={control}
                    rules={{
                      required: "Feedback is required",
                      maxLength: {
                        value: 10000,
                        message: "Maximun length is 10000",
                      },
                    }}
                  />
                  {errors.feedback ? (
                    <FormFeedBack feedBack={errors.feedback.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button submit>Submit</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddFeedbackModal;
