import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/hrm`;

export const getFeedbacks = async (token, feedbackType) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/mentorship?feedbackType=${feedbackType}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createFeedback = async (token, feedbackDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/mentorship`,
    headers: {
      "x-access-token": token,
    },
    data: feedbackDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getAllUsersFeedbacks = async (token, userDetails = null) => {
  let query = "";
  if (userDetails && userDetails.userId) {
    query = `?userId=${userDetails.userId}`;
  }
  return axios({
    method: "GET",
    url: `${apiUrl}/mentorship/all${query}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
