import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_HRM_INTERVIEW_STAGES_REQUEST,
  GET_HRM_INTERVIEW_STAGES_SUCCESS,
  GET_HRM_INTERVIEW_STAGES_FAILURE,
  CREATE_HRM_INTERVIEW_STAGE_REQUEST,
  CREATE_HRM_INTERVIEW_STAGE_SUCCESS,
  CREATE_HRM_INTERVIEW_STAGE_FAILURE,
  UPDATE_HRM_INTERVIEW_STAGE_REQUEST,
  UPDATE_HRM_INTERVIEW_STAGE_SUCCESS,
  UPDATE_HRM_INTERVIEW_STAGE_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";
import { toast } from "react-toastify";
import _ from "lodash";

export function* interviewStageSaga() {
  yield takeLatest(GET_HRM_INTERVIEW_STAGES_REQUEST, getStages);
  yield takeLatest(CREATE_HRM_INTERVIEW_STAGE_REQUEST, createStage);
  yield takeLatest(UPDATE_HRM_INTERVIEW_STAGE_REQUEST, updateStage);
}

function* updateStage(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const stage = yield call(
      apis.hrmApis.updateStage,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (stage.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: UPDATE_HRM_INTERVIEW_STAGE_FAILURE,
        error: stage.body.data.error,
      });
      if (Array.isArray(stage.body.data.error)) {
        errorMessage = stage.body.data.error[0].msg;
      }
      if (_.isString(stage.body.data.error)) {
        errorMessage = stage.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({
        type: UPDATE_HRM_INTERVIEW_STAGE_SUCCESS,
        data: stage.body.data,
      });
      yield put({
        type: GET_HRM_INTERVIEW_STAGES_REQUEST,
      });
      toast.success("Stage updated", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createStage(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const stage = yield call(
      apis.hrmApis.createStage,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (stage.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: CREATE_HRM_INTERVIEW_STAGE_FAILURE,
        error: stage.body.data.error,
      });
      if (Array.isArray(stage.body.data.error)) {
        errorMessage = stage.body.data.error[0].msg;
      }
      if (_.isString(stage.body.data.error)) {
        errorMessage = stage.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({
        type: CREATE_HRM_INTERVIEW_STAGE_SUCCESS,
        data: stage.body.data,
      });
      yield put({
        type: GET_HRM_INTERVIEW_STAGES_REQUEST,
      });
      toast.success("Stage created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getStages() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const stages = yield call(
      apis.hrmApis.getStages,
      loggedUser.userDetails.auth.accessToken
    );
    if (stages.error) {
      yield put({
        type: GET_HRM_INTERVIEW_STAGES_FAILURE,
        error: stages.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_INTERVIEW_STAGES_SUCCESS,
        data: stages.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
