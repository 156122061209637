import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";

export function* userSaga() {
  yield takeLatest(GET_USERS_REQUEST, getUsers);
  yield takeLatest(CREATE_USER_REQUEST, createUser);
  yield takeLatest(UPDATE_USER_REQUEST, updateUser);
  yield takeLatest(DELETE_USER_REQUEST, deleteUser);
}

function* getUsers() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const users = yield call(
      apis.timeTrackApis.getUsers,
      loggedUser.userDetails.auth.accessToken
    );
    if (users.error) {
      yield put({
        type: GET_USERS_FAILURE,
        error: users.body.data.error,
      });
    } else {
      yield put({ type: GET_USERS_SUCCESS, data: users.body.data });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createUser(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const user = yield call(
      apis.timeTrackApis.createUser,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (user.error) {
      yield put({
        type: CREATE_USER_FAILURE,
        error: user.body.data.error,
      });
    } else {
      yield put({ type: CREATE_USER_SUCCESS, data: user.body.data });
      yield put({ type: GET_USERS_REQUEST });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* updateUser(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const user = yield call(
      apis.timeTrackApis.updateUser,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (user.error) {
      yield put({
        type: UPDATE_USER_FAILURE,
        error: user.body.data.error,
      });
    } else {
      yield put({ type: UPDATE_USER_SUCCESS, data: user.body.data });
      const data = {
        date: details.payload.formValues.date,
      };
      yield put({ type: GET_USERS_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* deleteUser(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const user = yield call(
      apis.timeTrackApis.deleteUser,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues.userId
    );
    if (user.error) {
      yield put({
        type: DELETE_USER_FAILURE,
        error: user.body.data.error,
      });
    } else {
      yield put({ type: DELETE_USER_SUCCESS, data: user.body.data });
      const data = {
        date: details.payload.formValues.date,
      };
      yield put({ type: GET_USERS_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
