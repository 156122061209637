import {
  ARCHIVE_HRM_PERFORMANCE_SURVEY_REQUEST,
  ARCHIVE_HRM_PERFORMANCE_SURVEY_SUCCESS,
  ARCHIVE_HRM_PERFORMANCE_SURVEY_FAILURE,
} from "../../survey/actionTypes";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const updateItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case ARCHIVE_HRM_PERFORMANCE_SURVEY_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case ARCHIVE_HRM_PERFORMANCE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case ARCHIVE_HRM_PERFORMANCE_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default updateItemReducer;
