export const USERLIST_REQUEST = "USERLIST_REQUEST";
export const USERLIST_SUCCESS = "USERLIST_SUCCESS";

export const SIGNUP_DATA_UPDATE = "SIGNUP_DATA_UPDATE";

export const GETUSER_REQUEST = "GETUSER_REQUEST";
export const GETUSER_SUCCESS = "GETUSER_SUCCESS";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const RESET_MY_PASSWORD_REQUEST = "RESET_MY_PASSWORD_REQUEST";
export const RESET_MY_PASSWORD_SUCCESS = "RESET_MY_PASSWORD_SUCCESS";
export const RESET_MY_PASSWORD_FAILURE = "RESET_MY_PASSWORD_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const GET_USER_ROLE_REQUEST = "GET_USER_ROLE_REQUEST";
export const GET_USER_ROLE_SUCCESS = "GET_USER_ROLE_SUCCESS";
export const GET_USER_ROLE_FAILURE = "GET_USER_ROLE_FAILURE";

export const GET_USER_DESIGNATION_REQUEST = "GET_USER_DESIGNATION_REQUEST";
export const GET_USER_DESIGNATION_SUCCESS = "GET_USER_DESIGNATION_SUCCESS";
export const GET_USER_DESIGNATION_FAILURE = "GET_USER_DESIGNATION_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const USER_DEPARTMENT_REQUEST = "USER_DEPARTMENT_REQUEST";
export const USER_DEPARTMENT_SUCCESS = "USER_DEPARTMENT_SUCCESS";
export const USER_DEPARTMENT_FAILURE = "USER_DEPARTMENT_FAILURE";

export const RESET_SINGLE_USER_REQUEST = "RESET_SINGLE_USER_REQUEST";
