import React from "react";
import { Button, Spinner } from "reactstrap";

const BtnNormal = (props) => {
  return (
    <React.Fragment>
      <Button
        onClick={props.onClick}
        className={props.class || "normal-btn"}
        style={props.style}
        disabled={props.disabled}
      >
        {props.loading ? <Spinner size="sm" color="light" /> : props.text}
      </Button>
    </React.Fragment>
  );
};

export default BtnNormal;
