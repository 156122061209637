import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import { Container, Row, Col, Alert } from "reactstrap";
import BtnNormal from "../../button";

const ApplyClaimModal = (props) => {
  const {
    className,
    title,
    openModal,
    toggle,
    onSubmit,
    applyingStatus,
    claimTypeList,
  } = props;

  const onValidSubmit = (event, values) => {
    onSubmit(values);
  };

  const onInvalidSubmit = (values) => {};

  const clearState = () => {
    toggle();
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        toggle={() => clearState()}
        className={className}
        size="lg"
      >
        <ModalHeader toggle={() => clearState()}>{title}</ModalHeader>
        <AvForm onValidSubmit={onValidSubmit} onInvalidSubmit={onInvalidSubmit}>
          <ModalBody>
            {claimTypeList.loading ? (
              <p>Add spinner here.....</p>
            ) : (
              <Container fluid className="add-user-wrapper">
                {applyingStatus.error ? (
                  <Row>
                    <Col sm={12}>
                      <Alert color="danger">
                        <p>{applyingStatus.error}</p>
                      </Alert>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label>Claim Type</Label>
                      <AvInput
                        type="select"
                        name="claimType"
                        id="claimType"
                        required
                      >
                        <option value="null">Claim type</option>
                        {claimTypeList.data.map((claimType, key) => {
                          return (
                            <option value={claimType.claimTypeId} key={key}>
                              {claimType.type}
                            </option>
                          );
                        })}
                      </AvInput>
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <AvGroup>
                      <Label>Claim Date</Label>
                      <AvInput
                        type="date"
                        name="claimDate"
                        id="claimDate"
                        required
                      />
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <AvGroup>
                      <Label>Claim Amount</Label>
                      <AvInput
                        type="number"
                        name="claimAmount"
                        id="claimAmount"
                        className="input"
                        placeholder="Claim amount"
                        required
                      />
                    </AvGroup>
                  </Col>
                  <Col sm={6}>
                    <AvGroup>
                      <Label>Remarks</Label>
                      <AvInput
                        name="claimRemarks"
                        type="textarea"
                        placeholder="Remarks"
                        required
                      />
                    </AvGroup>
                  </Col>
                </Row>
                {/* Hidden for now
                <Row>
                  <Col sm={12}>
                    <AvGroup>
                      <Label>Document</Label>
                      <AvInput name="claimDocument" type="file" />
                    </AvGroup>
                  </Col>
                </Row> */}
              </Container>
            )}
          </ModalBody>
          <ModalFooter>
            <BtnNormal
              color="primary"
              text="Apply"
              loading={applyingStatus.loading}
            />
          </ModalFooter>
        </AvForm>
      </Modal>
    </div>
  );
};

export default ApplyClaimModal;
