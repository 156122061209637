import React, { useState } from "react";
import {
  Collapse,
  CardText,
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardTitle,
  Badge,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import StarRatings from "react-star-ratings";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import "./singleFeedback.scss";

const SingleFeedback = (props) => {
  const { feedback } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const classes = new BEMhelper("feedbackCard");
  const reviews = [];
  Object.entries(feedback.questionList).forEach(([key, value]) => {
    reviews.push({ category: key, hrmCandidateQuestions: value });
  });

  const getBadgeColor = (status) => {
    if (status === "Pass") {
      return "success";
    } else {
      return "danger";
    }
  };

  return (
    <div {...classes("feedback")}>
      <Card body onClick={toggle}>
        <CardText>
          <ListGroup>
            <ListGroupItem>
              <Row>
                <Col md={8}>
                  <h4>
                    {feedback.feedback.user.firstName}{" "}
                    {feedback.feedback.user.lastName}
                  </h4>
                </Col>
                <Col md={4} {...classes("startRating")}>
                  <h6>
                    <p {...classes("interviewStage")}>
                      {feedback.feedback.hrmInterviewStage.name}
                    </p>
                    <Badge color={getBadgeColor(feedback.feedback.status)}>
                      {feedback.feedback.status}
                    </Badge>
                    {isOpen ? <FaArrowUp /> : <FaArrowDown />}
                  </h6>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </CardText>
      </Card>
      <Collapse isOpen={isOpen}>
        <Card body>
          {reviews.map((category, key) => {
            return (
              <div {...classes(`${key > 0 ? "review-card" : ""}`)}>
                <CardTitle>{category.category}</CardTitle>
                <CardText>
                  <ListGroup>
                    {category.hrmCandidateQuestions.map(
                      (question, questionKey) => {
                        return (
                          <ListGroupItem>
                            <Row>
                              <Col md={9}>
                                <p>{question.hrmCandidateQuestion.question}</p>
                              </Col>
                              <Col md={3} {...classes("startRating")}>
                                <StarRatings
                                  rating={question.rating || 0}
                                  starRatedColor="blue"
                                  numberOfStars={5}
                                  name="rating"
                                  starDimension="20px"
                                  starSpacing="5px"
                                  starRatedColor="#052a4f"
                                />
                              </Col>
                            </Row>
                          </ListGroupItem>
                        );
                      }
                    )}
                  </ListGroup>
                </CardText>
              </div>
            );
          })}
          <div {...classes("review-card")}>
            <CardTitle>Overall rating</CardTitle>
            <CardText>
              <ListGroup>
                <ListGroupItem>
                  <Row>
                    <Col md={9}>
                      <p>Overall rating</p>
                    </Col>
                    <Col md={3} {...classes("startRating")}>
                      <StarRatings
                        rating={feedback.feedback.overall}
                        starRatedColor="blue"
                        numberOfStars={5}
                        name="rating"
                        starDimension="20px"
                        starSpacing="5px"
                        starRatedColor="#052a4f"
                      />
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardText>
          </div>
          <div {...classes("review-card")}>
            <CardTitle>Comments</CardTitle>
            <CardText>
              <ListGroup>
                <ListGroupItem>
                  <Row>
                    <Col md={12}>
                      <p>{feedback.feedback.comments || "-"}</p>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardText>
          </div>
        </Card>
      </Collapse>
    </div>
  );
};

export default SingleFeedback;
