import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import inventoryModule from "../../../../redux/inventory";
import { Container, Row, Col, ButtonGroup } from "reactstrap";
import moment from "moment";
import { FaPlus } from "react-icons/fa";
import CustomTable from "../../../components/table";
import Loader from "../../../components/ui-components/Loader";
import Button from "../../../components/ui-components/Button";
import bemClass from "../../../components/ui-components/Utils/bemClass";
import AddItemTypeModal from "../../../components/modals/inventory/addItemTypeModal";
import "./typeList.scss";

class InventoryTypeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddTypeModal: false,
      selectedType: {},
      editMode: false,
    };
  }

  componentDidMount() {
    this.props.getTypes();
  }

  getTableHeading = () => {
    return (
      <tr>
        <th>Name</th>
        <th>Added on</th>
        <th></th>
      </tr>
    );
  };

  getTableContent = (data) => {
    return (data || []).map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>{row.name}</td>
          <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
          <td className="Inventory__type-list-actions">
            <ButtonGroup size="sm">
              <Button
                onClick={() =>
                  this.setState({
                    editMode: true,
                    showAddTypeModal: true,
                    selectedType: row,
                  })
                }
                size="sm"
              >
                Edit
              </Button>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  closeAddItemType = () => {
    this.setState({
      showAddTypeModal: false,
      editMode: false,
      selectedType: {},
    });
  };

  render() {
    const classes = new bemClass("InventoryTypeList");
    return (
      <Container className={classes.elem("wrapper").b()}>
        {this.state.showAddTypeModal ? (
          <AddItemTypeModal
            openModal={this.state.showAddTypeModal}
            onClose={this.closeAddItemType}
            isEditMode={this.state.editMode}
            objectToEdit={this.state.selectedType}
            title={this.state.editMode ? "Edit Item Type" : "Add Item Type"}
          />
        ) : null}
        <Row>
          <Col sm={7} className="text-left">
            <h2 className="list-header-title">Item Types</h2>
          </Col>
          <Col sm={5} className="text-right">
            <Button
              color="deep-blue"
              onClick={() => {
                this.setState({ showAddTypeModal: true, editMode: false });
              }}
            >
              <FaPlus /> Create Type
            </Button>
          </Col>

          <Col sm={12}>
            <div className="hr"></div>
            {this.props.types.loading ? (
              <div className={classes.elem("loader").b()}>
                <Loader />
              </div>
            ) : this.props.types.data ? (
              <React.Fragment>
                <CustomTable
                  content={this.getTableContent(this.props.types.data)}
                  tableHeadings={this.getTableHeading()}
                  customHeaders
                />
              </React.Fragment>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTypes: inventoryModule.typeModule.actions.getTypes,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    types: state.inventoryItemTypes,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(InventoryTypeList);
