import React from "react";
import PropTypes from "prop-types";
import "./Tabs.scss";
import Tab from "../Tab";
import bemClass from "../Utils/bemClass";

const colors = ["white", "blue", "bright-blue", "medium-purple"];
const sizes = ["lg", "md", "sm"];
const alignments = ["left", "center", "right"];
const propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(Tab)]),
  color: PropTypes.oneOf(colors).isRequired,
  align: PropTypes.oneOf(alignments).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  selectedRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  size: PropTypes.oneOf(sizes).isRequired,
  noFlex: PropTypes.bool,
  noTransition: PropTypes.bool,
  showBorderOnHover: PropTypes.bool,
};

const defaultProps = {
  className: "",
  color: "white",
  align: "center",
  selected: null,
  onChange: () => {},
  size: "md",
  showBorderOnHover: false,
};

class Tabs extends React.Component {
  constructor(props) {
    super(props);

    this.updateSliderPosition = () => {
      if (this.props.selected) {
        const [selectedElem] = this.ref.querySelectorAll(".Tab--selected");
        if (!selectedElem) {
          return;
        }
        const rect = selectedElem.getBoundingClientRect();
        var childPos = selectedElem.offsetLeft;
        this.sliderRef.style.width = rect.width + "px";
        this.sliderRef.style.left = childPos + "px";
      }
    };
  }

  componentDidMount() {
    this.updateSliderPosition();
  }

  componentDidUpdate() {
    this.updateSliderPosition();
  }

  render() {
    const {
      className,
      children,
      color,
      align,
      selected: selectedId,
      size,
      noFlex,
      showBorderOnHover,
      noTransition,
      selectedRef,
      ...props
    } = this.props;
    const tabsClass = new bemClass("Tabs", className);
    const tabsModMap = {
      [color]: color,
      [size]: size,
      "no-flex": noFlex,
      "no-transition": noTransition,
    };
    const tabModMap = {
      "hover-border": showBorderOnHover,
      [`align-${align}`]: align,
      [size]: size,
    };

    // Map tabs
    const tabs = React.Children.map(children, (child) => {
      if (!child) {
        return null;
      }
      const childClass = new bemClass("Tab", child.props.className);
      const childProps = {
        className: childClass.mod(tabModMap).b(),
        selected: false,
        onClick: this.props.onChange.bind(null, child.props.id),
      };

      childProps.color = color;

      if (noFlex) {
        childProps.noFlex = true;
      }

      if (selectedId && child.props.id && child.props.id === selectedId) {
        childProps.selected = true;
        childProps.selectedRef = selectedRef;
      }
      return React.cloneElement(child, childProps);
    });

    return (
      <div
        ref={(r) => (this.ref = r)}
        className={tabsClass.mod(tabsModMap).b()}
        {...props}
      >
        {tabs}
        <div
          ref={(r) => (this.sliderRef = r)}
          className="Tabs__selected-slider"
        ></div>
      </div>
    );
  }
}

Tabs.colors = colors;
Tabs.sizes = sizes;
Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default Tabs;
