import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  USERLIST_REQUEST,
  USERLIST_SUCCESS,
  GETUSER_REQUEST,
  GETUSER_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GET_USER_ROLE_FAILURE,
  GET_USER_ROLE_SUCCESS,
  GET_USER_DESIGNATION_FAILURE,
  GET_USER_DESIGNATION_SUCCESS,
  GET_USER_ROLE_REQUEST,
  GET_USER_DESIGNATION_REQUEST,
  USER_DEPARTMENT_REQUEST,
  USER_DEPARTMENT_SUCCESS,
  USER_DEPARTMENT_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_MY_PASSWORD_REQUEST,
  RESET_MY_PASSWORD_SUCCESS,
  RESET_MY_PASSWORD_FAILURE,
  LOGOUT_REQUEST,
} from "../actionTypes";
import apis from "../../../api";
import { push } from "connected-react-router";
import { toast } from "react-toastify";

export function* userSaga() {
  yield takeLatest(USERLIST_REQUEST, getUserList);
  yield takeLatest(GETUSER_REQUEST, singleUser);
  yield takeLatest(LOGIN_REQUEST, loginUser);
  yield takeLatest(GET_USER_ROLE_REQUEST, getUserRole);
  yield takeLatest(GET_USER_DESIGNATION_REQUEST, getUserDesignation);
  yield takeLatest(USER_DEPARTMENT_REQUEST, getUserDepartment);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(RESET_MY_PASSWORD_REQUEST, resetMyPassword);
}

function* resetMyPassword(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const resetPassword = yield call(
      apis.resetMyPassword,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (resetPassword.error) {
      yield put({
        type: RESET_MY_PASSWORD_FAILURE,
        data: resetPassword.body.data.error,
      });
    } else {
      yield put({
        type: RESET_MY_PASSWORD_SUCCESS,
        data: resetPassword.body.data,
      });
      toast.success(resetPassword.body.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put({ type: LOGOUT_REQUEST });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* resetPassword(details) {
  try {
    const resetUser = yield call(apis.resetPassword, details.payload);
    if (resetUser.error) {
      yield put({
        type: RESET_PASSWORD_FAILURE,
        data: resetUser.body.data.error,
      });
    } else {
      yield put({ type: RESET_PASSWORD_SUCCESS, data: resetUser.body.data });
      toast.success(resetUser.body.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(push("/"));
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* forgotPassword(details) {
  try {
    const emailUser = yield call(apis.forgotPassword, details.payload);
    if (emailUser.error) {
      yield put({
        type: FORGOT_PASSWORD_FAILURE,
        data: emailUser.body.data.error,
      });
    } else {
      yield put({ type: FORGOT_PASSWORD_SUCCESS, data: emailUser.body.data });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getUserList() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const userList = yield call(
      apis.getUserList,
      loggedUser.userDetails.auth.accessToken
    );
    yield put({ type: USERLIST_SUCCESS, data: userList.data });
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* singleUser(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const user = yield call(
      apis.getUserById,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    yield put({ type: GETUSER_SUCCESS, data: user.data });
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* loginUser(details) {
  try {
    const userList = yield call(apis.login, details.payload);
    if (userList.error) {
      yield put({ type: LOGIN_FAILURE, data: userList.body.data.error });
    } else {
      yield put({ type: LOGIN_SUCCESS, data: userList.body.data });
      yield put(push("/landing"));
    }
  } catch (error) {
    yield put({ type: LOGIN_FAILURE, data: "Internal server error" });
    console.log("oops error -> ", error);
  }
}

function* getUserRole(details) {
  try {
    const userList = yield call(apis.getRoles, details.payload);
    if (userList.error) {
      yield put({
        type: GET_USER_ROLE_FAILURE,
        data: userList.body.data.error,
      });
    } else {
      yield put({ type: GET_USER_ROLE_SUCCESS, data: userList.body.data });
      // yield put(push('/dashboard'));
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getUserDesignation(details) {
  try {
    const userList = yield call(apis.getDesignation, details.payload);
    if (userList.error) {
      yield put({
        type: GET_USER_DESIGNATION_FAILURE,
        data: userList.body.data.error,
      });
    } else {
      yield put({
        type: GET_USER_DESIGNATION_SUCCESS,
        data: userList.body.data,
      });
      // yield put(push('/dashboard'));
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getUserDepartment() {
  try {
    const departmentList = yield call(apis.getDepartmentList);
    if (departmentList.error) {
      yield put({
        type: USER_DEPARTMENT_FAILURE,
        data: departmentList.body.data.error,
      });
    } else {
      yield put({
        type: USER_DEPARTMENT_SUCCESS,
        data: departmentList.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
