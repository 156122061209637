import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import hrmModule from "../../../../../../redux/hrm";
import Button from "../../../../../components/ui-components/Button";
import BEMhelper from "react-bem-helper";
import { FaPlus, FaEdit } from "react-icons/fa";
import { Container, Row, Col, ButtonGroup } from "reactstrap";
import ToolTip from "../../../../../components/toolTip";
import CustomTable from "../../../../../components/table";
import AddInterviewStageModal from "../../../../../components/modals/hrm/recruitment/interviewStage/addInterviewStage";
import moment from "moment";
import "./interviewStages.scss";

class InterviewStages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStageModal: false,
      selectedStage: null,
      editMode: false,
    };
  }

  componentDidMount() {
    this.props.getStages();
  }

  getStageList = (stages) => {
    const loggedUserAccess = this.props.user.userDetails.accessRoles;
    if (stages && stages.length > 0) {
      return stages.map((row, key) => {
        return (
          <tr key={key} className="value-row">
            <td>{row.name}</td>
            <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
            <td className="stage-actions">
              {loggedUserAccess.isAdmin ||
              loggedUserAccess.isHrmAdmin ||
              loggedUserAccess.isSuperAdmin ? (
                <ButtonGroup size="sm">
                  <Button
                    size="sm"
                    onClick={() => {
                      this.setState({
                        showStageModal: true,
                        selectedStage: row,
                        editMode: true,
                      });
                    }}
                    color="bright-blue"
                    id={`edit-${key}`}
                  >
                    <FaEdit />
                  </Button>
                  <ToolTip
                    position="top"
                    tip="Edit Stage"
                    rowId={`edit-${key}`}
                  />
                </ButtonGroup>
              ) : null}
            </td>
          </tr>
        );
      });
    }
    return [];
  };

  closeStageModal = () => {
    this.setState({
      showStageModal: false,
      editMode: false,
      selectedStage: null,
    });
  };

  addStage = (values) => {
    const data = {
      formValues: {
        name: values.name,
        ...(this.state.editMode && {
          stageId: this.state.selectedStage.hrmInterviewStageId,
        }),
      },
      closeModal: this.closeStageModal,
    };
    if (this.state.editMode) {
      this.props.updateStage(data);
    } else {
      this.props.createStage(data);
    }
  };

  render() {
    const classes = new BEMhelper("InterviewStage");
    const loggedUserAccess = this.props.user.userDetails.accessRoles;
    return (
      <Container fluid {...classes("list")}>
        {this.state.showStageModal ? (
          <AddInterviewStageModal
            openModal={this.state.showStageModal}
            title={"Create Interview Stage"}
            toggle={this.closeStageModal}
            submit={this.addStage}
            isEditMode={this.state.editMode}
            objectToEdit={this.state.selectedStage}
          />
        ) : null}

        <Row>
          <Col sm={7} className="text-left">
            <div className="headerTitle-wrap">
              <h2 className="list-header-title">Interview Stages</h2>
            </div>
          </Col>
          <Col sm={5} className="text-right">
            {loggedUserAccess.isAdmin ||
            loggedUserAccess.isHrmAdmin ||
            loggedUserAccess.isSuperAdmin ? (
              <Button
                color="deep-blue"
                onClick={() => {
                  this.setState({
                    showStageModal: true,
                  });
                }}
              >
                <FaPlus /> New Stage
              </Button>
            ) : null}
          </Col>
        </Row>
        <React.Fragment>
          <Row>
            <Col sm={12}>
              <div className="hr"></div>
              {this.props.stages.loading ? (
                <p>Loading...</p>
              ) : (
                <CustomTable
                  content={this.getStageList(this.props.stages.data)}
                  tableHeadings={["Stage name", "Added on", ""]}
                />
              )}
            </Col>
          </Row>
        </React.Fragment>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createStage: hrmModule.interviewStageModule.actions.createStage,
      updateStage: hrmModule.interviewStageModule.actions.updateStage,
      getStages: hrmModule.interviewStageModule.actions.getStages,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    stages: state.hrmInterviewStages,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(InterviewStages);
