import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import timeTrackModule from "../../../../redux/timetracking";
import { Container, Row, Col, Button, ButtonGroup } from "reactstrap";
import BEMhelper from "react-bem-helper";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import AddCategoryModal from "../../../components/modals/timeTracking/addNewCategoryModal";
import CustomTable from "../../../components/table";
import DeleteModal from "../../../components/modals/common/deleteModal";

class TimeTrackCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserModal: false,
      showDeleteModal: false,
      categorySelected: null,
      editMode: false,
    };
  }

  addCategory = (name) => {
    const data = {
      formValues: {
        name: name,
        ...(this.state.editMode && {
          categoryId: this.state.categorySelected.timeTrackCategoryId,
        }),
      },
      closeModal: this.toggle,
    };
    if (this.state.editMode) {
      this.props.updateCategory(data);
    } else {
      this.props.createCategory(data);
    }
  };

  toggle = (toggleState = false) => {
    this.setState({
      showUserModal: toggleState,
    });
  };

  openForAdd = () => {
    this.setState({
      editMode: false,
      categorySelected: null,
    });
    this.toggle(true);
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      categorySelected: null,
    });
  };

  openForEdit = (category) => {
    this.setState({
      editMode: true,
      categorySelected: category,
    });
    this.toggle(true);
  };

  openForDelete = (category) => {
    this.setState({
      showDeleteModal: true,
      categorySelected: category,
    });
  };

  deleteCategory = () => {
    const data = {
      formValues: {
        categoryId: this.state.categorySelected.timeTrackCategoryId,
      },
      closeModal: this.closeDeleteModal,
    };
    this.props.deleteCategory(data);
  };

  componentDidMount() {
    this.props.getCategories();
  }

  getTableHeading = () => {
    return ["Name", "Added on", ""];
  };

  getTableContent = (data) => {
    return data.map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>{row.name}</td>
          <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
          <td>
            <ButtonGroup size="sm">
              <Button
                outline
                color="info"
                size="sm"
                onClick={() => this.openForEdit(row)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                outline
                color="danger"
                size="sm"
                onClick={() => this.openForDelete(row)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  render() {
    const classes = new BEMhelper("time-tracking");
    return (
      <Container {...classes("categories")}>
        {this.state.showUserModal ? (
          <AddCategoryModal
            openModal={this.state.showUserModal}
            toggle={this.toggle}
            title={this.state.editMode ? "Edit category" : "Add new category"}
            onSubmit={this.addCategory}
            isEditMode={this.state.editMode}
            objectToEdit={this.state.categorySelected}
          />
        ) : null}
        {this.state.showDeleteModal ? (
          <DeleteModal
            openModal={this.state.showDeleteModal}
            body={"Are you sure you want to delete this category?"}
            onClose={this.closeDeleteModal}
            onConfirm={this.deleteCategory}
          />
        ) : null}

        <Row>
          <Col sm={9} className="text-left">
            <h2 className="list-header-title">Categories</h2>
          </Col>
          <Col sm={3} className="text-right">
            <Button
              className="btn-primary"
              onClick={() => {
                this.openForAdd();
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> New Category
            </Button>
          </Col>

          <Col sm={12}>
            <div className="hr"></div>
            {this.props.categories.loading ? (
              <p>Loading...</p>
            ) : (
              <CustomTable
                content={this.getTableContent(this.props.categories.data)}
                tableHeadings={this.getTableHeading()}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCategories: timeTrackModule.categoryModule.actions.getCategories,
      createCategory: timeTrackModule.categoryModule.actions.createCategory,
      deleteCategory: timeTrackModule.categoryModule.actions.deleteCategory,
      updateCategory: timeTrackModule.categoryModule.actions.updateCategory,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    categories: state.timeTrackingEntryCategories,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(TimeTrackCategories);
