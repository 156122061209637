import { getTasks, createTask, deleteTask, updateTask } from "./actionCreators";
import allTasksReducer from "./reducer/allTasksReducer";
import { taskSaga } from "./saga/taskSaga";

const taskModule = {
  actions: {
    getTasks,
    createTask,
    deleteTask,
    updateTask,
  },
  reducers: {
    allTasksReducer: allTasksReducer,
  },
  sagas: {
    taskSaga: taskSaga,
  },
};

export default taskModule;
