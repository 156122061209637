import {
  getReport,
  getCustomReport,
  downloadCustomReport,
  resetReport,
} from "./actionCreators";
import { reportSaga } from "./saga/reportSaga";
import reportReducer from "./reducer/reportReducer";

const reportModule = {
  actions: {
    getReport,
    getCustomReport,
    downloadCustomReport,
    resetReport,
  },
  reducers: {
    reportReducer: reportReducer,
  },
  sagas: {
    reportSaga: reportSaga,
  },
};

export default reportModule;
