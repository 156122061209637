import allPermissionTypesReducer from "./reducer/allPermissionTypesReducer";
import allDataReducer from "./reducer/allDataReducer";
import { getPermissionTypes, getCommonData } from "./actionCreators";
import { permissionTypeSaga } from "./saga/permissionTypeSaga";
import { commonDataSaga } from "./saga/commonDataSaga";

const commonModule = {
  actions: {
    getPermissionTypes,
    getCommonData,
  },
  reducers: {
    allPermissionTypesReducer: allPermissionTypesReducer,
    allDataReducer: allDataReducer,
  },
  sagas: {
    permissionTypeSaga: permissionTypeSaga,
    commonDataSaga: commonDataSaga,
  },
};

export default commonModule;
