import React, { Component } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import CustomTable from "../../../../components/table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import inventoryModule from "../../../../../redux/inventory";
import bemClass from "../../../../components/ui-components/Utils/bemClass";
import Button from "../../../../components/ui-components/Button";
import Paginator from "../../../../components/ui-components/Paginator";
import Loader from "../../../../components/ui-components/Loader";
import InfoModal from "../../../../components/modals/infoModal";
import Input from "../../../../components/inputNew";
import moment from "moment";
import "./logReport.scss";

class InventoryLogReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      showPayloadModal: false,
      selectedLog: null,
      searchVal: null,
      hasItemId: false,
    };
  }

  getTableHeading = () => {
    return (
      <tr>
        <th>Description</th>
        <th>Date</th>
        <th></th>
      </tr>
    );
  };

  getTableContent = (data) => {
    return (data.records || []).map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>{this.formatDescription(row)}</td>
          <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
          <td>
            <Button
              size="sm"
              onClick={() =>
                this.setState({ showPayloadModal: true, selectedLog: row })
              }
            >
              Payload
            </Button>
          </td>
        </tr>
      );
    });
  };

  formatDescription = (log) => {
    try {
      const payload = JSON.parse(log.payload);
      if (log.description === "create") {
        return `${
          log.user ? `${log.user.firstName} ${log.user.lastName}` : ""
        } created item ${payload.name}`;
      } else if (log.description === "update") {
        return `${
          log.user ? `${log.user.firstName} ${log.user.lastName}` : ""
        } updated item ${payload.name}`;
      } else if (log.description === "delete") {
        return `${
          log.user ? `${log.user.firstName} ${log.user.lastName}` : ""
        } deleted item ${payload.name}`;
      } else {
        return "item went through some changes";
      }
    } catch (error) {
      return "";
    }
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  getModalBody = () => {
    const payload = JSON.parse(this.state.selectedLog.payload);
    return (
      <Table>
        <tbody>
          <tr>
            <th scope="row">Category</th>
            <td>{payload.category}</td>
          </tr>
          <tr>
            <th scope="row">Name</th>
            <td>{payload.name}</td>
          </tr>
          <tr>
            <th scope="row">Condition</th>
            <td>
              {payload.condition ? this.capitalize(payload.condition) : "-"}
            </td>
          </tr>
          <tr>
            <th scope="row">Price</th>
            <td>{payload.price}</td>
          </tr>
          <tr>
            <th scope="row">Quantity</th>
            <td>{payload.quantity}</td>
          </tr>
          <tr>
            <th scope="row">Available</th>
            <td>{payload.available}</td>
          </tr>
          <tr>
            <th scope="row">Purchased Date</th>
            <td>{moment(payload.purchase_date).format("YYYY-MM-DD")}</td>
          </tr>
          <tr>
            <th scope="row">Warranty</th>
            <td>
              {payload.warranty ? this.capitalize(payload.warranty) : "-"}
            </td>
          </tr>
          <tr>
            <th scope="row">Asset Location</th>
            <td>{payload.asset_location}</td>
          </tr>
          <tr>
            <th scope="row">Type</th>
            <td>{payload.type ? this.capitalize(payload.type) : "-"}</td>
          </tr>
          <tr>
            <th scope="row">Brand</th>
            <td>{payload.brand}</td>
          </tr>
          <tr>
            <th scope="row">Serial Number</th>
            <td>{payload.serial_number}</td>
          </tr>
          <tr>
            <th scope="row">Vendor</th>
            <td>{payload.vendor}</td>
          </tr>
          <tr>
            <th scope="row">Occupied By</th>
            <td>
              {payload.occupied_by
                ? `${payload.occupied_by.firstName} ${payload.occupied_by.lastName}`
                : ""}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  toggle = () => {
    this.setState({
      showPayloadModal: false,
    });
  };

  searchItem = (e) => {
    this.setState({ searchVal: e.target.value });
    this.props.getCustomReport({
      offset: 0,
      reportType: "LogReport",
      searchVal: e.target.value,
    });
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const itemId = query.get("itemId");
    this.props.getCustomReport({
      offset: this.state.offset,
      reportType: "LogReport",
      ...(itemId && { itemId: itemId }),
    });
  }

  render() {
    const classes = new bemClass("InventoryLogReport");
    const query = new URLSearchParams(this.props.location.search);
    const itemId = query.get("itemId");
    return (
      <Container className={classes.elem("wrapper").b()}>
        {this.state.showPayloadModal ? (
          <InfoModal
            openModal={this.state.showPayloadModal}
            toggle={this.toggle}
            title={"Log Payload"}
            body={this.getModalBody()}
            size="md"
          />
        ) : null}
        <Row>
          <Col sm={8} className="text-left">
            <h2 className="list-header-title">Log Report</h2>
          </Col>
          <Col sm={4} className="text-right">
            {!itemId ? (
              <Input
                name="search"
                placeholder="Search logs by item name"
                className={classes.elem("logSearch").b()}
                onChange={this.searchItem}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="hr"></div>
            {this.props.report.loading ? (
              <div className={classes.elem("loader").b()}>
                <Loader />
              </div>
            ) : (
              <React.Fragment>
                <CustomTable
                  content={this.getTableContent(this.props.report.data)}
                  tableHeadings={this.getTableHeading()}
                  customHeaders
                />
                <Paginator
                  style={{ padding: "10px 0" }}
                  limit={10}
                  offset={this.state.offset}
                  total={this.props.report.data.count}
                  align="center"
                  onChange={(offset) => {
                    const query = new URLSearchParams(
                      this.props.location.search
                    );
                    const itemId = query.get("itemId");
                    const data = {
                      reportType: "LogReport",
                      offset: offset,
                      ...(itemId && { itemId: itemId }),
                      searchVal: this.state.searchVal,
                    };
                    this.props.getCustomReport(data);
                    this.setState({ offset: offset });
                  }}
                />
              </React.Fragment>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCustomReport: inventoryModule.reportModule.actions.getCustomReport,
      downloadCustomReport:
        inventoryModule.reportModule.actions.downloadCustomReport,
      resetReport: inventoryModule.reportModule.actions.resetReport,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    report: state.inventoryReport,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(InventoryLogReport);
