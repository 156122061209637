import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import hrmModule from "../../../../../redux/hrm";
import { Container, Row, Col } from "reactstrap";
import bemClass from "../../../../components/ui-components/Utils/bemClass";
import Loader from "../../../../components/ui-components/Loader";
import { Tree, TreeNode } from "react-organizational-chart";
import { Bar } from "react-chartjs-2";
import "./recruitmentDashboard.scss";

class hrmRecruitmentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getTreeCard = (title, number, level) => {
    const classes = new bemClass("hrmRecruitmentDashboard");
    if (level === 1) {
      return (
        <div className={classes.elem("details-card-root").b()}>
          <h3>{title}</h3>
          <h3>{number}</h3>
        </div>
      );
    }
    return (
      <div className={classes.elem("details-card-levelOne").b()}>
        <h5>{title}</h5>
        <h5>{number}</h5>
      </div>
    );
  };

  getInforCard = (text, number) => {
    const classes = new bemClass("hrmRecruitmentDashboard");
    return (
      <div className={classes.elem("info-card").b()}>
        <h5>{text}</h5>
        <h5>{number}</h5>
      </div>
    );
  };

  generateBarChartData = () => {
    const labels = [];
    const costs = [];
    this.props.dashboardData.data.hiredBySource.map((source, index) => {
      labels.push(source.sourceOfHire.toUpperCase());
      costs.push(source.count);
    });
    const data = {
      labels: labels,
      datasets: [
        {
          backgroundColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
          data: costs,
        },
      ],
    };
    return data;
  };

  componentDidMount() {
    this.props.getDashboardData();
  }

  render() {
    const classes = new bemClass("hrmRecruitmentDashboard");
    const { dashboardData } = this.props;
    const options = {
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    return (
      <Container fluid className={classes.elem("wrapper").b()}>
        <React.Fragment>
          {dashboardData.loading ? (
            <div className={classes.elem("loader").b()}>
              <Loader />
            </div>
          ) : (
            <Row>
              <Col md={3}>
                <Col md={12} className={classes.elem("info-column").b()}>
                  <Row>
                    <Col sm={12}>
                      {this.getInforCard(
                        `Average Days Per Hire`,
                        dashboardData.data.DaysToHire || 0
                      )}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm={12}>
                      {this.getInforCard(
                        `Total Agents`,
                        dashboardData.data.Agents || 0
                      )}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm={12}>
                      {this.getInforCard(
                        `Total Vacancies`,
                        dashboardData.data.Vacancies || 0
                      )}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm={12}>
                      {this.getInforCard(
                        `Average Expected Salary`,
                        ` ${this.props.user.userDetails.company.currency} ${
                          dashboardData.data.AverageSalary || 0
                        }`
                      )}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm={12}>
                      {this.getInforCard(
                        `Offer Acceptance Ratio`,
                        `${dashboardData.data.offerAcceptanceRate || 0}%`
                      )}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm={12}>
                      {this.getInforCard(
                        `Referral Program Ratio`,
                        `${dashboardData.data.ReferalRate || 0}%`
                      )}
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col md={{ size: 9 }}>
                <Col md={12} className={classes.elem("info-column").b()}>
                  <Tree
                    lineWidth={"2px"}
                    lineColor={"grey"}
                    lineBorderRadius={"10px"}
                    label={this.getTreeCard(
                      "Total Applications Received",
                      dashboardData.data.Total || 0,
                      1
                    )}
                  >
                    <TreeNode
                      label={this.getTreeCard(
                        "Hired",
                        dashboardData.data.Hired || 0,
                        2
                      )}
                    ></TreeNode>
                    <TreeNode
                      label={this.getTreeCard(
                        "Rejected",
                        dashboardData.data.Rejected || 0,
                        2
                      )}
                    ></TreeNode>
                    <TreeNode
                      label={this.getTreeCard(
                        "Approved",
                        dashboardData.data.Approved || 0,
                        2
                      )}
                    ></TreeNode>
                    <TreeNode
                      label={this.getTreeCard(
                        "Declined",
                        dashboardData.data.Declined || 0,
                        2
                      )}
                    ></TreeNode>
                    <TreeNode
                      label={this.getTreeCard(
                        "Processing",
                        dashboardData.data.Pending || 0,
                        2
                      )}
                    ></TreeNode>
                  </Tree>
                  <hr />
                  <div className={classes.elem("application-source").b()}>
                    <h4>Applications By Source</h4>
                    <Bar data={this.generateBarChartData()} options={options} />
                  </div>
                </Col>
              </Col>
            </Row>
          )}
        </React.Fragment>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDashboardData: hrmModule.dashboardModule.actions.getDashboardData,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    dashboardData: state.hrmRecruitmentDashboard,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(hrmRecruitmentDashboard);
