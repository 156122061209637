import React from "react";
import { Container, Row, Col, FormGroup, Card, Alert } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Input from "../../components/inputNew";
import Button from "../../components/ui-components/Button";
import FormFeedBack from "../../components/ui-components/FormFeedBack";
import BEMhelper from "react-bem-helper";
import userModule from "../../../redux/user";
import "./resetPassword.scss";

const classes = new BEMhelper("ResetPassword");
const ResetPassword = () => {
  const defaultValues = {};

  const { handleSubmit, register, errors, watch } = useForm({
    defaultValues,
  });

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("auth");

  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const details = {
      formValues: {
        password: values.password,
      },
      token: token,
    };
    dispatch(userModule.actions.resetPassword(details));
  };

  const resetPasswordRequest = useSelector((state) => state.resetPassword);

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...classes("form")}>
      <Container fluid>
        <Row>
          <Col sm={12} md={{ size: 6, offset: 3 }} className="text-center">
            <div className="login-form-card">
              <h1 className="login-form-header">Reset Password</h1>
              <Card body>
                {resetPasswordRequest.error ? (
                  <Row>
                    <Col md={12}>
                      <Alert color="danger">{resetPasswordRequest.error}</Alert>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Input
                        type="password"
                        placeholder="Password"
                        name="password"
                        register={register}
                        refdetails={{
                          required: "Password required",
                          minLength: {
                            value: 6,
                            message: "Must be atleast 6 characters long",
                          },
                        }}
                        state={errors.password ? "error" : "none"}
                      />
                      {errors.password ? (
                        <FormFeedBack feedBack={errors.password.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Input
                        type="password"
                        placeholder="Re-Enter-Password"
                        name="rePassword"
                        register={register}
                        refdetails={{
                          required: "Password re-enter required",
                          validate: (value) =>
                            value === watch("password") ||
                            "Passwords don't match.",
                        }}
                        state={errors.rePassword ? "error" : "none"}
                      />
                      {errors.rePassword ? (
                        <FormFeedBack feedBack={errors.rePassword.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup {...classes("submit")}>
                      <Button submit loading={resetPasswordRequest.loading}>
                        Submit
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default ResetPassword;
