export const CLAIM_TYPE_REQUEST = "CLAIM_TYPE_REQUEST";
export const CLAIM_TYPE_SUCCESS = "CLAIM_TYPE_SUCCESS";
export const CLAIM_TYPE_FAILURE = "CLAIM_TYPE_FAILURE";

export const APPLY_CLAIM_REQUEST = "APPLY_CLAIM_REQUEST";
export const APPLY_CLAIM_SUCCESS = "APPLY_CLAIM_SUCCESS";
export const APPLY_CLAIM_FAILURE = "APPLY_CLAIM_FAILURE";

export const CLAIM_HISTORY_REQUEST = "CLAIM_HISTORY_REQUEST";
export const CLAIM_HISTORY_SUCCESS = "CLAIM_HISTORY_SUCCESS";
export const CLAIM_HISTORY_FAILURE = "CLAIM_HISTORY_FAILURE";
