import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import userModule from "../../../../../redux/user";
import adminModule from "../../../../../redux/admin";
import supervisorModule from "../../../../../redux/supervisor";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card } from "reactstrap";
import moment from "moment";
import Stepper from "react-stepper-horizontal";
import StepOne from "../FormSteps/stepOne";
import StepTwo from "../FormSteps/stepTwo";
import StepThree from "../FormSteps/stepThree";
import BEMhelper from "react-bem-helper";
import "./AddUser.scss";

class AddUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      stepOneData: null,
      stepTwoData: null,
      stepThreeData: null,
      isEditMode: false,
    };
  }

  getFormatteFeatureRoles = (featureRoles) => {
    return featureRoles.map((role) => {
      return role.value;
    });
  };

  submitValues = (values) => {
    const tab = this.state.activeTab;
    if (tab === 0) {
      this.setState({
        activeTab: 1,
        stepOneData: values,
        isEditMode: this.state.stepTwoData ? true : false,
      });
    } else if (tab === 1) {
      this.setState({
        activeTab: 2,
        stepTwoData: values,
        isEditMode: this.state.stepThreeData ? true : false,
      });
    } else {
      this.setState({ stepThreeData: values, isEditMode: false });
      const stepOne = this.state.stepOneData;
      const stepTwo = this.state.stepTwoData;
      const stepThree = this.state.stepThreeData;
      const data = {
        firstName: stepOne.firstname,
        middleName: stepOne.middlename,
        lastName: stepOne.lastname,
        email: stepOne.email,
        district: stepOne.district || null,
        maritalstatus: stepOne.maritalstatus
          ? stepOne.maritalstatus.value
          : null,
        religion: stepOne.religion || null,
        address: stepOne.permanentaddress,
        gender: stepOne.gender.value,
        nic: stepOne.nic,
        birthday: moment(stepOne.dateofbirth).format("YYYY-MM-DD"),
        bloodGroup: stepOne.bloodGroup.value,
        title: stepOne.title.value,
        phone: stepOne.mobilenumber || null,

        joinDate: moment(stepTwo.joindate).format("YYYY-MM-DD"),
        password: stepTwo.password,
        departmentId: stepTwo.department.value,
        designationId: stepTwo.designation.value,
        roleId: stepTwo.role.value,
        employeeId: stepTwo.employeeId,
        employeeCategory: stepTwo.employeecategory.value,
        sourceOfHire: stepTwo.source.value,
        officeEmail: stepTwo.officeemail,
        emergencyDetails: {
          name: stepTwo.emergencycontactname,
          relationship: stepTwo.relationship,
          contactNumber: stepTwo.contactnumber,
        },
        ...(stepTwo.featureRoles && {
          featureRoles: this.getFormatteFeatureRoles(stepTwo.featureRoles),
        }),
        ...(stepTwo.spousename && {
          spouse: {
            name: stepTwo.spousename,
            birthday: stepTwo.spousebirthday ? stepTwo.spousebirthday : null,
          },
        }),
        ...(stepTwo.education && { education: [{ institute: "test" }] }),
        ...(stepTwo.workplaces && { workExperience: stepTwo.workplaces }),
        ...(stepTwo.notes && { aboutMe: stepTwo.notes }),
        ...(stepTwo.resigned && { resigned: stepTwo.resigned }),

        ...(stepThree.drivinglicensenumber && {
          drivingLicenceNumber: stepThree.drivinglicensenumber,
        }),
        ...(stepThree.electorate && { electorate: stepThree.electorate }),
        ...(stepThree.passportnumber && {
          passportNumber: stepThree.passportnumber,
        }),
        ...(stepThree.police && { policeStation: stepThree.police }),
      };
      this.props.signupUser(data);
    }
  };

  changeTab = (tabValue) => {
    this.setState({
      activeTab: tabValue,
      isEditMode: true,
    });
  };

  componentDidMount() {
    this.props.userRoles();
    this.props.userDesignation();
    this.props.userDepartment();
    this.props.getUserList();
    this.props.getSupervisorLevels();
    this.props.featureAccessRole();
  }

  render() {
    const classes = new BEMhelper("AddUser");
    return (
      <Container fluid {...classes("add-user-wrapper")}>
        <Row>
          <Col sm={8} className="title text-left">
            <h2 className="list-header-title">Add User</h2>
          </Col>
          <Col sm={4} className="text-right time">
            <h4>
              {moment().format("dddd")}, {moment().format("LL")}
            </h4>
          </Col>
        </Row>
        <div className="hr"></div>
        <Card>
          <Row>
            <Col sm={12}>
              <div>
                <Stepper
                  steps={[
                    {
                      title: "Personal information",
                      // onClick: () => this.changeTab(0),
                    },
                    {
                      title: "Employee information",
                      // onClick: () => this.changeTab(1),
                    },
                    {
                      title: "Other information",
                      // onClick: () => this.changeTab(2),
                    },
                  ]}
                  activeStep={this.state.activeTab}
                  circleFontSize={0}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div {...classes("userFrom")}>
                {this.state.activeTab === 0 ? (
                  <StepOne
                    submit={(values) => this.submitValues(values)}
                    stepOneData={this.state.stepOneData}
                    isEditMode={this.state.isEditMode}
                  />
                ) : null}
                {this.state.activeTab === 1 ? (
                  <StepTwo
                    submit={(values) => this.submitValues(values)}
                    designations={
                      this.props.designations.roles
                        ? this.props.designations.roles
                        : []
                    }
                    departments={
                      this.props.departments.departments
                        ? this.props.departments.departments
                        : []
                    }
                    roles={this.props.roles.roles ? this.props.roles.roles : []}
                    stepTwoData={this.state.stepTwoData}
                    isEditMode={this.state.isEditMode}
                    changeTab={(tab) => this.changeTab(tab)}
                    featureAccessRoles={this.props.featureAccessRoles.data}
                  />
                ) : null}
                {this.state.activeTab === 2 ? (
                  <StepThree
                    submit={(values) => this.submitValues(values)}
                    stepThreeData={this.state.stepThreeData}
                    isEditMode={this.state.isEditMode}
                    changeTab={(tab) => this.changeTab(tab)}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signupUser: userModule.actions.signupUser,
      userRoles: userModule.actions.getUserRole,
      userDesignation: userModule.actions.getUserDesignation,
      userDepartment: userModule.actions.getUserDepartmentList,
      getUserList: userModule.actions.getUserList,
      getSupervisorLevels: supervisorModule.actions.getSupervisorLevelList,
      featureAccessRole: adminModule.commonModule.actions.featureAccessRole,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    signupUserDetails: state.signupUser,
    roles: state.userRole,
    designations: state.userDesignation,
    departments: state.userDepartment,
    userList: state.userList,
    supervisorLevels: state.supervisorLevels,
    featureAccessRoles: state.featureAccessRole,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(AddUsers);
