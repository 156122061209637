import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import Select from "react-select";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../ui-components/Button";
import Input from "../../../inputNew";
import FormFeedBack from "../../../ui-components/FormFeedBack";
import Toggle from "../../../ui-components/Toggle";

const classes = new BEMhelper("AddTaskModal");
const AddTaskModal = (props) => {
  const {
    title,
    openModal,
    toggle,
    submit,
    categories,
    isEditMode,
    objectToEdit,
  } = props;
  let selectedCategory = null;

  const categoryOptions = categories.map((category) => {
    return {
      value: category.timeTrackCategoryId,
      label: `${category.name}`,
    };
  });

  if (isEditMode) {
    _.forEach(categoryOptions, function (category) {
      if (category.value === objectToEdit.timeTrackCategoryId) {
        selectedCategory = category;
      }
    });
  }

  const defaultValues = {
    name: isEditMode ? objectToEdit.name : null,
    hourlyRate: isEditMode ? parseInt(objectToEdit.hourlyRate) : null,
    category: selectedCategory,
    activeStatus: isEditMode ? objectToEdit.isActive : true,
  };

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => submit(values);

  return (
    <div>
      <Modal
        {...classes("modal")}
        isOpen={openModal}
        toggle={() => toggle()}
        centered={true}
        draggable={false}
        size="md"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={() => toggle()} className="modal-header">
            {title}
          </ModalHeader>
          <ModalBody>
            <Container fluid>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Name</Label>
                    <Input
                      type="text"
                      name="name"
                      register={register}
                      refdetails={{
                        required: "Name required",
                      }}
                      state={errors.name ? "error" : "none"}
                      min={0}
                    />
                    {errors.name ? (
                      <FormFeedBack feedBack={errors.name.message} />
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Hourly rate</Label>
                    <Input
                      type="number"
                      name="hourlyRate"
                      register={register}
                      refdetails={{
                        required: "Hourly rate required",
                      }}
                      state={errors.hourlyRate ? "error" : "none"}
                      min={0}
                    />
                    {errors.hourlyRate ? (
                      <FormFeedBack feedBack={errors.hourlyRate.message} />
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Category</Label>
                    <Controller
                      as={
                        <Select
                          placeholder="Select category"
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              border: `1px solid ${
                                errors.category ? "#dc3545" : "#cad2dd"
                              }`,
                            }),
                          }}
                        />
                      }
                      options={categoryOptions}
                      name="category"
                      isClearable
                      control={control}
                      onChange={([selected]) => {
                        return { value: selected };
                      }}
                      rules={{
                        required: "Category is required",
                      }}
                    />
                    {errors.category ? (
                      <FormFeedBack feedBack={errors.category.message} />
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Active status</Label>
                    <Controller
                      as={
                        <Toggle
                          checked={isEditMode ? objectToEdit.isActive : true}
                        />
                      }
                      name="activeStatus"
                      control={control}
                      onChange={([selected]) => {
                        return { value: selected };
                      }}
                    />
                    {errors.activeStatus ? (
                      <FormFeedBack feedBack={errors.activeStatus.message} />
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button submit>Submit</Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default AddTaskModal;
