import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/time-tracking`;

export const getProjects = async (token) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/project`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createProject = async (token, projectDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/project`,
    headers: {
      "x-access-token": token,
    },
    data: projectDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const updateProject = async (token, projectDetails) => {
  return axios({
    method: "PUT",
    url: `${apiUrl}/project/${projectDetails.projectId}`,
    headers: {
      "x-access-token": token,
    },
    data: projectDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const deleteProject = async (token, projectId) => {
  return axios({
    method: "DELETE",
    url: `${apiUrl}/project/${projectId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
