import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import Button from "../../../../components/ui-components/Button";
import CustomTable from "../../../../components/table";
import hrmModule from "../../../../../redux/hrm";
import userModule from "../../../../../redux/user";
import moment from "moment";
import BEMhelper from "react-bem-helper";
import InfoModal from "../../../../components/modals/infoModal";
import Loader from "../../../../components/ui-components/Loader";
import Select from "react-select";
import "./viewAllUserFeedback.scss";

class ViewAllUserFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showViewFeedbackModal: false,
      feedbackSelected: null,
    };
  }

  viewFeedback = (feedback) => {
    this.setState({ feedbackSelected: feedback, showViewFeedbackModal: true });
  };

  getFeedbackList = (feedbackList) => {
    if (feedbackList.length) {
      return feedbackList.map((row, key) => {
        return (
          <tr key={key} className="value-row">
            <td>
              {row.mentorDetails.firstName} {row.mentorDetails.lastName}
            </td>
            <td>
              {row.menteeDetails.firstName} {row.menteeDetails.lastName}
            </td>
            <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
            <td className="feedback-actions">
              <Button size="sm" onClick={() => this.viewFeedback(row)}>
                View feedback
              </Button>
            </td>
          </tr>
        );
      });
    }
    return [];
  };

  getFeedbackHeadings = () => {
    return ["Mentor", "Mentee", "Added on", ""];
  };

  closeViewFeedbackModal = () => {
    this.setState({ feedbackSelected: null, showViewFeedbackModal: false });
  };

  getFeedbackContent = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: this.state.feedbackSelected.feedback,
        }}
      />
    );
  };

  getFormattedUserList = (users) => {
    return users.map((user) => {
      return {
        value: user.userId,
        label: `${user.firstName} ${user.lastName}`,
      };
    });
  };

  componentDidMount = () => {
    this.props.getFeedbacks();
    this.props.getUserList();
  };

  render() {
    const classes = new BEMhelper("mentorship");
    return (
      <Container fluid {...classes("userFeedbacks")}>
        {this.state.showViewFeedbackModal ? (
          <InfoModal
            openModal={this.state.showViewFeedbackModal}
            toggle={this.closeViewFeedbackModal}
            title={"Feedback"}
            body={this.getFeedbackContent()}
          />
        ) : null}

        <Row>
          <Col sm={8} className="text-left">
            <div className="headerTitle-wrap">
              <h2 className="list-header-title">Feedback List</h2>
              <Button
                size="sm"
                type="link"
                onClick={() => this.props.history.push("/hrm/mentorship")}
              >
                My Feedbacks
              </Button>
            </div>
          </Col>
          <Col sm={4}>
            <Select
              isLoading={this.props.userList.loading}
              isClearable={true}
              isSearchable={true}
              options={this.getFormattedUserList(this.props.userList.userList)}
              placeholder="Select mentee"
              onChange={(user) =>
                this.props.getFeedbacks({ userId: user ? user.value : 0 })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="hr"></div>
            <React.Fragment>
              {this.props.feedbackList.loading ? (
                <div {...classes("loader")}>
                  <Loader />
                </div>
              ) : (
                <CustomTable
                  content={this.getFeedbackList(this.props.feedbackList.data)}
                  tableHeadings={this.getFeedbackHeadings()}
                />
              )}
            </React.Fragment>
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFeedbacks: hrmModule.mentorshipModule.actions.getAllUsersFeedbacks,
      getUserList: userModule.actions.getUserList,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    feedbackList: state.hrmMentorshipFeedback,
    userList: state.userList,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(ViewAllUserFeedback);
