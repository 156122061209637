import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Container, Row, Col, ButtonGroup, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import hrmModule from "../../../../../../redux/hrm";
import Button from "../../../../../components/ui-components/Button";
import BEMhelper from "react-bem-helper";
import { FaPlus, FaFilePdf, FaStreetView, FaBookOpen } from "react-icons/fa";
import CustomTable from "../../../../../components/table";
import AddCandidateModal from "../../../../../components/modals/hrm/recruitment/candidate/addCandidate";
import InfoModal from "../../../../../components/modals/infoModal";
import ToolTip from "../../../../../components/toolTip";
import Paginator from "../../../../../components/ui-components/Paginator";
import Input from "../../../../../components/inputNew";
import processStatusses from "../../../../../../enum/common/processStatus";
import _ from "lodash";
import "./cvPool.scss";

class CandidateCvPool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCandidateModal: false,
      showResumeModal: false,
      selectedCandidate: null,
      offset: 0,
      searchVal: null,
      processStatusId: processStatusses.Pending,
    };
  }

  viewCandidate = (candidate) => {
    this.setState({ selectedCandidate: candidate, showResumeModal: true });
  };

  getResume = () => {
    return (
      <div className="candidates__cvPool-resumePreview">
        <iframe
          src={this.state.selectedCandidate.cvUrl}
          width="100%"
          height="100%"
          className="iframe"
        ></iframe>
      </div>
    );
  };

  getLastInterviewFaced = (feedbacks) => {
    const lastFeedback = _.last(feedbacks);
    return lastFeedback.hrmInterviewStage.name;
  };

  getCandidateList = (candidateList) => {
    const loggedUserAccess = this.props.user.userDetails.accessRoles;
    if (candidateList.candidates && candidateList.candidates.length) {
      return candidateList.candidates.map((row, key) => {
        return (
          <tr key={key} className="value-row">
            <td>{row.name}</td>
            <td className="candidate__appliedPosition">
              <a
                href={`/vacancy/${row.hrmVacancy.hrmVacancyId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {row.hrmVacancy.name}
              </a>
            </td>
            <td>{row.hired ? "Hired" : row.processStatus.status}</td>
            <td>
              {row.hrmCandidateFeedbacks.length
                ? this.getLastInterviewFaced(row.hrmCandidateFeedbacks)
                : "-"}
            </td>
            <td>
              <Badge pill>{row.hrmCandidateFeedbacks.length}</Badge>
            </td>
            <td className="candidate__application-actions">
              <ButtonGroup size="sm">
                {loggedUserAccess.isAdmin ||
                loggedUserAccess.isHrmAdmin ||
                loggedUserAccess.isSuperAdmin ? (
                  <React.Fragment>
                    <Button
                      size="sm"
                      onClick={() => this.viewCandidate(row)}
                      color="grey"
                      id={`resume-${key}`}
                    >
                      <FaFilePdf />
                    </Button>
                    <ToolTip
                      position="top"
                      tip="View Resume"
                      rowId={`resume-${key}`}
                    />
                    <Link
                      to={`/hrm/recruitment/cvPool/application/${row.hrmCandidateId}`}
                      id={`application-${key}`}
                    >
                      <Button size="sm">
                        <FaStreetView />
                      </Button>
                    </Link>
                    <ToolTip
                      position="top"
                      tip="View Application"
                      rowId={`application-${key}`}
                    />
                  </React.Fragment>
                ) : null}
                <Link
                  to={
                    row.hired
                      ? "#"
                      : `/hrm/recruitment/cvPool/feedback/${row.hrmCandidateId}`
                  }
                  id={`feedback-${key}`}
                >
                  <Button size="sm" color="medium-blue" disabled={row.hired}>
                    <FaBookOpen />
                  </Button>
                </Link>
                <ToolTip
                  position="top"
                  tip="Add Feedback"
                  rowId={`feedback-${key}`}
                />
              </ButtonGroup>
            </td>
          </tr>
        );
      });
    }
    return [];
  };

  closeCandidateModal = () => {
    this.setState({ showCandidateModal: false });
  };

  closeResumeModal = () => {
    this.setState({ selectedCandidate: null, showResumeModal: false });
  };

  addCandidate = (values) => {
    const formData = new FormData();
    formData.append("name", values.candidateName);
    if (values.noticePeriod) {
      formData.append("noticePeriod", values.noticePeriod);
    }
    formData.append("appliedVacancy", values.vacancy.value);
    formData.append("resume", values.resume[0]);
    if (values.expectedSalary) {
      formData.append("expectedSalary", values.expectedSalary);
    }
    formData.append("email", values.email);
    formData.append("phone", values.phone || null);
    formData.append("sourceOfHire", values.source.value);
    if (values.agent && values.source.value === "agency") {
      formData.append("agentId", values.agent.value);
    }
    const data = {
      formValues: formData,
      closeModal: this.closeCandidateModal,
    };
    this.props.createCandidate(data);
  };

  getColor = (number) => {
    if (number === 1) {
      return "warning";
    } else if (number === 2) {
      return "success";
    } else {
      return "danger";
    }
  };

  searchItem = (e) => {
    this.setState({ searchVal: e.target.value });
    this.props.getCandidates({
      offset: 0,
      searchVal: e.target.value,
      processStatusId: this.state.processStatusId,
    });
  };

  getCans = (id) => {
    this.setState({
      processStatusId: id,
    });
    this.props.getCandidates({
      offset: 0,
      searchVal: this.state.searchVal,
      processStatusId: id,
    });
  };

  componentDidMount = () => {
    this.props.getCandidates({
      offset: 0,
      processStatusId: processStatusses.Pending,
    });
    this.props.getAgents();
    this.props.getVacancies();
  };

  render() {
    const classes = new BEMhelper("candidates");
    const loggedUserAccess = this.props.user.userDetails.accessRoles;
    return (
      <Container fluid {...classes("cvPool")}>
        {this.state.showCandidateModal ? (
          <AddCandidateModal
            openModal={this.state.showCandidateModal}
            title={"Add Candidate"}
            toggle={this.closeCandidateModal}
            submit={this.addCandidate}
            vacancies={
              this.props.vacancies.data ? this.props.vacancies.data : []
            }
            agents={this.props.agents.data ? this.props.agents.data : []}
            companyCurrency={this.props.user.userDetails.company.currency}
            isBtnLoading={this.props.createItem.loading}
          />
        ) : null}
        {this.state.showResumeModal ? (
          <InfoModal
            openModal={this.state.showResumeModal}
            toggle={this.closeResumeModal}
            title={"View Resume"}
            body={this.getResume()}
          />
        ) : null}

        <React.Fragment>
          <Row>
            <Col sm={7} className="text-left">
              <div className="headerTitle-wrap">
                <h2 className="list-header-title">Processing Candidates</h2>
                <h4 className="list-sub-header-title">
                  {/* {!this.props.candidates.loading &&
                    (this.props.candidates.data.statuses || []).map(
                      (status) => {
                        if (
                          status.processStatusId === processStatusses.Pending
                        ) {
                          return (
                            <Badge color="secondary">
                              Total - {status.count}
                            </Badge>
                          );
                        }
                      }
                    )} */}
                </h4>
              </div>
            </Col>
            <Col sm={5} className="text-right">
              <Input
                name="search"
                placeholder="Search candidate name"
                {...classes("itemSearch")}
                onChange={this.searchItem}
              />
              {loggedUserAccess.isAdmin ||
              loggedUserAccess.isHrmAdmin ||
              loggedUserAccess.isSuperAdmin ? (
                <Button
                  color="deep-blue"
                  onClick={() => {
                    this.setState({
                      showCandidateModal: true,
                    });
                  }}
                >
                  <FaPlus /> New Candidate
                </Button>
              ) : null}
            </Col>
          </Row>
          {this.props.candidates.loading ? (
            <p>Loading...</p>
          ) : (
            <Row>
              <Col sm={12}>
                <div className="hr"></div>
                <CustomTable
                  content={this.getCandidateList(this.props.candidates.data)}
                  tableHeadings={[
                    "Candidate name",
                    "Applied vacancy",
                    "Application status",
                    "Last faced interview",
                    "No.of feedbacks",
                    "",
                  ]}
                />
                <Paginator
                  style={{ padding: "10px 0" }}
                  limit={10}
                  offset={this.state.offset}
                  total={this.props.candidates.data.count}
                  align="center"
                  onChange={(offset) => {
                    this.props.getCandidates({
                      page: offset,
                      limit: 5,
                      offset: offset,
                      searchVal: this.state.searchVal,
                      processStatusId: this.state.processStatusId,
                    });
                    this.setState({ offset: offset });
                  }}
                />
              </Col>
            </Row>
          )}
        </React.Fragment>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createCandidate: hrmModule.candidateModule.actions.createCandidate,
      getCandidates: hrmModule.candidateModule.actions.getCandidates,
      getAgents: hrmModule.agentModule.actions.getAgents,
      getVacancies: hrmModule.vacancyModule.actions.getVacancies,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    candidates: state.hrmCandidates,
    agents: state.hrmAgents,
    vacancies: state.hrmVacancies,
    createItem: state.hrmCreateItem,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(CandidateCvPool);
