import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_DATA_COMMON_REQUEST,
  GET_DATA_COMMON_SUCCESS,
  GET_DATA_COMMON_FAILURE,
} from "../actionTypes";
import apis from "../../../api";

export function* commonDataSaga() {
  yield takeLatest(GET_DATA_COMMON_REQUEST, getCommonData);
}

const getApi = (apiName) => {
  let apiPointer = null;
  if (apiName === "inventoryGetItems") {
    apiPointer = apis.inventoryApis.getItems;
  }
  return apiPointer;
};

function* getCommonData(details) {
  try {
    if (details.payload.apiEndpoint) {
      const apiPointer = getApi(details.payload.apiEndpoint);
      if (apiPointer) {
        const loggedUser = yield select((state) => state.loggedUser);
        const commondata = yield call(
          apiPointer,
          loggedUser.userDetails.auth.accessToken,
          details.payload
        );
        if (commondata.error) {
          yield put({
            type: GET_DATA_COMMON_FAILURE,
            error: commondata.body.data.error,
          });
        } else {
          yield put({
            type: GET_DATA_COMMON_SUCCESS,
            data: commondata.body.data,
          });
        }
      } else {
        console.log("No api pointer");
      }
    } else {
      console.log("No endpoint specified");
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
