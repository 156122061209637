import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  APPLY_LEAVE_REQUEST,
  APPLY_LEAVE_SUCCESS,
  APPLY_LEAVE_FAILURE,
  LEAVE_BALANCE_REQUEST,
  LEAVE_BALANCE_SUCCESS,
  LEAVE_BALANCE_FAILURE,
  LEAVE_DATE_TYPE_REQUEST,
  LEAVE_DATE_TYPE_SUCCESS,
  LEAVE_DATE_TYPE_FAILURE,
  LEAVE_HISTORY_REQUEST,
  LEAVE_HISTORY_SUCCESS,
  LEAVE_HISTORY_FAILURE,
  PROCESS_LEAVE_REQUEST,
  PROCESS_LEAVE_SUCCESS,
  PROCESS_LEAVE_FAILURE,
} from "../actionTypes";
import apis from "../../../api";
import { toast } from "react-toastify";

export function* leaveSaga() {
  yield takeLatest(APPLY_LEAVE_REQUEST, applyLeave);
  yield takeLatest(LEAVE_BALANCE_REQUEST, leaveBalance);
  yield takeLatest(LEAVE_DATE_TYPE_REQUEST, leaveDateType);
  yield takeLatest(LEAVE_HISTORY_REQUEST, leaveHistory);
  yield takeLatest(PROCESS_LEAVE_REQUEST, processLeave);
}

function* applyLeave(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const leaveApplied = yield call(
      apis.applyLeave,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValue
    );
    if (leaveApplied.error) {
      yield put({
        type: APPLY_LEAVE_FAILURE,
        error: leaveApplied.body.data.error,
      });
      toast.error("Something bad happened!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put({ type: APPLY_LEAVE_SUCCESS, data: leaveApplied.body.data });
      toast.success("Leave applied successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal(true);
    }
  } catch (error) {
    console.log("oops error -> ", error);
    toast.error("Something bad happened!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

function* leaveBalance(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const leaveBalance = yield call(
      apis.leaveBalance,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (leaveBalance.error) {
      yield put({
        type: LEAVE_BALANCE_FAILURE,
        error: leaveBalance.body.data.error,
      });
    } else {
      yield put({ type: LEAVE_BALANCE_SUCCESS, data: leaveBalance.body.data });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* leaveDateType() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const leaveDateType = yield call(
      apis.leaveDateType,
      loggedUser.userDetails.auth.accessToken
    );
    if (leaveDateType.error) {
      yield put({
        type: LEAVE_DATE_TYPE_FAILURE,
        error: leaveDateType.body.data.error,
      });
    } else {
      yield put({
        type: LEAVE_DATE_TYPE_SUCCESS,
        data: leaveDateType.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* leaveHistory(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const leaveHistory = yield call(
      apis.leaveHistory,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (leaveHistory.error) {
      yield put({
        type: LEAVE_HISTORY_FAILURE,
        error: leaveHistory.body.data.error,
      });
    } else {
      yield put({ type: LEAVE_HISTORY_SUCCESS, data: leaveHistory.body.data });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* processLeave(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const processLeave = yield call(
      apis.processLeave,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (processLeave.error) {
      yield put({
        type: PROCESS_LEAVE_FAILURE,
        error: processLeave.body.data.error,
      });
    } else {
      yield put({ type: PROCESS_LEAVE_SUCCESS, data: processLeave.body.data });
    }
    details.payload.clearState();
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
