import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
// import { Router, Route ,Switch} from 'react-router-dom'
import Login from "../views/pages/login";
import ForgotPassword from "../views/pages/forgotPassword";
import ResetPassword from "../views/pages/resetPassword";
import ResetMyPassword from "../views/pages/settings/resetPassword";
import Signup from "../views/pages/signup";
import Dashboard from "../views/pages/hrm/dashboard";
// import Adduser from "../views/pages/administration/adduser";
import AddUsers from "../views/pages/administration/User/Addusers";
import EditUsers from "../views/pages/administration/User/Editusers";
import Listuser from "../views/pages/administration/listuser";
import LandingPage from "../views/pages/landingPage";
import PrivateRoute from "./privateRoutes";
import AgentPrivateRoute from "./agentPrivateRoutes";

import LeaveHistory from "../views/pages/hrm/leaveHistory";
import ClaimHistory from "../views/pages/hrm/claimHistory";
import AdministrationDashboard from "../views/pages/administration/dashboard";
import FeatureAccess from "../views/pages/administration/featureAccess";
import LeaveList from "../views/pages/administration/leaveList";
import TimeTrackDashboard from "../views/pages/timeTracking/dashboard";
import TimeTrackProjects from "../views/pages/timeTracking/projects";
import TimeTrackCategories from "../views/pages/timeTracking/categories";
import TimeTrackTask from "../views/pages/timeTracking/tasks";
import TimeTrackClient from "../views/pages/timeTracking/client";
import TimeTrackUser from "../views/pages/timeTracking/users";
import TimeTrackReports from "../views/pages/timeTracking/reports";
///////////////////////////////////////////////////////////////////////////
import InventoryDashboard from "../views/pages/inventory/dashboard";
import InventoryItemList from "../views/pages/inventory/itemList";
import InventoryReportType from "../views/pages/inventory/reports/reportTypes";
import InventoryGeneralReport from "../views/pages/inventory/reports/generalReport";
import InventoryQuantityReport from "../views/pages/inventory/reports/quantityReport";
import InventoryCostReport from "../views/pages/inventory/reports/costReport";
import InventorySignOffReport from "../views/pages/inventory/reports/signOffReport";
import InventoryLogReport from "../views/pages/inventory/reports/logReport";
import InventoryMyItems from "../views/pages/inventory/myItems";
import InventoryCategoryList from "../views/pages/inventory/categoryList";
import InventoryTypeList from "../views/pages/inventory/typeList";

////////////////////////////////////////////////////////////////////////////
import ViewAllFeedback from "../views/pages/hrm/mentorship/viewAllFeedback";
import ViewAllUserFeedback from "../views/pages/hrm/mentorship/viewAllUserFeedback";

import hrmRecruitmentDashboard from "../views/pages/hrm/recruitment/dashboard";
import AddAgentCandidate from "../views/pages/hrm/recruitment/agents/addCandidate";
import AgentList from "../views/pages/hrm/recruitment/agents";
import VacancyList from "../views/pages/hrm/recruitment/vacancies/vacancyList";
import ViewVacancyExternal from "../views/pages/hrm/recruitment/vacancies/viewVacancyExternal";
import CandidateHiredPool from "../views/pages/hrm/recruitment/candidates/hiredPool";
import CandidateRejectedPool from "../views/pages/hrm/recruitment/candidates/rejectedPool";
import CandidateApprovedPool from "../views/pages/hrm/recruitment/candidates/approvedPool";
import CandidateTurnedDownPool from "../views/pages/hrm/recruitment/candidates/turnDownPool";
import CandidateCvPool from "../views/pages/hrm/recruitment/candidates/cvPool";
import CandidateApplication from "../views/pages/hrm/recruitment/candidates/candidateApplication";
import CandidateFeedback from "../views/pages/hrm/recruitment/candidates/candidateFeedback";
import InterviewStages from "../views/pages/hrm/settings/recruitment/interviewStages";
import HrmSurveyList from "../views/pages/hrm/performance/surveyList";
import HrmSurveyCreate from "../views/pages/hrm/performance/createSurvey";
import HrmSurveyInfo from "../views/pages/hrm/performance/surveyInfo";
import HrmSurveyEmployee from "../views/pages/hrm/performance/surveyEmployee";
import HrmSurveyFeedback from "../views/pages/hrm/performance/surveyFeedback";
import HrmSurveySingleReport from "../views/pages/hrm/performance/singleSurveyReport";

class Routes extends Component {
  render() {
    const isLogged = this.props.appState.loggedUser.userDetails ? true : false;
    const userRole = this.props.appState.loggedUser.userDetails
      ? this.props.appState.loggedUser.userDetails.user.role.roleId
      : false;
    const accessPermissions = this.props.appState.loggedUser.userDetails
      ? this.props.appState.loggedUser.userDetails.accessRoles
      : null;
    const isAgentLogged = this.props.appState.hrmAgentLogin.data ? true : false;
    return (
      // <Router>
      <Switch>
        <Route exact path="/">
          {isLogged ? <Redirect to="/landing" /> : <Login />}
        </Route>
        <Route exact path="/forgot-password" component={ForgotPassword}></Route>
        <Route exact path="/reset-password" component={ResetPassword}></Route>
        <Route exact path="/reset-password" component={ResetPassword}></Route>
        {/* <Route exact path="/signup" component={Signup}></Route> */}
        <Route
          exact
          path="/landing"
          component={isLogged ? LandingPage : Login}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
        >
          {isLogged ? (
            <LandingPage logoutAction={this.props.appActions.logout} />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        {/* HRM ROUTES */}
        {/* <PrivateRoute
          exact
          path="/hrm/dashboard/"
          component={Dashboard}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          allowedRoles={[1, 2, 3]}
          userRole={userRole}
          user={this.props.appState.loggedUser}
          authorized={accessPermissions}
        />
        <PrivateRoute
          exact
          path="/hrm/leave-history"
          component={LeaveHistory}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2, 3]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
        />
        <PrivateRoute
          exact
          path="/hrm/claim"
          component={ClaimHistory}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2, 3]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
        /> */}
        <PrivateRoute
          exact
          path="/hrm/mentorship"
          component={ViewAllFeedback}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2, 3]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
          authorized={accessPermissions}
        />
        <PrivateRoute
          exact
          path="/hrm/mentorship/all"
          component={ViewAllUserFeedback}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2, 3]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmFeedbackAccess)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/recruitment/cvpool"
          component={CandidateCvPool}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/recruitment/hiredpool"
          component={CandidateHiredPool}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/recruitment/rejectedpool"
          component={CandidateRejectedPool}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/recruitment/approvedpool"
          component={CandidateApprovedPool}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/recruitment/turnDownPool"
          component={CandidateTurnedDownPool}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/recruitment/cvpool/application/:applicationId"
          component={CandidateApplication}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/recruitment/cvpool/feedback/:applicationId"
          component={CandidateFeedback}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={true}
        />
        <PrivateRoute
          exact
          path="/hrm/recruitment/agents"
          component={AgentList}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/recruitment/vacancies"
          component={VacancyList}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/settings/recruitment/stages"
          component={InterviewStages}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/recruitment/dashboard"
          component={hrmRecruitmentDashboard}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/performance/surveys"
          component={HrmSurveyList}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/performance/create-survey"
          component={HrmSurveyCreate}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/performance/survey-info/:surveyId"
          component={HrmSurveyInfo}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/performance/survey/:surveyId/employee/:employeeId"
          component={HrmSurveyEmployee}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/hrm/performance/survey/:surveyId/report"
          component={HrmSurveySingleReport}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <Route
          exact
          path="/hrm/performance/survey/feedback"
          component={HrmSurveyFeedback}
        ></Route>
        {/* Administration ROUTES */}
        <PrivateRoute
          exact
          path="/administration/dashboard"
          component={AdministrationDashboard}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          allowedRoles={[1, 2]}
          userRole={userRole}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin || accessPermissions.isSuperAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/administration/addusers"
          component={AddUsers}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          allowedRoles={[1, 2]}
          userRole={userRole}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin || accessPermissions.isSuperAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/administration/edituser/:userId"
          component={EditUsers}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          allowedRoles={[1, 2]}
          userRole={userRole}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin || accessPermissions.isSuperAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/administration/listusers"
          component={Listuser}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin || accessPermissions.isSuperAdmin)
          }
        />
        {/* <PrivateRoute
          exact
          path="/administration/feature-access"
          component={FeatureAccess}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin || accessPermissions.isSuperAdmin)
          }
        /> */}
        {/* <PrivateRoute
          exact
          path="/administration/Leave-list"
          component={LeaveList}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin || accessPermissions.isSuperAdmin)
          }
        /> */}
        {/* Inventory routes */}
        <PrivateRoute
          exact
          path="/inventory/my-items"
          component={InventoryMyItems}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={true}
        />
        <PrivateRoute
          exact
          path="/inventory/dashboard"
          component={InventoryDashboard}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isInventoryAdmin ||
              accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/inventory/items"
          component={InventoryItemList}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2, 3]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isInventoryAdmin ||
              accessPermissions.isInventoryManager ||
              accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/inventory/reports"
          component={InventoryReportType}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/inventory/reports/general"
          component={InventoryGeneralReport}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/inventory/reports/quantity"
          component={InventoryQuantityReport}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/inventory/reports/cost"
          component={InventoryCostReport}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/inventory/reports/signOff"
          component={InventorySignOffReport}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/inventory/reports/log"
          component={InventoryLogReport}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/inventory/item/categories"
          component={InventoryCategoryList}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />
        <PrivateRoute
          exact
          path="/inventory/item/types"
          component={InventoryTypeList}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={
            accessPermissions &&
            (accessPermissions.isAdmin ||
              accessPermissions.isSuperAdmin ||
              accessPermissions.hasHrmRecruitmentAccess ||
              accessPermissions.isHrmAdmin)
          }
        />

        <PrivateRoute
          exact
          path="/settings/reset-password"
          component={ResetMyPassword}
          isLoggedIn={isLogged}
          logoutAction={this.props.appActions.logout}
          user={this.props.appState.loggedUser}
          authorized={true}
        />
        {/* Time tracking routes */}
        {/* <PrivateRoute
          exact
          path="/time-track/dashboard"
          component={TimeTrackDashboard}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2, 3]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
        />
        <PrivateRoute
          exact
          path="/time-track/projects"
          component={TimeTrackProjects}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2, 3]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
        />
        <PrivateRoute
          exact
          path="/time-track/categories"
          component={TimeTrackCategories}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2, 3]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
        />
        <PrivateRoute
          exact
          path="/time-track/tasks"
          component={TimeTrackTask}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2, 3]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
        />
        <PrivateRoute
          exact
          path="/time-track/clients"
          component={TimeTrackClient}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2, 3]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
        />
        <PrivateRoute
          exact
          path="/time-track/team"
          component={TimeTrackUser}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2, 3]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
        />
        <PrivateRoute
          exact
          path="/time-track/reports"
          component={TimeTrackReports}
          isLoggedIn={isLogged}
          allowedRoles={[1, 2, 3]}
          logoutAction={this.props.appActions.logout}
          userRole={userRole}
          user={this.props.appState.loggedUser}
        /> */}
        {/* EXTERNAL AGENT PAGES */}
        <Route exact path="/agent/login">
          {isAgentLogged ? <Redirect to="/agent/addCandidate" /> : <Login />}
        </Route>
        <AgentPrivateRoute
          exact
          path="/agent/addCandidate"
          component={AddAgentCandidate}
          isLoggedIn={isAgentLogged}
          logoutAction={this.props.appActions.logout}
          agent={this.props.appState.hrmAgentLogin.data}
        />
        {/* EXTERNAL VACANCY PAGE */}
        <Route
          exact
          path="/vacancy/:vacancyId"
          component={ViewVacancyExternal}
        />
        <Route
          component={LandingPage}
          logoutAction={this.props.appActions.logout}
        />
      </Switch>
      // </Router>
    );
  }
}

export default Routes;
