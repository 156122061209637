import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import { useForm } from "react-hook-form";
import Button from "../../../ui-components/Button";
import Input from "../../../inputNew";
import FormFeedBack from "../../../ui-components/FormFeedBack";

const classes = new BEMhelper("AddContactModal");
const AddContactModal = (props) => {
  const { title, openModal, toggle, submit } = props;

  const defaultValues = {};

  const { handleSubmit, register, errors } = useForm({
    defaultValues,
  });
  const onSubmit = (values) => submit(values);

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => toggle()}
      centered={true}
      draggable={false}
      size="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => toggle()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>First name</Label>
                  <Input
                    name="firstName"
                    register={register}
                    refdetails={{
                      required: "First name required",
                    }}
                    state={errors.firstName ? "error" : "none"}
                  />
                  {errors.firstName ? (
                    <FormFeedBack feedBack={errors.firstName.message} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Last name</Label>
                  <Input
                    name="lastName"
                    register={register}
                    refdetails={{
                      required: "Last name required",
                    }}
                    state={errors.lastName ? "error" : "none"}
                  />
                  {errors.lastName ? (
                    <FormFeedBack feedBack={errors.lastName.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    name="email"
                    register={register}
                    refdetails={{
                      required: "Email required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    }}
                    state={errors.email ? "error" : "none"}
                  />
                  {errors.email ? (
                    <FormFeedBack feedBack={errors.email.message} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Title</Label>
                  <Input
                    name="title"
                    register={register}
                    refdetails={{
                      required: "Title required",
                    }}
                    state={errors.title ? "error" : "none"}
                  />
                  {errors.title ? (
                    <FormFeedBack feedBack={errors.title.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Office number</Label>
                  <Input
                    name="officeNumber"
                    register={register}
                    refdetails={{
                      required: "Office number required",
                    }}
                    state={errors.officeNumber ? "error" : "none"}
                  />
                  {errors.officeNumber ? (
                    <FormFeedBack feedBack={errors.officeNumber.message} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Mobile number</Label>
                  <Input
                    name="mobileNumber"
                    register={register}
                    refdetails={{
                      required: "Mobile number required",
                    }}
                    state={errors.mobileNumber ? "error" : "none"}
                  />
                  {errors.mobileNumber ? (
                    <FormFeedBack feedBack={errors.mobileNumber.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Fax number</Label>
                  <Input
                    name="faxNumber"
                    register={register}
                    refdetails={{
                      required: "Fax number required",
                    }}
                    state={errors.faxNumber ? "error" : "none"}
                  />
                  {errors.faxNumber ? (
                    <FormFeedBack feedBack={errors.faxNumber.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button submit>Submit</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddContactModal;
