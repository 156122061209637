// import {
//   dashboardData,
//   featureAccessRole,
//   addFeatureAccess,
//   featureAccessUsers,
//   leaveList,
// } from "./actionCreators";
// import adminDashboardReducer from "./reducer/adminDashboardReducer";
// import featureAccessRoleReducer from "./reducer/featureAccessRoleReducer";
// import addFeatureAccessReducer from "./reducer/addFeatureAccessReducer";
// import featureAccessUsersReducer from "./reducer/featureAccessUsersReducer";
// import leaveListReducer from "./reducer/leaveListReducer";
// import { adminSaga } from "./saga/adminSaga";

// const adminModule = {
//   actions: {
//     dashboardData,
//     featureAccessRole,
//     addFeatureAccess,
//     featureAccessUsers,
//     leaveList,
//   },
//   reducers: {
//     adminDashboardReducer: adminDashboardReducer,
//     featureAccessRoleReducer: featureAccessRoleReducer,
//     addFeatureAccessReducer: addFeatureAccessReducer,
//     featureAccessUsersReducer: featureAccessUsersReducer,
//     leaveListReducer: leaveListReducer,
//   },
//   sagas: {
//     adminSaga: adminSaga,
//   },
// };

// export default adminModule;

import commonModule from "./common";
import userModule from "./user";
import dashboardModule from "./dashboard";

const adminModule = {
  commonModule,
  userModule,
  dashboardModule,
};

export default adminModule;
