import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import { Container, Row, Col, Alert } from "reactstrap";
import BtnNormal from "../../button";

const ApplyLeaveModal = (props) => {
  const {
    className,
    title,
    leaveBalanceList,
    openModal,
    toggle,
    onSubmit,
    applyingStatus,
    leaveDateTypeList,
  } = props;

  const [avaiableLeaves, setAvailability] = useState(0);
  const [showHafType, setHalfType] = useState(false);

  const onValidSubmit = (event, values) => {
    onSubmit(values);
  };

  const onInvalidSubmit = (values) => {};

  const checkAvailability = (e) => {
    if (e.target.value && e.target.value !== "null") {
      const leave = leaveBalanceList.data.filter(
        (leave) => leave.leaveTypeId === parseInt(e.target.value)
      );
      setAvailability(leave[0].available);
    }
  };

  const clearState = () => {
    toggle();
    setAvailability(0);
  };

  const leaveDateTypeChange = (e) => {
    if (parseInt(e.target.value) === 1) {
      setHalfType(true);
    } else {
      setHalfType(false);
    }
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        toggle={() => clearState()}
        className={className}
      >
        <ModalHeader toggle={() => clearState()}>{title}</ModalHeader>
        <AvForm onValidSubmit={onValidSubmit} onInvalidSubmit={onInvalidSubmit}>
          <ModalBody>
            {leaveBalanceList.loading || leaveDateTypeList.loading ? (
              <p>Add spinner here.....</p>
            ) : (
              <Container fluid className="add-user-wrapper">
                {applyingStatus.error ? (
                  <Row>
                    <Col sm={12}>
                      <Alert color="danger">
                        <p>{applyingStatus.error}</p>
                      </Alert>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col sm={8}>
                    <AvGroup>
                      <AvInput
                        type="select"
                        name="leaveTypeId"
                        id="leaveTypeId"
                        onChange={checkAvailability}
                        required
                      >
                        <option value="null">Leave type</option>
                        {leaveBalanceList.data.map((leave, key) => {
                          return (
                            <option value={leave.leaveTypeId} key={key}>
                              {leave.type}
                            </option>
                          );
                        })}
                      </AvInput>
                    </AvGroup>
                  </Col>
                  <Col sm={4}>
                    <p>Available: {avaiableLeaves}</p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <AvGroup>
                      <AvInput
                        type="select"
                        name="leaveDateTypeId"
                        id="leaveDateTypeId"
                        onChange={leaveDateTypeChange}
                        required
                      >
                        <option value="null">Leave date type</option>
                        {leaveDateTypeList.data.map((type, key) => {
                          return (
                            <option value={type.leaveDateTypeId} key={key}>
                              {type.dateType}
                            </option>
                          );
                        })}
                      </AvInput>
                    </AvGroup>
                  </Col>
                </Row>
                {showHafType ? (
                  <Row>
                    <Col sm={12}>
                      <AvGroup>
                        <AvInput type="select" name="leaveHalf" id="leaveHalf">
                          <option value="First half">First half</option>
                          <option value="Second half">Second half</option>
                        </AvInput>
                      </AvGroup>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col sm={12}>
                    <AvGroup>
                      <AvInput name="leaveDate" type="date" required />
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <AvGroup>
                      <AvInput
                        name="leaveReason"
                        type="textarea"
                        placeholder="Reason"
                        required
                      />
                    </AvGroup>
                  </Col>
                </Row>
              </Container>
            )}
          </ModalBody>
          <ModalFooter>
            <BtnNormal
              color="primary"
              text="Apply"
              disabled={avaiableLeaves === 0 ? true : false}
              loading={applyingStatus.loading}
            />
          </ModalFooter>
        </AvForm>
      </Modal>
    </div>
  );
};

export default ApplyLeaveModal;
