export const GET_HRM_AGENTS_REQUEST = "GET_HRM_AGENTS_REQUEST";
export const GET_HRM_AGENTS_SUCCESS = "GET_HRM_AGENTS_SUCCESS";
export const GET_HRM_AGENTS_FAILURE = "GET_HRM_AGENTS_FAILURE";

export const CREATE_HRM_AGENTS_REQUEST = "CREATE_HRM_AGENTS_REQUEST";
export const CREATE_HRM_AGENTS_SUCCESS = "CREATE_HRM_AGENTS_SUCCESS";
export const CREATE_HRM_AGENTS_FAILURE = "CREATE_HRM_AGENTS_FAILURE";

export const UPDATE_HRM_AGENTS_REQUEST = "UPDATE_HRM_AGENTS_REQUEST";
export const UPDATE_HRM_AGENTS_SUCCESS = "UPDATE_HRM_AGENTS_SUCCESS";
export const UPDATE_HRM_AGENTS_FAILURE = "UPDATE_HRM_AGENTS_FAILURE";

export const AGENT_LOGIN_REQUEST = "AGENT_LOGIN_REQUEST";
export const AGENT_LOGIN_SUCCESS = "AGENT_LOGIN_SUCCESS";
export const AGENT_LOGIN_FAILURE = "AGENT_LOGIN_FAILURE";

export const GET_VACANCIES_AGENT_REQUEST = "GET_VACANCIES_AGENT_REQUEST";
export const GET_VACANCIES_AGENT_SUCCESS = "GET_VACANCIES_AGENT_SUCCESS";
export const GET_VACANCIES_AGENT_FAILURE = "GET_VACANCIES_AGENT_FAILURE";

export const CREATE_HRM_CANDIDATE_AGENT_REQUEST =
  "CREATE_HRM_CANDIDATE_AGENT_REQUEST";
export const CREATE_HRM_CANDIDATE_AGENTS_SUCCESS =
  "CREATE_HRM_CANDIDATE_AGENTS_SUCCESS";
export const CREATE_HRM_CANDIDATE_AGENTS_FAILURE =
  "CREATE_HRM_CANDIDATE_AGENTS_FAILURE";

export const AGENT_DELETE_AGREEMENT_FILE_REQUEST =
  "AGENT_DELETE_AGREEMENT_FILE_REQUEST";
export const AGENT_DELETE_AGREEMENT_FILE_SUCCESS =
  "AGENT_DELETE_AGREEMENT_FILE_SUCCESS";
export const AGENT_DELETE_AGREEMENT_FILE_FAILURE =
  "AGENT_DELETE_AGREEMENT_FILE_FAILURE";
