import React, { Component } from "react";
import CustomTable from "../../../components/table";
import InfoModal from "../../../components/modals/infoModal";
import Button from "../../../components/ui-components/Button";
import Loader from "../../../components/ui-components/Loader";
import { Container, Row, Col, Table, Badge, ButtonGroup } from "reactstrap";
import userModule from "../../../../redux/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import leaveModule from "../../../../redux/leave";
import BEMhelper from "react-bem-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./listUsers.scss";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserModal: false,
    };
  }

  getSingleUser = (user) => {
    this.props.getSingleUser(user.userId);
    this.props.leaveBalance(user.userId);
    this.setState({
      showUserModal: true,
    });
  };

  getModalBody = () => {
    if (this.props.singleUser.userDetails) {
      const userDetails = this.props.singleUser.userDetails;
      return (
        <React.Fragment>
          <Row className="details">
            <Col sm={6}>
              <h4>
                <span>First name</span>: {userDetails.user.firstName}
              </h4>
              <h4>
                <span>Email</span>: {userDetails.user.email}
              </h4>
              <h4>
                <span>Join date</span>:{" "}
                {moment(userDetails.user.joinDate).format("YYYY-MM-DD")}
              </h4>
            </Col>
            <Col sm={6}>
              <h4>
                <span>Last name</span>: {userDetails.user.lastName}
              </h4>
              <h4>
                <span>Designation</span>: {userDetails.user.designation.name}
              </h4>
              <h4>
                <span>Status</span>:{" "}
                {userDetails.user.resigned ? (
                  <Badge color="danger" pill>
                    Inactive
                  </Badge>
                ) : (
                  <Badge color="success" pill>
                    Active
                  </Badge>
                )}
              </h4>
            </Col>
          </Row>
          <Container className="tableLeave">
            <Row>
              {this.props.leaveBalanceGroup.loading ? (
                <p>Loading....</p>
              ) : this.props.leaveBalanceGroup.data.length > 0 ? (
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Entitled</th>
                      <th>Available</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.leaveBalanceGroup.data.map((leave, key) => {
                      return (
                        <tr key={key}>
                          <td>{leave.type}</td>
                          <td>{leave.entitled}</td>
                          <td>{leave.available}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <p>No available leave balance records found for this user</p>
              )}
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  };

  toggle = () => {
    this.setState({
      showUserModal: false,
    });
  };

  getTableHeading = () => {
    return [
      "Employee Id",
      "First Name",
      "Last Name",
      "Email",
      "Designation",
      "Role",
      "",
    ];
  };

  getTableContent = (data) => {
    return data.map((row, key) => {
      return (
        <tr
          key={key}
          // onClick={() => this.getSingleUser(row)}
          className="value-row"
        >
          <td>{row.employeeId}</td>
          <td>{row.firstName}</td>
          <td>{row.lastName}</td>
          <td>{row.email}</td>
          <td>{row.designation.name}</td>
          <td>{row.role.name}</td>
          <td>
            <ButtonGroup size="sm">
              <Link to={`/administration/edituser/${row.userId}`}>
                <Button outline color="info" size="sm">
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </Link>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  componentDidMount() {
    this.props.getUserList();
  }

  render() {
    const classes = new BEMhelper("userList");
    return (
      <React.Fragment>
        {this.state.showUserModal ? (
          <InfoModal
            openModal={this.state.showUserModal}
            toggle={this.toggle}
            title={"User Information"}
            body={this.getModalBody()}
            bodyLoading={this.props.singleUser.loading}
          />
        ) : null}
        <Row className="text-left">
          <Col sm={12} className="text-left">
            <h2 className="list-header-title">User List</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="hr"></div>
            {this.props.userList.loading ? (
              <div {...classes("loader")}>
                <Loader />
              </div>
            ) : (
              <CustomTable
                content={this.getTableContent(this.props.userList.userList)}
                tableHeadings={this.getTableHeading()}
              />
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserList: userModule.actions.getUserList,
      getSingleUser: userModule.actions.getSingleUser,
      leaveBalance: leaveModule.actions.leaveBalance,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    userList: state.userList,
    singleUser: state.singleUser,
    leaveBalanceGroup: state.leaveBalanceNew,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(UserList);
