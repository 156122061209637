import { getCategories, createCategory, updateCategory } from "./category";
import {
  getItems,
  createItem,
  updateItem,
  deleteItem,
  deleteItemFile,
  createItemsBulk,
  swopUserItems,
} from "./item";
import { getReport, getCustomReport, downloadCustomReport } from "./report";
import { getDashboardData } from "./dashboard";
import { getTypes, createType, updateType } from "./type";

const inventoryApis = {
  getCategories,
  getItems,
  createItem,
  updateItem,
  deleteItem,
  getReport,
  getDashboardData,
  deleteItemFile,
  getCustomReport,
  downloadCustomReport,
  getTypes,
  createType,
  createItemsBulk,
  swopUserItems,
  createCategory,
  updateCategory,
  updateType,
};

export default inventoryApis;
