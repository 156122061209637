import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import Select from "react-select";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../ui-components/Button";
import CustomTable from "../../../table";

const classes = new BEMhelper("AddUserModal");
const AddTeamMemberModal = (props) => {
  const { title, openModal, toggle, submit, userList, objectToEdit } = props;

  let defaultTeam = null;

  if (objectToEdit.team.length > 0) {
    const team = [];
    _.forEach(objectToEdit.team, function (member) {
      const user = member.timeTrackUser.user;
      team.push({
        label: `${user.firstName} ${user.lastName}`,
        value: member.timeTrackUser,
      });
    });
    defaultTeam = team;
  }

  const userOptions = userList.map((user) => {
    return {
      value: user,
      label: `${user.user.firstName} ${user.user.lastName}`,
    };
  });

  const defaultValues = {
    team: defaultTeam,
  };

  const { handleSubmit, errors, control, watch } = useForm({
    defaultValues,
  });

  const teamWatcher = watch(["team"]);

  const onSubmit = (values) => submit(values);

  const getTableContent = () => {
    if (teamWatcher.team) {
      return teamWatcher.team.map((row, key) => {
        return (
          <tr key={key} className="value-row">
            <td>{row.label}</td>
            <td>{row.value.permissionType.type}</td>
            <td>{row.value.user.designation.name}</td>
          </tr>
        );
      });
    }
  };

  return (
    <div>
      <Modal
        {...classes("modal")}
        isOpen={openModal}
        toggle={() => toggle()}
        centered={true}
        draggable={false}
        size="lg"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={() => toggle()} className="modal-header">
            {title}
          </ModalHeader>
          <ModalBody>
            <Container fluid>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Team</Label>
                    <Controller
                      as={
                        <Select
                          placeholder="Select member"
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              border: `1px solid ${
                                errors.team ? "#dc3545" : "#cad2dd"
                              }`,
                            }),
                          }}
                        />
                      }
                      options={userOptions}
                      name="team"
                      isClearable
                      isMulti
                      control={control}
                      onChange={([selected]) => {
                        return { value: selected };
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <CustomTable
                    content={getTableContent()}
                    tableHeadings={["Name", "Member type", "Designation"]}
                  />
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button submit>Submit</Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default AddTeamMemberModal;
