import {
  GETUSER_REQUEST,
  GETUSER_SUCCESS,
  RESET_SINGLE_USER_REQUEST,
} from "../actionTypes";

const initialState = {
  loading: true,
  userDetails: null,
  error: null,
};

const singleUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETUSER_REQUEST:
      return {
        ...state,
        loading: true,
        userDetails: null,
        error: null,
      };
    case GETUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.data,
        error: null,
      };
    case RESET_SINGLE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        userDetails: null,
        error: null,
      };
    default:
      return state;
  }
};

export default singleUserReducer;
