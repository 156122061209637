import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import BEMhelper from "react-bem-helper";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FaCog } from "react-icons/fa";

const AppHeader = (props) => {
  const [isCollapsed, setCollapsed] = useState(false);
  const toggle = () => setCollapsed(isCollapsed ? false : true);
  const classes = new BEMhelper("appHeader");
  return (
    <div {...classes("main-content-header")}>
      <Row>
        <Col sm={8}>
          <div className="search">
            {/* <FontAwesomeIcon icon={faSearch}/>
                        <input className="form-control" type="text" placeholder="Search" aria-label="Search" /> */}
          </div>
        </Col>
        <Col sm={4}>
          <div className="rightWrapper">
            {/* <div className="notification">
              <a alt="notification">
                <img
                  src={require("../../../assets/img/notification.png")}
                  alt="logout"
                />
              </a>
              <div className="buble">
                <span>1</span>
              </div>
            </div> */}
            {/* <div className="logoutbtn" onClick={() => props.logoutAction()}>
              <a alt="logout">
                <img
                  src={require("../../../assets/img/logout.png")}
                  alt="logout"
                />
              </a>
              <div className="bubleBox">
                <img
                  src={require("../../../assets/img/trianglecolor.png")}
                  alt="logout"
                />
                <p>Logout</p>
              </div>
            </div> */}
            <div className="logoutbtn">
              <ButtonDropdown isOpen={isCollapsed} toggle={toggle}>
                <DropdownToggle caret size="md">
                  <FaCog />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() =>
                      props.history.push(`/settings/reset-password`)
                    }
                  >
                    Reset Password
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => props.logoutAction()}>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AppHeader;
