import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/hrm/agent`;

export const getAgents = async (token) => {
  return axios({
    method: "GET",
    url: `${apiUrl}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createAgent = async (token, agentDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}`,
    headers: {
      "x-access-token": token,
      "'content-type'": "multipart/form-data",
    },
    data: agentDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const updateAgent = async (token, agentDetails) => {
  return axios({
    method: "PUT",
    url: `${apiUrl}/${agentDetails.get("agentId")}`,
    headers: {
      "x-access-token": token,
    },
    data: agentDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const agentLogin = async (loginDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/login`,
    data: loginDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getAgentVacancies = async (token) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/vacancy`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createAgentCandidate = async (token, candidateDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/candidate`,
    headers: {
      "x-access-token": token,
      "'content-type'": "multipart/form-data",
    },
    data: candidateDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const deleteAgentAgreementFile = async (token, fileDetails) => {
  return axios({
    method: "DELETE",
    url: `${apiUrl}/agreement`,
    headers: {
      "x-access-token": token,
    },
    data: fileDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
