import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Alert,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import Button from "../../../ui-components/Button";
import Dropzone from "../../../ui-components/Dropzone";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import "./bulkAddModal.scss";

const classes = new BEMhelper("BulkAddModal");
const BulkAddModal = (props) => {
  const {
    title,
    openModal,
    onClose,
    onConfirm,
    size,
    errors,
    errorCsvData,
  } = props;

  const [file, setfile] = useState(null);

  const addFile = (files) => {
    const maxSize = parseInt(5) * 1024 * 1024;

    if (files.length > 1) {
      toast.warn(`Please select only 1 file`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      if (files[0].size > maxSize) {
        toast.warn(`${files[0].name} is too big. max file size is 5MB`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        if (!files[0].name.match("^.+.(csv)$")) {
          toast.warn(
            `File format is incorrect -> ${files[0].name}. Supported format is csv`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        } else {
          setfile(files[0]);
        }
      }
    }
  };

  return (
    <div>
      <Modal
        {...classes("modal")}
        isOpen={openModal}
        toggle={() => onClose()}
        centered={true}
        draggable={false}
        size={size ? size : "md"}
      >
        <ModalHeader toggle={() => onClose()}>
          {title ? title : "Bulk Upload"}
        </ModalHeader>
        <ModalBody>
          <div {...classes("dropzone")}>
            <Dropzone
              addedFiles={addFile}
              disabled={false}
              bodyText={
                file
                  ? file.name
                  : "Drag 'n' drop a .csv file here, or click to select a file"
              }
            />
          </div>
          <div {...classes("errorzone")}>
            {errors ? (
              <Alert color="danger">
                <CSVLink
                  data={errorCsvData.csvData}
                  headers={errorCsvData.csvHeaders}
                  filename={"bulk-upload-error-list.csv"}
                >
                  There are errors in the file you uploaded. Click here to view!
                </CSVLink>
              </Alert>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <a
              href={require("../../../../../assets/files/inventory/bulk-upload-file-preparation.pdf")}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <Button color="blue" {...classes("download-sample-Btn")}>
                {"Download Instructions"}
              </Button>
            </a>

            <a
              href={require("../../../../../assets/files/inventory/item_upload_bulk_sample.csv")}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <Button color="orange" {...classes("download-sample-Btn")}>
                {"Download Sample"}
              </Button>
            </a>

            <Button onClick={() => onConfirm(file)} disabled={file === null}>
              {"Upload"}
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BulkAddModal;
