import * as actions from "./actionTypes";

export const getProjects = () => ({
  type: actions.GET_PROJECTS_REQUEST,
});

export const createProject = (data) => ({
  type: actions.CREATE_PROJECT_REQUEST,
  payload: data,
});

export const updateProject = (data) => ({
  type: actions.UPDATE_PROJECT_REQUEST,
  payload: data,
});

export const deleteProject = (data) => ({
  type: actions.DELETE_PROJECT_REQUEST,
  payload: data,
});

export const assignTeamToProject = (data) => ({
  type: actions.ASSIGN_TEAM_TO_PROJECT_REQUEST,
  payload: data,
});
