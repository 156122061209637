import React, { useState } from "react";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import Input from "../../../../components/inputNew";
import CustomTable from "../../../../components/table";
import Button from "../../../../components/ui-components/Button";
import FormFeedBack from "../../../../components/ui-components/FormFeedBack";
import Textarea from "../../../../components/ui-components/Textarea";
import Toggle from "../../../../components/ui-components/Toggle";
import BEMhelper from "react-bem-helper";
import DatePicker from "react-datepicker";
import "../Addusers/AddUser.scss";
import AddWorkplaceModal from "../../../../components/modals/inventory/addWorkplaceModal";
import AddDesignationModal from "../../../../components/modals/administration/addDesignation";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const classes = new BEMhelper("AddUserStepTwo");
const StepTwo = (props) => {
  const {
    submit,
    designations,
    departments,
    roles,
    changeTab,
    featureAccessRoles,
  } = props;
  let selectedEmployeeCategory = null;
  let selectedSource = null;
  let selectedDesignation = null;
  let selectedDepartment = null;
  let selectedRole = null;
  let selectedFeatureRole = [];

  const designationOptions = designations.map((designation) => {
    return {
      value: designation.designationId,
      label: designation.name,
    };
  });

  const departmentOptions = departments.map((department) => {
    return {
      value: department.departmentId,
      label: department.name,
    };
  });

  const roleOptions = roles.map((role) => {
    return {
      value: role.roleId,
      label: role.name,
    };
  });

  const featureRolesOptions = featureAccessRoles.map((role) => {
    return {
      value: role.featureAccessRoleId,
      label: role.roleType,
    };
  });

  const employeeCategories = [
    {
      value: "permanent",
      label: "Permanent",
    },
    {
      value: "probation",
      label: "Probation",
    },
    {
      value: "contract",
      label: "Contract",
    },
  ];

  const sourcesOfHire = [
    {
      value: "newspaper",
      label: "Newspaper",
    },
    {
      value: "media",
      label: "Media",
    },
    {
      value: "friend",
      label: "Friend",
    },
    {
      value: "direct",
      label: "Direct",
    },
    {
      value: "agency",
      label: "Agency",
    },
    {
      value: "referral",
      label: "Referral",
    },
  ];

  if (props.isEditMode) {
    _.forEach(employeeCategories, function (category) {
      if (category.value === props.stepTwoData.employeecategory.value) {
        selectedEmployeeCategory = category;
      }
    });

    _.forEach(sourcesOfHire, function (source) {
      if (source.value === props.stepTwoData.source.value) {
        selectedSource = source;
      }
    });

    _.forEach(designationOptions, function (designation) {
      if (designation.value === props.stepTwoData.designation.value) {
        selectedDesignation = designation;
      }
    });

    _.forEach(departmentOptions, function (department) {
      if (department.value === props.stepTwoData.department.value) {
        selectedDepartment = department;
      }
    });

    _.forEach(roleOptions, function (role) {
      if (role.value === props.stepTwoData.role.value) {
        selectedRole = role;
      }
    });

    if (props.stepTwoData.featureRoles) {
      _.forEach(props.stepTwoData.featureRoles, function (preRole) {
        _.forEach(featureRolesOptions, function (role) {
          if (role.value === preRole.value) {
            selectedFeatureRole.push(role);
          }
        });
      });
    }
  }

  const defaultValues = {
    employeecategory: selectedEmployeeCategory,
    source: selectedSource,
    joindate: props.isEditMode ? new Date(props.stepTwoData.joindate) : null,
    designation: selectedDesignation,
    department: selectedDepartment,
    role: selectedRole,
    officeemail: props.isEditMode ? props.stepTwoData.officeemail : null,
    employeeId: props.isEditMode ? props.stepTwoData.employeeId : null,
    password: props.isEditMode ? props.stepTwoData.password : null,
    notes: props.isEditMode ? props.stepTwoData.notes : null,
    resigned: props.isEditMode ? props.stepTwoData.resigned : false,
    emergencycontactname: props.isEditMode
      ? props.stepTwoData.emergencycontactname
      : null,
    relationship: props.isEditMode ? props.stepTwoData.relationship : null,
    contactnumber: props.isEditMode ? props.stepTwoData.contactnumber : null,
    spousename: props.isEditMode ? props.stepTwoData.spousename : null,
    spousebirthday:
      props.isEditMode && props.stepTwoData.spousebirthday
        ? new Date(props.stepTwoData.spousebirthday)
        : null,
    featureRoles: selectedFeatureRole,
  };

  const {
    handleSubmit,
    register,
    errors,
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues,
  });
  const [showWorkplaceModal, setShowWorkplaceModal] = useState(false);
  const [showDesignationModal, setShowDesignationModal] = useState(false);
  const [workPlaces, setworkPlaces] = useState(
    props.isEditMode ? props.stepTwoData.workplaces : []
  );

  const onSubmit = (values) => {
    values.workplaces = workPlaces;
    submit(values);
  };

  const addWorkplace = (values) => {
    const places = workPlaces;
    places.push({
      companyName: values.companyname,
      designation: values.designation,
      from: moment(values.from).format("YYYY-MM-DD"),
      to: moment(values.to).format("YYYY-MM-DD"),
      description: values.notes,
    });
    setworkPlaces(places);
    setShowWorkplaceModal(false);
  };

  const removeWorkplace = (placeIndex) => {
    const result = workPlaces.filter(
      (workPlace, index) => index !== placeIndex
    );
    setworkPlaces(result);
  };

  const getWorkExperiences = (workPlaces) => {
    return workPlaces.map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>{row.companyName}</td>
          <td>{row.designation}</td>
          <td>{row.from}</td>
          <td>{row.to}</td>
          <td>{row.description}</td>
          <td>
            <Button color="red" size="xs" onClick={() => removeWorkplace(key)}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  const stepOneWatcher = watch(["joindate", "spousebirthday"]);

  return (
    <div>
      {showWorkplaceModal ? (
        <AddWorkplaceModal
          openModal={showWorkplaceModal}
          toggle={() => setShowWorkplaceModal(false)}
          title={"Add workplace"}
          submit={addWorkplace}
        />
      ) : null}
      {showDesignationModal ? (
        <AddDesignationModal
          openModal={showDesignationModal}
          toggle={() => setShowDesignationModal(false)}
          title="Create Designation"
        />
      ) : null}
      <form
        onSubmit={handleSubmit(onSubmit)}
        {...classes("formTwo")}
        autocomplete="off"
      >
        <Container fluid>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Employee Category</Label>
                <Controller
                  as={
                    <Select
                      placeholder="Select category"
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          border: `1px solid ${
                            errors.employeecategory ? "#dc3545" : "#cad2dd"
                          }`,
                        }),
                      }}
                    />
                  }
                  options={employeeCategories}
                  name="employeecategory"
                  isClearable
                  control={control}
                  onChange={([selected]) => {
                    return { value: selected };
                  }}
                  rules={{
                    required: "Category is required",
                  }}
                />
                {errors.employeecategory ? (
                  <FormFeedBack feedBack={errors.employeecategory.message} />
                ) : null}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Source of Hire</Label>
                <Controller
                  as={
                    <Select
                      placeholder="Select source"
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          border: `1px solid ${
                            errors.source ? "#dc3545" : "#cad2dd"
                          }`,
                        }),
                      }}
                    />
                  }
                  options={sourcesOfHire}
                  name="source"
                  isClearable
                  control={control}
                  onChange={([selected]) => {
                    return { value: selected };
                  }}
                  rules={{
                    required: "Source is required",
                  }}
                />
                {errors.source ? (
                  <FormFeedBack feedBack={errors.source.message} />
                ) : null}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Joined Date</Label>
                <Controller
                  as={
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      placeholderText={"Joined date"}
                      className={`form-control ${
                        errors.joindate ? "date-error" : ""
                      }`}
                      selected={getValues().joindate}
                    />
                  }
                  valueName="selected"
                  name="joindate"
                  control={control}
                  onChange={(date) => setValue("joindate", date)}
                  rules={{
                    required: "Joined date is required",
                  }}
                />
                {errors.joindate ? (
                  <FormFeedBack feedBack={errors.joindate.message} />
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>
                  Designation{" "}
                  <span
                    className="create-designation"
                    onClick={() => setShowDesignationModal(true)}
                  >
                    Create
                  </span>
                </Label>
                <Controller
                  as={
                    <Select
                      placeholder="Select designation"
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          border: `1px solid ${
                            errors.designation ? "#dc3545" : "#cad2dd"
                          }`,
                        }),
                      }}
                    />
                  }
                  options={designationOptions}
                  name="designation"
                  isClearable
                  control={control}
                  onChange={([selected]) => {
                    return { value: selected };
                  }}
                  rules={{
                    required: "Designation is required",
                  }}
                />
                {errors.designation ? (
                  <FormFeedBack feedBack={errors.designation.message} />
                ) : null}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Department</Label>
                <Controller
                  as={
                    <Select
                      placeholder="Select department"
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          border: `1px solid ${
                            errors.department ? "#dc3545" : "#cad2dd"
                          }`,
                        }),
                      }}
                    />
                  }
                  options={departmentOptions}
                  name="department"
                  isClearable
                  control={control}
                  onChange={([selected]) => {
                    return { value: selected };
                  }}
                  rules={{
                    required: "Department is required",
                  }}
                />
                {errors.department ? (
                  <FormFeedBack feedBack={errors.department.message} />
                ) : null}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Role</Label>
                <Controller
                  as={
                    <Select
                      placeholder="Select role"
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          border: `1px solid ${
                            errors.role ? "#dc3545" : "#cad2dd"
                          }`,
                        }),
                      }}
                    />
                  }
                  options={roleOptions}
                  name="role"
                  isClearable
                  control={control}
                  onChange={([selected]) => {
                    return { value: selected };
                  }}
                  rules={{
                    required: "Role is required",
                  }}
                />
                {errors.role ? (
                  <FormFeedBack feedBack={errors.role.message} />
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Office Email</Label>
                <Input
                  name="officeemail"
                  register={register}
                  refdetails={{
                    required: "Email required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  }}
                  state={errors.officeemail ? "error" : "none"}
                />
                {errors.officeemail ? (
                  <FormFeedBack feedBack={errors.officeemail.message} />
                ) : null}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Employee ID</Label>
                <Input
                  name="employeeId"
                  register={register}
                  refdetails={{
                    required: "Employee ID required",
                  }}
                  state={errors.employeeId ? "error" : "none"}
                />
                {errors.employeeId ? (
                  <FormFeedBack feedBack={errors.employeeId.message} />
                ) : null}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  type="password"
                  name="password"
                  register={register}
                  refdetails={{
                    required: "Password required",
                    minLength: {
                      value: 6,
                      message: "Must be atleast 6 characters long",
                    },
                  }}
                  state={errors.password ? "error" : "none"}
                  autoComplete="new-password"
                />
                {errors.password ? (
                  <FormFeedBack feedBack={errors.password.message} />
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>About</Label>
                <Textarea
                  name="notes"
                  register={register}
                  state={errors.notes ? "error" : "none"}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Feature Access Roles</Label>
                <Controller
                  as={
                    <Select
                      placeholder="Select feature roles"
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          border: `1px solid ${
                            errors.featureRoles ? "#dc3545" : "#cad2dd"
                          }`,
                        }),
                      }}
                    />
                  }
                  options={featureRolesOptions}
                  name="featureRoles"
                  isClearable
                  isMulti
                  control={control}
                  onChange={([selected]) => {
                    return { value: selected };
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Resigned</Label>
                <Controller
                  as={
                    <Toggle
                      checked={
                        props.isEditMode ? props.stepTwoData.resigned : false
                      }
                    />
                  }
                  name="resigned"
                  control={control}
                  onChange={([selected]) => {
                    return { value: selected };
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="hr"></div>
          <Row>
            <Col md={12}>
              <h3>Emergency Contact Details</h3>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  name="emergencycontactname"
                  register={register}
                  refdetails={{
                    required: "Name required",
                  }}
                  state={errors.emergencycontactname ? "error" : "none"}
                />
                {errors.emergencycontactname ? (
                  <FormFeedBack
                    feedBack={errors.emergencycontactname.message}
                  />
                ) : null}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Relationship</Label>
                <Input
                  name="relationship"
                  register={register}
                  refdetails={{
                    required: "Relationship required",
                  }}
                  state={errors.relationship ? "error" : "none"}
                />
                {errors.relationship ? (
                  <FormFeedBack feedBack={errors.relationship.message} />
                ) : null}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Contact Number</Label>
                <Input
                  name="contactnumber"
                  register={register}
                  refdetails={{
                    required: "Contact number required",
                  }}
                  state={errors.contactnumber ? "error" : "none"}
                />
                {errors.contactnumber ? (
                  <FormFeedBack feedBack={errors.contactnumber.message} />
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <div className="hr"></div>
          <Row>
            <Col md={12}>
              <h3>Spouse Details</h3>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  name="spousename"
                  register={register}
                  state={errors.spousename ? "error" : "none"}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Date of Birth</Label>
                <Controller
                  as={
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      placeholderText={"Birth date"}
                      className={`form-control ${
                        errors.spousebirthday ? "date-error" : ""
                      }`}
                      selected={getValues().spousebirthday}
                    />
                  }
                  valueName="selected"
                  name="spousebirthday"
                  control={control}
                  onChange={(date) => setValue("spousebirthday", date)}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="hr"></div>
          <Row>
            <Col md={3}>
              <h3>Work Experience</h3>
            </Col>
            <Col md={9}>
              <Button size="xs" onClick={() => setShowWorkplaceModal(true)}>
                Add workplace
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <CustomTable
                content={getWorkExperiences(workPlaces)}
                tableHeadings={[
                  "Company name",
                  "Designation",
                  "From",
                  "To",
                  "Description",
                  "",
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup {...classes("buttonGroup")}>
                <Button color="light-grey" onClick={() => changeTab(0)}>
                  Prev
                </Button>{" "}
                <Button submit>Next</Button>
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};

export default StepTwo;
