import {
  DELETE_INVENTORY_ITEM_REQUEST,
  DELETE_INVENTORY_ITEM_SUCCESS,
  DELETE_INVENTORY_ITEM_FAILURE,
  DELETE_INVENTORY_ITEM_FILE_REQUEST,
  DELETE_INVENTORY_ITEM_FILE_SUCCESS,
  DELETE_INVENTORY_ITEM_FILE_FAILURE,
} from "../../item/actionTypes";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const deleteItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_INVENTORY_ITEM_REQUEST:
    case DELETE_INVENTORY_ITEM_FILE_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case DELETE_INVENTORY_ITEM_SUCCESS:
    case DELETE_INVENTORY_ITEM_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case DELETE_INVENTORY_ITEM_FAILURE:
    case DELETE_INVENTORY_ITEM_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default deleteItemReducer;
