import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";

export function* categorySaga() {
  yield takeLatest(GET_CATEGORIES_REQUEST, getCategories);
  yield takeLatest(CREATE_CATEGORY_REQUEST, createCategory);
  yield takeLatest(UPDATE_CATEGORY_REQUEST, updateCategory);
  yield takeLatest(DELETE_CATEGORY_REQUEST, deleteCategory);
}

function* getCategories() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const categories = yield call(
      apis.timeTrackApis.getCategories,
      loggedUser.userDetails.auth.accessToken
    );
    if (categories.error) {
      yield put({
        type: GET_CATEGORIES_FAILURE,
        error: categories.body.data.error,
      });
    } else {
      yield put({ type: GET_CATEGORIES_SUCCESS, data: categories.body.data });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createCategory(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const category = yield call(
      apis.timeTrackApis.createCategory,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (category.error) {
      yield put({
        type: CREATE_CATEGORY_SUCCESS,
        error: category.body.data.error,
      });
    } else {
      yield put({ type: CREATE_CATEGORY_FAILURE, data: category.body.data });
      yield put({ type: GET_CATEGORIES_REQUEST });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* updateCategory(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const category = yield call(
      apis.timeTrackApis.updateCategory,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (category.error) {
      yield put({
        type: UPDATE_CATEGORY_FAILURE,
        error: category.body.data.error,
      });
    } else {
      yield put({ type: UPDATE_CATEGORY_SUCCESS, data: category.body.data });
      const data = {
        date: details.payload.formValues.date,
      };
      yield put({ type: GET_CATEGORIES_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* deleteCategory(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const category = yield call(
      apis.timeTrackApis.deleteCategory,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues.categoryId
    );
    if (category.error) {
      yield put({
        type: DELETE_CATEGORY_FAILURE,
        error: category.body.data.error,
      });
    } else {
      yield put({ type: DELETE_CATEGORY_SUCCESS, data: category.body.data });
      const data = {
        date: details.payload.formValues.date,
      };
      yield put({ type: GET_CATEGORIES_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
