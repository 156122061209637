import React, { useEffect } from "react";
import { Container, Row, Col, FormGroup, Card, Label } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Input from "../../../../../components/inputNew";
import Button from "../../../../../components/ui-components/Button";
import FormFeedBack from "../../../../../components/ui-components/FormFeedBack";
import BEMhelper from "react-bem-helper";
import agentModule from "../../../../../../redux/hrm/agent";
import Select from "react-select";
import "./addAgentCandidate.scss";

const classes = new BEMhelper("AddAgentCandidate");
const AddAgentCandidate = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(agentModule.actions.getVacancies());
  }, [dispatch]);

  const companyCurrency = props.agent.parentCompany.currency;

  const vacancies = useSelector((state) => state.hrmVacancies);

  const vacancyOptions = vacancies.data.map((vacancy) => {
    return {
      value: vacancy.hrmVacancyId,
      label: vacancy.name,
    };
  });

  const defaultValues = {
    appliedVacancy: null,
  };

  const { handleSubmit, register, errors, control, reset } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values.candidateName);
    if (values.noticePeriod) {
      formData.append("noticePeriod", values.noticePeriod);
    }
    formData.append("appliedVacancy", values.vacancy.value);
    formData.append("resume", values.resume[0]);
    if (values.expectedSalary) {
      formData.append("expectedSalary", values.expectedSalary);
    }
    formData.append("sourceOfHire", "agency");
    formData.append("email", values.email);

    if (values.phone) {
      formData.append("phone", values.phone);
    }
    const data = {
      formValues: formData,
      restForm: reset,
    };
    dispatch(agentModule.actions.createCandidate(data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...classes("form")}>
      <Container fluid>
        <Row>
          <Col sm={12} md={{ size: 6, offset: 3 }} className="text-center">
            <div className="login-form-card">
              <h1 className="login-form-header">Create Candidate</h1>
              <Card body>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Candidate Name</Label>
                      <Input
                        name="candidateName"
                        register={register}
                        refdetails={{
                          required: "Candidate name required",
                        }}
                        state={errors.candidateName ? "error" : "none"}
                      />
                      {errors.candidateName ? (
                        <FormFeedBack feedBack={errors.candidateName.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Notice Period</Label>
                      <Input
                        name="noticePeriod"
                        register={register}
                        refdetails={{
                          required: "Candidate notice period required",
                        }}
                        state={errors.noticePeriod ? "error" : "none"}
                      />
                      {errors.noticePeriod ? (
                        <FormFeedBack feedBack={errors.noticePeriod.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                        name="email"
                        register={register}
                        refdetails={{
                          required: "Email required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        }}
                        state={errors.email ? "error" : "none"}
                      />
                      {errors.email ? (
                        <FormFeedBack feedBack={errors.email.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Phone</Label>
                      <Input
                        name="phone"
                        register={register}
                        state={errors.phone ? "error" : "none"}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Expected Salary ({companyCurrency})</Label>
                      <Input
                        type="number"
                        name="expectedSalary"
                        register={register}
                        state={errors.expectedSalary ? "error" : "none"}
                        refdetails={{
                          max: {
                            value: 10000000,
                            message: "Maximum salary limit is 10000000",
                          },
                        }}
                      />
                      {errors.expectedSalary ? (
                        <FormFeedBack
                          feedBack={errors.expectedSalary.message}
                        />
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Candidate Resume</Label>
                      <Input
                        type="file"
                        accept="application/pdf"
                        name="resume"
                        register={register}
                        refdetails={{
                          required: "Candidate resume required",
                        }}
                        state={errors.resume ? "error" : "none"}
                        className="resume-upload"
                      />
                      {errors.resume ? (
                        <FormFeedBack feedBack={errors.resume.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Applied Vacancy</Label>
                      <Controller
                        as={
                          <Select
                            placeholder={"Select vacancy"}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                border: `1px solid ${
                                  errors.vacancy ? "#dc3545" : "#cad2dd"
                                }`,
                              }),
                            }}
                          />
                        }
                        options={vacancyOptions}
                        name="vacancy"
                        isClearable
                        control={control}
                        onChange={([selected]) => {
                          return { value: selected };
                        }}
                        rules={{
                          required: "Vacancy is required",
                        }}
                      />
                      {errors.vacancy ? (
                        <FormFeedBack feedBack={errors.vacancy.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup {...classes("submit")}>
                      <Button submit>Submit</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default AddAgentCandidate;
