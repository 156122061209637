import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/time-tracking`;

export const getUsers = async (token) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/user`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const createUser = async (token, userDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/user`,
    headers: {
      "x-access-token": token,
    },
    data: userDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const updateUser = async (token, userDetails) => {
  return axios({
    method: "PUT",
    url: `${apiUrl}/user/${userDetails.userId}`,
    headers: {
      "x-access-token": token,
    },
    data: userDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const deleteUser = async (token, userId) => {
  return axios({
    method: "DELETE",
    url: `${apiUrl}/user/${userId}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const assignTeamToProject = async (token, teamDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/user/assignProject`,
    headers: {
      "x-access-token": token,
    },
    data: teamDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
