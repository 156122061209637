import React, { Component } from "react";
import { bindActionCreators } from "redux";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Card,
  CardHeader,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import hrmModule from "../../../../../../redux/hrm";
import Button from "../../../../../components/ui-components/Button";
import BEMhelper from "react-bem-helper";
import { FaArrowLeft, FaFileAlt } from "react-icons/fa";
import ToolTip from "../../../../../components/toolTip";
import Loader from "../../../../../components/ui-components/Loader";
import AddCandidateDirectModal from "../../../../../components/modals/hrm/recruitment/candidate/addCandidateDirect";
import "./viewVacancyExternal.scss";

class ViewVacancyExternal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showJdDescription: false,
      showCandidateModal: false,
    };
  }

  applyVacancy = (values) => {
    const formData = new FormData();
    formData.append("name", values.candidateName);
    if (values.noticePeriod) {
      formData.append("noticePeriod", values.noticePeriod);
    }
    formData.append("appliedVacancy", this.props.vacancy.data.hrmVacancyId);
    formData.append("resume", values.resume[0]);
    if (values.expectedSalary) {
      formData.append("expectedSalary", values.expectedSalary);
    }
    formData.append("sourceOfHire", "direct");
    formData.append("email", values.email);
    if (values.phone) {
      formData.append("phone", values.phone);
    }
    const data = {
      formValues: formData,
      closeModal: this.closeCandidateModal,
    };
    this.props.createCandidate(data);
  };

  viewVacancyFile = () => {
    this.setState({ showJdDescription: !this.state.showJdDescription });
  };

  closeCandidateModal = () => {
    this.setState({ showCandidateModal: false });
  };

  componentDidMount = () => {
    this.props.getVacancy({
      vacancyId: this.props.match.params.vacancyId,
    });
  };

  render() {
    const classes = new BEMhelper("Vacancy");
    return (
      <Container fluid {...classes("external")}>
        {this.state.showCandidateModal ? (
          <AddCandidateDirectModal
            openModal={this.state.showCandidateModal}
            title={"Apply for vacancy"}
            toggle={this.closeCandidateModal}
            submit={this.applyVacancy}
            companyCurrency={this.props.vacancy.data.parentCompany.currency}
          />
        ) : null}
        <Row>
          <Col sm={12} md={{ size: 6, offset: 3 }}>
            {this.props.vacancy.loading ? (
              <div className="loading-vacancy-card">
                <Card body>
                  <Loader />
                </Card>
              </div>
            ) : (
              <div className="vacancy-card">
                <Card body>
                  {this.props.vacancy.data ? (
                    <React.Fragment>
                      <div className="company-header-name">
                        <img
                          src={this.props.vacancy.data.parentCompany.logo}
                          width="45px"
                        />
                        <h4 className="company-name">
                          {this.props.vacancy.data.parentCompany.name}
                        </h4>
                      </div>
                      <CardHeader className="job-header">
                        <Row>
                          <Col md={9} className="header-name">
                            <div>
                              <h1>{this.props.vacancy.data.name}</h1>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="header-actions">
                              <ButtonGroup size="sm">
                                {this.props.vacancy.data.jdImageUrl &&
                                this.props.vacancy.data.jobDescription ? (
                                  <React.Fragment>
                                    <Button
                                      size="sm"
                                      onClick={() => this.viewVacancyFile()}
                                      color="grey"
                                      className="vacancy-file-view"
                                      id="viewJd"
                                    >
                                      {this.state.showJdDescription ? (
                                        <FaArrowLeft />
                                      ) : (
                                        <FaFileAlt />
                                      )}
                                    </Button>
                                    <ToolTip
                                      position="top"
                                      tip={
                                        this.state.showJdDescription
                                          ? "Go Back"
                                          : "View JD"
                                      }
                                      rowId={`viewJd`}
                                    />
                                  </React.Fragment>
                                ) : null}
                                <Button
                                  size="sm"
                                  onClick={() =>
                                    this.setState({ showCandidateModal: true })
                                  }
                                  color="blue"
                                >
                                  Apply Now
                                </Button>
                              </ButtonGroup>
                            </div>
                          </Col>
                        </Row>
                      </CardHeader>
                      <div {...classes("jobDescription")}>
                        {this.props.vacancy.data.jobDescription === "" ? (
                          <div {...classes("jdImageFile")}>
                            <img src={this.props.vacancy.data.jdImageUrl} />
                          </div>
                        ) : this.state.showJdDescription ? (
                          <div {...classes("jdImageFile")}>
                            <img src={this.props.vacancy.data.jdImageUrl} />
                          </div>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.props.vacancy.data.jobDescription,
                            }}
                          />
                        )}
                      </div>
                    </React.Fragment>
                  ) : (
                    <Alert color="danger">{this.props.vacancy.error}</Alert>
                  )}
                </Card>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getVacancy: hrmModule.vacancyModule.actions.getVacancy,
      createCandidate: hrmModule.candidateModule.actions.createDirectCandidate,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    vacancy: state.hrmVacancy,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(ViewVacancyExternal);
