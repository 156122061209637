import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_MY_PASSWORD_REQUEST,
  RESET_MY_PASSWORD_SUCCESS,
  RESET_MY_PASSWORD_FAILURE,
} from "../actionTypes";

const initialState = {
  loading: false,
  response: null,
  error: null,
};

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
    case RESET_MY_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        response: null,
        error: null,
      };
    case RESET_PASSWORD_SUCCESS:
    case RESET_MY_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.data,
        error: null,
      };
    case RESET_PASSWORD_FAILURE:
    case RESET_MY_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        response: null,
        error: action.data,
      };
    default:
      return state;
  }
};

export default resetPasswordReducer;
