import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import hrmModule from "../../../../../redux/hrm";
import Button from "../../../../components/ui-components/Button";
import BEMhelper from "react-bem-helper";
import { FaPlus, FaEdit } from "react-icons/fa";
import { Container, Row, Col, ButtonGroup, Badge } from "reactstrap";
import ToolTip from "../../../../components/toolTip";
import CustomTable from "../../../../components/table";
import AddAgentModal from "../../../../components/modals/hrm/recruitment/agent/addAgent";
import "./agents.scss";

class AgentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAgentModal: false,
      selectedAgent: null,
      editMode: false,
    };
  }

  componentDidMount() {
    this.props.getAgents();
  }

  getAgentList = (agents) => {
    const loggedUserAccess = this.props.user.userDetails.accessRoles;
    if (agents && agents.length > 0) {
      return agents.map((row, key) => {
        return (
          <tr key={key} className="value-row">
            <td>{row.companyName}</td>
            <td>{row.agentName}</td>
            <td>{row.email}</td>
            <td>{row.phoneNumber}</td>
            <td>
              <Badge color={row.active ? "success" : "danger"}>
                {row.active ? "Active" : "Inactive"}
              </Badge>
            </td>
            <td>
              {loggedUserAccess.isAdmin ||
              loggedUserAccess.isHrmAdmin ||
              loggedUserAccess.isSuperAdmin ? (
                <ButtonGroup size="sm">
                  <Button
                    size="sm"
                    onClick={() => {
                      this.setState({
                        showAgentModal: true,
                        selectedAgent: row,
                        editMode: true,
                      });
                    }}
                    color="bright-blue"
                    id={`edit-${key}`}
                  >
                    <FaEdit />
                  </Button>
                  <ToolTip
                    position="top"
                    tip="Edit Agent"
                    rowId={`edit-${key}`}
                  />
                </ButtonGroup>
              ) : null}
            </td>
          </tr>
        );
      });
    }
    return [];
  };

  closeAgentModal = () => {
    this.setState({
      showAgentModal: false,
      editMode: false,
      selectedAgent: null,
    });
  };

  addAgent = (values) => {
    const formData = new FormData();
    formData.append("companyName", values.companyName);
    formData.append("agentName", values.agentName);
    formData.append("email", values.email);
    formData.append("phoneNumber", values.mobileNumber);
    if (values.landNumber) {
      formData.append("landNumber", values.landNumber);
    }
    formData.append("active", values.active);
    if (this.state.editMode) {
      formData.append("agentId", this.state.selectedAgent.hrmAgentId);
    }
    if (values.password !== "Find if you can") {
      formData.append("password", values.password);
    }
    if (values.agreement && values.agreement[0]) {
      formData.append("agreement", values.agreement[0]);
    }
    const data = {
      formValues: formData,
      closeModal: this.closeAgentModal,
    };
    if (this.state.editMode) {
      this.props.updateAgent(data);
    } else {
      this.props.createAgent(data);
    }
  };

  render() {
    const classes = new BEMhelper("agentList");
    const loggedUserAccess = this.props.user.userDetails.accessRoles;
    return (
      <Container fluid {...classes("list")}>
        {this.state.showAgentModal ? (
          <AddAgentModal
            openModal={this.state.showAgentModal}
            title={"Create Agent"}
            toggle={this.closeAgentModal}
            submit={this.addAgent}
            isEditMode={this.state.editMode}
            objectToEdit={this.state.selectedAgent}
          />
        ) : null}

        <Row>
          <Col sm={7} className="text-left">
            <div className="headerTitle-wrap">
              <h2 className="list-header-title">Agent List</h2>
            </div>
          </Col>
          <Col sm={5} className="text-right">
            {loggedUserAccess.isAdmin ||
            loggedUserAccess.isHrmAdmin ||
            loggedUserAccess.isSuperAdmin ? (
              <Button
                color="deep-blue"
                onClick={() => {
                  this.setState({
                    showAgentModal: true,
                  });
                }}
              >
                <FaPlus /> New Agent
              </Button>
            ) : null}
          </Col>
        </Row>
        <React.Fragment>
          <Row>
            <Col sm={12}>
              <div className="hr"></div>
              {this.props.agents.loading || !this.props.agents.data ? (
                <p>Loading...</p>
              ) : (
                <CustomTable
                  content={this.getAgentList(this.props.agents.data)}
                  tableHeadings={[
                    "Company Name",
                    "Agent Name",
                    "Email",
                    "Mobile Number",
                    "Status",
                    "",
                  ]}
                />
              )}
            </Col>
          </Row>
        </React.Fragment>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAgents: hrmModule.agentModule.actions.getAgents,
      createAgent: hrmModule.agentModule.actions.createAgent,
      updateAgent: hrmModule.agentModule.actions.updateAgent,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    agents: state.hrmAgents,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(AgentList);
