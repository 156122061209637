import * as actions from "./actionTypes";

export const getTasks = () => ({
  type: actions.GET_TASKS_REQUEST,
});

export const createTask = (data) => ({
  type: actions.CREATE_TASK_REQUEST,
  payload: data,
});

export const updateTask = (data) => ({
  type: actions.UPDATE_TASK_REQUEST,
  payload: data,
});

export const deleteTask = (data) => ({
  type: actions.DELETE_TASK_REQUEST,
  payload: data,
});
