import * as actions from "./actionTypes";

export const applyLeave = (data) => ({
  type: actions.APPLY_LEAVE_REQUEST,
  payload: data,
});

export const leaveBalance = (data) => ({
  type: actions.LEAVE_BALANCE_REQUEST,
  payload: data,
});

export const leaveDateType = () => ({
  type: actions.LEAVE_DATE_TYPE_REQUEST,
});

export const leaveHistory = (data) => ({
  type: actions.LEAVE_HISTORY_REQUEST,
  payload: data,
});

export const processLeave = (data) => ({
  type: actions.PROCESS_LEAVE_REQUEST,
  payload: data,
});
