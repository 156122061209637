import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  CREATE_HRM_PERFORMANCE_SURVEY_REQUEST,
  CREATE_HRM_PERFORMANCE_SURVEY_SUCCESS,
  CREATE_HRM_PERFORMANCE_SURVEY_FAILURE,
  GET_HRM_PERFORMANCE_SURVEY_REQUEST,
  GET_HRM_PERFORMANCE_SURVEY_SUCCESS,
  GET_HRM_PERFORMANCE_SURVEY_FAILURE,
  GET_HRM_PERFORMANCE_SURVEY_INFO_REQUEST,
  GET_HRM_PERFORMANCE_SURVEY_INFO_SUCCESS,
  GET_HRM_PERFORMANCE_SURVEY_INFO_FAILURE,
  GET_HRM_PERFORMANCE_SURVEY_DETAILS_REQUEST,
  GET_HRM_PERFORMANCE_SURVEY_DETAILS_SUCCESS,
  GET_HRM_PERFORMANCE_SURVEY_DETAILS_FAILURE,
  SUBMIT_HRM_PERFORMANCE_SURVEY_FEEDBACK_REQUEST,
  SUBMIT_HRM_PERFORMANCE_SURVEY_FEEDBACK_SUCCESS,
  SUBMIT_HRM_PERFORMANCE_SURVEY_FEEDBACK_FAILURE,
  GET_HRM_PERFORMANCE_SIGNLE_SURVEY_INFO_REQUEST,
  GET_HRM_PERFORMANCE_SIGNLE_SURVEY_INFO_SUCCESS,
  GET_HRM_PERFORMANCE_SIGNLE_SURVEY_INFO_FAILURE,
  GET_HRM_PERFORMANCE_REPORT_REQUEST,
  GET_HRM_PERFORMANCE_REPORT_SUCCESS,
  GET_HRM_PERFORMANCE_REPORT_FAILURE,
  GET_HRM_PERFORMANCE_SURVEY_PAPER_REQUEST,
  GET_HRM_PERFORMANCE_SURVEY_PAPER_SUCCESS,
  GET_HRM_PERFORMANCE_SURVEY_PAPER_FAILURE,
  ARCHIVE_HRM_PERFORMANCE_SURVEY_REQUEST,
  ARCHIVE_HRM_PERFORMANCE_SURVEY_SUCCESS,
  ARCHIVE_HRM_PERFORMANCE_SURVEY_FAILURE,
} from "../actionTypes";
import apis from "../../../../../api";
import { toast } from "react-toastify";
import _ from "lodash";
import { push } from "connected-react-router";

export function* surveySaga() {
  yield takeLatest(GET_HRM_PERFORMANCE_SURVEY_REQUEST, getSurveys);
  yield takeLatest(CREATE_HRM_PERFORMANCE_SURVEY_REQUEST, createSurvey);
  yield takeLatest(GET_HRM_PERFORMANCE_SURVEY_INFO_REQUEST, getSurveyInfo);
  yield takeLatest(
    GET_HRM_PERFORMANCE_SURVEY_DETAILS_REQUEST,
    getSurveyDetails
  );
  yield takeLatest(
    SUBMIT_HRM_PERFORMANCE_SURVEY_FEEDBACK_REQUEST,
    submitFeedback
  );
  yield takeLatest(
    GET_HRM_PERFORMANCE_SIGNLE_SURVEY_INFO_REQUEST,
    getFeedbackInfo
  );
  yield takeLatest(GET_HRM_PERFORMANCE_REPORT_REQUEST, getSurveyReport);
  yield takeLatest(GET_HRM_PERFORMANCE_SURVEY_PAPER_REQUEST, getSurveyPaper);
  yield takeLatest(ARCHIVE_HRM_PERFORMANCE_SURVEY_REQUEST, archiveSurvey);
}

function* archiveSurvey(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const survey = yield call(
      apis.hrmApis.performance.archiveSurvey,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (survey.error) {
      yield put({
        type: ARCHIVE_HRM_PERFORMANCE_SURVEY_FAILURE,
        error: survey.body.data.error,
      });
    } else {
      yield put({
        type: ARCHIVE_HRM_PERFORMANCE_SURVEY_SUCCESS,
        data: survey.body.data,
      });
      yield put({
        type: GET_HRM_PERFORMANCE_SURVEY_REQUEST,
        payload: {
          query: details.payload.appliedFilters
            ? details.payload.appliedFilters
            : null,
        },
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getSurveyPaper(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const surveyPaper = yield call(
      apis.hrmApis.performance.getSurveyPaper,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (surveyPaper.error) {
      yield put({
        type: GET_HRM_PERFORMANCE_SURVEY_PAPER_FAILURE,
        error: surveyPaper.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_PERFORMANCE_SURVEY_PAPER_SUCCESS,
        data: surveyPaper.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getSurveyReport(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const reportData = yield call(
      apis.hrmApis.performance.getSurveyReport,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (reportData.error) {
      yield put({
        type: GET_HRM_PERFORMANCE_REPORT_FAILURE,
        error: reportData.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_PERFORMANCE_REPORT_SUCCESS,
        data: reportData.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getFeedbackInfo(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const feedbackInfo = yield call(
      apis.hrmApis.performance.getFeedbackInfo,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (feedbackInfo.error) {
      yield put({
        type: GET_HRM_PERFORMANCE_SIGNLE_SURVEY_INFO_FAILURE,
        error: feedbackInfo.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_PERFORMANCE_SIGNLE_SURVEY_INFO_SUCCESS,
        data: feedbackInfo.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* submitFeedback(details) {
  try {
    const submitInfo = yield call(
      apis.hrmApis.performance.submitFeedback,
      details.payload
    );
    if (submitInfo.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: SUBMIT_HRM_PERFORMANCE_SURVEY_FEEDBACK_FAILURE,
        error: submitInfo.body.data.error,
      });
      if (_.isString(submitInfo.body.data.error)) {
        errorMessage = submitInfo.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({
        type: SUBMIT_HRM_PERFORMANCE_SURVEY_FEEDBACK_SUCCESS,
        data: submitInfo.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getSurveyDetails(details) {
  try {
    const surveyInfo = yield call(
      apis.hrmApis.performance.getSurveyDetails,
      details.payload
    );
    if (surveyInfo.error) {
      yield put({
        type: GET_HRM_PERFORMANCE_SURVEY_DETAILS_FAILURE,
        error: surveyInfo.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_PERFORMANCE_SURVEY_DETAILS_SUCCESS,
        data: surveyInfo.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getSurveyInfo(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const surveyInfo = yield call(
      apis.hrmApis.performance.getSurveyInfo,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (surveyInfo.error) {
      yield put({
        type: GET_HRM_PERFORMANCE_SURVEY_INFO_FAILURE,
        error: surveyInfo.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_PERFORMANCE_SURVEY_INFO_SUCCESS,
        data: surveyInfo.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createSurvey(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const survey = yield call(
      apis.hrmApis.performance.createSurvey,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (survey.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: CREATE_HRM_PERFORMANCE_SURVEY_FAILURE,
        error: survey.body.data.error,
      });
      if (Array.isArray(survey.body.data.error)) {
        errorMessage = survey.body.data.error[0].msg;
      }
      if (_.isString(survey.body.data.error)) {
        errorMessage = survey.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({
        type: CREATE_HRM_PERFORMANCE_SURVEY_SUCCESS,
        data: survey.body.data,
      });
      toast.success("Survey created & Emails sent!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put(push("/hrm/performance/surveys"));
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getSurveys(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const surveys = yield call(
      apis.hrmApis.performance.getSurveys,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (surveys.error) {
      yield put({
        type: GET_HRM_PERFORMANCE_SURVEY_FAILURE,
        error: surveys.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_PERFORMANCE_SURVEY_SUCCESS,
        data: surveys.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
