import { getMainEntryReport } from "./actionCreators";
import allEntryReportDataReducer from "./reducer/allEntryReportDataReducer";
import { reportSaga } from "./saga/reportSaga";

const reportModule = {
  actions: {
    getMainEntryReport,
  },
  reducers: {
    allEntryReportDataReducer: allEntryReportDataReducer,
  },
  sagas: {
    reportSaga: reportSaga,
  },
};

export default reportModule;
