/** @format */

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
// import SideNavBar from "../views/components/sideNavBar";
import SideBar from "../views/components/sideBar";
import AppHeader from "../views/components/appHeader";
import BEMhelper from "react-bem-helper";
import { toast } from "react-toastify";

const permissionError = () => {
  toast.warning("You don't have permission. Contact administrator", {
    position: toast.POSITION.TOP_RIGHT,
  });
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const classes = new BEMhelper("main");
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isLoggedIn ? (
          // _.indexOf(rest.allowedRoles, rest.userRole) !== -1 ? (
          rest.authorized ? (
            <Container fluid>
              <Row>
                <div {...classes("main-wrapper")}>
                  <SideBar
                    userRole={rest.userRole}
                    user={rest.user.userDetails.user}
                    company={rest.user.userDetails.company}
                    featurePermission={rest.user.userDetails.accessRoles}
                  />
                  {/* <SideNavBar
                    userRole={rest.userRole}
                    user={rest.user.userDetails.user}
                    company={rest.user.userDetails.company}
                    featurePermission={rest.user.userDetails.accessRoles}
                  /> */}
                  <div className="main-content">
                    <AppHeader
                      logoutAction={rest.logoutAction}
                      {...props}
                      {...rest}
                    />
                    <div className="content">
                      <Component {...props} {...rest} />
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          ) : (
            <React.Fragment>
              {permissionError()}
              <Redirect to={"/landing"} />
            </React.Fragment>
          )
        ) : (
          <Redirect to={"/"} />
        )
      }
    />
  );
};

export default PrivateRoute;
