import {
  GET_HRM_PERFORMANCE_SURVEY_REQUEST,
  GET_HRM_PERFORMANCE_SURVEY_SUCCESS,
  GET_HRM_PERFORMANCE_SURVEY_FAILURE,
  GET_HRM_PERFORMANCE_SURVEY_INFO_REQUEST,
  GET_HRM_PERFORMANCE_SURVEY_INFO_SUCCESS,
  GET_HRM_PERFORMANCE_SURVEY_INFO_FAILURE,
  RESET_HRM_PERFORMANCE_SURVEY_REQUEST,
} from "../actionTypes";

const initialState = {
  loadingSurveyInfo: true,
  loading: true,
  data: [],
  surveyInfo: {},
  error: null,
};

const allSurveysReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HRM_PERFORMANCE_SURVEY_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case GET_HRM_PERFORMANCE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case GET_HRM_PERFORMANCE_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };

    case GET_HRM_PERFORMANCE_SURVEY_INFO_REQUEST:
      return {
        ...state,
        loadingSurveyInfo: true,
        surveyInfo: {},
        error: null,
      };
    case GET_HRM_PERFORMANCE_SURVEY_INFO_SUCCESS:
      return {
        ...state,
        loadingSurveyInfo: false,
        surveyInfo: action.data,
        error: null,
      };
    case GET_HRM_PERFORMANCE_SURVEY_INFO_FAILURE:
      return {
        ...state,
        loadingSurveyInfo: false,
        surveyInfo: {},
        error: action.error,
      };
    case RESET_HRM_PERFORMANCE_SURVEY_REQUEST:
      return {
        ...state,
        loading: true,
        loadingSurveyInfo: true,
        data: [],
        surveyInfo: {},
        error: null,
      };
    default:
      return state;
  }
};

export default allSurveysReducer;
