import * as actions from "./actionTypes";

export const signupUser = (data) => ({
  type: actions.SIGNUP_REQUEST,
  payload: data,
});

export const updateUser = (data) => ({
  type: actions.ADMIN_UPDATE_USER_REQUEST,
  payload: data,
});

export const createDesignation = (data) => ({
  type: actions.ADMIN_CREATE_DESIGNATION_REQUEST,
  payload: data,
});
