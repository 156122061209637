import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_HRM_VACANCIES_REQUEST,
  GET_HRM_VACANCIES_SUCCESS,
  GET_HRM_VACANCIES_FAILURE,
  CREATE_HRM_VACANCIES_REQUEST,
  CREATE_HRM_VACANCIES_SUCCESS,
  CREATE_HRM_VACANCIES_FAILURE,
  UPDATE_HRM_VACANCIES_REQUEST,
  UPDATE_HRM_VACANCIES_SUCCESS,
  UPDATE_HRM_VACANCIES_FAILURE,
  GET_HRM_VACANCY_REQUEST,
  GET_HRM_VACANCY_SUCCESS,
  GET_HRM_VACANCY_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";
import { toast } from "react-toastify";
import _ from "lodash";

export function* vacancySaga() {
  yield takeLatest(GET_HRM_VACANCIES_REQUEST, getVacancies);
  yield takeLatest(CREATE_HRM_VACANCIES_REQUEST, createVacancy);
  yield takeLatest(UPDATE_HRM_VACANCIES_REQUEST, updateVacancy);
  yield takeLatest(GET_HRM_VACANCY_REQUEST, getVacancy);
}

function* getVacancy(details) {
  try {
    const vacancy = yield call(
      apis.hrmApis.getVacancy,
      details.payload.vacancyId
    );
    if (vacancy.error) {
      yield put({
        type: GET_HRM_VACANCY_FAILURE,
        error: vacancy.body.data.error,
      });
      // if (vacancy.body.status === 404) {
      //   yield put(push("/hrm/cvPool"));
      // }
    } else {
      yield put({
        type: GET_HRM_VACANCY_SUCCESS,
        data: vacancy.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* updateVacancy(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const vacancy = yield call(
      apis.hrmApis.updateVacancy,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues,
      details.payload.vacancyId
    );
    if (vacancy.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: UPDATE_HRM_VACANCIES_FAILURE,
        error: vacancy.body.data.error,
      });
      if (Array.isArray(vacancy.body.data.error)) {
        errorMessage = vacancy.body.data.error[0].msg;
      }
      if (_.isString(vacancy.body.data.error)) {
        errorMessage = vacancy.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({
        type: UPDATE_HRM_VACANCIES_SUCCESS,
        data: vacancy.body.data,
      });
      yield put({
        type: GET_HRM_VACANCIES_REQUEST,
      });
      toast.success("Vacancy updated", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createVacancy(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const vacancy = yield call(
      apis.hrmApis.createVacancy,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (vacancy.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: CREATE_HRM_VACANCIES_FAILURE,
        error: vacancy.body.data.error,
      });
      if (Array.isArray(vacancy.body.data.error)) {
        errorMessage = vacancy.body.data.error[0].msg;
      }
      if (_.isString(vacancy.body.data.error)) {
        errorMessage = vacancy.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({
        type: CREATE_HRM_VACANCIES_SUCCESS,
        data: vacancy.body.data,
      });
      yield put({
        type: GET_HRM_VACANCIES_REQUEST,
      });
      toast.success("Vacancy created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getVacancies() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const vacancies = yield call(
      apis.hrmApis.getVacancies,
      loggedUser.userDetails.auth.accessToken
    );
    if (vacancies.error) {
      yield put({
        type: GET_HRM_VACANCIES_FAILURE,
        error: vacancies.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_VACANCIES_SUCCESS,
        data: vacancies.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
