import React, { Component } from "react";
import CustomTable from "../../../components/table";
import ApplyClaimModal from "../../../components/modals/applyClaimModal";
import { Row, Col, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import claimModule from "../../../../redux/claim";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

class ClaimHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserModal: false,
    };
  }

  componentDidMount() {
    this.props.claimHistory(this.props.loggedUser.userDetails.user.userId);
  }

  openApplyLeaveModal = () => {
    if (this.props.claimTypeGroup.data.length === 0) {
      this.props.claimType();
    }
    this.setState({
      showUserModal: true,
    });
  };

  toggle = (reloadData = false) => {
    if (reloadData) {
      this.props.claimHistory(this.props.loggedUser.userDetails.user.userId);
    }
    this.setState({
      showUserModal: false,
    });
  };

  applyClaim = (values) => {
    const details = {
      formValue: {
        claimTypeId: parseInt(values.claimType),
        claimDate: values.claimDate,
        claimAmount: values.claimAmount,
        remarks: values.claimRemarks,
      },
      closeModal: this.toggle,
    };
    this.props.applyClaim(details);
  };

  getTableHeading = () => {
    return [
      "Requested Date",
      "Claim Date",
      "Claim Type",
      "Amount",
      "Remarks",
      "Status",
    ];
  };

  getTableContent = (data) => {
    return data.map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>{moment(row.createdAt).format("YYYY-MM-DD")}</td>
          <td>{row.date}</td>
          <td>{row.claimType.type}</td>
          <td>{row.amount}</td>
          <td>{row.remarks}</td>
          <td>{row.processStatus.status}</td>
        </tr>
      );
    });
  };

  render() {
    // const classes = new BEMhelper("claimList");
    return (
      <React.Fragment>
        {this.state.showUserModal ? (
          <ApplyClaimModal
            openModal={this.state.showUserModal}
            toggle={this.toggle}
            title={"Apply Claim"}
            onSubmit={this.applyClaim}
            applyingStatus={this.props.applyForClaim}
            claimTypeList={this.props.claimTypeGroup}
          />
        ) : null}

        <Row>
          <Col sm={10} className="text-left">
            <h2 className="list-header-title">Claim History</h2>
          </Col>
          <Col sm={2} className="text-right">
            <Button
              className="btn-primary"
              onClick={() => {
                this.openApplyLeaveModal();
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> Apply Claim
            </Button>
          </Col>

          <Col sm={12}>
            <div className="hr"></div>
            {this.props.claimHistoryGroup.loading ? (
              <p>Loading...</p>
            ) : (
              <CustomTable
                content={this.getTableContent(
                  this.props.claimHistoryGroup.data
                )}
                tableHeadings={this.getTableHeading()}
              />
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      claimType: claimModule.actions.claimType,
      applyClaim: claimModule.actions.applyClaim,
      claimHistory: claimModule.actions.claimHistory,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    loggedUser: state.loggedUser,
    claimTypeGroup: state.claimType,
    applyForClaim: state.applyClaim,
    claimHistoryGroup: state.claimHistory,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(ClaimHistory);
