import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import adminModule from "../../../../redux/admin";
import { Row, Col } from "reactstrap";
import Card from "../../../components/card";
import Loader from "../../../components/loader";
import BEMhelper from "react-bem-helper";
import moment from "moment";

class AdministrationDashboard extends Component {
  componentDidMount() {
    this.props.dashboardData();
  }

  getCardbody = (stat) => {
    return <h6>{stat.count}</h6>;
  };

  render() {
    const classes = new BEMhelper("dashboard");
    return (
      <Row {...classes("cardWrapper")}>
        <Col sm={8} className="title">
          <h3>
            Hi <span>Kushal</span>
          </h3>
        </Col>
        <Col sm={4} className="text-right time">
          <h4>
            {moment().format("dddd")}, {moment().format("LL")}
          </h4>
        </Col>
        <Col sm={12}>
          <div className="hr"></div>
          <Row>
            {this.props.adminDashboard.loading ? (
              <Loader />
            ) : this.props.adminDashboard.error ? (
              <p>{this.props.adminDashboard.error}</p>
            ) : (
              this.props.adminDashboard.data.map((stat, key) => {
                return (
                  <Col sm={4} className={["card" + key, "text-left"]} key={key}>
                    <Card title={stat.label} body={this.getCardbody(stat)} />
                  </Col>
                );
              })
            )}
          </Row>
        </Col>
      </Row>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dashboardData: adminModule.dashboardModule.actions.getDashboardData,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    loggedUser: state.loggedUser,
    leaveBalanceGroup: state.leaveBalanceNew,
    adminDashboard: state.adminDashboard,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(AdministrationDashboard);
