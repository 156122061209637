import * as actions from "./actionTypes";

export const getPermissionTypes = (data) => ({
  type: actions.GET_PERMISSION_TYPES_REQUEST,
  payload: data,
});

export const getCommonData = (data) => ({
  type: actions.GET_DATA_COMMON_REQUEST,
  payload: data,
});
