import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_REQUEST } from '../actionTypes';

const initialState = {
  loading: false,
  userDetails: null,
  error: null,
};

const loggedUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        userDetails: null,
        error: null
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.data,
        error: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        userDetails: null,
        error: action.data
      };
      case LOGOUT_REQUEST:
        return {
          ...state,
          loading: false,
          userDetails: null,
          error: null
        };
    default:
      return state;
  }
};

export default loggedUserReducer;