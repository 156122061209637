import { getTypes, createType, updateType } from "./actionCreators";
import allTypesReducer from "./reducer/allTypesReducer";
import { typeSaga } from "./saga/typeSaga";

const typeModule = {
  actions: {
    getTypes,
    createType,
    updateType,
  },
  reducers: {
    allTypesReducer: allTypesReducer,
  },
  sagas: {
    typeSaga: typeSaga,
  },
};

export default typeModule;
