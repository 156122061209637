import * as actions from "./actionTypes";

export const getItems = (data) => ({
  type: actions.GET_INVENTORY_ITEMS_REQUEST,
  payload: data,
});

export const createItem = (data) => ({
  type: actions.CREATE_INVENTORY_ITEMS_REQUEST,
  payload: data,
});

export const updateItem = (data) => ({
  type: actions.UPDATE_INVENTORY_ITEM_REQUEST,
  payload: data,
});

export const deleteItem = (data) => ({
  type: actions.DELETE_INVENTORY_ITEM_REQUEST,
  payload: data,
});

export const deleteItemFile = (data) => ({
  type: actions.DELETE_INVENTORY_ITEM_FILE_REQUEST,
  payload: data,
});

export const createItemsBulk = (data) => ({
  type: actions.CREATE_INVENTORY_ITEMS_BULK_REQUEST,
  payload: data,
});

export const swopUserItems = (data) => ({
  type: actions.SWOP_INVENTORY_USER_ITEM_REQUEST,
  payload: data,
});
