import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/inventory`;

export const getReport = async (token, queryDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/report`,
    headers: {
      "x-access-token": token,
    },
    data: queryDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const getCustomReport = async (token, queryDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/report/custom?offset=${queryDetails.offset || 0}`,
    headers: {
      "x-access-token": token,
    },
    data: queryDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const downloadCustomReport = async (token, queryDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}/report/custom/download`,
    headers: {
      "x-access-token": token,
    },
    data: queryDetails,
  })
    .then(function (response) {
      return { error: false, body: response };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
