import React, { Component } from "react";
import { bindActionCreators } from "redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  ListGroup,
  ListGroupItem,
  ButtonGroup,
  FormGroup,
  Label,
  Alert,
} from "reactstrap";
import _ from "lodash";
import StarRatings from "react-star-ratings";
import { connect } from "react-redux";
import hrmModule from "../../../../../../redux/hrm";
import Button from "../../../../../components/ui-components/Button";
import Textarea from "../../../../../components/ui-components/Textarea";
import FormFeedBack from "../../../../../components/ui-components/FormFeedBack";
import BEMhelper from "react-bem-helper";
import { toast } from "react-toastify";
import Select from "react-select";
import "./candidateFeedback.scss";

class CandidateFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      comments: null,
      overallRating: 0,
      finalStatus: null,
      interviewStage: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let update = {};
    if (props.categories.data !== state.categories) {
      update.categories = props.categories.data;
    }
    if (
      props.candidate.data &&
      props.candidate.data.invitation &&
      props.candidate.data.invitation !== state.interviewStage
    ) {
      update.interviewStage =
        props.candidate.data.invitation.hrmInterviewStage.hrmInterviewStageId;
    }

    return update;
  }

  changeRating = (key, value, questionKey) => {
    const categories = this.state.categories;
    categories[key].hrmCandidateQuestions[questionKey].rating = value;
    this.setState({ categories: categories });
  };

  submitFeedback = () => {
    let errorMessage = null;
    if (!this.state.interviewStage) {
      errorMessage = "Please select the interview stage";
    }
    if (!this.state.finalStatus) {
      errorMessage = "Please let us know you final opinion";
    }
    if (this.state.overallRating === 0) {
      errorMessage = "Please give an overall rating";
    }
    if (!errorMessage) {
      const categories = this.state.categories;
      const ratings = [];
      _.forEach(categories, function (category) {
        _.forEach(category.hrmCandidateQuestions, function (question) {
          ratings.push({
            questionId: question.hrmCandidateQuestionId,
            rating: question.rating || 0,
          });
        });
      });
      const body = {
        candidateId: this.props.match.params.applicationId,
        feedbacks: ratings,
        comments: this.state.comments,
        overall: this.state.overallRating,
        status: this.state.finalStatus,
        interviewStage: this.state.interviewStage,
      };
      this.props.createCandidateFeedback(body);
    } else {
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  getFormattedStageList = (stages) => {
    return stages.map((stage) => {
      return {
        value: stage.hrmInterviewStageId,
        label: stage.name,
      };
    });
  };

  componentDidMount = () => {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("invite");
    this.props.getCandidate({
      candidateId: this.props.match.params.applicationId,
      token: token || null,
    });
    this.props.getCandidateQuestionCategories();
    this.props.getStages();
  };

  render() {
    const classes = new BEMhelper("feedback");
    return (
      <Container fluid {...classes("candidate")}>
        {this.props.candidate.loading ? (
          <p>Loading...</p>
        ) : (
          <React.Fragment>
            {this.props.candidate.error ? (
              <Row>
                <Col md={12}>
                  <Alert color="danger">
                    You do not have permission to add feedback to this
                    candidate.
                  </Alert>
                </Col>
              </Row>
            ) : (
              <React.Fragment>
                <Row>
                  <Col sm={7} className="text-left">
                    <div className="headerTitle-wrap">
                      <h2 className="list-header-title">
                        {this.props.candidate.data.name}
                      </h2>
                      <h2 className="list-sub-header-title">
                        {this.props.candidate.data.hrmVacancy.name}
                      </h2>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className="hr"></div>
                    {this.props.categories.loading ? (
                      <p>Loading..</p>
                    ) : (
                      <div>
                        <Card body>
                          <Row>
                            <Col md={4} {...classes("top-card-col")}>
                              <FormGroup {...classes("top-card-group")}>
                                <Label>Interview stage</Label>
                                <Select
                                  isLoading={this.props.stages.loading}
                                  isDisabled={
                                    this.props.candidate.data.invitation
                                  }
                                  isClearable={true}
                                  isSearchable={true}
                                  options={this.getFormattedStageList(
                                    this.props.stages.data
                                  )}
                                  placeholder="Select stage"
                                  defaultValue={
                                    this.props.candidate.data.invitation
                                      ? {
                                          label: this.props.candidate.data
                                            .invitation.hrmInterviewStage.name,
                                          value: this.props.candidate.data
                                            .invitation.hrmInterviewStage
                                            .hrmInterviewStageId,
                                        }
                                      : null
                                  }
                                  onChange={(stage) =>
                                    this.setState({
                                      interviewStage: stage
                                        ? stage.value
                                        : null,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4} {...classes("top-card-col")}>
                              <FormGroup {...classes("top-card-group")}>
                                <Label>What is your final opinion?</Label>
                                <div>
                                  <ButtonGroup>
                                    <Button
                                      color="medium-green"
                                      type={
                                        this.state.finalStatus === "Pass"
                                          ? ""
                                          : "lined"
                                      }
                                      onClick={() =>
                                        this.setState({ finalStatus: "Pass" })
                                      }
                                      size="md"
                                      {...classes("decision-btn")}
                                    >
                                      Pass
                                    </Button>
                                    <Button
                                      color="red"
                                      type={
                                        this.state.finalStatus === "Fail"
                                          ? ""
                                          : "lined"
                                      }
                                      onClick={() =>
                                        this.setState({ finalStatus: "Fail" })
                                      }
                                      size="md"
                                      {...classes("decision-btn")}
                                    >
                                      Fail
                                    </Button>
                                  </ButtonGroup>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup {...classes("top-card-group")}>
                                <Label>Overall rating</Label>
                                <div md={3} {...classes("overallStartRating")}>
                                  <StarRatings
                                    rating={this.state.overallRating}
                                    changeRating={(val) =>
                                      this.setState({ overallRating: val })
                                    }
                                    numberOfStars={5}
                                    name="rating"
                                    starDimension="30px"
                                    starSpacing="5px"
                                    starRatedColor="#052a4f"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Card>

                        {this.state.categories.map((category, key) => {
                          return (
                            <Card body {...classes("review-card")}>
                              <CardTitle>{category.category}</CardTitle>
                              <CardText>
                                <ListGroup>
                                  {category.hrmCandidateQuestions.map(
                                    (question, questionKey) => {
                                      return (
                                        <ListGroupItem>
                                          <Row>
                                            <Col md={9}>
                                              <p>{question.question}</p>
                                            </Col>
                                            <Col
                                              md={3}
                                              {...classes("startRating")}
                                            >
                                              <StarRatings
                                                rating={question.rating || 0}
                                                changeRating={(val) =>
                                                  this.changeRating(
                                                    key,
                                                    val,
                                                    questionKey
                                                  )
                                                }
                                                numberOfStars={5}
                                                name="rating"
                                                starDimension="20px"
                                                starSpacing="5px"
                                                starRatedColor="#052a4f"
                                              />
                                            </Col>
                                          </Row>
                                        </ListGroupItem>
                                      );
                                    }
                                  )}
                                </ListGroup>
                              </CardText>
                            </Card>
                          );
                        })}
                        <Textarea
                          placeholder="Comments"
                          {...classes("comments")}
                          value={this.state.comments}
                          onChange={(val) =>
                            this.setState({ comments: val.target.value })
                          }
                        />
                        <div {...classes("comments")}>
                          <FormFeedBack
                            feedBack="This feedback is not editable once submitted."
                            feedBackType="info"
                          />
                          <Button onClick={this.submitFeedback}>
                            Submit Feedback
                          </Button>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCandidateQuestionCategories:
        hrmModule.candidateModule.actions.getCandidateQuestionCategories,
      getCandidate: hrmModule.candidateModule.actions.getCandidate,
      createCandidateFeedback:
        hrmModule.candidateModule.actions.createCandidateFeedback,
      getStages: hrmModule.interviewStageModule.actions.getStages,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    categories: state.hrmCandidateQuestionCategories,
    candidate: state.hrmCandidate,
    stages: state.hrmInterviewStages,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(CandidateFeedback);
