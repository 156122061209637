import PropTypes from "prop-types";
import React, { Component } from "react";
import "./Textarea.scss";

const states = ["error", "focus", "warning", "success", "none"];
const sizes = ["lg", "md", "sm"];

const propTypes = {
  state: PropTypes.oneOf(states),
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  inputClassName: PropTypes.string.isRequired,
  value: PropTypes.string,
  noborder: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  autoHeight: PropTypes.bool,
  textareaRef: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

class Textarea extends Component {
  constructor(props) {
    super(props);

    this.setHeight = () => {
      if (this.props.autoHeight) {
        const el = this._textarea;
        setTimeout(() => {
          el.style.cssText = "height:auto; padding:0;";
          el.style.cssText = "height:" + (el.scrollHeight + 2) + "px;";
        }, 0);
      }
    };

    this.onChange = (e) => {
      this.setHeight();
      this.props.onChange(e);
    };
  }

  componentDidMount() {
    this.setHeight();
  }

  componentDidUpdate() {
    this.setHeight();
  }

  render() {
    const {
      state,
      className,
      inputClassName,
      disabled,
      noborder,
      autoHeight,
      textareaRef,
      register,
      ...inputProps
    } = this.props;
    const outerClassName = ["Textarea", ...className.split(" ")];
    let rows = null;

    if (state) {
      outerClassName.push(`Textarea--${state}`);
    }

    if (disabled) {
      outerClassName.push("Textarea--disabled");
    }
    if (noborder) {
      outerClassName.push("Textarea--noborder");
    }
    if (autoHeight) {
      rows = 1;
      outerClassName.push("Textarea--auto-height");
    }

    return (
      <div className={`${outerClassName.join(" ").trim()}`}>
        <textarea
          rows={rows}
          ref={register && register(this.props.refdetails)}
          // ref={(r) => {
          //   this._textarea = r;
          //   textareaRef(r);
          // }}
          className={`Textarea__input ${inputClassName}`}
          {...inputProps}
          onChange={this.onChange}
          disabled={disabled}
        />
      </div>
    );
  }
}

Textarea.sizes = sizes;
Textarea.states = states;
Textarea.propTypes = propTypes;
Textarea.defaultProps = {
  size: "md",
  placeholder: "",
  className: "",
  inputClassName: "",
  textareaRef: () => {},
  onChange: () => {},
};
export default Textarea;
