import { getSupervisorLevelList } from './actionCreators';
import supervisorLevelReducer from './reducer/supervisorLevelReducer';
import { supervisorSaga } from './saga/supervisorSaga';

const supervisorModule = {
  actions: {
    getSupervisorLevelList
  },
  reducers: {
    supervisorLevelReducer: supervisorLevelReducer
  },
  sagas: {
    supervisorSaga: supervisorSaga
  }
};

export default supervisorModule;
