import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/time-tracking`;

export const getMainEntryReport = async (token, details) => {
  return axios({
    method: "GET",
    url: `${apiUrl}/report?from=${details.from}&to=${details.to}`,
    headers: {
      "x-access-token": token,
    },
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
