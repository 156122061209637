export const GET_INVENTORY_CATEGORIES_REQUEST =
  "GET_INVENTORY_CATEGORIES_REQUEST";
export const GET_INVENTORY_CATEGORIES_SUCCESS =
  "GET_INVENTORY_CATEGORIES_SUCCESS";
export const GET_INVENTORY_CATEGORIES_FAILURE =
  "GET_INVENTORY_CATEGORIES_FAILURE";

export const CREATE_INVENTORY_CATEGORY_REQUEST =
  "CREATE_INVENTORY_CATEGORY_REQUEST";
export const CREATE_INVENTORY_CATEGORY_SUCCESS =
  "CREATE_INVENTORY_CATEGORY_SUCCESS";
export const CREATE_INVENTORY_CATEGORY_FAILURE =
  "CREATE_INVENTORY_CATEGORY_FAILURE";

export const UPDATE_INVENTORY_CATEGORY_REQUEST =
  "UPDATE_INVENTORY_CATEGORY_REQUEST";
export const UPDATE_INVENTORY_CATEGORY_SUCCESS =
  "UPDATE_INVENTORY_CATEGORY_SUCCESS";
export const UPDATE_INVENTORY_CATEGORY_FAILURE =
  "UPDATE_INVENTORY_CATEGORY_FAILURE";
