import React, { Component } from "react";
import { Container, Row, Col, Card, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import inventoryModule from "../../../../redux/inventory";
import { Bar, Line } from "react-chartjs-2";
import bemClass from "../../../components/ui-components/Utils/bemClass";
import Loader from "../../../components/ui-components/Loader";
import "./dashboard.scss";

class InventoryDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserModal: false,
    };
  }

  generateBarChartDataByTypes = () => {
    const labels = [];
    const costs = [];
    this.props.dashboardData.data.total_by_type.forEach((type) => {
      labels.push(
        type.inventoryItemType ? type.inventoryItemType.name : "Other"
      );
      costs.push(type.total_amount);
    });
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Cost",
          backgroundColor: "#02C5BA",
          borderColor: "#052a4f",
          borderWidth: 1,
          hoverBackgroundColor: "#008F87",
          hoverBorderColor: "#052a4f",
          data: costs,
        },
      ],
    };
    return data;
  };

  generateBarChartData = () => {
    const labels = [];
    const costs = [];
    this.props.dashboardData.data.other.forEach((category) => {
      labels.push(category.inventoryMainCategory.name);
      costs.push(category.total_amount);
    });
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Cost",
          backgroundColor: "#02C5BA",
          borderColor: "#052a4f",
          borderWidth: 1,
          hoverBackgroundColor: "#008F87",
          hoverBorderColor: "#052a4f",
          data: costs,
        },
      ],
    };
    return data;
  };

  generateLineartData = () => {
    const labels = [];
    const costs = [];
    this.props.dashboardData.data.this_year_cost.map((year) => {
      labels.push(year.month);
      costs.push(year.data.total_cost || 0);
    });
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Cost",
          data: costs,
          fill: false,
          backgroundColor: "#052a4f",
          borderColor: "#3367c0",
        },
      ],
    };
    return data;
  };

  componentDidMount() {
    this.props.getDashboardData();
  }

  render() {
    const classes = new bemClass("InventoryDashboard");
    const { dashboardData } = this.props;

    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback(value) {
                return Number(value).toLocaleString("en");
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, chart) {
            return "Cost: " + Number(tooltipItem.yLabel).toLocaleString("en");
          },
        },
        // intersect: false,
      },
    };

    return (
      <Container fluid className={classes.elem("details").b()}>
        {dashboardData.loading ? (
          <div className={classes.elem("loader").b()}>
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            <Row>
              <Col md={3}>
                <Card
                  body
                  className="text-center"
                  inverse
                  style={{ backgroundColor: "#3BABE7", borderColor: "#3BABE7" }}
                >
                  <CardTitle>
                    <h3>Total Cost</h3>
                  </CardTitle>
                  <h3>
                    {Intl.NumberFormat("si-LK", {
                      notation: "compact",
                      compactDisplay: "short",
                      style: "currency",
                      currency: this.props.user.userDetails.company.currency,
                    }).format(dashboardData.data.total_cost)}
                  </h3>
                </Card>
              </Col>
              <Col md={3}>
                <Card
                  body
                  className="text-center"
                  inverse
                  style={{ backgroundColor: "#FF4C52", borderColor: "#FF4C52" }}
                >
                  <CardTitle>
                    <h3>This Month Cost</h3>
                  </CardTitle>
                  <h3>
                    {Intl.NumberFormat("si-LK", {
                      notation: "compact",
                      compactDisplay: "short",
                      style: "currency",
                      currency: this.props.user.userDetails.company.currency,
                    }).format(dashboardData.data.this_month_cost)}
                  </h3>
                </Card>
              </Col>
              <Col md={3}>
                <Card
                  body
                  className="text-center"
                  inverse
                  style={{ backgroundColor: "#11C26D", borderColor: "#11C26D" }}
                >
                  <CardTitle>
                    <h3>Total Item Count</h3>
                  </CardTitle>
                  <h3>
                    {Intl.NumberFormat("si-LK", {
                      notation: "compact",
                      compactDisplay: "short",
                    }).format(dashboardData.data.total_item_count)}
                  </h3>
                </Card>
              </Col>
              <Col md={3}>
                <Card
                  body
                  className="text-center"
                  inverse
                  style={{ backgroundColor: "#FFCD17", borderColor: "#FFCD17" }}
                >
                  <CardTitle>
                    <h3>Total Vendors</h3>
                  </CardTitle>
                  <h3>{dashboardData.data.total_vendors}</h3>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              {this.props.dashboardData.data.map((category, index) => {
                return (
                  <Col sm={3} key={index}>
                    <Card
                      body
                      style={{
                        backgroundColor: "#E8E9F9",
                        borderColor: "#D6D6D6",
                      }}
                      className="text-center dashboardCard"
                    >
                      <CardTitle>
                        {category.inventoryMainCategory.name}
                      </CardTitle>
                      <h3>
                        {new Intl.NumberFormat("si-LK", {
                          style: "currency",
                          currency: this.props.user.userDetails.company
                            .currency,
                        }).format(category.total_amount)}
                      </h3>
                    </Card>
                  </Col>
                );
              })}
            </Row> */}
            <Row className={classes.elem("charts").b()}>
              <Col sm={6}>
                <Card body className="text-center">
                  <CardTitle>
                    <b>{"Total Inventoy Cost For Categories"}</b>
                  </CardTitle>
                  <Bar data={this.generateBarChartData()} options={options} />
                </Card>
              </Col>
              <Col sm={6}>
                <Card body className="text-center">
                  <CardTitle>
                    <b>{"Total Inventoy Cost By Types"}</b>
                  </CardTitle>
                  <Bar
                    data={this.generateBarChartDataByTypes()}
                    options={options}
                  />
                </Card>
                {/* <Card body className="text-center">
                  <CardTitle>
                    <b>{"Inventoy Cost For This Year"}</b>
                  </CardTitle>
                  <Line data={this.generateLineartData()} options={options} />
                </Card> */}
              </Col>
            </Row>
            <Row className={classes.elem("charts").b()}>
              <Col sm={12}>
                <Card body className="text-center">
                  <CardTitle>
                    <b>{"Inventoy Cost For This Year"}</b>
                  </CardTitle>
                  <Line
                    data={this.generateLineartData()}
                    options={options}
                    height={80}
                  />
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDashboardData:
        inventoryModule.dashboardModule.actions.getDashboardData,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    dashboardData: state.inventoryDashboardData,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(InventoryDashboard);
