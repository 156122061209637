import {
  getProjects,
  createProject,
  deleteProject,
  updateProject,
  assignTeamToProject,
} from "./actionCreators";
import allProjectsReducer from "./reducer/allProjectsReducer";
import { projectSaga } from "./saga/projectSaga";

const projectModule = {
  actions: {
    getProjects,
    createProject,
    deleteProject,
    updateProject,
    assignTeamToProject,
  },
  reducers: {
    allProjectsReducer: allProjectsReducer,
  },
  sagas: {
    projectSaga: projectSaga,
  },
};

export default projectModule;
