import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import CustomTable from "../../../../components/table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import inventoryModule from "../../../../../redux/inventory";
import userModule from "../../../../../redux/user";
import bemClass from "../../../../components/ui-components/Utils/bemClass";
import Button from "../../../../components/ui-components/Button";
import ReportFilterModal from "../../../../components/modals/inventory/reportFilterModal";
import moment from "moment";
import Paginator from "../../../../components/ui-components/Paginator";
import Loader from "../../../../components/ui-components/Loader";
import AddItemModal from "../../../../components/modals/inventory/addItemModal";
import itemColumns from "../../../../../enum/inventory/itemColumn";
import "./generalReport.scss";

class InventoryGeneralReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilterModal: false,
      offset: 0,
      appliedFilters: {
        columns: [itemColumns[0]],
      },
      showItemModal: false,
      itemSelected: null,
    };
  }

  getTableHeading = () => {
    return (
      <tr>
        {this.state.appliedFilters.columns.map((column, index) => {
          return <th key={index}>{column.label}</th>;
        })}
        <th>{""}</th>
      </tr>
    );
  };

  getTableContent = (data) => {
    return (data.records || []).map((row, key) => {
      let name = row.user ? `${row.user.firstName} ${row.user.lastName}` : `-`;
      if (!row.user) {
        row.user = {};
      }
      row.user.fullName = name;
      return (
        <tr key={key} className="value-row">
          {this.state.appliedFilters.columns.map((column, index) => {
            const columnValue = column.value.split(".").reduce(function (o, x) {
              return o[x] || "-";
            }, row);
            return <td key={index}>{columnValue}</td>;
          })}
          <td className="InventoryGeneralReport__item-actions">
            <Button
              color="bright-blue"
              onClick={() => {
                this.setState({ showItemModal: true, itemSelected: row });
              }}
              size="sm"
            >
              View Item
            </Button>
          </td>
        </tr>
      );
    });
  };

  getFormattedUserList = (users) => {
    return users.map((user) => {
      return {
        value: user.userId,
        label: `${user.firstName} ${user.lastName}`,
      };
    });
  };

  applyFilters = (values) => {
    const data = {
      ...(values.categoryId && { categoryId: values.categoryId.value }),
      ...(values.typeId && { typeId: values.typeId.value }),
      ...(values.occupiedBy && { occupiedBy: values.occupiedBy.value }),
      ...(values.warrantyMonth && {
        warrantyMonth: values.warrantyMonth.value,
      }),
      ...(values.fromDate && {
        fromDate: moment(values.fromDate).format("YYYY-MM-DD"),
      }),
      ...(values.toDate && {
        toDate: moment(values.toDate).format("YYYY-MM-DD"),
      }),
      ...(values.serialNumber && { serialNumber: values.serialNumber }),
      ...(values.isExpired && { isExpired: values.isExpired }),
      columns: values.columns,
      offset: 0,
    };
    this.props.getCustomReport(data);
    this.setState({ appliedFilters: data, offset: 0 });
    this.toggle();
  };

  toggle = () => {
    this.setState({ showFilterModal: false, showItemModal: false });
  };

  downloadReport = (type) => {
    let filters = this.state.appliedFilters;
    filters.documentType = type;
    this.props.downloadCustomReport(filters);
  };

  componentDidMount() {
    this.props.getCustomReport({ offset: this.state.offset });
    this.props.getCategories();
    this.props.getTypes();
    this.props.getUserList();
  }

  componentWillUnmount() {
    this.props.resetReport();
  }

  render() {
    const classes = new bemClass("InventoryGeneralReport");
    return (
      <Container className={classes.elem("items").b()}>
        {this.state.showFilterModal ? (
          <ReportFilterModal
            openModal={this.state.showFilterModal}
            toggle={this.toggle}
            title={"Edit Filters"}
            submit={this.applyFilters}
            categories={this.props.categories.data}
            users={this.props.userList.userList}
            types={this.props.types.data}
          />
        ) : null}
        {this.state.showItemModal ? (
          <AddItemModal
            openModal={this.state.showItemModal}
            toggle={this.toggle}
            title={"View Item"}
            submit={this.toggle}
            isEditMode={true}
            objectToEdit={this.state.itemSelected}
            viewMode={true}
          />
        ) : null}

        <Row>
          <Col sm={8} className="text-left">
            <h2 className="list-header-title">General Report</h2>
          </Col>
          <Col sm={4} className="text-right">
            <React.Fragment>
              <Button
                color="light-grey"
                onClick={() => {
                  this.downloadReport("pdf");
                }}
                className="report-action-btn"
              >
                Export Pdf
              </Button>
              <Button
                color="light-grey"
                onClick={() => {
                  this.downloadReport("csv");
                }}
                className="report-action-btn"
              >
                Export CSV
              </Button>
              <Button
                color="deep-blue"
                onClick={() => {
                  this.setState({ showFilterModal: true });
                }}
              >
                Filters
              </Button>
            </React.Fragment>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="hr"></div>
            {this.props.report.loading ? (
              <div className={classes.elem("loader").b()}>
                <Loader />
              </div>
            ) : (
              <React.Fragment>
                <CustomTable
                  content={this.getTableContent(this.props.report.data)}
                  tableHeadings={this.getTableHeading()}
                  customHeaders
                />
                <Paginator
                  style={{ padding: "10px 0" }}
                  limit={10}
                  offset={this.state.offset}
                  total={this.props.report.data.count}
                  align="center"
                  onChange={(offset) => {
                    let filters = this.state.appliedFilters;
                    filters.documentType = null;
                    filters.offset = offset;
                    this.props.getCustomReport(filters);
                    this.setState({ offset: offset });
                  }}
                />
              </React.Fragment>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCustomReport: inventoryModule.reportModule.actions.getCustomReport,
      getUserList: userModule.actions.getUserList,
      getCategories: inventoryModule.categoryModule.actions.getCategories,
      getTypes: inventoryModule.typeModule.actions.getTypes,
      downloadCustomReport:
        inventoryModule.reportModule.actions.downloadCustomReport,
      resetReport: inventoryModule.reportModule.actions.resetReport,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    categories: state.inventoryMainCategories,
    types: state.inventoryItemTypes,
    userList: state.userList,
    report: state.inventoryReport,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(InventoryGeneralReport);
