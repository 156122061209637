import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { FaTimes } from "react-icons/fa";
import BEMhelper from "react-bem-helper";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../ui-components/Button";
import Input from "../../../inputNew";
import FormFeedBack from "../../../ui-components/FormFeedBack";
import Textarea from "../../../ui-components/Textarea";
import Select from "react-select";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import Dropzone from "../../../ui-components/Dropzone";
import { useDispatch } from "react-redux";
import { IconContext } from "react-icons";
import itemModule from "../../../../../redux/inventory/item";
import itemWarrenties from "../../../../../enum/inventory/itemWarrenty";
import itemConditions from "../../../../../enum/inventory/itemCondition";
import DatePicker from "react-datepicker";
import "./addItemModal.scss";

const classes = new BEMhelper("AddItemModal");
const AddItemModal = (props) => {
  const {
    title,
    openModal,
    toggle,
    isEditMode,
    objectToEdit,
    submit,
    categories,
    users,
    viewMode,
    btnLoading,
    typesNew,
    showAddItemType,
    showAddItemCategory,
  } = props;

  const dispatch = useDispatch();
  const [files, setfiles] = useState(
    isEditMode ? objectToEdit.files || [] : []
  );

  const addFile = (file) => {
    const allFiles = files;
    const maxSize = parseInt(5) * 1024 * 1024;
    file.forEach((element) => {
      if (allFiles.length + 1 < 6) {
        if (file.size > maxSize) {
          toast.warn(`${file.name} is too big. max file size is 5MB`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          allFiles.push(element);
        }
      }
    });
    setfiles([...allFiles]);
  };

  let selectedCategory = null;
  let selectedType = null;
  let selectedOccupiedBy = null;
  let selectedwarrantyMonth = null;
  let selectedCondition = null;

  const categoryOptions = (categories || []).map((category) => {
    return {
      value: category.inventoryMainCategoryId,
      label: category.name,
    };
  });

  const typeOptions = (typesNew || []).map((type) => {
    return {
      value: type.inventoryItemTypeId,
      label: type.name,
    };
  });

  const userOptions = (users || []).map((user) => {
    return {
      value: user.userId,
      label: `${user.firstName} ${user.lastName}`,
    };
  });

  const warrenties = itemWarrenties;

  if (viewMode) {
    selectedCategory = {
      value: objectToEdit.inventoryMainCategoryId,
      label: objectToEdit.inventoryMainCategory.name,
    };
    if (objectToEdit.occupiedBy) {
      selectedOccupiedBy = {
        value: objectToEdit.occupiedBy,
        label: `${objectToEdit.user.firstName} ${objectToEdit.user.lastName}`,
      };
    }
    if (objectToEdit.inventoryItemTypeId) {
      selectedType = {
        value: objectToEdit.inventoryItemTypeId,
        label: objectToEdit.inventoryItemType.name,
      };
    }
  }

  if (isEditMode) {
    _.forEach(categoryOptions, function (category) {
      if (category.value === objectToEdit.inventoryMainCategoryId) {
        selectedCategory = category;
      }
    });

    _.forEach(typeOptions, function (type) {
      if (type.value === objectToEdit.inventoryItemTypeId) {
        selectedType = type;
      }
    });

    _.forEach(itemConditions, function (condition) {
      if (condition.value === objectToEdit.condition) {
        selectedCondition = condition;
      }
    });

    if (objectToEdit.occupiedBy) {
      _.forEach(userOptions, function (user) {
        if (user.value === objectToEdit.occupiedBy) {
          selectedOccupiedBy = user;
        }
      });
    }

    if (objectToEdit.warrantyMonth) {
      _.forEach(warrenties, function (month) {
        if (month.value === objectToEdit.warrantyMonth) {
          selectedwarrantyMonth = month;
        }
      });
    }
  }

  const defaultValues = {
    purchaseDate: isEditMode ? new Date(objectToEdit.purchaseDate) : null,
    categoryId: selectedCategory,
    name: isEditMode ? objectToEdit.name : "",
    quantity: isEditMode ? objectToEdit.quantity : null,
    available: isEditMode ? objectToEdit.available : null,
    typeId: selectedType,
    condition: selectedCondition,
    brand: isEditMode ? objectToEdit.brand : "",
    serialNumber: isEditMode ? objectToEdit.serialNumber : "",
    notes: isEditMode ? objectToEdit.notes : "",
    occupiedBy: selectedOccupiedBy,
    price: isEditMode ? objectToEdit.cost : null,
    vendor: isEditMode ? objectToEdit.vendor : "",
    assetLocation: isEditMode ? objectToEdit.assetLocation : "",
    warrantyMonth: selectedwarrantyMonth,
  };

  const { handleSubmit, register, errors, control, watch, setValue } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    if (parseInt(values.quantity) < parseInt(values.available)) {
      toast.error("Quantity cannot be greater than available", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      const available =
        itemWatcher.categoryId &&
        (itemWatcher.categoryId.value === 3 ||
          itemWatcher.categoryId.value === 4)
          ? itemWatcher.occupiedBy && itemWatcher.occupiedBy.value
            ? 0
            : 1
          : itemWatcher.available;
      const quantity =
        itemWatcher.categoryId &&
        (itemWatcher.categoryId.value === 3 ||
          itemWatcher.categoryId.value === 4)
          ? 1
          : itemWatcher.quantity;
      values.available = available;
      values.quantity = quantity;
      values.files = files;
      submit(values);
    }
  };

  const generateFileUrl = (file) => {
    if (file.url) {
      return file.url;
    }
    const objectURL = URL.createObjectURL(file);
    return objectURL;
  };

  const itemWatcher = watch([
    "purchaseDate",
    "categoryId",
    "quantity",
    "available",
    "occupiedBy",
    "warrantyMonth",
  ]);

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => toggle()}
      centered={true}
      draggable={false}
      size="xl"
      backdrop={"static"}
    >
      <fieldset disabled={viewMode ? true : false}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={() => toggle()} className="modal-header">
            {title}
          </ModalHeader>
          <ModalBody>
            <Container fluid>
              <React.Fragment>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>
                        Category{" "}
                        <span
                          className="create-type"
                          onClick={() => showAddItemCategory()}
                        >
                          Create
                        </span>
                      </Label>
                      <Controller
                        as={
                          <Select
                            placeholder="Select category"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                border: `1px solid ${
                                  errors.categoryId ? "#dc3545" : "#cad2dd"
                                }`,
                              }),
                            }}
                          />
                        }
                        options={categoryOptions}
                        name="categoryId"
                        isClearable
                        isDisabled={viewMode}
                        control={control}
                        onChange={([selected]) => {
                          setValue("quantity", 1);
                          return { value: selected };
                        }}
                        rules={{
                          required: "Category is required",
                        }}
                      />
                      {errors.categoryId ? (
                        <FormFeedBack feedBack={errors.categoryId.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Item Name</Label>
                      <Input
                        name="name"
                        register={register}
                        refdetails={{
                          required: "Item name required",
                        }}
                        state={errors.name ? "error" : "none"}
                      />
                      {errors.name ? (
                        <FormFeedBack feedBack={errors.name.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Total Price</Label>
                      <Input
                        type="number"
                        name="price"
                        register={register}
                        refdetails={{
                          required: "Price required",
                          max: {
                            value: 10000000,
                            message: "Maximum price limit is 10000000",
                          },
                        }}
                        state={errors.price ? "error" : "none"}
                      />
                      {errors.price ? (
                        <FormFeedBack feedBack={errors.price.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Condition</Label>
                      <Controller
                        as={
                          <Select
                            placeholder="Select condition"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                border: `1px solid ${
                                  errors.condition ? "#dc3545" : "#cad2dd"
                                }`,
                              }),
                            }}
                          />
                        }
                        options={itemConditions}
                        name="condition"
                        isClearable
                        isDisabled={viewMode}
                        control={control}
                        onChange={([selected]) => {
                          return { value: selected };
                        }}
                        rules={{
                          required: "Condition is required",
                        }}
                      />
                      {errors.condition ? (
                        <FormFeedBack feedBack={errors.condition.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Quantity</Label>
                      <Input
                        value={
                          itemWatcher.categoryId &&
                          (itemWatcher.categoryId.value === 3 ||
                            itemWatcher.categoryId.value === 4)
                            ? 1
                            : itemWatcher.quantity
                        }
                        name="quantity"
                        type="number"
                        register={register}
                        refdetails={{
                          required: "Quantity required",
                          min: {
                            value: 0,
                            message: "Quantity should not be less than 0",
                          },
                        }}
                        state={errors.quantity ? "error" : "none"}
                        disabled={
                          itemWatcher.categoryId &&
                          (itemWatcher.categoryId.value === 3 ||
                            itemWatcher.categoryId.value === 4)
                        }
                      />
                      {errors.quantity ? (
                        <FormFeedBack feedBack={errors.quantity.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Available</Label>
                      <Input
                        value={
                          itemWatcher.categoryId &&
                          (itemWatcher.categoryId.value === 3 ||
                            itemWatcher.categoryId.value === 4)
                            ? itemWatcher.occupiedBy &&
                              itemWatcher.occupiedBy.value
                              ? 0
                              : 1
                            : itemWatcher.available
                        }
                        name="available"
                        type="number"
                        register={register}
                        refdetails={{
                          required: "Availability required",
                          min: {
                            value: 0,
                            message: "Availability should not be less than 0",
                          },
                        }}
                        state={errors.available ? "error" : "none"}
                        disabled={
                          itemWatcher.categoryId &&
                          (itemWatcher.categoryId.value === 3 ||
                            itemWatcher.categoryId.value === 4)
                        }
                      />
                      {errors.available ? (
                        <FormFeedBack feedBack={errors.available.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Purchase Date</Label>
                      <Controller
                        as={
                          <DatePicker
                            dateFormat="yyyy-MM-dd"
                            placeholderText={"Purchase date"}
                            className={`form-control ${
                              errors.purchaseDate ? "date-error" : ""
                            }`}
                            selected={itemWatcher.purchaseDate}
                            popperPlacement="top-end"
                            showYearDropdown
                            showMonthDropdown
                          />
                        }
                        valueName="selected"
                        name="purchaseDate"
                        control={control}
                        onChange={(date) => setValue("purchaseDate", date)}
                        rules={{
                          required: "Purchase date is required",
                        }}
                      />
                      {errors.purchaseDate ? (
                        <FormFeedBack feedBack={errors.purchaseDate.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Serial number</Label>
                      <Input
                        name="serialNumber"
                        register={register}
                        state={errors.serialNumber ? "error" : "none"}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Item location</Label>
                      <Input
                        name="assetLocation"
                        register={register}
                        state={errors.assetLocation ? "error" : "none"}
                      />
                      {errors.assetLocation ? (
                        <FormFeedBack feedBack={errors.assetLocation.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Warranty</Label>
                      <Controller
                        as={
                          <Select
                            placeholder="Select warranty"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                border: `1px solid ${
                                  errors.warrantyMonth ? "#dc3545" : "#cad2dd"
                                }`,
                              }),
                            }}
                          />
                        }
                        options={warrenties}
                        name="warrantyMonth"
                        isClearable
                        isDisabled={viewMode}
                        control={control}
                        onChange={([selected]) => {
                          return { value: selected };
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Brand</Label>
                      <Input
                        name="brand"
                        register={register}
                        state={errors.brand ? "error" : "none"}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Vendor</Label>
                      <Input
                        name="vendor"
                        register={register}
                        state={errors.vendor ? "error" : "none"}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>
                        Type{" "}
                        <span
                          className="create-type"
                          onClick={() => showAddItemType()}
                        >
                          Create
                        </span>
                      </Label>
                      <Controller
                        as={
                          <Select
                            placeholder="Select type"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                border: `1px solid ${
                                  errors.typeId ? "#dc3545" : "#cad2dd"
                                }`,
                              }),
                            }}
                          />
                        }
                        options={typeOptions}
                        name="typeId"
                        isClearable
                        isDisabled={viewMode}
                        control={control}
                        onChange={([selected]) => {
                          return { value: selected };
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <React.Fragment>
                    <Col md={4}>
                      <FormGroup>
                        <Label>Occupied By</Label>
                        <Controller
                          as={
                            <Select
                              placeholder="Select user"
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  border: `1px solid ${
                                    errors.occupiedBy ? "#dc3545" : "#cad2dd"
                                  }`,
                                }),
                              }}
                            />
                          }
                          options={userOptions}
                          name="occupiedBy"
                          isClearable
                          isDisabled={viewMode}
                          control={control}
                          onChange={([selected]) => {
                            return { value: selected };
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </React.Fragment>
                </Row>
                <Row>
                  <Col md={6}>
                    <Label>Files</Label>
                    <div {...classes("dropzone")}>
                      <Dropzone
                        addedFiles={addFile}
                        disabled={files.length > 4}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div {...classes("file-list")}>
                      <React.Fragment>
                        <Label></Label>
                        <p>Maximun number of files you can upload is 5</p>
                        <p>Maximun size of each file is 5MB</p>
                        {files.length ? (
                          <ol>
                            {files.map((file, indexToRemove) => {
                              return (
                                <li key={indexToRemove}>
                                  <a
                                    target="_blank"
                                    href={generateFileUrl(file)}
                                  >
                                    {file.name}
                                  </a>
                                  {""}
                                  <IconContext.Provider
                                    value={{
                                      className: "removeItem-file-icon",
                                    }}
                                  >
                                    {!viewMode ? (
                                      <FaTimes
                                        onClick={() => {
                                          if (isEditMode) {
                                            const file = files.find(
                                              (element, index) =>
                                                index === indexToRemove
                                            );
                                            const isFile = file instanceof File;
                                            if (!isFile) {
                                              const data = {
                                                itemId:
                                                  objectToEdit.inventoryItemId,
                                                fileName: file.name,
                                              };
                                              dispatch(
                                                itemModule.actions.deleteItemFile(
                                                  data
                                                )
                                              );
                                            }
                                          }
                                          const newImages = files
                                            .slice(0, indexToRemove)
                                            .concat(
                                              files.slice(
                                                indexToRemove + 1,
                                                files.length
                                              )
                                            );
                                          setfiles(newImages);
                                        }}
                                      />
                                    ) : null}
                                  </IconContext.Provider>
                                </li>
                              );
                            })}
                          </ol>
                        ) : (
                          <p>No files uploaded</p>
                        )}
                      </React.Fragment>
                    </div>
                  </Col>
                </Row>
                {!viewMode ? (
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label>Notes</Label>
                        <Textarea
                          name="notes"
                          register={register}
                          state={errors.notes ? "error" : "none"}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                {isEditMode && objectToEdit.inventoryItemNotes.length > 0 ? (
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label>
                          <b>Previous notes</b>
                        </Label>
                        {objectToEdit.inventoryItemNotes.map((note, index) => {
                          return (
                            <p key={index}>
                              {note.note} -{" "}
                              <span {...classes("notesBy")}>
                                {note.user.firstName} {note.user.lastName} on{" "}
                                {moment(note.createdAt).format("YYYY-MM-DD")}
                              </span>
                            </p>
                          );
                        })}
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
              </React.Fragment>
            </Container>
          </ModalBody>
          {!viewMode ? (
            <ModalFooter>
              <Button submit loading={btnLoading || false}>
                Submit
              </Button>
            </ModalFooter>
          ) : null}
        </form>
      </fieldset>
    </Modal>
  );
};

export default AddItemModal;
