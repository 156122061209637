import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";

export function* clientSaga() {
  yield takeLatest(GET_CLIENTS_REQUEST, getClients);
  yield takeLatest(CREATE_CLIENT_REQUEST, createClient);
  yield takeLatest(UPDATE_CLIENT_REQUEST, updateClient);
  yield takeLatest(DELETE_CLIENT_REQUEST, deleteClient);
  yield takeLatest(CREATE_CONTACT_REQUEST, createContact);
}

function* getClients() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const clients = yield call(
      apis.timeTrackApis.getClients,
      loggedUser.userDetails.auth.accessToken
    );
    if (clients.error) {
      yield put({
        type: GET_CLIENTS_FAILURE,
        error: clients.body.data.error,
      });
    } else {
      yield put({ type: GET_CLIENTS_SUCCESS, data: clients.body.data });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createClient(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const client = yield call(
      apis.timeTrackApis.createClient,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (client.error) {
      yield put({
        type: CREATE_CLIENT_FAILURE,
        error: client.body.data.error,
      });
    } else {
      yield put({ type: CREATE_CLIENT_SUCCESS, data: client.body.data });
      yield put({ type: GET_CLIENTS_REQUEST });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* updateClient(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const client = yield call(
      apis.timeTrackApis.updateClient,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (client.error) {
      yield put({
        type: UPDATE_CLIENT_FAILURE,
        error: client.body.data.error,
      });
    } else {
      yield put({ type: UPDATE_CLIENT_SUCCESS, data: client.body.data });
      const data = {
        date: details.payload.formValues.date,
      };
      yield put({ type: GET_CLIENTS_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* deleteClient(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const client = yield call(
      apis.timeTrackApis.deleteClient,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues.clientId
    );
    if (client.error) {
      yield put({
        type: DELETE_CLIENT_FAILURE,
        error: client.body.data.error,
      });
    } else {
      yield put({ type: DELETE_CLIENT_SUCCESS, data: client.body.data });
      const data = {
        date: details.payload.formValues.date,
      };
      yield put({ type: GET_CLIENTS_REQUEST, payload: data });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createContact(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const contact = yield call(
      apis.timeTrackApis.createContact,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (contact.error) {
      yield put({
        type: CREATE_CONTACT_FAILURE,
        error: contact.body.data.error,
      });
    } else {
      yield put({ type: CREATE_CONTACT_SUCCESS, data: contact.body.data });
      yield put({ type: GET_CLIENTS_REQUEST });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
