import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Loader from "../../../../components/ui-components/Loader";
import userModule from "../../../../../redux/user";
import supervisorModule from "../../../../../redux/supervisor";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card } from "reactstrap";
import moment from "moment";
import Stepper from "react-stepper-horizontal";
import StepOne from "../FormSteps/stepOne";
import StepTwo from "../FormSteps/stepTwo";
import StepThree from "../FormSteps/stepThree";
import BEMhelper from "react-bem-helper";
import "../Addusers/AddUser.scss";
import "./editUser.scss";
import adminModule from "../../../../../redux/admin";

class EditUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      stepOneData: null,
      stepTwoData: null,
      stepThreeData: null,
      isEditMode: false,
      userId: 0,
      initialLoading: true,
      formatWorkPlaces: this.formatWorkPlaces.bind(this),
      formatFeatureRoles: this.formatFeatureRoles.bind(this),
    };
  }

  componentWillUnmount() {
    // this.setState({
    //   stepOneData: null,
    //   stepTwoData: null,
    //   stepThreeData: null,
    //   initialLoading: true,
    // });
    this.props.resetSingleUser();
  }

  getFormatteFeatureRoles = (featureRoles) => {
    return featureRoles.map((role) => {
      return role.value;
    });
  };

  submitValues = (values) => {
    const tab = this.state.activeTab;
    if (tab === 0) {
      this.setState({
        activeTab: 1,
        stepOneData: values,
        isEditMode: this.state.stepTwoData ? true : false,
      });
    } else if (tab === 1) {
      this.setState({
        activeTab: 2,
        stepTwoData: values,
        isEditMode: this.state.stepThreeData ? true : false,
      });
    } else {
      this.setState({ stepThreeData: values, isEditMode: false });
      const stepOne = this.state.stepOneData;
      const stepTwo = this.state.stepTwoData;
      const stepThree = this.state.stepThreeData;
      const data = {
        userId: this.state.userId,
        firstName: stepOne.firstname,
        middleName: stepOne.middlename,
        lastName: stepOne.lastname,
        email: stepOne.email,
        district: stepOne.district || null,
        maritalstatus: stepOne.maritalstatus
          ? stepOne.maritalstatus.value
          : null,
        religion: stepOne.religion || null,
        address: stepOne.permanentaddress,
        gender: stepOne.gender.value,
        nic: stepOne.nic,
        birthday: moment(stepOne.dateofbirth).format("YYYY-MM-DD"),
        bloodGroup: stepOne.bloodGroup.value,
        title: stepOne.title.value,
        phone: stepOne.mobilenumber || null,

        joinDate: moment(stepTwo.joindate).format("YYYY-MM-DD"),
        ...(stepTwo.password !== "Find if you can" && {
          password: stepTwo.password,
        }),
        departmentId: stepTwo.department.value,
        designationId: stepTwo.designation.value,
        roleId: stepTwo.role.value,
        employeeId: stepTwo.employeeId,
        employeeCategory: stepTwo.employeecategory.value,
        sourceOfHire: stepTwo.source.value,
        officeEmail: stepTwo.officeemail,
        featureRoles: stepTwo.featureRoles
          ? this.getFormatteFeatureRoles(stepTwo.featureRoles)
          : [],
        emergencyDetails: {
          name: stepTwo.emergencycontactname,
          relationship: stepTwo.relationship,
          contactNumber: stepTwo.contactnumber,
        },
        spouse: {
          name: stepTwo.spousename ? stepTwo.spousename : null,
          birthday: stepTwo.spousebirthday ? stepTwo.spousebirthday : null,
        },
        ...(stepTwo.education && { education: [{ institute: "test" }] }),
        workExperience: stepTwo.workplaces ? stepTwo.workplaces : [],
        aboutMe: stepTwo.notes ? stepTwo.notes : null,
        resigned: stepTwo.resigned ? stepTwo.resigned : null,
        drivingLicenceNumber: stepThree.drivinglicensenumber
          ? stepThree.drivinglicensenumber
          : null,
        electorate: stepThree.electorate ? stepThree.electorate : null,
        passportNumber: stepThree.passportnumber
          ? stepThree.passportnumber
          : null,
        policeStation: stepThree.police ? stepThree.police : null,
      };
      this.props.updateUser(data);
    }
  };

  changeTab = (tabValue) => {
    this.setState({
      activeTab: tabValue,
      isEditMode: true,
    });
  };

  formatWorkPlaces = (work) => {
    if (work.length > 0) {
      return work.map((place) => {
        return {
          companyName: place.company,
          from: place.from,
          to: place.to,
          description: place.description,
          designation: place.designation,
        };
      });
    }
    return [];
  };

  formatFeatureRoles = (roles) => {
    return roles.map((role) => {
      return { value: role.featureAccessRoleId };
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.singleUser.loading && state.initialLoading) {
      const userObject = props.singleUser.userDetails;
      const stepOneData = {
        firstname: userObject.user.firstName,
        middlename: userObject.user.middleName,
        lastname: userObject.user.lastName,
        email: userObject.user.email,
        district: userObject.user.district || null,
        maritalstatus: userObject.user.maritalStatus
          ? { value: userObject.user.maritalStatus }
          : null,
        religion: userObject.user.religion || null,
        permanentaddress: userObject.user.address,
        gender: userObject.user.gender
          ? { value: userObject.user.gender }
          : null,
        nic: userObject.user.nic,
        dateofbirth: userObject.user.birthday,
        bloodGroup: userObject.user.bloodGroup
          ? { value: userObject.user.bloodGroup }
          : null,
        title: userObject.user.title ? { value: userObject.user.title } : null,
        mobilenumber: userObject.user.phone || null,
      };

      const stepTwoData = {
        employeecategory: userObject.user.employeeCategory
          ? { value: userObject.user.employeeCategory }
          : null,
        source: userObject.user.sourceOfHire
          ? { value: userObject.user.sourceOfHire }
          : null,
        joindate: userObject.user.joinDate,
        designation: userObject.user.designationId
          ? { value: userObject.user.designationId }
          : null,
        department: userObject.user.departmentId
          ? { value: userObject.user.departmentId }
          : null,
        role: userObject.user.roleId ? { value: userObject.user.roleId } : null,
        officeemail: userObject.user.officeEmail,
        employeeId: userObject.user.employeeId,
        password: userObject.user.password,
        notes: userObject.user.aboutMe,
        resigned: userObject.user.resigned,
        emergencycontactname: userObject.user.emergencyDetail.name,
        relationship: userObject.user.emergencyDetail.relationship,
        contactnumber: userObject.user.emergencyDetail.contactNumber,
        spousename: userObject.user.spouseId
          ? userObject.user.spouse.name
          : null,
        spousebirthday: userObject.user.spouseId
          ? userObject.user.spouse.birthday
          : null,
        workplaces:
          userObject.workExperience.length > 0
            ? state.formatWorkPlaces(userObject.workExperience)
            : [],
        featureRoles:
          userObject.featureRoles.length > 0
            ? state.formatFeatureRoles(userObject.featureRoles)
            : [],
      };

      const stepThreeData = {
        police: userObject.user.policeStation,
        passportnumber: userObject.user.passportNumber,
        drivinglicensenumber: userObject.user.drivingLicenceNumber,
        electorate: userObject.user.electorate,
      };
      return {
        stepOneData: stepOneData,
        stepTwoData: stepTwoData,
        stepThreeData: stepThreeData,
        isEditMode: true,
        initialLoading: false,
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({ userId: parseInt(params.userId) });
    this.props.getSingleUser(params.userId);
    this.props.userRoles();
    this.props.userDesignation();
    this.props.userDepartment();
    this.props.getUserList();
    this.props.getSupervisorLevels();
    this.props.featureAccessRole();
  }

  render() {
    const classes = new BEMhelper("EditUser");
    return (
      <Container fluid {...classes("edit-user-wrapper")}>
        <Row>
          <Col sm={8} className="title text-left">
            <h2 className="list-header-title">Edit User</h2>
          </Col>
          <Col sm={4} className="text-right time">
            <h4>
              {moment().format("dddd")}, {moment().format("LL")}
            </h4>
          </Col>
        </Row>
        <div className="hr"></div>
        {this.props.singleUser.loading ? (
          <div {...classes("loader")}>
            <Loader />
          </div>
        ) : (
          <Card>
            <Row>
              <Col sm={12}>
                <div>
                  <Stepper
                    steps={[
                      {
                        title: "Personal information",
                        // onClick: () => this.changeTab(0),
                      },
                      {
                        title: "Employee information",
                        // onClick: () => this.changeTab(1),
                      },
                      {
                        title: "Other information",
                        // onClick: () => this.changeTab(2),
                      },
                    ]}
                    activeStep={this.state.activeTab}
                    circleFontSize={0}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div {...classes("userFrom")}>
                  {this.state.activeTab === 0 ? (
                    <StepOne
                      submit={(values) => this.submitValues(values)}
                      stepOneData={this.state.stepOneData}
                      isEditMode={this.state.isEditMode}
                    />
                  ) : null}
                  {this.state.activeTab === 1 ? (
                    <StepTwo
                      submit={(values) => this.submitValues(values)}
                      designations={
                        this.props.designations.roles
                          ? this.props.designations.roles
                          : []
                      }
                      departments={
                        this.props.departments.departments
                          ? this.props.departments.departments
                          : []
                      }
                      roles={
                        this.props.roles.roles ? this.props.roles.roles : []
                      }
                      stepTwoData={this.state.stepTwoData}
                      isEditMode={this.state.isEditMode}
                      changeTab={(tab) => this.changeTab(tab)}
                      featureAccessRoles={this.props.featureAccessRoles.data}
                    />
                  ) : null}
                  {this.state.activeTab === 2 ? (
                    <StepThree
                      submit={(values) => this.submitValues(values)}
                      stepThreeData={this.state.stepThreeData}
                      isEditMode={this.state.isEditMode}
                      changeTab={(tab) => this.changeTab(tab)}
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
          </Card>
        )}
      </Container>
    );
  }
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateUser: adminModule.userModule.actions.updateUser,
      userRoles: userModule.actions.getUserRole,
      userDesignation: userModule.actions.getUserDesignation,
      userDepartment: userModule.actions.getUserDepartmentList,
      getUserList: userModule.actions.getUserList,
      getSupervisorLevels: supervisorModule.actions.getSupervisorLevelList,
      getSingleUser: userModule.actions.getSingleUser,
      featureAccessRole: adminModule.commonModule.actions.featureAccessRole,
      resetSingleUser: userModule.actions.resetSingleUser,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    signupUserDetails: state.signupUser,
    roles: state.userRole,
    designations: state.userDesignation,
    departments: state.userDepartment,
    userList: state.userList,
    supervisorLevels: state.supervisorLevels,
    singleUser: state.singleUser,
    featureAccessRoles: state.featureAccessRole,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(EditUsers);
