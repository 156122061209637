import React, { Component } from "react";
import { Container, Row, Col, Card, CardTitle } from "reactstrap";
import CustomTable from "../../../../components/table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import inventoryModule from "../../../../../redux/inventory";
import userModule from "../../../../../redux/user";
import bemClass from "../../../../components/ui-components/Utils/bemClass";
import Button from "../../../../components/ui-components/Button";
import moment from "moment";
import Paginator from "../../../../components/ui-components/Paginator";
import Loader from "../../../../components/ui-components/Loader";
import ReportFilterModal from "../../../../components/modals/inventory/reportFilterModal";
import itemColumns from "../../../../../enum/inventory/itemColumn";
import "./costReport.scss";

class InventoryCostReport extends Component {
  constructor(props) {
    super(props);
    const initianlColumns = itemColumns.filter((column) => {
      if (column.value === "name" || column.value === "cost") {
        column.active = true;
        return column;
      }
    });
    this.state = {
      showFilterModal: false,
      offset: 0,
      filterByType: false,
      appliedFilters: {
        columns: initianlColumns,
      },
    };
  }

  getFormattedUserList = (users) => {
    return users.map((user) => {
      return {
        value: user.userId,
        label: `${user.firstName} ${user.lastName}`,
      };
    });
  };

  getTableHeading = () => {
    return (
      <tr>
        {this.state.appliedFilters.columns.map((column, index) => {
          return (
            <th
              key={index}
              className={
                column.value === "cost" ? "InventoryCostReport__itemCost" : null
              }
            >
              {column.label}{" "}
              {column.value === "cost"
                ? `(${this.props.user.userDetails.company.currency})`
                : null}
            </th>
          );
        })}
      </tr>
    );
  };

  getTableContent = (data) => {
    return (data.records || []).map((row, key) => {
      let name = row.user ? `${row.user.firstName} ${row.user.lastName}` : `-`;
      if (!row.user) {
        row.user = {};
      }
      row.user.fullName = name;
      return (
        <tr key={key} className="value-row">
          {this.state.appliedFilters.columns.map((column, index) => {
            const columnValue = column.value.split(".").reduce(function (o, x) {
              return o[x] || "-";
            }, row);
            return (
              <td
                key={index}
                className={
                  column.value === "cost"
                    ? "InventoryCostReport__itemCost"
                    : null
                }
              >
                {columnValue}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  applyFilters = (values) => {
    const data = {
      ...(values.categoryId && { categoryId: values.categoryId.value }),
      ...(values.typeId && { typeId: values.typeId.value }),
      ...(values.occupiedBy && { occupiedBy: values.occupiedBy.value }),
      ...(values.warrantyMonth && {
        warrantyMonth: values.warrantyMonth.value,
      }),
      ...(values.fromDate && {
        fromDate: moment(values.fromDate).format("YYYY-MM-DD"),
      }),
      ...(values.toDate && {
        toDate: moment(values.toDate).format("YYYY-MM-DD"),
      }),
      ...(values.serialNumber && { serialNumber: values.serialNumber }),
      ...(values.isExpired && { isExpired: values.isExpired }),
      columns: values.columns,
      reportType: "CostReport",
      offset: 0,
    };
    this.props.getCustomReport(data);
    this.setState({ appliedFilters: data, offset: 0 });
    this.toggle();
  };

  toggle = () => {
    this.setState({ showFilterModal: false });
  };

  downloadReport = (type) => {
    let filters = this.state.appliedFilters;
    filters.documentType = type;
    filters.reportType = "CostReport";
    this.props.downloadCustomReport(filters);
  };

  componentDidMount() {
    this.props.getCustomReport({
      offset: this.state.offset,
      reportType: "CostReport",
    });
    this.props.getCategories();
    this.props.getTypes();
    this.props.getUserList();
  }

  componentWillUnmount() {
    this.props.resetReport();
  }

  render() {
    const classes = new bemClass("InventoryCostReport");
    return (
      <Container className={classes.elem("wrapper").b()}>
        {this.state.showFilterModal ? (
          <ReportFilterModal
            openModal={this.state.showFilterModal}
            toggle={this.toggle}
            title={"Edit Filters"}
            submit={this.applyFilters}
            categories={this.props.categories.data}
            users={this.props.userList.userList}
            selectedDisbaledColumns={[
              { label: "Item Name", value: "name" },
              { label: "Price", value: "cost" },
            ]}
            types={this.props.types.data}
          />
        ) : null}

        <Row>
          <Col sm={8} className="text-left">
            <h2 className="list-header-title">Cost Report</h2>
          </Col>
          <Col sm={4} className="text-right">
            <React.Fragment>
              <Button
                color="light-grey"
                onClick={() => {
                  this.downloadReport("pdf");
                }}
                className="report-action-btn"
              >
                Export Pdf
              </Button>
              <Button
                color="light-grey"
                onClick={() => {
                  this.downloadReport("csv");
                }}
                className="report-action-btn"
              >
                Export CSV
              </Button>
              <Button
                color="deep-blue"
                onClick={() => {
                  this.setState({ showFilterModal: true });
                }}
              >
                Filters
              </Button>
            </React.Fragment>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="hr"></div>
            {this.props.report.loading ? (
              <div className={classes.elem("loader").b()}>
                <Loader />
              </div>
            ) : (
              <React.Fragment>
                <CustomTable
                  content={this.getTableContent(this.props.report.data)}
                  tableHeadings={this.getTableHeading()}
                  customHeaders
                />
                <Paginator
                  style={{ padding: "10px 0" }}
                  limit={10}
                  offset={this.state.offset}
                  total={this.props.report.data.count}
                  align="center"
                  onChange={(offset) => {
                    let filters = this.state.appliedFilters;
                    filters.documentType = null;
                    filters.reportType = "CostReport";
                    filters.offset = offset;
                    this.props.getCustomReport(filters);
                    this.setState({ offset: offset });
                  }}
                />
                <Card body className={classes.elem("total-card").b()}>
                  <CardTitle>
                    <b>TOTAL INVENTORY COST</b>
                  </CardTitle>
                  {new Intl.NumberFormat("si-LK", {
                    style: "currency",
                    currency: this.props.user.userDetails.company.currency,
                  }).format(this.props.report.data.total_cost || 0)}
                </Card>
              </React.Fragment>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCustomReport: inventoryModule.reportModule.actions.getCustomReport,
      getUserList: userModule.actions.getUserList,
      getCategories: inventoryModule.categoryModule.actions.getCategories,
      getTypes: inventoryModule.typeModule.actions.getTypes,
      downloadCustomReport:
        inventoryModule.reportModule.actions.downloadCustomReport,
      resetReport: inventoryModule.reportModule.actions.resetReport,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    report: state.inventoryReport,
    categories: state.inventoryMainCategories,
    userList: state.userList,
    types: state.inventoryItemTypes,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(InventoryCostReport);
