import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import timeTrackModule from "../../../../redux/timetracking";
import { Container, Row, Col, Card, ButtonGroup } from "reactstrap";
import BEMhelper from "react-bem-helper";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowRight,
  faArrowLeft,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import NewRecordModal from "../../../components/modals/timeTracking/newRecordModal";
import DeleteModal from "../../../components/modals/common/deleteModal";
import Button from "../../../components/ui-components/Button";
import _ from "lodash";

class TimeTrackDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEntryModal: false,
      showDeleteModal: false,
      selectedDate: moment(new Date()).format("LL"),
      selectedDayName: moment(new Date()).format("dddd"),
      editMode: false,
      editObject: {},
    };
  }

  toggle = () => {
    this.setState({
      showEntryModal: false,
      editMode: false,
      editObject: {},
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  componentDidMount() {
    const data = {
      date: moment(new Date()).format("YYYY-MM-DD"),
    };
    this.props.getEntrieForDate(data);
    this.props.getProjects();
    this.props.getTasks();
  }

  returnToday = () => {
    const data = {
      date: moment(new Date()).format("YYYY-MM-DD"),
    };

    const selectedDate = moment(new Date()).format("LL");
    const selectedDayName = moment(new Date()).format("dddd");

    this.setState({
      selectedDate: selectedDate,
      selectedDayName: selectedDayName,
    });
    this.props.getEntrieForDate(data);
  };

  getCardbody = (stat) => {
    return <h6>{stat.count}</h6>;
  };

  deleteEntry = () => {
    const data = {
      formValues: {
        entryId: this.state.editObject.timeTrackEntryId,
        date: moment(this.state.selectedDate).format("YYYY-MM-DD"),
      },
      closeModal: this.closeDeleteModal,
    };
    this.props.deleteEntry(data);
  };

  addEntry = (values) => {
    const data = {
      formValues: {
        date: moment(this.state.selectedDate).format("YYYY-MM-DD"),
        description: values.notes,
        hours: values.hours,
        minutes: values.minutes,
        projectId: values.project.value,
        taskId: values.task.value,
        ...(this.state.editMode && {
          entryId: this.state.editObject.timeTrackEntryId,
        }),
      },
      closeModal: this.toggle,
    };
    if (this.state.editMode) {
      this.props.updateEntry(data);
    } else {
      this.props.createEntry(data);
    }
  };

  openNewRecordModal = () => {
    this.setState({ showEntryModal: true });
  };

  changeSelectedDate = (move) => {
    const newDate =
      move === "next"
        ? moment(this.state.selectedDate).add(1, "days").format("LL")
        : moment(this.state.selectedDate).subtract(1, "days").format("LL");
    this.setState({
      selectedDate: newDate,
      selectedDayName: moment(newDate).format("dddd"),
    });
    const data = {
      date: moment(newDate).format("YYYY-MM-DD"),
    };
    this.props.getEntrieForDate(data);
  };

  openForEdit = (entry) => {
    this.setState({
      editMode: true,
      editObject: entry,
      selectedDate: entry.date,
    });
    this.openNewRecordModal();
  };

  openForDelete = (entry) => {
    this.setState({
      editObject: entry,
      showDeleteModal: true,
      selectedDate: entry.date,
    });
  };

  calculateTotal = () => {
    let total = "00:00";
    let minutes = 0;
    let hours = 0;
    if (this.props.entries.data.length > 0) {
      _.forEach(this.props.entries.data, function (entry) {
        minutes = minutes + entry.minutes;
        hours = hours + entry.hours;
      });
    }
    if (minutes > 60) {
      const additionalHours = minutes / 60;
      const roundAdditionalHours = Math.floor(additionalHours);
      const additionalMinutes = (additionalHours - roundAdditionalHours) * 60;
      const roundAdditionalMinutes = Math.round(additionalMinutes);
      minutes = roundAdditionalMinutes;
      hours = hours + roundAdditionalHours;
    }
    total = `${hours}:${minutes}`;
    return total;
  };

  render() {
    const classes = new BEMhelper("time-tracking");
    return (
      <Container {...classes("dashboard")}>
        {this.state.showEntryModal ? (
          <NewRecordModal
            openModal={this.state.showEntryModal}
            toggle={this.toggle}
            title={"New time entry"}
            projects={this.props.projects.data}
            bodyLoading={
              this.props.tasks.loading || this.props.projects.loading
            }
            isEditMode={this.state.editMode}
            objectToEdit={this.state.editObject}
            submit={this.addEntry}
            tasks={this.props.tasks.data}
          />
        ) : null}
        {this.state.showDeleteModal ? (
          <DeleteModal
            openModal={this.state.showDeleteModal}
            body={"Are you sure you want to delete this entry?"}
            onClose={this.closeDeleteModal}
            onConfirm={this.deleteEntry}
          />
        ) : null}
        <Card className="header-line">
          <Row>
            <Col xs="10">
              <Row>
                <Col xs="4">
                  <h3>
                    {this.state.selectedDayName}, {this.state.selectedDate}
                  </h3>
                </Col>
                <Col xs="4" className="text-left">
                  <ButtonGroup>
                    <Button onClick={() => this.changeSelectedDate("prev")}>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <Button onClick={() => this.changeSelectedDate("next")}>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </ButtonGroup>
                  {moment(this.state.selectedDate).format("YYYY-MM-DD") !==
                  moment(new Date()).format("YYYY-MM-DD") ? (
                    <p
                      className="return-today"
                      onClick={() => this.returnToday()}
                    >
                      Return to today
                    </p>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col xs="2">
              <Button
                className="btn-primary"
                onClick={() => {
                  this.openNewRecordModal();
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> New Record
              </Button>
            </Col>
          </Row>
        </Card>
        <Col sm={12}>
          <div className="hr"></div>
          <Card className="details-card">
            {this.props.entries.loading ? (
              <p>Loading...</p>
            ) : this.props.entries.data.length > 0 ? (
              <React.Fragment>
                {this.props.entries.data.map((entry) => {
                  return (
                    <Row
                      className="text-left with-border"
                      key={entry.timeTrackEntryId}
                    >
                      <Col xs="9" className="entry-body">
                        <p className="project-name">
                          <b>{entry.timeTrackProject.name}</b>
                        </p>
                        <p className="entry-category">
                          {entry.timeTrackTask.name} -
                          <span className="entry-description">
                            {" "}
                            {entry.description}
                          </span>
                        </p>
                      </Col>
                      <Col xs="1" className="entry-time times">
                        <p className="time">
                          {entry.hours}:{String(entry.minutes).padStart(2, "0")}
                        </p>
                      </Col>
                      <Col xs="2" className="entry-time actions">
                        <ButtonGroup size="sm">
                          <Button
                            outline
                            color="info"
                            size="sm"
                            onClick={() => this.openForEdit(entry)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          <Button
                            outline
                            color="danger"
                            size="sm"
                            onClick={() => this.openForDelete(entry)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  );
                })}
                <Row className="text-left with-border" key={1212121212}>
                  <Col xs="9" className="total-hours-col">
                    <p className="total-hours">Total</p>
                  </Col>
                  <Col xs="1" className="entry-time times">
                    <p className="time">{this.calculateTotal()}</p>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <div className="empty-entries">
                <p className="time-quote">
                  The key is in not spending time, but in investing it.
                </p>
              </div>
            )}
          </Card>
        </Col>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProjects: timeTrackModule.projectModule.actions.getProjects,
      createEntry: timeTrackModule.entryModule.actions.createEntry,
      getEntrieForDate: timeTrackModule.entryModule.actions.getEntrieForDate,
      updateEntry: timeTrackModule.entryModule.actions.updateEntry,
      deleteEntry: timeTrackModule.entryModule.actions.deleteEntry,
      getTasks: timeTrackModule.taskModule.actions.getTasks,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    projects: state.timeTrackingProjects,
    entries: state.timeTrackingEntriesForDate,
    tasks: state.timeTrackingTasks,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(TimeTrackDashboard);
