import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../ui-components/Button";
import DatePicker from "react-datepicker";
import "./reportFilterModal.scss";
import moment from "moment";
import { toast } from "react-toastify";
import Input from "../../../inputNew";
import Select from "react-select";
import itemWarrenties from "../../../../../enum/inventory/itemWarrenty";
import itemColumns from "../../../../../enum/inventory/itemColumn";
import FormFeedBack from "../../../ui-components/FormFeedBack";
import Toggle from "../../../ui-components/Toggle";

const classes = new BEMhelper("ReportFilterModal");
const ReportFilterModal = (props) => {
  const {
    title,
    openModal,
    toggle,
    submit,
    categories,
    users,
    selectedDisbaledColumns,
    types,
  } = props;

  let modifiedItemColumns = itemColumns;
  if (selectedDisbaledColumns && selectedDisbaledColumns.length) {
    selectedDisbaledColumns.forEach((defaultColumn) => {
      modifiedItemColumns = itemColumns.map((column) => {
        if (column.value === defaultColumn.value) {
          column.disabled = true;
          column.active = true;
        }
        return column;
      });
    });
  }
  const [allColumns, setColumns] = useState(modifiedItemColumns);

  const defaultValues = {};

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch,
    register,
  } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    if (
      values.fromDate &&
      values.toDate &&
      moment(values.fromDate).isSameOrAfter(moment(values.toDate))
    ) {
      toast.error("From date cannot be greater than to date", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      const finalColumns = allColumns.filter((column) => column.active);
      values.columns = finalColumns;
      submit(values);
    }
  };

  const categoryOptions = categories.map((category) => {
    return {
      value: category.inventoryMainCategoryId,
      label: category.name,
    };
  });

  const typeOptions = (types || []).map((type) => {
    return {
      value: type.inventoryItemTypeId,
      label: type.name,
    };
  });

  const userOptions = users.map((user) => {
    return {
      value: user.userId,
      label: `${user.firstName} ${user.lastName}`,
    };
  });

  const addColumn = (selectedColumn) => {
    const allColumnsList = allColumns.map((column) => {
      if (column.value === selectedColumn.value) {
        column.active = true;
      }
      return column;
    });
    setColumns([...allColumnsList]);
  };

  const removeColumn = (selectedColumn) => {
    const allActiveColumns = allColumns.filter((column) => {
      return column.active === true;
    });
    if (allActiveColumns.length > 1) {
      const allColumnsList = allColumns.map((column) => {
        if (column.value === selectedColumn.value) {
          column.active = false;
        }
        return column;
      });
      setColumns([...allColumnsList]);
    }
  };

  const filterWatcher = watch(["fromDate", "toDate"]);

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => toggle()}
      centered={true}
      draggable={false}
      size="xl"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => toggle()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={12}>
                <Breadcrumb tag="nav" listTag="div">
                  <BreadcrumbItem active tag="span">
                    Filters
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>Category</Label>
                  <Controller
                    as={
                      <Select
                        placeholder="Select category"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: `1px solid ${
                              errors.categoryId ? "#dc3545" : "#cad2dd"
                            }`,
                          }),
                        }}
                      />
                    }
                    options={categoryOptions}
                    name="categoryId"
                    isClearable
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Type</Label>
                  <Controller
                    as={
                      <Select
                        placeholder="Select type"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: `1px solid ${
                              errors.typeId ? "#dc3545" : "#cad2dd"
                            }`,
                          }),
                        }}
                      />
                    }
                    options={typeOptions}
                    name="typeId"
                    isClearable
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Occupied By</Label>
                  <Controller
                    as={
                      <Select
                        placeholder="Select user"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: `1px solid ${
                              errors.occupiedBy ? "#dc3545" : "#cad2dd"
                            }`,
                          }),
                        }}
                      />
                    }
                    options={userOptions}
                    name="occupiedBy"
                    isClearable
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Warranty</Label>
                  <Controller
                    as={
                      <Select
                        placeholder="Select warranty"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            border: `1px solid ${
                              errors.warrantyMonth ? "#dc3545" : "#cad2dd"
                            }`,
                          }),
                        }}
                      />
                    }
                    options={itemWarrenties}
                    name="warrantyMonth"
                    isClearable
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>From</Label>
                  <Controller
                    as={
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        placeholderText={"From date"}
                        className={`form-control ${
                          errors.fromDate ? "date-error" : ""
                        }`}
                        selected={getValues().fromDate}
                        popperPlacement="top-end"
                        showMonthDropdown
                        showYearDropdown
                      />
                    }
                    valueName="selected"
                    name="fromDate"
                    control={control}
                    onChange={(date) => setValue("fromDate", date)}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Upto</Label>
                  <Controller
                    as={
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        placeholderText={"To date"}
                        className={`form-control ${
                          errors.toDate ? "date-error" : ""
                        }`}
                        selected={getValues().toDate}
                        popperPlacement="top-end"
                        showMonthDropdown
                        showYearDropdown
                      />
                    }
                    valueName="selected"
                    name="toDate"
                    control={control}
                    onChange={(date) => setValue("toDate", date)}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Serial number</Label>
                  <Input
                    name="serialNumber"
                    register={register}
                    state={errors.serialNumber ? "error" : "none"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Brand</Label>
                  <Input
                    name="brand"
                    register={register}
                    state={errors.brand ? "error" : "none"}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <FormGroup>
                  <Label>Expired</Label>
                  <Controller
                    as={<Toggle checked={false} />}
                    name="isExpired"
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Breadcrumb tag="nav" listTag="div">
                  <BreadcrumbItem active tag="span">
                    Columns
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Row>
                <Col md={12}>
                  <div {...classes("column-feedback")}>
                    <FormFeedBack
                      feedBack={
                        "Select columns which you want to be in your report"
                      }
                      feedBackType="info"
                    />
                  </div>
                </Col>
              </Row>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Available Columns</Label>
                  <br />
                  {allColumns.map((column, index) => {
                    return (
                      <Button
                        type="lined"
                        onClick={() => addColumn(column)}
                        disabled={column.active}
                        key={index}
                      >
                        {column.label}
                      </Button>
                    );
                  })}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Selected Columns</Label>
                  <br />
                  {allColumns.map((column, index) => {
                    if (column.active) {
                      return (
                        <Button
                          type="lined"
                          onClick={() => removeColumn(column)}
                          color="deep-blue"
                          disabled={column.disabled}
                          key={index}
                        >
                          {column.label}
                        </Button>
                      );
                    }
                  })}
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button submit>Apply</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ReportFilterModal;
