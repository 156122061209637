export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const ADMIN_UPDATE_USER_REQUEST = "ADMIN_UPDATE_USER_REQUEST";
export const ADMIN_UPDATE_USER_SUCCESS = "ADMIN_UPDATE_USER_SUCCESS";
export const ADMIN_UPDATE_USER_FAILURE = "ADMIN_UPDATE_USER_FAILURE";

export const ADMIN_CREATE_DESIGNATION_REQUEST =
  "ADMIN_CREATE_DESIGNATION_REQUEST";
export const ADMIN_CREATE_DESIGNATION_SUCCESS =
  "ADMIN_CREATE_DESIGNATION_SUCCESS";
export const ADMIN_CREATE_DESIGNATION_FAILURE =
  "ADMIN_CREATE_DESIGNATION_FAILURE";
