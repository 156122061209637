import * as actions from "./actionTypes";

export const getCandidates = (data) => ({
  type: actions.GET_HRM_CANDIDATES_REQUEST,
  payload: data,
});

export const createCandidate = (data) => ({
  type: actions.CREATE_HRM_CANDIDATE_REQUEST,
  payload: data,
});

export const getCandidateQuestionCategories = () => ({
  type: actions.CREATE_HRM_CANDIDATE_QUESTION_CATEGORIES_REQUEST,
});

export const getCandidate = (data) => ({
  type: actions.GET_HRM_CANDIDATE_REQUEST,
  payload: data,
});

export const createCandidateFeedback = (data) => ({
  type: actions.CREATE_HRM_CANDIDATE_FEEDBACK_REQUEST,
  payload: data,
});

export const getAllCandidateFeedback = (data) => ({
  type: actions.GET_HRM_ALL_CANDIDATE_FEEDBACK_REQUEST,
  payload: data,
});

export const updateCandidate = (data) => ({
  type: actions.UPDATE_HRM_CANDIDATE_REQUEST,
  payload: data,
});

export const deleteCandidate = (data) => ({
  type: actions.DELETE_HRM_CANDIDATE_REQUEST,
  payload: data,
});

export const createDirectCandidate = (data) => ({
  type: actions.CREATE_HRM_DIRECT_CANDIDATE_REQUEST,
  payload: data,
});

export const getInterviewInvitations = (data) => ({
  type: actions.GET_HRM_CANDIDATE_INTERVIEW_INVITATION_REQUEST,
  payload: data,
});

export const createInterviewInvitation = (data) => ({
  type: actions.CREATE_HRM_CANDIDATE_INTERVIEW_INVITATION_REQUEST,
  payload: data,
});
