import { call, put, takeLatest } from 'redux-saga/effects';
import { SUPERVISOR_LEVELS_REQUEST, SUPERVISOR_LEVELS_SUCCESS, SUPERVISOR_LEVELS_FAILURE } from '../actionTypes';
import apis from '../../../api'

export function* supervisorSaga() {
  yield takeLatest(SUPERVISOR_LEVELS_REQUEST, getSupervisorLevels);
}

function* getSupervisorLevels() {
  try {
    const supervisorLevels = yield call(apis.getSupervisorLevels);
    if (supervisorLevels.error) {
      yield put({ type: SUPERVISOR_LEVELS_FAILURE, error: supervisorLevels.body.data.error });
    } else {
      yield put({ type: SUPERVISOR_LEVELS_SUCCESS, data: supervisorLevels.body.data });
    } 
  } catch (error) {
    console.log("oops error -> ", error)
  }
}
