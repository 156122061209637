import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import inventoryModule from "../../../../redux/inventory";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import moment from "moment";
import _ from "lodash";
import userModule from "../../../../redux/user";
import bemClass from "../../../components/ui-components/Utils/bemClass";
import ToolTip from "../../../components/toolTip";
import "./itemInfo.scss";

class InventoryItemInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const classes = new bemClass("InventoryItemInfo");
    const { item } = this.props;
    return (
      <Container className={classes.elem("wrapper").b()}>
        <Row className={classes.elem("info-row").b()}>
          <Col md={3}>
            <FormGroup>
              <Label>Category</Label>
              <p>{item.inventoryMainCategory.name}</p>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Name</Label>
              <p>{item.name}</p>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Total Price</Label>
              <p>{item.cost}</p>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Condition</Label>
              <p>{item.condition}</p>
            </FormGroup>
          </Col>
        </Row>
        <Row className={classes.elem("info-row").b()}>
          <Col md={3}>
            <FormGroup>
              <Label>Quantity</Label>
              <p>{item.quantity}</p>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Available</Label>
              <p>{item.available}</p>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Item Location</Label>
              <p>{item.assetLocation || "-"}</p>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Serial Number</Label>
              <p>{item.serialNumber || "-"}</p>
            </FormGroup>
          </Col>
        </Row>
        <Row className={classes.elem("info-row").b()}>
          <Col md={3}>
            <FormGroup>
              <Label>Purchase Date</Label>
              <p>{moment(item.purchaseDate).format("YYYY-MM-DD")}</p>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Warranty</Label>
              <p>{item.warrantyMonth || "-"}</p>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Warranty Exipre Date</Label>
              <p>
                {item.warrantyExpireDate
                  ? moment(item.warrantyExpireDate).format("YYYY-MM-DD")
                  : "-"}
              </p>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Vendor</Label>
              <p>{item.vendor || "-"}</p>
            </FormGroup>
          </Col>
        </Row>
        <Row className={classes.elem("info-row").b()}>
          <Col md={3}>
            <FormGroup>
              <Label>Brand</Label>
              <p>{item.brand || "-"}</p>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Type</Label>
              <p>
                {item.inventoryItemType ? item.inventoryItemType.name : "-"}
              </p>
            </FormGroup>
          </Col>
        </Row>
        {item.files.length ? (
          <React.Fragment>
            <hr />
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Files</Label>
                  <Row>
                    {item.files.map((file, key) => {
                      return (
                        <div key={key}>
                          <div className={classes.elem("file-view-header").b()}>
                            <a target="_blank" href={file.url}>
                              View
                            </a>
                          </div>
                          <object
                            width="100"
                            height="175"
                            data={file.url}
                            id={`file-${key}`}
                          >
                            File not found.
                          </object>
                          <ToolTip
                            position="top"
                            tip={file.name}
                            rowId={`file-${key}`}
                          />
                        </div>
                      );
                    })}
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </React.Fragment>
        ) : null}
        {item.inventoryItemNotes.length ? (
          <React.Fragment>
            <hr />
            <Col md={12}>
              <FormGroup>
                <Label>Notes</Label>
                <ul>
                  {item.inventoryItemNotes.map((note, key) => {
                    return <li key={key}>{note.note}</li>;
                  })}
                </ul>
              </FormGroup>
            </Col>
          </React.Fragment>
        ) : null}
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCategories: inventoryModule.categoryModule.actions.getCategories,
      createItem: inventoryModule.itemModule.actions.createItem,
      getItems: inventoryModule.itemModule.actions.getItems,
      updateItem: inventoryModule.itemModule.actions.updateItem,
      deleteItem: inventoryModule.itemModule.actions.deleteItem,
      getUserList: userModule.actions.getUserList,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    categories: state.inventoryMainCategories,
    creating: state.inventoryCreateItem,
    items: state.inventoryItems,
    userList: state.userList,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(InventoryItemInfo);
