export const statusColor = (status) => {
  let color = "primary";
  switch (status) {
    case "Pending":
      color = "warning";
      break;
    case "Approved":
      color = "success";
      break;
    case "Rejected":
      color = "danger";
      break;
    default:
      color = "primary";
  }
  return color;
};
