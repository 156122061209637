import React from "react";
import { Container, Row, Col, FormGroup, Card, Alert } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Input from "../../components/inputNew";
import Button from "../../components/ui-components/Button";
import FormFeedBack from "../../components/ui-components/FormFeedBack";
import BEMhelper from "react-bem-helper";
import userModule from "../../../redux/user";
import "./forgotPassword.scss";

const classes = new BEMhelper("ForgotPassword");
const ForgotPassword = () => {
  const defaultValues = {};

  const { handleSubmit, register, errors } = useForm({
    defaultValues,
  });
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const details = {
      email: values.email,
    };
    dispatch(userModule.actions.forgotPassword(details));
  };

  const forgotPasswordRequest = useSelector((state) => state.forgotPassword);

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...classes("form")}>
      <Container fluid>
        <Row>
          <Col sm={12} md={{ size: 6, offset: 3 }} className="text-center">
            <div className="login-form-card">
              <h1 className="login-form-header">Forgot Password</h1>
              <Card body>
                {forgotPasswordRequest.error ? (
                  <Row>
                    <Col md={12}>
                      <Alert color="danger">
                        We couldn't find a user related to the email you
                        provided!
                      </Alert>
                    </Col>
                  </Row>
                ) : null}
                {forgotPasswordRequest.response ? (
                  <Row>
                    <Col md={12}>
                      <Alert color="success">
                        {forgotPasswordRequest.response}
                      </Alert>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Input
                        placeholder="Email"
                        name="email"
                        register={register}
                        refdetails={{
                          required: "Email required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        }}
                        state={errors.email ? "error" : "none"}
                      />
                      {errors.email ? (
                        <FormFeedBack feedBack={errors.email.message} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup {...classes("submit")}>
                      <Button submit loading={forgotPasswordRequest.loading}>
                        Submit
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default ForgotPassword;
