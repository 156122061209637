import {
  applyLeave,
  leaveBalance,
  leaveDateType,
  leaveHistory,
  processLeave,
} from "./actionCreators";
import leaveBalanceNewReducer from "./reducer/leaveBalanceNewReducer";
import applyLeaveReducer from "./reducer/applyLeaveReducer";
import leaveDateTypeReducer from "./reducer/leaveDateTypeReducer";
import leaveHistoryReducer from "./reducer/leaveHistoryReducer";
import processLeaveReducer from "./reducer/processLeaveReducer";
import { leaveSaga } from "./saga/leaveSaga";

const leaveModule = {
  actions: {
    applyLeave,
    leaveBalance,
    leaveDateType,
    leaveHistory,
    processLeave,
  },
  reducers: {
    applyLeaveReducer: applyLeaveReducer,
    leaveBalanceNewReducer: leaveBalanceNewReducer,
    leaveDateTypeReducer: leaveDateTypeReducer,
    leaveHistoryReducer: leaveHistoryReducer,
    processLeaveReducer: processLeaveReducer,
  },
  sagas: {
    leaveSaga: leaveSaga,
  },
};

export default leaveModule;
