import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { FaTrashAlt } from "react-icons/fa";
import { IconContext } from "react-icons";
import BEMhelper from "react-bem-helper";
import { useForm, Controller } from "react-hook-form";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Button from "../../../../../ui-components/Button";
import FormFeedBack from "../../../../../ui-components/FormFeedBack";
import Input from "../../../../../inputNew";
import Toggle from "../../../../../ui-components/Toggle";
import { toast } from "react-toastify";
import "./addVacancy.scss";

const classes = new BEMhelper("AddVacancyModal");
const AddVacancyModal = (props) => {
  const { title, openModal, toggle, isEditMode, objectToEdit, submit } = props;
  const [imageRemoval, removeImageJd] = useState(
    isEditMode && objectToEdit.jdImageUrl ? false : true
  );

  ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "insertTable",
        "|",
        "undo",
        "redo",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
  };

  const urgentStatus = isEditMode ? objectToEdit.isUrgent : false;
  const defaultValues = {
    name: isEditMode ? objectToEdit.name : null,
    // jdFile: isEditMode ? objectToEdit.jdImageUrl : null,
    jobDescription: isEditMode ? objectToEdit.jobDescription : null,
    urgent: urgentStatus,
  };

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    if (isEditMode && !values.jdFile && !imageRemoval) {
      values.jdImageUrl = objectToEdit.jdImageUrl;
    }
    if (!values.jdFile.length && !values.jobDescription) {
      toast.error("Please add either job description text or file", {
        position: toast.POSITION.TOP_BOTTOM,
      });
    } else {
      submit(values);
    }
  };

  const removeImage = () => {
    removeImageJd(true);
  };

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => toggle()}
      centered={true}
      draggable={false}
      size="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => toggle()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Vacancy Name</Label>
                  <Input
                    name="name"
                    register={register}
                    refdetails={{
                      required: "Vacancy name required",
                    }}
                    state={errors.name ? "error" : "none"}
                  />
                  {errors.name ? (
                    <FormFeedBack feedBack={errors.name.message} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>
                    Job Description File{" "}
                    {isEditMode && objectToEdit.jdImageUrl && !imageRemoval ? (
                      <IconContext.Provider
                        value={{
                          color: "red",
                          className: "AddVacancyModal__delete-jdImage",
                          size: "12px",
                        }}
                      >
                        <FaTrashAlt onClick={() => removeImage()} />
                      </IconContext.Provider>
                    ) : null}
                  </Label>
                  {isEditMode && objectToEdit.jdImageUrl && !imageRemoval ? (
                    <div {...classes("jd-image")}>
                      <a
                        href={objectToEdit.jdImageUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {objectToEdit.jdImageUrl}
                      </a>
                    </div>
                  ) : (
                    <Input
                      type="file"
                      name="jdFile"
                      register={register}
                      state={errors.lastName ? "error" : "none"}
                      className="jd-upload"
                    />
                  )}
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Urgent</Label>
                  <Controller
                    as={<Toggle checked={urgentStatus} />}
                    name="urgent"
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Job Description</Label>
                  <Controller
                    name="jobDescription"
                    render={({ onChange }) => {
                      return (
                        <CKEditor
                          editor={ClassicEditor}
                          data={isEditMode ? objectToEdit.jobDescription : null}
                          onChange={(value, editor) => {
                            onChange(editor.getData());
                          }}
                        />
                      );
                    }}
                    control={control}
                    rules={{
                      maxLength: {
                        value: 10000,
                        message: "Maximun length is 10000",
                      },
                    }}
                  />
                  {errors.jobDescription ? (
                    <FormFeedBack feedBack={errors.jobDescription.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button submit>Submit</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddVacancyModal;
