import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  GET_HRM_AGENTS_REQUEST,
  GET_HRM_AGENTS_SUCCESS,
  GET_HRM_AGENTS_FAILURE,
  CREATE_HRM_AGENTS_REQUEST,
  CREATE_HRM_AGENTS_SUCCESS,
  CREATE_HRM_AGENTS_FAILURE,
  UPDATE_HRM_AGENTS_REQUEST,
  UPDATE_HRM_AGENTS_SUCCESS,
  UPDATE_HRM_AGENTS_FAILURE,
  AGENT_LOGIN_REQUEST,
  AGENT_LOGIN_SUCCESS,
  AGENT_LOGIN_FAILURE,
  GET_VACANCIES_AGENT_REQUEST,
  GET_VACANCIES_AGENT_SUCCESS,
  GET_VACANCIES_AGENT_FAILURE,
  CREATE_HRM_CANDIDATE_AGENT_REQUEST,
  CREATE_HRM_CANDIDATE_AGENTS_SUCCESS,
  CREATE_HRM_CANDIDATE_AGENTS_FAILURE,
  AGENT_DELETE_AGREEMENT_FILE_REQUEST,
  AGENT_DELETE_AGREEMENT_FILE_SUCCESS,
  AGENT_DELETE_AGREEMENT_FILE_FAILURE,
} from "../actionTypes";
import apis from "../../../../api";
import { toast } from "react-toastify";
import _ from "lodash";
import { push } from "connected-react-router";

export function* agentSaga() {
  yield takeLatest(GET_HRM_AGENTS_REQUEST, getAgents);
  yield takeLatest(CREATE_HRM_AGENTS_REQUEST, createAgent);
  yield takeLatest(UPDATE_HRM_AGENTS_REQUEST, updateAgent);
  yield takeLatest(AGENT_LOGIN_REQUEST, agentLogin);
  yield takeLatest(GET_VACANCIES_AGENT_REQUEST, getVacancies);
  yield takeLatest(CREATE_HRM_CANDIDATE_AGENT_REQUEST, createCandidate);
  yield takeLatest(
    AGENT_DELETE_AGREEMENT_FILE_REQUEST,
    deleteAgentAgreementFile
  );
}

function* deleteAgentAgreementFile(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const itemFile = yield call(
      apis.hrmApis.deleteAgentAgreementFile,
      loggedUser.userDetails.auth.accessToken,
      details.payload
    );
    if (itemFile.error) {
      yield put({
        type: AGENT_DELETE_AGREEMENT_FILE_FAILURE,
        error: itemFile.body.data.error,
      });
    } else {
      yield put({
        type: AGENT_DELETE_AGREEMENT_FILE_SUCCESS,
        data: itemFile.body.data,
      });
      toast.success("Agreement removed successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createCandidate(details) {
  try {
    const loggedUser = yield select((state) => state.hrmAgentLogin);
    const candidate = yield call(
      apis.hrmApis.createAgentCandidate,
      loggedUser.data.auth.accessToken,
      details.payload.formValues
    );
    if (candidate.error) {
      yield put({
        type: CREATE_HRM_CANDIDATE_AGENTS_FAILURE,
        error: candidate.body.data.error,
      });
    } else {
      toast.success("Thank you! We received your candidate details.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      yield put({
        type: CREATE_HRM_CANDIDATE_AGENTS_SUCCESS,
        error: candidate.body.data.error,
      });
      details.payload.restForm({ vacancy: null });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getVacancies() {
  try {
    const loggedUser = yield select((state) => state.hrmAgentLogin);
    const vacancies = yield call(
      apis.hrmApis.getAgentVacancies,
      loggedUser.data.auth.accessToken
    );
    if (vacancies.error) {
      yield put({
        type: GET_VACANCIES_AGENT_FAILURE,
        error: vacancies.body.data.error,
      });
    } else {
      yield put({
        type: GET_VACANCIES_AGENT_SUCCESS,
        data: vacancies.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* agentLogin(details) {
  try {
    const agent = yield call(apis.hrmApis.agentLogin, details.payload);
    if (agent.error) {
      yield put({ type: AGENT_LOGIN_FAILURE, error: agent.body.data.error });
    } else {
      yield put({ type: AGENT_LOGIN_SUCCESS, data: agent.body.data });
      yield put(push("/agent/addCandidate"));
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* updateAgent(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const agent = yield call(
      apis.hrmApis.updateAgent,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (agent.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: UPDATE_HRM_AGENTS_FAILURE,
        error: agent.body.data.error,
      });
      if (Array.isArray(agent.body.data.error)) {
        errorMessage = agent.body.data.error[0].msg;
      }
      if (_.isString(agent.body.data.error)) {
        errorMessage = agent.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({
        type: UPDATE_HRM_AGENTS_SUCCESS,
        data: agent.body.data,
      });
      yield put({
        type: GET_HRM_AGENTS_REQUEST,
      });
      toast.success("Agent updated", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* createAgent(details) {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const agent = yield call(
      apis.hrmApis.createAgent,
      loggedUser.userDetails.auth.accessToken,
      details.payload.formValues
    );
    if (agent.error) {
      let errorMessage = "oopz. Something went wrong!";
      yield put({
        type: CREATE_HRM_AGENTS_FAILURE,
        error: agent.body.data.error,
      });
      if (Array.isArray(agent.body.data.error)) {
        errorMessage = agent.body.data.error[0].msg;
      }
      if (_.isString(agent.body.data.error)) {
        errorMessage = agent.body.data.error;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      yield put({
        type: CREATE_HRM_AGENTS_SUCCESS,
        data: agent.body.data,
      });
      yield put({
        type: GET_HRM_AGENTS_REQUEST,
      });
      toast.success("Agent created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      details.payload.closeModal();
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}

function* getAgents() {
  try {
    const loggedUser = yield select((state) => state.loggedUser);
    const agents = yield call(
      apis.hrmApis.getAgents,
      loggedUser.userDetails.auth.accessToken
    );
    if (agents.error) {
      yield put({
        type: GET_HRM_AGENTS_FAILURE,
        error: agents.body.data.error,
      });
    } else {
      yield put({
        type: GET_HRM_AGENTS_SUCCESS,
        data: agents.body.data,
      });
    }
  } catch (error) {
    console.log("oops error -> ", error);
  }
}
