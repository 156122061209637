import {
  createItem,
  getItems,
  updateItem,
  deleteItem,
  deleteItemFile,
  createItemsBulk,
  swopUserItems,
} from "./actionCreators";
import { itemSaga } from "./saga/itemSaga";
import allItemsReducer from "./reducer/allItemsReducer";

const itemModule = {
  actions: {
    createItem,
    getItems,
    updateItem,
    deleteItem,
    deleteItemFile,
    createItemsBulk,
    swopUserItems,
  },
  reducers: {
    allItemsReducer: allItemsReducer,
  },
  sagas: {
    itemSaga: itemSaga,
  },
};

export default itemModule;
