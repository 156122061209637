import * as actions from "./actionTypes";

export const getSurveys = (data) => ({
  type: actions.GET_HRM_PERFORMANCE_SURVEY_REQUEST,
  payload: data,
});

export const createSurvey = (data) => ({
  type: actions.CREATE_HRM_PERFORMANCE_SURVEY_REQUEST,
  payload: data,
});

export const getSurveyInfo = (data) => ({
  type: actions.GET_HRM_PERFORMANCE_SURVEY_INFO_REQUEST,
  payload: data,
});

export const resetSurveys = () => ({
  type: actions.RESET_HRM_PERFORMANCE_SURVEY_REQUEST,
});

export const getSurveyDetails = (data) => ({
  type: actions.GET_HRM_PERFORMANCE_SURVEY_DETAILS_REQUEST,
  payload: data,
});

export const submitFeedback = (data) => ({
  type: actions.SUBMIT_HRM_PERFORMANCE_SURVEY_FEEDBACK_REQUEST,
  payload: data,
});

export const getSignleFeedbackInfo = (data) => ({
  type: actions.GET_HRM_PERFORMANCE_SIGNLE_SURVEY_INFO_REQUEST,
  payload: data,
});

export const getSurveyReport = (data) => ({
  type: actions.GET_HRM_PERFORMANCE_REPORT_REQUEST,
  payload: data,
});

export const getSurveyPaper = (data) => ({
  type: actions.GET_HRM_PERFORMANCE_SURVEY_PAPER_REQUEST,
  payload: data,
});

export const archiveSurvey = (data) => ({
  type: actions.ARCHIVE_HRM_PERFORMANCE_SURVEY_REQUEST,
  payload: data,
});
