import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import { useForm } from "react-hook-form";
import Button from "../../../../../ui-components/Button";
import FormFeedBack from "../../../../../ui-components/FormFeedBack";
import Input from "../../../../../inputNew";
import "./addCandidateDirect.scss";

const classes = new BEMhelper("AddCandidateDirectModal");
const AddCandidateDirectModal = (props) => {
  const { title, openModal, toggle, submit, companyCurrency } = props;

  const defaultValues = {};

  const { handleSubmit, register, errors } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    submit(values);
  };

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => toggle()}
      centered={true}
      draggable={false}
      size="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => toggle()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Candidate Name</Label>
                  <Input
                    name="candidateName"
                    register={register}
                    refdetails={{
                      required: "Candidate name required",
                    }}
                    state={errors.candidateName ? "error" : "none"}
                  />
                  {errors.candidateName ? (
                    <FormFeedBack feedBack={errors.candidateName.message} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Notice Period</Label>
                  <Input
                    name="noticePeriod"
                    register={register}
                    // refdetails={{
                    //   required: "Candidate notice period required",
                    // }}
                    state={errors.noticePeriod ? "error" : "none"}
                  />
                  {errors.noticePeriod ? (
                    <FormFeedBack feedBack={errors.noticePeriod.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    name="email"
                    register={register}
                    refdetails={{
                      required: "Email required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    }}
                    state={errors.email ? "error" : "none"}
                  />
                  {errors.email ? (
                    <FormFeedBack feedBack={errors.email.message} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Phone</Label>
                  <Input
                    name="phone"
                    register={register}
                    state={errors.phone ? "error" : "none"}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Expected Salary ({companyCurrency})</Label>
                  <Input
                    type="number"
                    name="expectedSalary"
                    register={register}
                    state={errors.expectedSalary ? "error" : "none"}
                    refdetails={{
                      max: {
                        value: 10000000,
                        message: "Maximum salary limit is 10000000",
                      },
                    }}
                  />
                  {errors.expectedSalary ? (
                    <FormFeedBack feedBack={errors.expectedSalary.message} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Candidate Resume</Label>
                  <Input
                    type="file"
                    accept="application/pdf"
                    name="resume"
                    register={register}
                    refdetails={{
                      required: "Candidate resume required",
                    }}
                    state={errors.resume ? "error" : "none"}
                    className="resume-upload"
                  />
                  {errors.resume ? (
                    <FormFeedBack feedBack={errors.resume.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button submit>Submit</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddCandidateDirectModal;
