import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin/user`;

export const createUser = async (token, userDetails) => {
  return axios({
    method: "POST",
    url: `${apiUrl}`,
    headers: {
      "x-access-token": token,
    },
    data: userDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};

export const updateUser = async (token, userDetails) => {
  return axios({
    method: "PUT",
    url: `${apiUrl}/${userDetails.userId}`,
    headers: {
      "x-access-token": token,
    },
    data: userDetails,
  })
    .then(function (response) {
      return { error: false, body: response.data };
    })
    .catch(function (error) {
      return { error: true, body: error.response };
    });
};
