import React, { Component } from "react";
import { Container, Row, Col, ButtonGroup } from "reactstrap";
import CustomTable from "../../../../components/table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import inventoryModule from "../../../../../redux/inventory";
import bemClass from "../../../../components/ui-components/Utils/bemClass";
import Button from "../../../../components/ui-components/Button";
import Paginator from "../../../../components/ui-components/Paginator";
import Loader from "../../../../components/ui-components/Loader";
import "./signOff.scss";

class InventorySignOffReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
    };
  }

  getTableHeading = () => {
    return (
      <tr>
        <th>Employee Name</th>
        <th>Designation</th>
        <th>Department</th>
        <th>Number Of Items</th>
        <th>{""}</th>
      </tr>
    );
  };

  getTableContent = (data) => {
    return (data.records || []).map((row, key) => {
      return (
        <tr key={key} className="value-row">
          <td>
            {row.user.firstName} {row.user.lastName}
          </td>
          <td>{row.user.designation.name}</td>
          <td>{row.user.department.name}</td>
          <td>{row.item_count}</td>
          <td className="InventorySignOffReport__item-actions">
            <ButtonGroup size="sm">
              <Button
                color="blue"
                onClick={() => {
                  this.props.history.push(
                    `/inventory/my-items?uid=${row.user.userId}`
                  );
                }}
                size="sm"
              >
                View Items
              </Button>
              <Button
                color="bright-blue"
                onClick={() => {
                  this.downloadReport("pdf", row.user.userId);
                }}
                size="sm"
              >
                Download Sign Off Document
              </Button>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };

  downloadReport = (type, userId) => {
    const data = {
      reportType: "SignOffReport",
      documentType: type,
      userId: userId,
    };
    this.props.downloadCustomReport(data);
  };

  componentDidMount() {
    this.props.getCustomReport({
      offset: this.state.offset,
      reportType: "SignOffReport",
    });
  }

  componentWillUnmount() {
    this.props.resetReport();
  }

  render() {
    const classes = new bemClass("InventorySignOffReport");
    return (
      <Container className={classes.elem("wrapper").b()}>
        <Row>
          <Col sm={8} className="text-left">
            <h2 className="list-header-title">Sign Off Report</h2>
          </Col>
          <Col sm={4} className="text-right"></Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="hr"></div>
            {this.props.report.loading ? (
              <div className={classes.elem("loader").b()}>
                <Loader />
              </div>
            ) : (
              <React.Fragment>
                <CustomTable
                  content={this.getTableContent(this.props.report.data)}
                  tableHeadings={this.getTableHeading()}
                  customHeaders
                />
                <Paginator
                  style={{ padding: "10px 0" }}
                  limit={10}
                  offset={this.state.offset}
                  total={this.props.report.data.count}
                  align="center"
                  onChange={(offset) => {
                    const data = {
                      reportType: "SignOffReport",
                      offset: offset,
                    };
                    this.props.getCustomReport(data);
                    this.setState({ offset: offset });
                  }}
                />
              </React.Fragment>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCustomReport: inventoryModule.reportModule.actions.getCustomReport,
      downloadCustomReport:
        inventoryModule.reportModule.actions.downloadCustomReport,
      resetReport: inventoryModule.reportModule.actions.resetReport,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    report: state.inventoryReport,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(InventorySignOffReport);
