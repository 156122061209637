import * as actions from "./actionTypes";

export const claimType = () => ({
  type: actions.CLAIM_TYPE_REQUEST,
});

export const applyClaim = (data) => ({
  type: actions.APPLY_CLAIM_REQUEST,
  payload: data,
});

export const claimHistory = (data) => ({
  type: actions.CLAIM_HISTORY_REQUEST,
  payload: data,
});
