import {
  getCandidates,
  createCandidate,
  getCandidateQuestionCategories,
  getCandidate,
  createCandidateFeedback,
  getAllCandidateFeedback,
  updateCandidate,
  deleteCandidate,
  createDirectCandidate,
  getInterviewInvitations,
  createInterviewInvitation,
} from "./actionCreators";
import { candidateSaga } from "./saga/candidateSaga";
import allCandidatesReducer from "./reducer/allCandidatesReducer";
import candidateQuestionCategoriesReducer from "./reducer/candidateQuestionCategoriesReducer";
import candidateReducer from "./reducer/candidateReducer";
import allCandidateFeedbackReducer from "./reducer/allCandidateFeedbackReducer";
import allInterviewInvitationReducer from "./reducer/allInterviewInvitationReducer";

const candidateModule = {
  actions: {
    getCandidates,
    createCandidate,
    getCandidateQuestionCategories,
    getCandidate,
    createCandidateFeedback,
    getAllCandidateFeedback,
    updateCandidate,
    deleteCandidate,
    createDirectCandidate,
    getInterviewInvitations,
    createInterviewInvitation,
  },
  reducers: {
    allCandidatesReducer: allCandidatesReducer,
    candidateQuestionCategoriesReducer: candidateQuestionCategoriesReducer,
    candidateReducer: candidateReducer,
    allCandidateFeedbackReducer: allCandidateFeedbackReducer,
    allInterviewInvitationReducer: allInterviewInvitationReducer,
  },
  sagas: {
    candidateSaga: candidateSaga,
  },
};

export default candidateModule;
