import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import BEMhelper from "react-bem-helper";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../../../ui-components/Button";
import FormFeedBack from "../../../../../ui-components/FormFeedBack";
import Input from "../../../../../inputNew";
import Toggle from "../../../../../ui-components/Toggle";
import { FaTrashAlt } from "react-icons/fa";
import { IconContext } from "react-icons";
import { useDispatch } from "react-redux";
import agentModule from "../../../../../../../redux/hrm/agent";
import "./addAgent.scss";

const classes = new BEMhelper("AddAgentModal");
const AddAgentModal = (props) => {
  const dispatch = useDispatch();
  const { title, openModal, toggle, isEditMode, objectToEdit, submit } = props;
  const activeStatus = isEditMode ? objectToEdit.active : false;
  const defaultValues = {
    companyName: isEditMode ? objectToEdit.companyName : null,
    agentName: isEditMode ? objectToEdit.agentName : null,
    email: isEditMode ? objectToEdit.email : null,
    password: isEditMode ? "Find if you can" : null,
    mobileNumber: isEditMode ? objectToEdit.phoneNumber : null,
    landNumber: isEditMode ? objectToEdit.landNumber : null,
    active: activeStatus,
  };

  const { handleSubmit, register, errors, control, watch, setValue } = useForm({
    defaultValues,
  });

  const onSubmit = (values) => {
    submit(values);
  };

  return (
    <Modal
      {...classes("modal")}
      isOpen={openModal}
      toggle={() => toggle()}
      centered={true}
      draggable={false}
      backdrop="static"
      size="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={() => toggle()} className="modal-header">
          {title}
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Company Name</Label>
                  <Input
                    name="companyName"
                    register={register}
                    refdetails={{
                      required: "Company name required",
                    }}
                    state={errors.companyName ? "error" : "none"}
                  />
                  {errors.companyName ? (
                    <FormFeedBack feedBack={errors.companyName.message} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Agent Name</Label>
                  <Input
                    name="agentName"
                    register={register}
                    refdetails={{
                      required: "Agent name required",
                    }}
                    state={errors.agentName ? "error" : "none"}
                  />
                  {errors.agentName ? (
                    <FormFeedBack feedBack={errors.agentName.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    name="email"
                    register={register}
                    refdetails={{
                      required: "Email required",
                    }}
                    state={errors.email ? "error" : "none"}
                  />
                  {errors.email ? (
                    <FormFeedBack feedBack={errors.email.message} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    type="password"
                    name="password"
                    register={register}
                    refdetails={{
                      required: "Password required",
                    }}
                    state={errors.password ? "error" : "none"}
                  />
                  {errors.password ? (
                    <FormFeedBack feedBack={errors.password.message} />
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label>Mobile Number</Label>
                  <Input
                    name="mobileNumber"
                    register={register}
                    refdetails={{
                      required: "Mobile number required",
                    }}
                    state={errors.mobileNumber ? "error" : "none"}
                  />
                  {errors.mobileNumber ? (
                    <FormFeedBack feedBack={errors.mobileNumber.message} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label>Land Number</Label>
                  <Input
                    name="landNumber"
                    register={register}
                    state={errors.landNumber ? "error" : "none"}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Active</Label>
                  <Controller
                    as={<Toggle checked={activeStatus} />}
                    name="active"
                    control={control}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label>Agreement</Label>
                  {isEditMode && objectToEdit.agreement ? (
                    <div {...classes("agreement-file")}>
                      <a href={objectToEdit.agreement.url} target="_blank">
                        {objectToEdit.agreement.name}
                      </a>
                      <IconContext.Provider
                        value={{
                          color: "red",
                          className: "AddAgentModal__delete-agreement",
                          size: "12px",
                        }}
                      >
                        <FaTrashAlt
                          onClick={() => {
                            const data = {
                              agentId: objectToEdit.hrmAgentId,
                              fileName: objectToEdit.agreement.name,
                            };
                            dispatch(
                              agentModule.actions.deleteAgreementFile(data)
                            );
                            objectToEdit.agreement = null;
                          }}
                        />
                      </IconContext.Provider>
                    </div>
                  ) : (
                    <Input
                      type="file"
                      accept="application/pdf"
                      name="agreement"
                      register={register}
                      state={errors.agreement ? "error" : "none"}
                      className="resume-upload"
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button submit>Submit</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddAgentModal;
