import { getDashboardData } from "./actionCreators";
import { dashboardSaga } from "./saga/dashboardSaga";
import dashboardDataReducer from "./reducer/dashboardDataReducer";

const dashboardModule = {
  actions: {
    getDashboardData,
  },
  reducers: {
    dashboardDataReducer: dashboardDataReducer,
  },
  sagas: {
    dashboardSaga: dashboardSaga,
  },
};

export default dashboardModule;
